// @ts-nocheck FE-XXXX Problems with types inheritance from UpdateFinancialRow
import FlyBottomPanelBar from 'bundles/Settings/components/REport/CategoriesList/PathBar/FlyBottomPanelBar';
import FlyBottomPanelContainer from 'bundles/Settings/components/REport/CategoriesList/PathBar/FlyBottomPanelContainer';
import { SearchableTree } from 'bundles/Settings/components/REport/CategoriesList/SearchableTree/SearchableTree';
import { DialogProps, useModal } from '@/shared/lib/hooks/useModal';
import { mapListToIds } from '@/shared/lib/listHelpers';
import { TreeData, transformTree } from 'lib/treeHelpers';
import pluralize from 'pluralize';
import { useState } from 'react';
import { DatumNode, TreeViewProps } from 'stories/TreeView/TreeView';
import { Button, IconButton, Modal, TreeView } from 'stories/index';
import {
  useLazyGetApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdFinancialCategoriesQuery,
  usePutApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdFinancialCategoriesMutation,
} from '@/entities/reconcile/forecasting/api/reconcileForecastingEntitiesEnchancedApi';

const CategoryModal = <N extends DatumNode>({
  onClose,
  onSubmit,
  initialCategories,
  ...treeViewProps
}: DialogProps<number[]> &
  TreeViewProps<N> & {
    initialCategories: { id: number }[];
  }) => {
  const [selected, setSelected] = useState<{ id: number }[]>(
    initialCategories || [],
  );

  return (
    <Modal
      toggle={onClose}
      size="huge"
      classes={{
        body: '!p-0 grid grid-cols-[300px_1fr]',
      }}
      header={
        <div className="flex flex-col">
          <h6 className="header6-bold">Set category</h6>
        </div>
      }
    >
      <SearchableTree
        data={treeViewProps.data}
        selectedListIds={mapListToIds(selected)}
        onSelect={(item) => {
          setSelected((prev) => {
            if (prev.find((i) => i.id === item.id)) {
              return prev.filter((i) => i.id !== item.id);
            }

            return [...prev, item];
          });
        }}
      />
      <TreeView<N>
        showMultipleRootsParent
        multipleRootsParentName="Categories"
        expandOnSelection
        everyNodeWithCheckbox
        selected={selected}
        onSelectedChange={(s) => {
          setSelected((prev) => {
            if (prev.find((v) => v.id === s.id)) {
              return prev.filter((v) => v.id !== s.id);
            }

            return [...prev, s];
          });
        }}
        {...treeViewProps}
      />
      {selected.length > 0 && (
        <FlyBottomPanelContainer>
          <FlyBottomPanelBar
            onClose={() => {
              setSelected([]);
            }}
          >
            <span className="mr-tw-1 text-neutral-400">Selected:</span>
            <span className="text-neutral-000">
              {selected.length} {pluralize('Categories', selected.length)}
            </span>
          </FlyBottomPanelBar>
          <Button
            variant="success"
            size="s"
            onClick={() => onSubmit?.(mapListToIds(selected))}
          >
            Set Category
          </Button>
        </FlyBottomPanelContainer>
      )}
    </Modal>
  );
};

export function UpdateForecastingFinancialRow({
  forecastId,
  budgetId,
}: {
  forecastId: string;
  budgetId: string;
}) {
  const [getCategories, { isFetching }] =
    useLazyGetApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdFinancialCategoriesQuery();
  const { openModal } = useModal();

  const [updateForecastingFinancialsCategories] =
    usePutApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdFinancialCategoriesMutation();

  const handleOpenFinancialCategoriesModal = async () => {
    const data = await getCategories({
      forecastId,
      budgetId,
    }).unwrap();

    if (data == null) {
      return;
    }

    const trees = data.tree.map((tree) =>
      transformTree(tree, (c) => ({
        ...c,
        name: c.code,
        title: c.code,
        locked: c.classificationMismatch,
      })),
    );

    const initialCategoriesSet = new Set<{ id: number }>();

    TreeData.deepFirst(data.tree, (i) => {
      if (!i.budgetFinancialCategory) {
        return false;
      }

      initialCategoriesSet.add({ id: i.id });

      return true;
    });

    const res = await openModal(CategoryModal, {
      data: trees,
      initialCategories: [...initialCategoriesSet],
    });

    if (res == null) return;

    await updateForecastingFinancialsCategories({
      forecastId,
      budgetId,
      body: {
        financial_category_ids: res,
      },
    });
  };

  return (
    <div className="flex w-full items-center justify-between gap-tw-4">
      Financials Categories
      <IconButton
        onClick={handleOpenFinancialCategoriesModal}
        isLoading={isFetching}
        iconName="settings"
      />
    </div>
  );
}
