import { createMapByKey } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/utils';
import { KpiCardWidgetSection } from '@/bundles/Shared/widgets/dashboard/widgets/kpiCard/model';
import { cn } from '@/shared/lib/css/cn';
import { getNumberFormatterByDisplayOptions } from '@/shared/lib/formatting/table';
import { useIsTextTruncated } from '@/shared/lib/hooks/useIsTextTruncated';
import Tooltip from '@/stories/Tooltip/Tooltip';
import VerticalSeparator from 'bundles/Shared/components/VerticalSeparator/VerticalSeparator';
import { KpiCardWidgetDto } from 'bundles/Shared/shared/api/dashboardsGeneratedApi';
import {
  WidgetProps,
  type WidgetViewMode,
} from 'bundles/Shared/widgets/dashboard/widgets/model';
import { isEmpty } from 'lodash-es';
import React, { CSSProperties, Fragment, useRef } from 'react';
import SkeletonBlock from 'stories/ProjectCard/SkeletonBlock';
import { TitledCard } from 'stories/TitledCard/TitledCard';
import {
  KPI_CARD_MAX_WIDTH,
  KPI_CARD_MAX_WIDTH_VARIANTS,
  KpiCardMaxWidthVariants,
} from './constants';
import { WidgetTextRenderer } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/renderers/TextCellRenderer';

const KpiWrapper = ({ children }: React.PropsWithChildren) => (
  <div className="flex flex-col items-center gap-tw-2">{children}</div>
);

const KpiCardWidgetPlaceholder = () => (
  <KpiWrapper>
    <SkeletonBlock className="h-[30px] w-[80px]" />
    <SkeletonBlock className="h-[20px] w-[40px]" />
  </KpiWrapper>
);

const KpiCardWidgetEmpty = () => (
  <KpiWrapper>
    <span className="header3-regular text-neutral-400">Value</span>
    <span className="inline-semibold text-neutral-400">Label</span>
  </KpiWrapper>
);

const KpiCardWidgetWrapper = ({
  children,
  mode,
  className,
  widgetSection,
  ...props
}: React.PropsWithChildren<
  Pick<
    WidgetProps<KpiCardWidgetDto, KpiCardWidgetSection>,
    'mode' | 'className' | 'widgetSection'
  > & { style?: CSSProperties }
>) => (
  <TitledCard
    {...props}
    className={cn(
      'h-full max-h-[200px] min-h-[144px] px-tw-4 2xl:px-tw-8',
      mode === 'pdf' && 'border border-solid border-neutral-200 shadow-none',
      className,
    )}
    classes={{
      children: 'flex flex-nowrap gap-tw-2 2xl:gap-tw-4',
    }}
    title={widgetSection.title}
  >
    {children}
  </TitledCard>
);

const KpiCardWidgetLabelTooltip = (props: {
  label: string;
  mode: WidgetViewMode;
}) => {
  const { label } = props;

  const nodeSpanRef = useRef<HTMLSpanElement>(null);
  const isTextTruncated = useIsTextTruncated(nodeSpanRef, label);

  return (
    <>
      <span
        ref={nodeSpanRef}
        className={cn(
          'inline-semibold text-center text-neutral-700',
          props.mode !== 'pdf' && 'line-clamp-2',
        )}
      >
        {label}
      </span>
      <Tooltip
        reference={nodeSpanRef}
        mainText={label}
        disabled={!isTextTruncated}
        maxWidth="max-content"
      />
    </>
  );
};

export function KpiCardWidget(
  props: WidgetProps<KpiCardWidgetDto, KpiCardWidgetSection>,
) {
  const { data, widgetSection, isLoading, className, mode } = props;
  const kpisVizConfigMap = createMapByKey(
    widgetSection.widgetConfig.viz_config.kpis,
  );

  if (isLoading) {
    return (
      <KpiCardWidgetWrapper
        mode={mode}
        className={className}
        widgetSection={widgetSection}
      >
        <KpiCardWidgetPlaceholder />
      </KpiCardWidgetWrapper>
    );
  }

  if (isEmpty(data?.kpis) || !data) {
    return (
      <KpiCardWidgetWrapper
        mode={mode}
        className={className}
        widgetSection={widgetSection}
      >
        <KpiCardWidgetEmpty />
      </KpiCardWidgetWrapper>
    );
  }

  const kpisCount = Math.min(
    data?.kpis.length ?? 1,
    KPI_CARD_MAX_WIDTH,
  ) as KpiCardMaxWidthVariants;

  const renderKpi = (
    kpi: KpiCardWidgetDto['kpis'][number],
    index: number,
    arr: KpiCardWidgetDto['kpis'][number][],
  ) => {
    const renderComponent = () => {
      const kpiVizConfig = kpisVizConfigMap.get(String(kpi.key));

      if (kpiVizConfig == null) return null;

      if (kpiVizConfig.cell_renderer != null) {
        return (
          <WidgetTextRenderer
            {...kpiVizConfig.cell_renderer.params}
            history={kpi.history}
            value={kpi.value}
          />
        );
      }

      const Component = getNumberFormatterByDisplayOptions(
        kpiVizConfig.value_display_options,
      );

      if (Component == null) return null;

      return (
        <Component
          classes={{
            allParts: 'header3-regular',
            value: 'text-neutral-850',
          }}
          value={kpi.value}
        />
      );
    };

    return (
      <Fragment key={kpi.key}>
        <div
          className={cn(
            KPI_CARD_MAX_WIDTH_VARIANTS[kpisCount],
            'w-full flex-grow overflow-hidden',
          )}
        >
          <div className="header3-regular flex w-full flex-col items-center gap-tw-2 text-neutral-850">
            {renderComponent()}
            <KpiCardWidgetLabelTooltip mode={mode} label={kpi.label} />
          </div>
        </div>
        {arr.length > 0 && index < arr.length - 1 && (
          <VerticalSeparator size="1.25rem" className="shrink-0" />
        )}
      </Fragment>
    );
  };

  return (
    <KpiCardWidgetWrapper
      mode={mode}
      className={className}
      widgetSection={widgetSection}
    >
      {data.kpis.map(renderKpi)}
    </KpiCardWidgetWrapper>
  );
}
