import React, { FC, ReactNode } from 'react';
import Icon from 'stories/Icon/Icon';
import './multiline-alert.scss';
import { cn } from '@/shared/lib/css/cn';
import { IconsId } from 'types/sre-icons';

export type TStatusAlert =
  | 'attention'
  | 'danger'
  | 'success'
  | 'info'
  | 'secondary';

export const MultilineAlertContainer = ({
  className,
  children,
  flexibleWidth,
  status = 'attention',
  classes,
}: React.PropsWithChildren<PropsWithClassName> & {
  flexibleWidth?: boolean;
  status?: TStatusAlert;
  classes?: {
    body?: string;
  };
}) => (
  <div
    className={cn(
      'sre-multialert',
      `sre-multialert_${status}`,
      flexibleWidth && 'sre-multialert_flexible',
      className,
    )}
  >
    <div className={cn('sre-multialert__body', classes?.body)}>{children}</div>
  </div>
);

interface IMultilineAlertProps
  extends Omit<
    React.ComponentProps<typeof MultilineAlertContainer>,
    'children'
  > {
  message?: string | ReactNode;
  title?: string;
  className?: string;
  icon?: IconsId;
  actions?: ReactNode;
}

export const MultilineAlert: FC<IMultilineAlertProps> = ({
  message,
  title,
  icon,
  actions,
  ...props
}) => (
  <MultilineAlertContainer {...props}>
    <Icon iconName={icon ?? 'info'} className={title ? 'pt-xxs' : ''} />
    <div className="flex w-full items-center justify-between">
      <div>
        {title && (
          <div className={cn('inline-semibold', message && 'mb-xs')}>
            {title}
          </div>
        )}
        <div>{message}</div>
      </div>
      <div>{actions}</div>
    </div>
  </MultilineAlertContainer>
);
