import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import styles from './SkeletonBlock.module.scss';

type Props = PropsWithClassName;

function SkeletonBlock({ className }: Props) {
  return <div className={cn(styles.block, 'rounded-lg', className)} />;
}

export default SkeletonBlock;
