import React, { useMemo } from 'react';
import { Button, IconButton, Modal, SearchInput } from '@/stories';
import {
  FormulaInUse,
  useReportFormulasQuery,
} from 'bundles/Shared/entities/formula';
import { useItemsFilterByText } from '@/shared/lib/hooks/useItemsFilterByText';
import { partition, startCase } from 'lodash-es';
import pluralize from 'pluralize';
import { DialogProps, useModal } from '@/shared/lib/hooks/useModal';
import { cn } from '@/shared/lib/css/cn';
import { useDeleteApiSettingsReportFormulasBulkDestroyMutation } from 'bundles/Shared/shared/api/settingsReportFormulasEnhanced';
import { UNTAGGED_FORMULA_TAG } from 'bundles/Shared/entities/formula/config';
import { canSyncTagsWithCustomers } from '@/widgets/report/manageTags';
import {
  EntityTag,
  isEntitySystem,
  isTagSystem,
  TAG_ENTITIES,
  TagEntity,
  TaggedEntity,
  useEntityTagsQuery,
} from 'bundles/Shared/entities/tag';
import { useReportManualVariableQuery } from '@/entities/report/manualVariable';
import {
  CreateTagButton,
  DeleteTagButton,
  EditTagButton,
} from '@/features/report/tag/crud';
import { SyncWithClientsButton } from 'bundles/Shared/features/syncWithUniform';

type Props = DialogProps & {
  entity: TagEntity;
};

const TagCardList = ({ children }: React.PropsWithChildren) => (
  <div className="flex flex-col gap overflow-hidden !rounded-lg bg-neutral-100">
    {children}
  </div>
);

const TagUsedEntities = ({
  count,
  entity,
}: {
  count: number;
  entity: TagEntity;
}) => {
  return (
    <div className="flex items-center gap-tw-0.5">
      {count > 0 && <FormulaInUse.UsedSvg used />}
      <span
        className={cn(
          'secondary-semibold text-neutral-700',
          count === 0 && 'text-neutral-550',
        )}
      >
        {count === 0 && `No ${startCase(entity)}s`}
        {count > 0 && count}
        {count > 0 && pluralize(` ${startCase(entity)}`, count)}
      </span>
    </div>
  );
};

const TagCard = ({
  tagName,
  className,
  actions,
  children,
}: React.PropsWithChildren<
  {
    tagName: string;
    actions?: React.ReactNode;
  } & PropsWithClassName
>) => {
  return (
    <div
      className={cn(
        'group/item flex flex-col gap-tw-2 bg-neutral-000 p-tw-4 pl-tw-6 hover:bg-neutral-050',
        className,
      )}
    >
      <div className="flex justify-between">
        <span className="body-semibold text-neutral-800">{tagName}</span>
        {actions && (
          <div className="invisible flex gap-tw-2 group-hover/item:!visible">
            {actions}
          </div>
        )}
      </div>

      {children}
    </div>
  );
};

const TagGroup = ({
  label,
  count,
  entity,
  children,
  header,
}: React.PropsWithChildren<{
  label: string;
  count: number;
  entity: TagEntity;
  header?: React.ReactNode;
}>) => (
  <div className="flex flex-col gap-tw-6">
    <div className="flex items-center justify-between">
      <div className="flex flex-col gap-tw-1">
        <span className="body-semibold text-neutral-700">{label}</span>
        <span className="secondary-regular text-neutral-550">
          {count} {pluralize(startCase(entity), count)}
        </span>
      </div>
      {header}
    </div>
    {children}
  </div>
);

export function ManageTagsModal({ onClose, entity }: Props) {
  const formulasData = useReportFormulasQuery({
    skip: entity === TAG_ENTITIES.MANUAL_VARIABLE,
  });
  const manualVariablesData = useReportManualVariableQuery(
    {},
    {
      skip: entity === TAG_ENTITIES.FORMULA,
    },
  );
  const entitiesTagPartition =
    entity === TAG_ENTITIES.FORMULA
      ? formulasData.tagFormulasPartition
      : manualVariablesData.tagVariablesPartition;
  const entities =
    entity === TAG_ENTITIES.FORMULA
      ? formulasData.formulas
      : manualVariablesData.manualVariables;
  const [taggedEntities, untaggedEntities] = entitiesTagPartition;
  const { tags, meta } = useEntityTagsQuery(
    entity,
    {},
    {
      // refetch on mount to check for sync status
      refetchOnMountOrArgChange: true,
    },
  );
  const [clearEntities, { isLoading: clearIsLoading }] =
    useDeleteApiSettingsReportFormulasBulkDestroyMutation();

  const { inputProps, filteredItems: filteredTags } = useItemsFilterByText(
    tags,
    'name',
  );
  const [systemEntities, userEntity] = partition(entities, (e) =>
    isEntitySystem(e),
  );
  const tagsWithEntities = useMemo<
    (EntityTag & {
      entities: {
        id: string;
        name: string;
      }[];
    })[]
  >(
    () =>
      filteredTags.map((tag) => ({
        ...tag,
        entities: taggedEntities
          .filter((e: TaggedEntity) => e.tags.some((t) => t.id === tag.id))
          .map((e) => ({
            id: e.id,
            name: 'name' in e ? e.name : e.label,
          })),
      })),
    [tags, taggedEntities, filteredTags],
  );
  const [systemTags, userTags] = partition(tagsWithEntities, (tag) =>
    isTagSystem(tag),
  );

  const ClearButton = ({ tagId }: { tagId: EntityTag['id'] }) => {
    if (entity === TAG_ENTITIES.MANUAL_VARIABLE) {
      // TODO: Implement clear for manual variables
      return null;
    }
    return (
      <Button
        disabled={clearIsLoading}
        size="xs"
        className="invisible group-hover/item:!visible"
        iconName="broom"
        onClick={() =>
          clearEntities({
            tagIds: tagId !== UNTAGGED_FORMULA_TAG.id ? [tagId] : undefined,
            untagged: tagId === UNTAGGED_FORMULA_TAG.id ? true : undefined,
          })
        }
        variant="secondary"
      >
        Clear
      </Button>
    );
  };

  return (
    <Modal header="Tags" toggle={onClose} size="500">
      <div className="flex flex-col gap-tw-6">
        <div className="flex items-center justify-between">
          <SearchInput
            className={cn(!canSyncTagsWithCustomers() && 'w-full')}
            placeholder="Search by Tag Name"
            size="s"
            {...inputProps}
          />
          <SyncWithClientsButton entity={entity} meta={meta} />
        </div>

        {systemTags.length > 0 && (
          <TagGroup
            entity={entity}
            label="System"
            count={systemEntities.length}
            header={<CreateTagButton system entity={entity} />}
          >
            <TagCardList>
              {systemTags.map((tag) => (
                <TagCard
                  actions={
                    <>
                      <EditTagButton entity={entity} tag={tag} />
                      <DeleteTagButton entity={entity} tag={tag} />
                    </>
                  }
                  className="group"
                  key={tag.id}
                  tagName={tag.name}
                >
                  <TagUsedEntities
                    entity={entity}
                    count={tag.entities.length}
                  />
                </TagCard>
              ))}
            </TagCardList>
          </TagGroup>
        )}
        <TagGroup
          header={<CreateTagButton entity={entity} />}
          entity={entity}
          label="Other"
          count={userEntity.length}
        >
          <TagCardList>
            <TagCard tagName={UNTAGGED_FORMULA_TAG.name}>
              <div className="flex items-center gap-tw-2">
                <TagUsedEntities
                  entity={entity}
                  count={untaggedEntities.length}
                />
                {untaggedEntities.length > 0 && (
                  <ClearButton tagId={UNTAGGED_FORMULA_TAG.id} />
                )}
              </div>
            </TagCard>
            {userTags.map((tag) => (
              <TagCard
                actions={
                  <>
                    <EditTagButton entity={entity} tag={tag} />
                    <DeleteTagButton entity={entity} tag={tag} />
                  </>
                }
                key={tag.id}
                tagName={tag.name}
              >
                <div className="flex items-center gap-tw-2 ">
                  <TagUsedEntities
                    entity={entity}
                    count={tag.entities.length}
                  />
                  {tag.entities.length > 0 && <ClearButton tagId={tag.id} />}
                  <div className="grow" />
                </div>
              </TagCard>
            ))}
          </TagCardList>
        </TagGroup>
      </div>
    </Modal>
  );
}

export const ManageTagsButton = ({ entity }: { entity: TagEntity }) => {
  const { openModal } = useModal();
  return (
    <IconButton
      iconName="edit"
      size="l"
      onClick={() =>
        openModal(ManageTagsModal, {
          entity,
        })
      }
    />
  );
};
