import { AgGridReact } from 'ag-grid-react';
import { sanitizeFileName } from 'lib/uploadFiles';
import { isDividerColumn } from 'bundles/Shared/widgets/dashboard/widgets/kpiTable';
import React, { RefObject, useCallback, useMemo } from 'react';
import { WidgetViewMode } from 'bundles/Shared/widgets/dashboard/widgets/model';
import { ExportButton } from '@/shared/ui/ExportButton';
import { ObjectDashboardWidgetContext } from 'bundles/Shared/widgets/dashboard/widgetsHelpers';
import {
  DateWidgetStates,
  PeriodTypeWidgetState,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import {
  CellClassParams,
  CellClassRules,
  ExcelExportParams,
  ExcelStyle,
  ICellRendererParams,
} from 'ag-grid-community';
import { isTotalRow } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/ColumnDefsBuilder';
import { reckonerPeriod } from '@/shared/lib/reckoner/period';
import { joinWithDash } from '@/shared/lib/string';
// @ts-ignore we have no declaration file for this pkg
import ahoy from '@symmetre-inc/ahoy';
import {
  formatDate,
  formatDateByDisplayOptions,
} from '@/shared/lib/formatting/dates';
import { THEME_COLORS } from 'tailwind';
import { convertColorToHex } from '@/shared/lib/colors';
import { DateSSN } from '@/shared/lib/dateSSN/ssn';
import { FormattedColDef } from '@/shared/lib/formatting/table';
import { AUTO_GROUP_COLUMN_KEY } from '@/lib/ag-grid/constants';

export const TABLE_EXCEL_COLORS = {
  header: convertColorToHex(THEME_COLORS['new-primary']['090']),
  total: convertColorToHex(THEME_COLORS.green.dark[3]),
};

export const HEADER_COLORS = {
  font: {
    bold: true,
    color: '#FFFFFF',
  },
  interior: {
    color: TABLE_EXCEL_COLORS.header,
    pattern: 'Solid',
    patternColor: undefined,
  },
} as const satisfies Readonly<Pick<ExcelStyle, 'interior' | 'font'>>;

const SUPPORTED_EXCEL_IDENTATION_LEVEL = 7;

export const INDENTATION_EXCEL_STYLES: ExcelStyle[] = Array.from(
  { length: SUPPORTED_EXCEL_IDENTATION_LEVEL },
  (_, i) => ({
    id: `indent-${i + 1}`,
    alignment: {
      indent: i + 1,
    },
    // note, dataType: 'String' required to ensure that numeric values aren't right-aligned
    dataType: 'String',
  }),
);

export const TABLE_EXCEL_STYLES = [
  {
    id: 'header',
    alignment: {
      vertical: 'Center',
      wrapText: true,
    },

    ...HEADER_COLORS,
  },
  {
    id: 'totalBackground',
    alignment: {
      horizontal: 'Right',
    },
    font: {
      color: '#FFFFFF',
      bold: true,
    },
    interior: {
      color: TABLE_EXCEL_COLORS.total,
      pattern: 'Solid',
    },
  },
  {
    id: 'headerGroup',
    alignment: {
      horizontal: 'Center',
    },
    ...HEADER_COLORS,
  },
] as const satisfies readonly ExcelStyle[];
export type ExcelStyleId = (typeof TABLE_EXCEL_STYLES)[number]['id'];
export const TABLE_EXCEL_STYLE_IDS = Object.fromEntries(
  TABLE_EXCEL_STYLES.map((s) => [s.id, s.id]),
) as Record<ExcelStyleId, ExcelStyleId>;

const getIndentClass = (params: CellClassParams): string[] | string => {
  const { node } = params;

  return joinWithDash(['indent', String(node.level)]);
};

export const buildExcelStyleId = ({
  id,
  type = 'cell',
}: {
  id: string;
  type?: 'cell' | 'header' | 'headerGroup' | 'row';
}) => joinWithDash(['excel', type, id]);

const resolveReckonerPeriodTitle = (state: PeriodTypeWidgetState) => {
  if (state?.period == null) return '';
  const formatMonth = (date: string) => formatDate(date, 'MMM-YY');
  if (
    reckonerPeriod.isCalendarPeriod(state.period) ||
    reckonerPeriod.isTrailingPeriod(state.period)
  ) {
    return formatMonth(state.period.date);
  }
  if (reckonerPeriod.isToDatePeriod(state.period)) {
    const { last_date, type } = state.period;

    return `${formatMonth(last_date)} ${type.toUpperCase()}`;
  }
  if (reckonerPeriod.isRangePeriod(state.period)) {
    return `${formatMonth(state.period.from_date)} ${formatMonth(
      state.period.to_date,
    )}`;
  }
  if (reckonerPeriod.isTrailingAliasPeriod(state.period)) {
    return `${formatMonth(
      state.period.date,
    )} ${state.period.type.toUpperCase()}`;
  }
  return '';
};

const FILE_NAME_DELIMITER = ' - ';

export const resolveExportFileNamePrefix = (
  context: Pick<ObjectDashboardWidgetContext, 'selectedAsset'>,
) => {
  return [context?.selectedAsset?.name]
    .filter(Boolean)
    .join(FILE_NAME_DELIMITER);
};

export const resolveExportSheetAndFileName = ({
  state,
  widgetTitle,
  ...args
}: {
  widgetTitle: string;
  state?: DateWidgetStates;
  fileName?: string;
}) => {
  const resolveDateTitlePart = () => {
    if (!state || 'period' in state) {
      return '';
    }
    if ('date' in state && !('dateFrom' in state)) {
      return state.date;
    }
    if ('dateFrom' in state && 'dateTo' in state) {
      return `${state.dateFrom} ${state.dateTo}`;
    }
    return '';
  };
  const resolvePeriodTitlePart = () => {
    if (!state) {
      return '';
    }
    if ('period' in state) {
      return resolveReckonerPeriodTitle(state);
    }
    if ('periodType' in state) {
      return state.periodType;
    }
    return '';
  };
  const fileName = [
    args?.fileName,
    widgetTitle,
    resolveDateTitlePart(),
    resolvePeriodTitlePart(),
  ]
    .join(' ')
    .trim();
  const sheetName = [resolveDateTitlePart(), resolvePeriodTitlePart()]
    .filter(Boolean)
    .join(' ');

  return {
    fileName,
    sheetName,
  };
};

export const useTableWidgetExportFeature = (
  {
    mode,
    gridRef,
    widgetId,
    state,
    ...options
  }: {
    mode: WidgetViewMode;
    gridRef: RefObject<AgGridReact>;
    widgetId: string;
    widgetTitle: string;
    isTotalRow?: (row: ICellRendererParams) => boolean;
    state?: DateWidgetStates;
  },
  params?: ExcelExportParams,
) => {
  const isRowTotal = options.isTotalRow ?? isTotalRow;
  const handleExport = useCallback(() => {
    if (!gridRef.current) {
      return;
    }

    ahoy.track('Widget Export', {
      widget_section_id: widgetId,
    });

    const { fileName, sheetName } = resolveExportSheetAndFileName({
      state,
      widgetTitle: options.widgetTitle,
      fileName: params?.fileName,
    });
    gridRef.current.api.exportDataAsExcel({
      columnKeys: gridRef?.current
        ? [
            ...gridRef.current.columnApi
              .getAllDisplayedColumns()
              .filter((c) => !isDividerColumn(c))
              .map((c) => c.getColId()),
          ]
        : [],
      ...params,
      sheetName,
      fileName: sanitizeFileName(fileName ?? 'Export'),
      processHeaderCallback: (headerParams) => {
        const colDef = headerParams.column.getColDef();
        const headerComponentParams =
          typeof colDef.headerComponentParams === 'function'
            ? colDef.headerComponentParams(headerParams)
            : colDef.headerComponentParams;

        return [colDef.headerName, headerComponentParams?.subHeaderName]
          .filter(Boolean)
          .join('\r\n');
      },
      processCellCallback: (cellParams) => {
        const colDef = cellParams.column.getColDef() as FormattedColDef;
        if (colDef.type === 'date' && cellParams.value) {
          return formatDateByDisplayOptions(
            new DateSSN(cellParams.value).toDate(),
            colDef.displayOptions,
          );
        }
        if (colDef.colId === AUTO_GROUP_COLUMN_KEY) {
          const cellRendererParams =
            typeof colDef.cellRendererParams === 'function'
              ? colDef.cellRendererParams(cellParams)
              : colDef.cellRendererParams;

          return [cellParams.value, cellRendererParams?.subLabel]
            .filter(Boolean)
            .join(' ');
        }
        // temporary skip array values (sparklines)
        if (Array.isArray(cellParams.value)) {
          return '';
        }
        return cellParams.value;
      },
    });
  }, [gridRef.current, params, state, options.widgetTitle]);

  const ExportButtonComponent = useCallback(() => {
    return mode !== 'pdf' && <ExportButton onClick={handleExport} />;
  }, [mode, handleExport]);

  const autoGroupColumnDef = useMemo(
    () => ({
      cellClass: getIndentClass,
    }),
    [],
  );

  return {
    ExportButtonComponent,
    excelStyles: TABLE_EXCEL_STYLES,
    cellClass: ({ colDef }: CellClassParams) =>
      colDef.colId && buildExcelStyleId({ id: colDef.colId }),
    cellClassRules: {
      [TABLE_EXCEL_STYLE_IDS.totalBackground]: (cellParams) =>
        isRowTotal(cellParams),
    } as CellClassRules,
    autoGroupColumnDef,
  };
};

export const useObjectDashboardWidgetTableExport = (
  args: Parameters<typeof useTableWidgetExportFeature>[0] & {
    context: Pick<ObjectDashboardWidgetContext, 'selectedAsset'>;
  },
  params?: ExcelExportParams,
) => {
  const fileName = resolveExportFileNamePrefix(args.context);
  return useTableWidgetExportFeature(args, {
    fileName,
    ...params,
  });
};
