import { ERPSystemIcon } from '@/entities/erpsystem/ui/ERPSystemIcon';
import { SOURCE_TYPE_SETTINGS } from 'lib/sourceType';
import { ERPSystem } from '@/entities/erpsystem';

type IERPSystem = {
  system: ERPSystem;
  typeThemeColor?: 'mono' | 'colorful';
} & PropsWithClassName;

export const getNameERPSystem = (system: ERPSystem): string =>
  SOURCE_TYPE_SETTINGS[system]?.title;

export const ERPSystemLabel = ({ system }: IERPSystem) => (
  <div className="group flex shrink-0 items-center gap-tw-2 [&>svg]:shrink-0">
    <ERPSystemIcon
      system={system}
      className="transition-filter grayscale duration-300 group-hover:grayscale-0"
    />
    <div className="text-neutral-900">{getNameERPSystem(system)}</div>
  </div>
);
