import {
  useGetApiSettingsReportThirdPartyExtractionsConnectionsByIdQuery,
  usePutApiSettingsReportThirdPartyExtractionsConnectionsByIdMutation,
} from '@/bundles/Settings/components/REport/Connections/api/settingsReportConnectionsEnhancedApi';
import { IConnection } from '@/bundles/Shared/entities/connection/model';
import { useParams } from '@reach/router';
import { SharedTextArea } from 'bundles/Shared/components/GroupForm/FormItems/new/SharedTextArea';
import { useEffect, useState } from 'react';
import { Button, IconButton } from 'stories/index';

const hasNote = (connection?: IConnection) => {
  return connection?.accessNote;
};

export const ConnectionAccessNoteCard = () => {
  const [showTextArea, setShowTextArea] = useState<boolean>(false);
  const params = useParams();

  const { data: connectionData } =
    useGetApiSettingsReportThirdPartyExtractionsConnectionsByIdQuery({
      id: Number(params.connectionId),
    });

  const connection = connectionData?.item as IConnection | undefined;

  const [valueTextArea, setValueTextArea] = useState<string>(
    connection?.accessNote ?? '',
  );

  const [updateConnection, { isLoading }] =
    usePutApiSettingsReportThirdPartyExtractionsConnectionsByIdMutation();

  useEffect(() => {
    setValueTextArea(connection?.accessNote ?? '');
  }, [connection?.accessNote]);

  const handleNote = async () => {
    const res = await updateConnection({
      id: Number(params.connectionId),
      body: {
        access_note: valueTextArea,
      },
    });

    if ('data' in res && res.data) {
      setShowTextArea(false);
    }
  };

  return (
    <div className="flex flex-col gap-tw-4 rounded-2xl bg-tw-white p-tw-4">
      <div className="flex items-center justify-between">
        <div className="font-semibold text-neutral-800">
          Encrypted Access Note
        </div>
        {showTextArea && (
          <Button
            variant="secondary"
            size="xs"
            onClick={handleNote}
            disabled={isLoading}
          >
            {hasNote(connection)
              ? 'Edit Encrypted Access Note'
              : 'Add Encrypted Access Note'}
          </Button>
        )}
        {!showTextArea && (
          <IconButton
            iconName={hasNote(connection) ? 'editAlt' : 'addSmall'}
            onClick={() => setShowTextArea(true)}
          />
        )}
      </div>
      {showTextArea && (
        <SharedTextArea<string>
          defaultValue={connection?.accessNote}
          value={valueTextArea}
          onChange={(e) => setValueTextArea(e.target.value)}
          disabled={isLoading}
        />
      )}
      {!showTextArea && hasNote(connection) && (
        <div className="flex flex-col gap-tw-2">
          <div className="inline-regular text-neutral-900">
            {connection?.accessNote}
          </div>
        </div>
      )}
      {!showTextArea && !hasNote(connection) && (
        <div className="inline-regular">No Encrypted Access Note</div>
      )}
    </div>
  );
};
