import React, { useMemo, useState } from 'react';
import { Button, Checkbox, Modal, SearchInput, ThinTabGroup } from '@/stories';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { IThinTabItem } from 'stories/Tabs/ThinTabGroup/ThinTabGroup';
import { includesInLowerCase } from '@/shared/lib/listHelpers';
import { orderBy } from 'lodash-es';
import {
  mapAssetToObject,
  mapSegmentToObject,
  ReportDashboardAsset,
} from 'bundles/Shared/entities/dashboard';
import type { IAsset } from '@/types/Asset';
import type { SymmetreObjectType } from '@/shared/types/model';
import NoDataOverlay from '@/bundles/Shared/components/NoDataOverlay';
import type { ReportSegment } from '@/bundles/Shared/entities/segment/model';
import { joinWithDash } from '@/shared/lib/string';

export type ReportDashboardFilterObject = {
  id: IAsset['id'];
  type: SymmetreObjectType;
};

type Props = DialogProps<
  ReportDashboardFilterObject[] | ReportDashboardAsset['id']
> & {
  assets: Pick<ReportDashboardAsset, 'id' | 'name'>[];
  segments?: Pick<ReportSegment, 'id' | 'title'>[];
  isMultiple?: boolean;
  initialSelectedObjects?: ReportDashboardFilterObject[];
  objectTypes?: SymmetreObjectType[];
};

const TABS = [
  {
    label: 'Assets',
    id: 'asset',
  },
  {
    label: 'Funds',
    id: 'fund',
  },
  {
    label: 'Segments',
    id: 'segment',
  },
] as const satisfies readonly IThinTabItem<SymmetreObjectType>[];

export function ReportDashboardFilterByObjectModal({
  onClose,
  onSubmit,
  assets,
  segments = [],
  isMultiple,
  initialSelectedObjects,
  objectTypes = ['asset'],
}: Props) {
  const [searchText, setSearchText] = useState('');
  const [selectedObjects, setSelectedObjects] = useState<
    ReportDashboardFilterObject[]
  >(initialSelectedObjects ?? []);
  const [selectedTab, setSelectedTab] = useState<
    IThinTabItem<SymmetreObjectType>
  >(TABS[0]);

  const options = useMemo(() => {
    const getOptions = () => {
      switch (selectedTab.id) {
        case 'asset':
          return assets.map(mapAssetToObject);
        case 'segment':
          return segments.map(mapSegmentToObject);
        default:
          return [];
      }
    };

    const sortedOptions = orderBy(getOptions(), 'name');

    return sortedOptions.filter((option) =>
      includesInLowerCase(option.name, searchText),
    );
  }, [assets, segments, selectedTab, searchText]);

  const handleMultyChange = (option: (typeof options)[number]) => {
    setSelectedObjects((prev) =>
      selectedObjects.find(
        (selected) =>
          selected.type === option.type && selected.id === option.id,
      )
        ? prev.filter(
            (selected) =>
              selected.type !== option.type || selected.id !== option.id,
          )
        : [
            ...prev,
            {
              id: option.id,
              type: option.type,
            },
          ],
    );
  };

  return (
    <Modal
      toggle={onClose}
      header="Object Selection"
      bodyHeight={600}
      classes={{
        body: 'bg-neutral-050',
        footer: 'flex items-center justify-between',
      }}
      actions={
        isMultiple && (
          <>
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="success"
              onClick={() => onSubmit?.(selectedObjects)}
            >
              Select
            </Button>
          </>
        )
      }
    >
      <div className="flex h-full flex-col gap-tw-4">
        <div className="flex flex-col gap-tw-3">
          <SearchInput
            placeholder="Search by Object name"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <ThinTabGroup
            selectedItem={selectedTab}
            onSelectedItemChange={setSelectedTab}
            items={TABS.map((tab) =>
              objectTypes.includes(tab.id) ? tab : { ...tab, disabled: true },
            )}
            classes={{
              itemContainer: 'grow',
              item: 'w-full justify-center',
            }}
          />
        </div>
        <div className="flex flex-col gap overflow-auto rounded-xl bg-neutral-150">
          {options.map((o) => (
            <label
              key={joinWithDash([String(o.id), o.type])}
              className="group/item flex w-full cursor-pointer items-center justify-between bg-neutral-000 p-tw-4"
            >
              <span className="body-semibold text-neutral-800">{o.name}</span>
              {isMultiple ? (
                <Checkbox
                  noInnerLabel
                  onChange={() => handleMultyChange(o)}
                  checked={Boolean(
                    selectedObjects.find(
                      (selected) =>
                        selected.type === o.type && selected.id === o.id,
                    ),
                  )}
                />
              ) : (
                <Button
                  className="invisible group-hover/item:!visible"
                  variant="secondary"
                  size="xs"
                  onClick={() => onSubmit?.(o.id)}
                >
                  Select
                </Button>
              )}
            </label>
          ))}
          {options.length === 0 && <NoDataOverlay title="No Objects" />}
        </div>
      </div>
    </Modal>
  );
}
