import { getTextClassByAlignment } from '@/bundles/Shared/widgets/dashboard/widgets/media/lib';
import type { AssetsReportBuilderMediaWidgetDto } from '@/entities/report/reportBuilder/api/coreAssetsReportBuilderReportsGeneratedApi';
import { cn } from '@/shared/lib/css/cn';
import { isEmpty } from 'lodash-es';

export const MediaWidgetFigure = ({
  caption,
  children,
  className,
  styles,
}: {
  caption: AssetsReportBuilderMediaWidgetDto['items'][number]['caption'];
} & LayoutProps &
  PropsWithStyles) => {
  return (
    <figure
      style={styles}
      className={cn(!isEmpty(caption?.text) && 'mb-tw-8', className)}
    >
      {children}
      <figcaption
        className={cn(
          'text-neutral-500',
          getTextClassByAlignment(caption?.alignment ?? 'left'),
        )}
      >
        {caption?.text}
      </figcaption>
    </figure>
  );
};
