import { transformPeriodShiftDtoToForm } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/config';
import { transformEmptyObjectToUndefined } from '@/shared/lib/yup';
import {
  ADJUSTMENT_EXPRESSION_SCHEMA,
  PERIOD_SHIFT_SCHEMA,
  TOTAL_CALCULATION_STRATEGIES,
} from 'bundles/Shared/widgets/dashboard/widgets/common/config';
import {
  NUMERIC_COLUMN_SETTINGS_FORM_SCHEMA,
  TEXT_COLUMN_SETTINGS_FORM_SCHEMA,
  transformColumnSettingsToForm,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/form';
import { TableVizConfigColumn } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import {
  KPI_TABLE_WIDGET_COLUMN_TYPES,
  KpiTableWidgetConfigColumn,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiTable/model';
import { ListOption } from 'stories/Checkbox/CheckList';
import * as yup from 'yup';

const KPI_TABLE_WIDGET_CONFIG_BASE_COLUMN_SCHEMA = yup.object().shape({
  key: yup.number(),
  label: yup.string().required(),
  expression: yup.string().required(),
  type: yup
    .string()
    .oneOf(Object.values(KPI_TABLE_WIDGET_COLUMN_TYPES))
    .required(),
  period_shift: PERIOD_SHIFT_SCHEMA.nullable()
    .optional()
    .transform(transformEmptyObjectToUndefined)
    .default(undefined),
});

export const KPI_TABLE_WIDGET_CONFIG_NUMERIC_COLUMN_SCHEMA =
  KPI_TABLE_WIDGET_CONFIG_BASE_COLUMN_SCHEMA.concat(
    NUMERIC_COLUMN_SETTINGS_FORM_SCHEMA,
  ).shape({
    adjustment: ADJUSTMENT_EXPRESSION_SCHEMA.nullable().optional(),
    total_calculation_strategy: yup
      .string()
      .oneOf(Object.values(TOTAL_CALCULATION_STRATEGIES))
      .nullable()
      .optional()
      .default(undefined),
  });

export const KPI_TABLE_WIDGET_CONFIG_TEXT_COLUMN_SCHEMA =
  KPI_TABLE_WIDGET_CONFIG_BASE_COLUMN_SCHEMA.concat(
    TEXT_COLUMN_SETTINGS_FORM_SCHEMA,
  );

export type KpiTableWidgetConfigNumericColumnForm = yup.InferType<
  typeof KPI_TABLE_WIDGET_CONFIG_NUMERIC_COLUMN_SCHEMA
>;

export type KpiTableWidgetConfigTextColumnForm = yup.InferType<
  typeof KPI_TABLE_WIDGET_CONFIG_TEXT_COLUMN_SCHEMA
>;

export const DEFAULT_KPI_TABLE_WIDGET_CONFIG_NUMERIC_COLUMN: Partial<KpiTableWidgetConfigNumericColumnForm> =
  {
    type: 'numeric',
    value_display_options: {
      kilo_formatting: false,
      type: 'number',
      precision: 2,
    },
    adjustment: null,
    period_shift: {
      key: 'weeks',
      value: 0,
    },
  };

export const DEFAULT_KPI_TABLE_WIDGET_CONFIG_TEXT_COLUMN: Partial<KpiTableWidgetConfigTextColumnForm> =
  {
    type: 'text',
    value_display_options: {
      kilo_formatting: false,
      type: 'text',
      precision: 0,
    },
    period_shift: {
      key: 'weeks',
      value: 0,
    },
    total_calculation_strategy: 'none',
    text_display_strategy: 'text_default',
  };

// month and custom
export const DATE_PICKER_SETTINGS_OPTIONS = [
  {
    value: 'month',
    label: 'Month',
  },
  {
    value: 'week',
    label: 'Week',
  },
] as const satisfies readonly ListOption[];

export const transformColumnConfigToForm = (
  columnConfig: KpiTableWidgetConfigColumn,
  columnSettings: TableVizConfigColumn,
): KpiTableWidgetConfigNumericColumnForm => {
  return {
    key: columnConfig.key,
    label: columnConfig.label,
    type: columnConfig.type,
    expression: columnConfig.expression,
    adjustment: columnConfig.adjustment_expression,
    period_shift: transformPeriodShiftDtoToForm(columnConfig.period_shift),
    total_calculation_strategy: columnConfig.total_calculation_strategy,
    ...transformColumnSettingsToForm(columnSettings),
  };
};
