import { FC, PropsWithChildren, useEffect } from 'react';
import { ShownInfoLine } from '@/bundles/Shared/components/Table/pagination/TablePagination';
import { DashboardLayout } from '@/bundles/Shared/components/layouts/dashboard/DashboardLayout';
import { useForecastByName } from '@/entities/reconcile/forecasting/features/api/forecast/useForecastByName';
import {
  useGetLegalEntityCodeFromURL,
  useGetForecastNameFromURL,
  useGetYearFromURL,
} from '@/entities/reconcile/forecasting/lib';
import { cn } from '@/shared/lib/css/cn';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { LinkWrapper } from '@/shared/ui/LinkWrapper';
import { Icon, ProjectCard } from '@/stories';
import SkeletonBlock from '@/stories/ProjectCard/SkeletonBlock';
import { RouteComponentProps, useNavigate } from '@reach/router';
import NoDataOverlay from 'bundles/Shared/components/NoDataOverlay';
import ListNavLayout from 'bundles/Shared/components/layouts/screenWithListNavigationLayout/ScreenWithListNavigationLayout';
import { range } from 'lodash-es';
import { useNavigateToFirst } from '@/shared/lib/hooks/useNavigateToFirst';
import { useForecastBudgets } from '@/entities/reconcile/forecasting/features/api/forecast/useForecastBudgets';
import { LEGAL_ENTITY_STATUS_MAP } from '@/bundles/REconcile/underwritting/UnderwritingBudgetPage';
import { useScrollToElement } from '@/shared/lib/hooks/useScrollToElement';

const SELECTED_BUDGET_DATA_ATTR = 'data-selected-budget';

const ForecastingBudgetsPage: FC<RouteComponentProps & PropsWithChildren> = ({
  children,
}) => {
  const forecastName = useGetForecastNameFromURL();
  const year = useGetYearFromURL();
  const {
    forecast,
    isLoading: isForecastLoading,
    isFetching: isForecastFetching,
  } = useForecastByName(forecastName);
  const navigate = useNavigate();

  const { budgets, budgetsCount, isLoading, isFetching } = useForecastBudgets(
    forecast?.id,
    Number(year),
  );

  const routePathText = `REconcile • Forecasting • ${forecast?.label ?? ''}`;

  const activeLegalEntityCode = useGetLegalEntityCodeFromURL();

  const navigateToMainForecastPage = () => {
    if (!forecastName) return;

    const parentPath = generateUrl(
      ROUTES_ROOT.reconcile.forecasting.forecast.fullPath,
      {
        pathParams: {
          forecastName,
        },
      },
    );

    navigate(parentPath);
  };

  useNavigateToFirst({
    items: budgets,
    matchParamKey: 'legalEntityCode',
    path: ROUTES_ROOT.reconcile.forecasting.budgets.budget.fullPath,
    idKey: 'legalEntityCode',
    pathParams: {
      forecastName,
      year,
    },
  });

  const { navigationRef, scrollTo } = useScrollToElement(
    `[${SELECTED_BUDGET_DATA_ATTR}]`,
  );

  useEffect(() => {
    scrollTo();
  }, [budgets]);

  return (
    <ListNavLayout
      className={cn(
        'grid-cols-[27.5rem_minmax(auto,1fr)] 2xl:grid-cols-[27.5rem_minmax(auto,1fr)]',
      )}
    >
      <ListNavLayout.Navigation
        className={cn('next-div-w-full relative gap-0 p-0')}
      >
        <DashboardLayout.StickyHeader className="bg-neutral-100">
          <DashboardLayout.Header>
            <DashboardLayout.Header.Title
              title={year}
              subtitle={routePathText}
              onBackClick={navigateToMainForecastPage}
            />
          </DashboardLayout.Header>
        </DashboardLayout.StickyHeader>

        <div className="p-tw-6 pt-tw-4">
          {budgetsCount > 0 && (
            <div className="mb-tw-3">
              <ShownInfoLine totalSize={budgetsCount} />
            </div>
          )}
          {(isLoading || isForecastLoading) && (
            <div className="flex flex-col gap-tw-4">
              {/* <SkeletonBlock className="h-[24px] w-full" /> */}
              <div className="flex flex-col gap overflow-hidden rounded-2xl">
                {range(0, 5).map((i) => (
                  <div key={i} className="flex flex-col gap-tw-4 bg-light-5">
                    <SkeletonBlock className="h-[112px]" />
                  </div>
                ))}
              </div>
            </div>
          )}
          <ListNavLayout.NavigationCardList
            className="rounded-2xl"
            ref={navigationRef}
          >
            {!isLoading &&
              !isForecastLoading &&
              forecastName &&
              year &&
              budgets?.map((item) => (
                // DashboardLayout.Header has h-[88px]
                <LinkWrapper
                  to={item.pageViewUrl}
                  key={item.id}
                  className="scroll-my-[88px]"
                  {...{
                    [SELECTED_BUDGET_DATA_ATTR]:
                      item.legalEntity.code === activeLegalEntityCode ||
                      undefined,
                  }}
                >
                  <ProjectCard
                    selected={item.legalEntity.code === activeLegalEntityCode}
                    className="py-tw-4 pr-tw-4"
                    header={
                      <div className="flex w-full items-start justify-between gap-tw-2">
                        <div className="flex grow flex-col gap-tw-3">
                          <div className="flex flex-col gap-tw-1">
                            <p className="inline-semibold text-neutral-800">
                              {item.legalEntity.name}
                            </p>
                          </div>
                        </div>

                        {item.state && (
                          <Icon
                            iconName={LEGAL_ENTITY_STATUS_MAP[item.state].icon}
                            className={cn(
                              LEGAL_ENTITY_STATUS_MAP[item.state].className,
                            )}
                          />
                        )}
                      </div>
                    }
                  >
                    <div className="flex flex-col gap-tw-2 text-xs text-neutral-550">
                      {item.legalEntity.assetName}
                    </div>
                  </ProjectCard>
                </LinkWrapper>
              ))}

            {!isForecastFetching && !isFetching && budgetsCount === 0 && (
              <NoDataOverlay title="Nothing found" />
            )}
          </ListNavLayout.NavigationCardList>
        </div>
      </ListNavLayout.Navigation>

      {children}
    </ListNavLayout>
  );
};

export default ForecastingBudgetsPage;
