import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Icon, LinkButton, OverlaySpinner } from 'stories';
import pluralize from 'pluralize';
import { snakeCase } from 'lodash-es';
import { bulkDownload, fetchSharedFilesByParams } from 'lib/sharedFile';
import {
  currentUserIsSreAdmin,
  currentUserIsSuperAdmin,
} from 'lib/permissions';
import { fetchTrashDocuments as fetchTrash } from 'lib/trashDocuments';
import canManageObject from 'lib/InvestmentObject';
import ShownAsButton from 'bundles/Shared/components/ShownAsButton';
import { fetchImpersonationUsers } from '@/entities/impersonation/api';
import { plainFetchFunds } from 'bundles/Funds/actions/funds';
import { plainFetchDocumentTypes } from 'bundles/Settings/actions/documentType';
import TableAppliedFilters from 'bundles/Shared/components/Table/filters/TableAppliedFilters';
import {
  prepareSelectedFilters,
  resetFilter,
} from 'bundles/Shared/components/Table/filters/helpers';
import {
  ISharedFilePermissions,
  typedSharedFilePermissions,
} from 'bundles/Shared/sharedFilePermissions';
import { useModal } from '@/shared/lib/hooks/useModal';
import {
  plainFetchAssets,
  removeSharedFiles,
  updateSharedFile,
} from '../actions';
import {
  documentsColumns,
  trashDocumentsColumns,
} from './SharedFiles/Table/generateDefaultColumns';
import Table from '../../Shared/components/Table/Table';
import TableSearch from '../../Shared/components/Table/TableSearch';
import TablePagination from '../../Shared/components/Table/pagination/TablePagination';
import removeTrashDocuments from '../actions/trashDocuments';
import BulkActionsPanel from '../../Shared/components/BulkActionsPanel/BulkActionsPanel';
import { SELECTION_COLUMN_CLASSES } from './consts';
import { ISharedDocument } from 'types/SharedDocument';
import { Permitted } from 'bundles/Shared/entities/permissions';

export interface UpdateSharedFilePermissionsParams {
  shared_file: ISharedFilePermissions & Pick<ISharedDocument, 'id'>;
}

const FileTracker = ({ removeDocuments, removeTrashDocuments }) => {
  const dispatch = useDispatch();
  const [sharedFiles, setSharedFiles] = useState([]);
  const [totalSize, setTotalSize] = useState();
  const [trashToggleCounter, setTrashToggleCounter] = useState();
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [trashOpened, setTrashOpened] = useState(false);
  const [investmentObjects, setInvestmentObjects] = useState(undefined);
  const [documentTypes, setDocumentTypes] = useState(undefined);

  const { confirm } = useModal();
  const trashOpenedRef = useRef();
  trashOpenedRef.current = trashOpened;

  const [pageParams, setPageParams] = useState({
    currentPage: 1,
    sizePerPage: 10,
    sortField: 'createdAt',
    sortOrder: 'desc',
    searchQuery: '',
    filters: {
      shownAsUser: undefined,
      onlyPublic: undefined,
      investmentObjects: [],
      documentTypes: [],
    },
  });
  const [users, setUsers] = useState(undefined);
  const selectedFilters = pageParams.filters;
  const setSelectedFilters = (filters) =>
    setPageParams({
      ...pageParams,
      currentPage: 1,
      filters: {
        ...selectedFilters,
        ...filters,
      },
    });

  const { currentPage, sizePerPage } = pageParams;
  const setSizePerPage = (size, page) => {
    setPageParams({ ...pageParams, currentPage: page, sizePerPage: size });
  };
  const setCurrentPage = (page, size) => {
    setPageParams({
      ...pageParams,
      sizePerPage: size ?? sizePerPage,
      currentPage: page,
    });
  };
  const setSearchQuery = (query) =>
    setPageParams({
      ...pageParams,
      currentPage: 1,
      searchQuery: query,
    });

  const { shownAsUser } = selectedFilters;
  const setShownAsUser = (user) =>
    setPageParams({
      ...pageParams,
      currentPage: 1,
      filters: {
        ...pageParams.filters,
        shownAsUser: user,
      },
    });

  const buildFilterParams = () => ({
    page: pageParams.currentPage,
    sort_field: snakeCase(pageParams.sortField),
    sort_order: snakeCase(pageParams.sortOrder),
    filters: {
      document_type_ids: pageParams.filters?.documentTypes?.map(({ id }) => id),
      asset_ids: pageParams.filters.investmentObjects
        .filter(({ klass }) => klass === 'Asset')
        ?.map(({ id }) => id),
      fund_ids: pageParams.filters.investmentObjects
        .filter(({ klass }) => klass === 'Fund')
        ?.map(({ id }) => id),
      shown_as_user_id: shownAsUser?.id,
      only_public: pageParams.filters?.onlyPublic,
    },
    search_query: pageParams.searchQuery,
    size_per_page: pageParams.sizePerPage,
  });

  const handleTrashDocumentsFetch = (filterParams) => {
    setLoading(true);
    fetchTrash(filterParams).then((json) => {
      setSharedFiles(json.trashDocuments);
      setTotalSize(json.meta.totalSize);
      setLoading(false);
    });
  };

  const fetchDocuments = () => {
    fetchSharedFilesByParams(
      buildFilterParams(),
      setSharedFiles,
      setTotalSize,
      setLoading,
    ).then((json) => setTrashToggleCounter(json.meta.totalTrashSize));
  };

  const fetchTrashDocuments = (data) => {
    const filterParams = buildFilterParams(data);
    handleTrashDocumentsFetch(filterParams);
  };

  const updateItem = async (data: UpdateSharedFilePermissionsParams) => {
    await dispatch(updateSharedFile(data));

    if (trashOpenedRef.current) {
      fetchTrashDocuments(buildFilterParams());
    } else {
      fetchSharedFilesByParams(
        buildFilterParams(),
        setSharedFiles,
        setTotalSize,
        setLoading,
      );
    }
  };

  const confirmRemove = async (ids) =>
    confirm({
      title: `Remove ${pluralize('file', ids.length)}`,
      subtitle: `Are you sure you want to remove ${pluralize(
        'file',
        ids.length,
      )}?`,
    });

  const submitPermissions = async (
    permissions: Omit<Permitted, 'allUsers'> & Pick<ISharedDocument, 'id'>,
  ) => {
    const data: ISharedFilePermissions & Pick<ISharedDocument, 'id'> = {
      id: permissions.id,
      ...typedSharedFilePermissions(permissions),
    };

    await updateItem({ shared_file: data });
  };

  const removeItems = async (ids, onConfirm) => {
    const confirmed = await confirmRemove(ids);

    if (confirmed) onConfirm();
  };

  const handleTrashDocumentsRemove = async (ids) => {
    await removeItems(ids, async () => {
      await removeTrashDocuments({ ids });
      handleTrashDocumentsFetch(buildFilterParams());
    });
  };

  const handleDocumentsRemove = async (ids) => {
    await removeItems(ids, async () => {
      await removeDocuments({ ids });
      fetchSharedFilesByParams(
        buildFilterParams(),
        setSharedFiles,
        setTotalSize,
        setLoading,
      ).then((json) => setTrashToggleCounter(json.meta.totalTrashSize));
    });
  };

  const canBulkRemove = () =>
    selectedRows
      .map(
        ({ documentType, documentableId, documentableType }) =>
          !documentType.internal &&
          canManageObject({ id: documentableId, objectType: documentableType }),
      )
      .every(Boolean);

  const handleBulkRemove = (ids) =>
    trashOpened ? handleTrashDocumentsRemove(ids) : handleDocumentsRemove(ids);

  const localResetFilter = (key, id) =>
    resetFilter(setSelectedFilters, selectedFilters, key, id);

  const trashColumns = trashDocumentsColumns(
    {
      submitPermissions,
      remove: handleTrashDocumentsRemove,
      setSelectedFilters,
      resetFilter: localResetFilter,
    },
    { selectedFilters },
    { investmentObjects, documentTypes },
  );

  const docColumns = documentsColumns(
    {
      submitPermissions,
      remove: handleDocumentsRemove,
      setSelectedFilters,
      resetFilter: localResetFilter,
    },
    { selectedFilters },
    { investmentObjects, documentTypes },
  );

  useEffect(() => {
    if (trashOpened) {
      fetchTrashDocuments();
    } else {
      fetchDocuments();
    }
  }, [pageParams]);

  useEffect(() => {
    fetchImpersonationUsers({ non_advisors: true }).then((data) =>
      setUsers(data.items),
    );
    const fetchInvestmentObjects = async () => {
      const assets = await plainFetchAssets();
      const funds = await plainFetchFunds();

      setInvestmentObjects([
        ...assets.assets.map((asset) => ({
          id: asset.id,
          uniqId: `${asset.id}-asset`,
          name: asset.name,
          klass: 'Asset',
        })),
        ...funds.funds.map((fund) => ({
          id: fund.id,
          uniqId: `${fund.id}-fund`,
          name: fund.name,
          klass: 'Fund',
        })),
      ]);
    };
    fetchInvestmentObjects();
  }, []);

  useEffect(() => {
    plainFetchDocumentTypes({
      with_documents: true,
      trash_only: trashOpened,
    }).then((data) => setDocumentTypes(data));
  }, [sharedFiles]);

  const resetFilters = () => {
    setSelectedFilters({
      shownAsUser: undefined,
      investmentObjects: [],
      documentTypes: [],
    });
  };

  const toggleTrash = () => {
    setTrashOpened((opened) => !opened);
    if (!trashOpened) {
      setTrashToggleCounter(totalSize);
    }
    setSelectedRows([]);
    resetFilters();
  };

  const LocalTablePagination = (props) => (
    <TablePagination
      loading={loading}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      totalSize={totalSize}
      onSizePerPageChange={setSizePerPage}
      sizePerPage={sizePerPage}
      {...props}
    />
  );

  return (
    <div className="flex flex-col gap-tw-4">
      <div className="flex flex-wrap items-center justify-between gap-y-tw-4">
        <div className="flex w-full justify-between lg:w-auto">
          <div className="light-90 font-weight-500 block lg:hidden">
            All documents
          </div>
          <div className="hidden items-center sm:flex">
            <LocalTablePagination />
          </div>
          <div className="flex items-center sm:hidden">
            <LocalTablePagination onSizePerPageChange={undefined} />
          </div>
        </div>
        <div className="flex w-full flex-wrap items-center gap-tw-4 lg:w-auto">
          {(currentUserIsSreAdmin() || currentUserIsSuperAdmin()) && (
            <ShownAsButton
              users={users}
              shownAsUser={shownAsUser}
              setShownAsUser={setShownAsUser}
              onSearch={(query) => {
                setUsers(undefined);
                fetchImpersonationUsers({
                  non_advisors: true,
                  search_query: query,
                }).then((data) => setUsers(data.items));
              }}
            />
          )}
          <div className="relative !grid w-full items-center lg:w-[initial]">
            {/* DEPRECATED: FE-2167 use SearchInput */}
            <TableSearch
              className="w-full"
              size="m"
              inputPlaceholder="Documents"
              setSearchQuery={setSearchQuery}
            />
          </div>
          {currentUserIsSreAdmin() && (
            <LinkButton onClick={toggleTrash} className="hidden lg:flex">
              <div className="file-tracker-trash-toggle-container flex rounded">
                {loading ? (
                  <OverlaySpinner size="small" inline />
                ) : (
                  <div className="flex">
                    <Icon iconName={trashOpened ? 'tableView' : 'trash'} />
                    <span className="file-tracker-trash-count">
                      {trashToggleCounter}
                    </span>
                  </div>
                )}
              </div>
            </LinkButton>
          )}
        </div>
      </div>
      <TableAppliedFilters
        className="flex"
        filters={prepareSelectedFilters(selectedFilters)}
        resetFilter={localResetFilter}
      />
      <Table
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        selectionColumn={SELECTION_COLUMN_CLASSES}
        items={sharedFiles}
        columns={trashOpened ? trashColumns : docColumns}
        loading={loading}
        settings={pageParams}
        setSettings={setPageParams}
        emptyDocumentsLabel={
          pageParams.searchQuery ? 'Nothing found' : 'No Documents Yet'
        }
        emptyDocumentsSubLabel={
          pageParams.searchQuery ? 'Reset filters to see all documents' : ''
        }
      />
      {selectedRows.length > 0 && (
        <BulkActionsPanel
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          actions={[
            {
              title: 'Download',
              icon: 'download',
              handleClick: () =>
                bulkDownload(selectedRows).then(() => setSelectedRows([])),
            },
            {
              title: 'Remove',
              icon: 'trash',
              handleClick:
                canBulkRemove() &&
                (async () => {
                  await handleBulkRemove(selectedRows.map(({ id }) => id));
                  setSelectedRows([]);
                }),
            },
          ]}
        />
      )}
    </div>
  );
};

const mapActionCreators = {
  update: updateSharedFile,
  removeDocuments: removeSharedFiles,
  removeTrashDocuments,
};

export default connect(null, mapActionCreators)(FileTracker);
