/* eslint-disable check-file/no-index */
import { ReportDashboardSectionPosition } from '@/bundles/Shared/entities/dashboard/model';
import { AdjustmentExpressionName } from '@/bundles/Shared/widgets/dashboard/widgets/common/config';

export * from './config';
export * from './data';

export const isUnderwritingAdjustment = (value: AdjustmentExpressionName) => {
  return value.startsWith('uw_');
};

export const isWidgetSectionPositionHalfSized = (
  p: Pick<ReportDashboardSectionPosition, 'w'>,
): boolean => {
  return p.w === 50;
};

export const isWidgetSectionPositionWidthFullSize = (
  p: Pick<ReportDashboardSectionPosition, 'w'>,
): boolean => {
  return p.w === 100;
};
