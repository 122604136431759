import { useCapitalCommitments } from '@/bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/hooks/useCapitalCommitments';
import { useCapitalContributions } from '@/bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/hooks/useCapitalContributions';
import { useCapitalDistributions } from '@/bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/hooks/useCapitalDistributions';
import { useCommitmentSettingsModalWithUnitPrices } from '@/bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/hooks/useCommitmentSettingsModal';
import { useLoadedCapitalUnitPrices } from '@/bundles/REturn/hooks/useLoadedUnitPrices';
import { Distribution } from '@/entities/return';
import {
  usePostApiCapitalInvestmentCommitmentsBulkMutation,
  usePostApiCapitalInvestmentContributionsBulkMutation,
  usePostApiCapitalInvestmentDistributionsBulkMutation,
} from '@/entities/return/api/capitalInvestmentObjectsEnhancedApi';
import {
  AccruedAdjustment,
  Commitment,
  InvestmentIndex,
} from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { mustFind } from '@/lib/typeHelpers';
import CapitalInvestmentEntryTable, {
  CommitmentsTable,
} from 'bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/CapitalInvestmentEntryTable';
import VerticalPagination from 'bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/VerticalPagination';
import {
  useAddManualContributionSettingsModal,
  useAddManualContributionWithUnitPricesSettingsModal,
} from 'bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/hooks/useAddManualContributionSettingsModal';
import { useAddManualDistributionSettingsModal } from 'bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/hooks/useAddManualDistributionSettingsModal';
import { useLoadedInvestmentObject } from 'bundles/REturn/hooks/useLoadedInvestmentObject';
import { TReturnInvestmentTransactionDirection } from 'bundles/REturn/types';
import { HUGE_MODAL_Z_INDEX } from 'bundles/Shared/constants';
import { useCustomer } from 'lib/customers';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { REPORT_PRODUCT_NAME } from 'lib/permissions';
import pluralize from 'pluralize';
import { useMemo, useState } from 'react';
import {
  AnimationLoader,
  Button,
  Dropdown,
  DropdownItem,
  IconButton,
  ModalHeaderWithSubtitle,
  ThinTabGroup,
} from 'stories';
import { SidePanel } from 'stories/Modals/Modal/Modal';
import { NavigateToCreateEntryWorkflowParams } from '../../ownershipTable/actions';
import { ENTRY_THIN_TAB_ITEMS } from './consts';
import {
  convertCentsToDollars,
  convertDollarsToCents,
} from '@/shared/lib/converters';
import { useCapitalAccruedPreferredReturns } from './hooks/useCapitalAccruedPreferredReturns';
import { AccruedPreferredReturnModalBody } from './AccruedPreferredReturnModalBody';
import { AccruedCards } from './AccruedCards';
import { AccruedPreferredReturn } from '@/entities/return/model/types/accruedPreferredReturn';

interface Props extends DialogProps {
  capitalInvestments: InvestmentIndex[];
  manageCapitalInvestmentModal: IManageCapitalInvestmentModal;
  navigateToCreateEntryWorkflow: (
    params: NavigateToCreateEntryWorkflowParams,
  ) => void;
  navigateToCreateTransferFlow: (params: { sourceId: string }) => void;
}

export interface IManageCapitalInvestmentModal {
  row: InvestmentIndex;
  kind: TReturnInvestmentTransactionDirection;
}

const ManageCapitalInvestmentModal = ({
  onClose,
  capitalInvestments,
  manageCapitalInvestmentModal,
  navigateToCreateEntryWorkflow,
  navigateToCreateTransferFlow,
}: Props) => {
  const { data: capitalInvestmentObject } = useLoadedInvestmentObject();
  const { fromSourceEnabled } = capitalInvestmentObject;

  const { kind: initialTransactionKind, row: initialCapitalInvestment } =
    manageCapitalInvestmentModal;
  const { id: initialId } = initialCapitalInvestment;
  const { data: unitPrices } = useLoadedCapitalUnitPrices();
  const commitmentModal = useCommitmentSettingsModalWithUnitPrices();

  const [capitalInvestmentId, setCapitalInvestmentId] =
    useState<number>(initialId);

  const currentCapitalInvestment = useMemo(() => {
    return mustFind(
      capitalInvestments,
      (investment) => investment.id === capitalInvestmentId,
    );
  }, [capitalInvestmentId, capitalInvestments]);

  const addManualContributionWithUnitPricesModal =
    useAddManualContributionWithUnitPricesSettingsModal({
      capitalInvestment: currentCapitalInvestment,
    });

  const addManualContributionModal = useAddManualContributionSettingsModal({
    capitalInvestment: currentCapitalInvestment,
  });
  const addManualDistributionModal = useAddManualDistributionSettingsModal({
    capitalInvestment: currentCapitalInvestment,
  });

  const currentCustomerHasReportProduct =
    useCustomer().products?.includes(REPORT_PRODUCT_NAME);

  const [selectedTab, setSelectedTab] = useState(
    ENTRY_THIN_TAB_ITEMS.find(
      ({ id: tabId }) => tabId === initialTransactionKind,
    ) ?? ENTRY_THIN_TAB_ITEMS[0],
  );

  const hasUnitPrices = unitPrices.length > 0;

  const {
    data: contributionsData,
    contributions,
    isFetching: isContributionDataFetching,
  } = useCapitalContributions({
    investmentId: capitalInvestmentId,
  });

  const {
    data: distributionData,
    distributions,
    isFetching: isDistributionDataFetching,
  } = useCapitalDistributions({
    investmentId: capitalInvestmentId,
  });

  const preferredDistributionsIncludingSplitItems = useMemo(() => {
    const preferredDistributions = [];

    preferredDistributions.push(
      ...distributions.filter((item) => item.kind == 'preferred'),
    );

    distributions
      .filter((item) => item.kind == 'split_items')
      .forEach((item) => {
        item.splitItems.forEach((splitItem: Distribution) => {
          if (splitItem.kind === 'preferred') {
            preferredDistributions.push({
              ...item,
              amountCents: convertDollarsToCents(item.amount),
              amount: convertCentsToDollars(splitItem.amountCents),
            });
          }
        });
      });

    return preferredDistributions;
  }, [distributions]);

  const {
    items: accruedPreferredReturnItems,
    isFetching: isAccruedPreferredDataFetching,
  } = useCapitalAccruedPreferredReturns({
    investmentId: capitalInvestmentId,
  });

  const { commitments, isFetching: isCommitmentDataFetching } =
    useCapitalCommitments({
      investmentIds: [capitalInvestmentId],
    });

  const [createBulkContributions] =
    usePostApiCapitalInvestmentContributionsBulkMutation();
  const [createBulkDistributions] =
    usePostApiCapitalInvestmentDistributionsBulkMutation();
  const [createBulkCommitments] =
    usePostApiCapitalInvestmentCommitmentsBulkMutation();

  const items = (() => {
    switch (selectedTab.id) {
      case 'contribution':
        return contributions;
      case 'distribution':
        return distributions;
      case 'commitment':
        return commitments;
      case 'accrued': {
        const accruedEntries = accruedPreferredReturnItems.flatMap(
          (item) => item.accruedEntries,
        );
        const adjustments = accruedPreferredReturnItems
          .flatMap((item) => item.adjustments)
          .map((item) => ({
            ...item,
            _type: 'adjustment',
          }));

        return [
          ...accruedEntries,
          ...preferredDistributionsIncludingSplitItems,
          ...adjustments,
        ].sort((a, b) => {
          return new Date(b.date).getTime() - new Date(a.date).getTime();
        });
      }
      default:
        return [];
    }
  })();

  const isLoading = (() => {
    switch (selectedTab.id) {
      case 'contribution':
        return isContributionDataFetching;
      case 'distribution':
        return isDistributionDataFetching;
      case 'commitment':
        return isCommitmentDataFetching;
      case 'accrued':
        return isAccruedPreferredDataFetching;
      default:
        return false;
    }
  })();

  const notMappedCount = (() => {
    switch (selectedTab.id) {
      case 'contribution':
        return contributionsData?.meta.notMappedCount ?? 0;
      case 'distribution':
        return distributionData?.meta.notMappedCount ?? 0;
      case 'commitment':
        return 0;
      default:
        return 0;
    }
  })();

  const currentIndex = capitalInvestments.findIndex(
    (investment) => investment.id === capitalInvestmentId,
  );
  const previousEntityId = capitalInvestments[currentIndex - 1]?.id;
  const nextEntityId = capitalInvestments[currentIndex + 1]?.id;

  const handleOpenSettingsModal = async () => {
    switch (selectedTab.id) {
      case 'contribution': {
        const contributionModal = hasUnitPrices
          ? addManualContributionWithUnitPricesModal
          : addManualContributionModal;

        const res = await contributionModal();
        if (res == null) return;

        await createBulkContributions({
          body: [
            {
              date: res.date,
              signed_date: res.signedDate,
              amount_cents: convertDollarsToCents(res.amount),
              investment_id: currentCapitalInvestment.id,
              investment_class: res.investmentClass,
              commitment_id: res.commitmentId,
              units_quantity: res.unitsQuantity,
            },
          ],
        });

        break;
      }

      case 'distribution': {
        const res = await addManualDistributionModal();
        if (res == null) return;

        await createBulkDistributions({
          body: [
            {
              ...res,
              investment_id: currentCapitalInvestment.id,
            },
          ],
        });
        break;
      }

      case 'commitment': {
        const res = await commitmentModal({
          capitalInvestment: currentCapitalInvestment,
          modalHeaderProps: {
            title: 'Create Commitment',
            subtitle: currentCapitalInvestment.investmentEntity.name,
          },
          submitButtonText: 'Create',
        });

        if (res == null) return;

        await createBulkCommitments({
          body: {
            commitment: [
              {
                investment_id: currentCapitalInvestment.id,
                amount_cents: res.amount
                  ? convertDollarsToCents(res.amount)
                  : undefined,
                signed_date: res.date,
              },
            ],
          },
        });
        break;
      }

      default: {
        break;
      }
    }
  };

  const counterLabel = {
    'Accrued Preferred': 'entry',
  };

  const commitmentsTable = (
    <CommitmentsTable
      capitalInvestment={currentCapitalInvestment}
      items={items as Commitment[]}
    />
  );

  const contributionDistributionTable = (
    <CapitalInvestmentEntryTable
      notMappedCount={notMappedCount}
      items={items}
      type={selectedTab.id}
      capitalInvestment={currentCapitalInvestment}
      capitalInvestments={capitalInvestments}
    />
  );

  const accruedTable = (
    <AccruedPreferredReturnModalBody
      preferredDistributions={preferredDistributionsIncludingSplitItems}
      items={
        items as AccruedPreferredReturn[] | Distribution[] | AccruedAdjustment[]
      }
      capitalInvestments={capitalInvestments}
      capitalInvestment={currentCapitalInvestment}
    />
  );

  const accruedCards = (
    <AccruedCards
      items={accruedPreferredReturnItems}
      investmentEntityName={currentCapitalInvestment.investmentEntity.name}
    />
  );

  return (
    <SidePanel
      toggle={onClose}
      maxHeight
      zIndex={HUGE_MODAL_Z_INDEX - 1}
      header={
        <ModalHeaderWithSubtitle
          title={currentCapitalInvestment.investmentEntity.name}
          subtitle="Investment Entity"
          order="subtitle-title"
        />
      }
      customClose={
        <div className="flex items-center">
          {capitalInvestments.length > 1 && (
            <VerticalPagination
              current={currentIndex + 1}
              total={capitalInvestments.length}
              onNext={() => setCapitalInvestmentId(nextEntityId)}
              onPrev={() => setCapitalInvestmentId(previousEntityId)}
            />
          )}
          <IconButton
            iconName="close"
            size="l"
            onClick={onClose}
            variant="secondary"
          />
        </div>
      }
      size="700"
      classes={{
        overlay: 'max-w-[700px]',
        body: 'flex flex-col gap-tw-4',
      }}
    >
      <ThinTabGroup
        className="self-start"
        items={ENTRY_THIN_TAB_ITEMS}
        selectedItem={selectedTab}
        onSelectedItemChange={setSelectedTab}
      />
      {isLoading && <AnimationLoader />}
      {!isLoading && (
        <>
          {selectedTab.id === 'accrued' && accruedCards}
          <div className="flex items-center justify-between">
            <span className="inline-semibold text-dark-60">
              {items.length}&nbsp;
              {pluralize(
                counterLabel[selectedTab.label] ?? selectedTab.label,
                items.length,
              )}
            </span>
            {selectedTab.id !== 'accrued' && (
              <>
                {fromSourceEnabled &&
                currentCustomerHasReportProduct &&
                selectedTab.id !== 'commitment' ? (
                  <Dropdown
                    items={
                      <>
                        <DropdownItem onClick={handleOpenSettingsModal}>
                          Manual Entry
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            navigateToCreateEntryWorkflow({
                              entryType: pluralize(selectedTab.id, 0) as
                                | 'contributions'
                                | 'distributions',
                              capitalInvestmentId: currentCapitalInvestment.id,
                            });
                          }}
                        >
                          From Source
                        </DropdownItem>

                        {selectedTab.id === 'contribution' && (
                          <DropdownItem
                            onClick={() => {
                              navigateToCreateTransferFlow({
                                sourceId: currentCapitalInvestment.id,
                              });
                            }}
                          >
                            Transfer of ownership
                          </DropdownItem>
                        )}
                      </>
                    }
                  >
                    <Button
                      variant="secondary"
                      iconName="bottom"
                      iconPosition="right"
                      size="s"
                      className="inline-semibold text-light-90"
                    >
                      Add
                    </Button>
                  </Dropdown>
                ) : (
                  <Button
                    variant="secondary"
                    size="xs"
                    title="Create"
                    className="border-none"
                    onClick={handleOpenSettingsModal}
                  >
                    Create
                  </Button>
                )}
              </>
            )}
          </div>
          {selectedTab.id === 'commitment' && commitmentsTable}

          {(selectedTab.id == 'contribution' ||
            selectedTab.id == 'distribution') &&
            contributionDistributionTable}

          {selectedTab.id === 'accrued' && accruedTable}
        </>
      )}
    </SidePanel>
  );
};

export default ManageCapitalInvestmentModal;
