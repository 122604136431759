import {
  formatDate,
  formatDateToQuarterWithPrefix,
} from '@/shared/lib/formatting/dates';
import type { ISelectOption } from '@/stories/FormControls/Select/Select';

import * as yup from 'yup';

export const REPORT_BUILDER_ORIENTATION_CLASSES = {
  ['portrait']: {
    className: 'w-[1170px] max-w-[1170px]',
    mediaWidgetClassName: 'flex flex-wrap justify-center',
  },
  ['landscape']: {
    className: 'w-[1680px] max-w-[1680px]',
    mediaWidgetClassName: 'flex flex-wrap justify-center',
  },
} as const;

export type BASE_WIDGET_CONFIG_CARD_INFO_LINE_TYPE = 'error' | 'no-data';

export const BASE_WIDGET_CONFIG_CARD_INFO_LINE_CONFIG = {
  error: {
    defaultMessage: 'Error',
    iconClassName: 'text-danger-055',
  },
  'no-data': {
    defaultMessage: 'No data, try choosing a different date',
    iconClassName: 'text-attention-055',
  },
} as const satisfies Record<
  BASE_WIDGET_CONFIG_CARD_INFO_LINE_TYPE,
  {
    defaultMessage: string;
    iconClassName: string;
  }
>;

export const REPORT_BUILDER_DATE_FORMAT_QUARTER = 'quarter';
const REPORT_BUILDER_DATE_FORMAT_DAY = 'day';

export const REPORT_BUILDER_DATE_FORMAT_MAP = {
  [REPORT_BUILDER_DATE_FORMAT_QUARTER]: {
    dateFormatter: (date: DateString) => formatDateToQuarterWithPrefix(date),
  },
  [REPORT_BUILDER_DATE_FORMAT_DAY]: {
    dateFormatter: (date: DateString) => formatDate(date, 'MM/DD/YYYY'),
  },
} as const;

export const REPORT_BUILDER_DATE_FORMAT_OPTIONS = [
  {
    id: REPORT_BUILDER_DATE_FORMAT_QUARTER,
    label: 'Quarter (Q1-YY)',
  },
  {
    id: REPORT_BUILDER_DATE_FORMAT_DAY,
    label: 'Day (MM/DD/YYYY)',
  },
] satisfies ISelectOption<string>[];

export const REPORT_BUILDER_FORM_SCHEMA = yup.object().shape({
  name: yup.string().required(),
  subtitle: yup.string().optional(),
  date: yup.string().required(),
  legalEntityIds: yup.array().of(yup.string().required()).required(),
  dateFormat: yup
    .string()
    .oneOf([REPORT_BUILDER_DATE_FORMAT_DAY, REPORT_BUILDER_DATE_FORMAT_QUARTER])
    .nullable(),
});

export type ReportBuilderForm = yup.InferType<
  typeof REPORT_BUILDER_FORM_SCHEMA
>;
