import { DashboardLayout } from '@/bundles/Shared/components/layouts/dashboard/DashboardLayout';
import { NoDataOverlayUpdated } from '@/bundles/Shared/components/NoDataOverlay';
import { useDeleteForecast } from '@/entities/reconcile/forecasting/features/api/forecast/useDeleteForecast';
import { useEditForecast } from '@/entities/reconcile/forecasting/features/api/forecast/useEditForecast';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import AnimationLoader from '@/stories/AnimationLoader/AnimationLoader';
import Button from '@/stories/Button/Button';
import IconButton from '@/stories/IconButton/IconButton';
import { useNavigate, useParams } from '@reach/router';
import { StatsCard } from '@/shared/ui/StatsCard';
import { ForecastYearRow } from '@/entities/reconcile/forecasting/features/ui/ForecastingYearRow';
import { ReactNode } from 'react';
import { useCreateForecastYear } from '@/entities/reconcile/forecasting/features/api/forecast/useCreateForecastYear';
import { LinkAsButton } from '@/stories';
import { useForecastByName } from '@/entities/reconcile/forecasting/features/api/forecast/useForecastByName';
import { useFetchForecastYears } from '@/entities/reconcile/forecasting/features/api/forecast/useFetchForecastYears';

const LoaderWrapper = ({
  children,
  isLoading,
}: {
  children: ReactNode;
  isLoading: boolean;
}) => {
  if (isLoading) {
    return <AnimationLoader className="static h-full w-full" />;
  }

  return children;
};

const ForecastPage = () => {
  const { forecastName } = useParams();
  const navigate = useNavigate();

  const navigateToMainForecastPage = () => {
    const parentPath = generateUrl(ROUTES_ROOT.reconcile.forecasting.fullPath);

    navigate(parentPath);
  };

  const { forecast, isLoading: isLoadingForecast } =
    useForecastByName(forecastName);

  const { deleteForecast } = useDeleteForecast();
  const { editForecast } = useEditForecast();

  const pageTitle = forecast?.label ?? '';

  const handleDeleteForecast = async () => {
    if (!forecast?.id) return;

    const res = await deleteForecast(forecast.id);

    if (!res) return;

    navigateToMainForecastPage();
  };

  const handleEditForecast = async () => {
    if (!forecast) return;

    await editForecast(forecast);
  };

  const { createForecastYear } = useCreateForecastYear(forecast?.id);
  const handleCreateForecastBudget = async () => {
    if (!forecast?.years) return;

    await createForecastYear(forecast.years);
  };

  const {
    budgets,
    budgetsCount,
    isLoading: isLoadingBudgets,
    isFetching: isFetchingBudgets,
  } = useFetchForecastYears(forecast?.id);

  const isLoading = isLoadingForecast || isLoadingBudgets || isFetchingBudgets;

  return (
    <div className="flex h-full flex-col bg-neutral-100">
      <DashboardLayout.StickyHeader className="bg-neutral-100">
        <DashboardLayout.Header className="h-auto">
          <DashboardLayout.Header.Title
            title={pageTitle}
            withoutBackButton
            className="min-h-tw-8"
          />
          {Boolean(forecast) && (
            <div className="flex items-center gap-tw-2">
              <IconButton iconName="trash" onClick={handleDeleteForecast} />
              <IconButton iconName="edit" onClick={handleEditForecast} />
            </div>
          )}
          <GrowDiv />
          {budgetsCount > 0 && (
            <Button
              disabled={isLoading}
              variant="primary"
              size="s"
              onClick={handleCreateForecastBudget}
            >
              Create Budgets
            </Button>
          )}
        </DashboardLayout.Header>
        <div className="px-tw-6">
          <div className="h-1 w-full bg-neutral-150" />
        </div>
      </DashboardLayout.StickyHeader>
      <div className="flex-grow px-tw-6 py-tw-4">
        <LoaderWrapper isLoading={isLoading}>
          {budgetsCount === 0 ? (
            <NoDataOverlayUpdated
              title="No Budgets Yet"
              subTitle="To get started click Сreate Budgets "
            >
              <Button
                variant="primary"
                size="s"
                onClick={handleCreateForecastBudget}
              >
                Create Budgets
              </Button>
            </NoDataOverlayUpdated>
          ) : (
            <div className="flex w-full flex-col rounded-xl border-b border-l border-r border-t border-solid border-neutral-150 bg-neutral-050">
              {budgets.map((budget) => (
                <div
                  key={budget.year}
                  className="flex w-full gap-tw-1 border-b border-solid border-neutral-150 p-tw-2 last:border-none"
                >
                  <ForecastYearRow row={budget} className="w-[28.75rem]" />
                  <div className="flex flex-grow items-center gap-tw-2 px-tw-4 py-tw-2">
                    <StatsCard
                      value={budget.publishedCount}
                      label="Published"
                      total={budget.totalBudgets}
                    >
                      <LinkAsButton
                        variant="secondary"
                        size="xs"
                        className="whitespace-nowrap"
                        link={budget.link}
                      >
                        Open Details
                      </LinkAsButton>
                    </StatsCard>
                    <StatsCard value={budget.draftCount} label="Draft">
                      <LinkAsButton
                        variant="secondary"
                        size="xs"
                        className="whitespace-nowrap"
                        link={budget.link}
                      >
                        Open Details
                      </LinkAsButton>
                    </StatsCard>
                    <StatsCard value={budget.noBudgetCount} label="No Budget">
                      <LinkAsButton
                        variant="secondary"
                        size="xs"
                        className="whitespace-nowrap"
                        link={budget.link}
                      >
                        Open Details
                      </LinkAsButton>
                    </StatsCard>
                  </div>
                </div>
              ))}
            </div>
          )}
        </LoaderWrapper>
      </div>
    </div>
  );
};

export default ForecastPage;
