import { IHeaderParams } from 'ag-grid-community';
import SetFilter from 'bundles/REconcile/components/operational/table/SetFilter';
import { ListOption } from 'stories/Checkbox/CheckList';
import { LegalEntityHeaderParams } from '../../GeneralLedgers';
import type * as Local from '../../types';

interface Props extends IHeaderParams, LegalEntityHeaderParams {}

export function LegalEntityHeader({
  displayName,
  allLEOptions,
  selectedLEOptions,
  setLegalEntityIds,
}: Props) {
  const handleChecked = (options: ListOption[]) => {
    const ids = options.map((o) => o.value) as Local.GLLegalEntityIds;
    setLegalEntityIds(ids);
  };

  return (
    <div className="flex items-center gap-tw-2">
      {displayName}
      <SetFilter
        placement="bottom-start"
        items={allLEOptions}
        label={displayName}
        onChange={handleChecked}
        value={selectedLEOptions}
        appendTo={() => document.body}
      />
    </div>
  );
}
