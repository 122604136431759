import React, { useRef } from 'react';
import { LabelWithCorners } from 'stories/TitledCard/TitledCard';
import styles from './ActivityLog.module.scss';
import { Icon, IconButton } from 'stories';
import dayjs, { Dayjs } from 'dayjs';
import { cn } from '@/shared/lib/css/cn';
import Slider from 'react-slick';

const TimestampFormatter = ({ date }: { date: Dayjs }) => (
  <span className="secondary-regular text-dark-60">
    {date.format('MMMM DD,YYYY')}
    <span className="text-light-60"> at {date.format('HH:MM A')}</span>
  </span>
);

const ActivityLogLabel = ({
  children,
  className,
}: React.PropsWithChildren<PropsWithClassName>) => (
  <div
    className={cn(
      'flex items-center gap-tw-2 whitespace-nowrap rounded-lg bg-light p-tw-2 text-dark-60',
      className,
    )}
  >
    <Icon iconName="checkDouble" className="text-green" />
    {children}
    <Icon iconName="externalLinkSquare" className="text-light-60" />
  </div>
);

const ActivityLogItem = ({
  date,
  title,
  className,
}: { date: Dayjs; title: string; last?: boolean } & PropsWithClassName) => (
  <div className={cn('flex flex-col gap-tw-2', className)}>
    <div className="flex items-center gap-tw-2">
      <TimestampFormatter date={date} />
      <div className="flex-grow border-b border-dashed border-light-60" />
    </div>
    <div className="pr-tw-2">
      <ActivityLogLabel>{title}</ActivityLogLabel>
    </div>
  </div>
);

function ReconcileDevelopmentDashboardActivityLog() {
  const slider = useRef<Slider>(null);

  return (
    <div className="relative flex flex-col gap-tw-2 rounded-2xl bg-white">
      <div className="flex items-end pb-[10px]">
        <div className="flex items-center gap-[10px]">
          <LabelWithCorners placement="top-left">Activity Log</LabelWithCorners>
          <span className="secondary-regular p-tw-1 text-light-60">
            21 Events
          </span>
        </div>
      </div>
      <div
        className="absolute bottom-0 right-0 top-0 z-[1] flex gap-tw-1 bg-[linear-gradient(90deg,rgba(255,255,255,0)_0%,#FFFFFF_100%)]
       pr-tw-3
       pt-tw-3
       "
      >
        <IconButton
          onClick={() => slider.current?.slickPrev()}
          variant="white"
          iconName="arrowLeft"
        />
        <IconButton
          onClick={() => slider.current?.slickNext()}
          variant="white"
          iconName="arrowRight"
        />
      </div>
      <div className="p-tw-4 pt-0">
        <Slider
          className={cn(styles.slider, 'w-full')}
          arrows={false}
          infinite={false}
          draggable
          speed={500}
          variableWidth
          slidesToScroll={1}
          ref={slider}
        >
          {Array.from({ length: 5 }).map((_, i) => (
            <ActivityLogItem
              key={i}
              date={dayjs()}
              title="Verified COR 0100: “%COR_Title%”"
            />
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default ReconcileDevelopmentDashboardActivityLog;
