import {
  Button,
  Modal,
  ModalActions,
  ModalHeaderWithSubtitle,
} from '@/stories';
import React, { useState } from 'react';
import { usePutApiCapitalInvestmentAccruedPreferredReturnsByAccruedPreferredReturnIdAccruedEntriesAndIdMutation } from '@/entities/return/api/capitalInvestmentObjectsEnhancedApi';
import {
  convertCentsToDollars,
  convertDollarsToCents,
} from '@/shared/lib/converters';
import { AccruedEntryDto } from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import CurrencyInputNumber from '@/bundles/Shared/components/GroupForm/FormItems/CurrencyInputNumber';

export const EditAccruedEntryModal = ({
  onClose,
  row,
  investmentEntityName,
}: {
  row: AccruedEntryDto;
  onClose: () => void;
  investmentEntityName: string;
}) => {
  const [formData, setFormData] = useState({
    id: row.id,
    amount: row.overriddenAmount || '',
  });

  const [updateAccruedEntry] =
    usePutApiCapitalInvestmentAccruedPreferredReturnsByAccruedPreferredReturnIdAccruedEntriesAndIdMutation();

  const onUpdate = async () => {
    await updateAccruedEntry({
      id: row.id,
      accruedPreferredReturnId: row.accruedPreferredReturnId,
      body: {
        accrued_entry: {
          amount_cents: convertDollarsToCents(formData.amount),
        },
      },
    });

    onClose();
  };

  const onReset = async () => {
    await updateAccruedEntry({
      id: row.id,
      accruedPreferredReturnId: row.accruedPreferredReturnId,
      body: {
        accrued_entry: {
          amount_cents: null,
        },
      },
    });

    onClose();
  };

  return (
    <Modal
      toggle={onClose}
      size="500"
      header={
        <ModalHeaderWithSubtitle
          title="Accrued Preferred"
          subtitle={investmentEntityName}
          order="subtitle-title"
        />
      }
      classes={{
        body: 'flex gap-tw-4 flex-col',
      }}
      actions={
        <ModalActions alignItems="space-between">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <div className="flex gap-tw-4">
            {Boolean(row.overriddenAmount) && (
              <Button variant="secondary" onClick={onReset}>
                Reset
              </Button>
            )}
            <Button
              variant="success"
              onClick={onUpdate}
              disabled={
                Math.abs(formData.amount) ===
                  Math.abs(convertCentsToDollars(row.amountCents)) ||
                !formData.amount
              }
            >
              Save Updates
            </Button>
          </div>
        </ModalActions>
      }
    >
      <div>
        <label>
          Amount <span className="red">*</span>
        </label>
        <CurrencyInputNumber
          prefix="("
          suffix=")"
          size="m"
          placeholder="Set amount override"
          allowNegative={false}
          defaultValue={formData.amount}
          onValueChange={(e) => {
            setFormData((prev) => ({
              ...prev,
              amount: e,
            }));
          }}
          className="input-light"
        />
      </div>
    </Modal>
  );
};
