import type { ReportSegment } from '@/bundles/Shared/entities/segment/model';
import { apiClient, requireAuthorizationHeaders } from '@/lib/http';
import { DEFAULT_STRINGIFY_OPTIONS } from '@/lib/stringify';
import { formatToDateStringForRequest } from '@/shared/lib/converters';
import type { IAsset } from '@/types/Asset';
import { stringify } from 'qs';
export * from './helpers';

export const plainPreviewReportTemplatePdf = ({
  templateId,
  assetId,
  date,
  renderPdfHost: render_pdf_host,
  assetIds: asset_ids,
  segmentIds: segment_ids,
}: {
  date: DateString;
  templateId: string;
  renderPdfHost: string;
  assetIds: IAsset['id'][];
  segmentIds?: ReportSegment['id'][];
  assetId?: string;
}) => {
  return apiClient.get<Blob>(
    `/api/settings/report_builder/templates/${templateId}/preview_pdf${stringify(
      {
        date: formatToDateStringForRequest(date),
        asset_id: assetId,
        render_pdf_host,
        asset_ids,
        segment_ids,
      },
      DEFAULT_STRINGIFY_OPTIONS,
    )}`,
    {
      responseType: 'blob',
      headers: requireAuthorizationHeaders({}),
    },
  );
};
