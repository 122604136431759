import React, { ComponentProps, ReactNode } from 'react';
import { cn } from '@/shared/lib/css/cn';
import './field.css';
import { FormItemHintTooltip } from 'stories';
import { useHovered } from '@/shared/lib/hooks/useHovered';
import { isEmpty } from 'lodash-es';
import { twMergeExtended } from '@/shared/lib/css/tw-merge';

export const FieldsContainer = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<PropsWithClassName>
>(({ children, className }, ref) => (
  <div
    ref={ref}
    className={twMergeExtended('flex flex-col gap-tw-4', className)}
  >
    {children}
  </div>
));

const RequiredMark = ({ className }: PropsWithClassName) => (
  <div className={cn('sre-field__required inline-flex', className)}>*</div>
);

const Label = ({
  children,
  className,
  newGen,
  required,
  ...props
}: React.PropsWithChildren<
  PropsWithClassName & {
    newGen?: boolean;
    required?: boolean;
  } & JSX.IntrinsicElements['label']
>) => (
  <label
    className={cn(
      'label-regular text-neutral-850',
      {
        ['font-semibold']: newGen,
      },
      className,
    )}
    {...props}
  >
    {children}
    {required && <RequiredMark className="ml-tw-0.5" />}
  </label>
);

const LabelContainer = ({ children }: React.PropsWithChildren) => (
  <div className="flex items-center gap-tw-1">{children}</div>
);

const Note = ({
  children,
  className,
}: React.PropsWithChildren<PropsWithClassName>) => (
  <div className={cn('sre-field__note', className)}>{children}</div>
);

const ItemsContainer = ({
  children,
  className,
}: React.PropsWithChildren<PropsWithClassName>) => (
  <div className={cn('sre-field__items', className)}>{children}</div>
);

interface ContainerProps
  extends React.PropsWithChildren<JSX.IntrinsicElements['div']> {
  noLabel?: boolean;
  flexVersion?: boolean;
}

const Container = ({
  children,
  className,
  noLabel,
  flexVersion,
  ...props
}: ContainerProps) => (
  <div
    className={twMergeExtended(
      'sre-field',
      noLabel && 'sre-field_no-label',
      flexVersion && 'sre-field_flex-version gap-tw-2',
      className,
    )}
    {...props}
  >
    {children}
  </div>
);

interface Props
  extends Partial<Pick<ComponentProps<typeof FormItemHintTooltip>, 'hint'>>,
    ContainerProps {
  children: ReactNode | ReactNode[];
  labelFor?: string;
  labelText?: React.ReactNode;
  note?: React.ReactNode;
  error?: string;
  required?: boolean;
  className?: string;
  classes?: {
    label?: string;
    items?: string;
  };
  newGen?: boolean;
}

export const Field = ({
  className,
  hint,
  children,
  labelText,
  labelFor,
  note,
  error,
  required,
  noLabel,
  newGen,
  classes,
  ...props
}: Props) => {
  const { hovered, onMouseLeave, onMouseOver } = useHovered(false);
  return (
    <Field.Container
      className={cn(className)}
      noLabel={noLabel}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      {...props}
    >
      <Field.LabelContainer>
        <Field.Label
          required={required}
          newGen={newGen}
          className={classes?.label}
          htmlFor={labelFor}
        >
          {labelText}
        </Field.Label>
        {hint && <FormItemHintTooltip hint={hint} hovered={hovered} />}
      </Field.LabelContainer>
      {!isEmpty(note) && <Field.Note className="!mb-tw-3">{note}</Field.Note>}
      <Field.ItemsContainer className={classes?.items}>
        {children}
      </Field.ItemsContainer>
      {error && <div className="sre-field__error">{error}</div>}
    </Field.Container>
  );
};

Field.Container = Container;
Field.ItemsContainer = ItemsContainer;
Field.LabelContainer = LabelContainer;
Field.RequiredMark = RequiredMark;
Field.Label = Label;
Field.Note = Note;

export default Field;
