import React, { FC, PropsWithChildren, ReactNode } from 'react';

interface IBracketsWrapperProps {
  children: ReactNode;
  negative?: boolean;
  variant?: 'brackets' | 'sign';
  styles?: {
    brackets: React.CSSProperties;
  };
}

const Container = ({ children }: PropsWithChildren) => (
  <span className="inline-flex items-center gap-tw-0.5">{children}</span>
);

const SignWrapper: FC<IBracketsWrapperProps & PropsWithClassName> = ({
  children,
  className,
  negative,
  styles,
  variant = 'brackets',
}) => {
  if (variant === 'sign') {
    return (
      <Container>
        <span style={styles?.brackets} className={className}>
          {negative ? '-' : '+'}
        </span>
        {children}
      </Container>
    );
  }
  if (!negative) {
    return <>{children}</>;
  }
  return (
    <Container>
      <span style={styles?.brackets} className={className}>
        (
      </span>
      {children}
      <span style={styles?.brackets} className={className}>
        )
      </span>
    </Container>
  );
};

export default SignWrapper;
