import { Field, Icon } from '@/stories';

import {
  EntityTag,
  EntityTagLabel,
  EntityTagsSelect,
  isSystemTagAndNotUniform,
  RawEntityTagsSelect,
  TagEntity,
} from 'bundles/Shared/entities/tag';
import { Control, useController } from 'react-hook-form';
import { FieldPath } from 'react-hook-form/dist/types/path/eager';
import { UnknownRecord } from 'type-fest/source/internal';

export const EntityTagField = <
  TFieldValues extends UnknownRecord = UnknownRecord,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  disabled,
  entity,
  isNewEntity,
  options,
  className,
}: {
  control: Control<TFieldValues>;
  name: TFieldName;
  entity?: TagEntity;
  disabled?: boolean;
  options?: { id: string; name: string }[];
  isNewEntity?: boolean;
} & PropsWithClassName) => {
  const { field } = useController({
    name,
    control,
  });

  const TagsSelect = entity == null ? RawEntityTagsSelect : EntityTagsSelect;
  return (
    <Field
      className={className}
      labelText="Tags"
      classes={{
        items: 'flex flex-col gap-tw-2',
      }}
    >
      <TagsSelect
        onChange={(tags) => {
          field.onChange(tags);
        }}
        options={options}
        entity={entity}
        disabled={disabled}
        value={field.value}
      />
      <EntityTagLabel.List>
        {(field.value as EntityTag[])?.map((tag) => (
          <EntityTagLabel
            key={tag.id}
            label={tag.name}
            className="cursor-pointer"
            disabled={!isNewEntity && isSystemTagAndNotUniform(tag)}
            onClick={() => {
              field.onChange(field.value?.filter((t) => t.id !== tag.id));
            }}
            endIcon={<Icon iconName="closeSmall" />}
          />
        ))}
      </EntityTagLabel.List>
    </Field>
  );
};
