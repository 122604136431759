import type { ReportBuilderTemplateEagleEyeWidgetTypes } from '@/bundles/Shared/entities/dashboard';
import { DEFAULT_OBJECT_LEVEL_ASSET_ID_PARAM_KEY } from '@/bundles/Shared/entities/dashboard/model/slices/reportBuilderTemplatesSlice';
import { useGetApiSettingsReportBuilderGotenbergTemplatesMetaQuery } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesEnhancedApi';
import type {
  GetApiSettingsReportBuilderGotenbergTemplatesMetaApiArg,
  ReportBuilderTemplateGroupWidgetSectionDto,
} from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';
import { useAssetIdSearchParam } from '@/shared/lib/hooks/navigation/useAssetIdSearchParam';

export const isReportBuilderTempalteWidgetEditable = (
  widgetType:
    | ReportBuilderTemplateEagleEyeWidgetTypes
    | ReportBuilderTemplateGroupWidgetSectionDto['widgetType'],
) => {
  switch (widgetType) {
    case 'kpi_card':
    case 'xy_chart':
    case 'historical_review_table':
    case 'delinquency_tracker_table':
    case 'financial_table_single_period':
    case 'financial_table_single_date':
    case 'unit_type_table':
    case 'kpi_table':
    case 'kpi_table_single_date':
    case 'xy_chart_single_kpi':
    case 'global_lease_table': {
      return true;
    }
    default: {
      return false;
    }
  }
};
export const isReportBuilderTempalteWidgetIncludeSourceUnitTypesEditable = (
  widgetType: ReportBuilderTemplateGroupWidgetSectionDto['widgetType'],
) => {
  switch (widgetType) {
    case 'recent_rent_roll_table': {
      return true;
    }
    default: {
      return false;
    }
  }
};

export const useGetReportBuilderTemplateMetaWithSearchParamAssetId = () => {
  const assetId = useAssetIdSearchParam();

  return useGetApiSettingsReportBuilderGotenbergTemplatesMetaQuery({
    [DEFAULT_OBJECT_LEVEL_ASSET_ID_PARAM_KEY]: assetId ? Number(assetId) : null,
  } as unknown as GetApiSettingsReportBuilderGotenbergTemplatesMetaApiArg);
};
