import { DASHBOARD_SKELETON_JSX } from '@/bundles/Settings/components/REport/Dashboards/Dashboard/config';
import NoDataOverlay from '@/bundles/Shared/components/NoDataOverlay';
import { cn } from '@/shared/lib/css/cn';
import { BaseCard } from '@/shared/ui/BaseCard';
import { ItemTableCounter } from '@/shared/ui/ItemTableCounter';
import { Button } from '@/stories';

import type { ComponentProps } from 'react';

export const TileList = (props: LayoutProps) => {
  return (
    <div
      className={cn('flex flex-col gap-tw-4 p-tw-6 pt-tw-9', props.className)}
    >
      {props.children}
    </div>
  );
};

TileList.Header = (props: LayoutProps) => (
  <div className={cn('flex items-center gap-tw-2', props.className)}>
    {props.children}
  </div>
);

TileList.ItemCounter = (
  props: Pick<ComponentProps<typeof ItemTableCounter>, 'count'> &
    Partial<ComponentProps<typeof ItemTableCounter>>,
) => {
  return <ItemTableCounter word="Item" {...props} />;
};

TileList.CreateButton = ({
  entityName,
  ...props
}: {
  entityName: string;
} & Partial<ComponentProps<typeof Button>>) => (
  <Button iconName="add" size="s" variant="primary" {...props}>
    Create {entityName}
  </Button>
);

TileList.Items = (
  props: LayoutProps & {
    isLoading: boolean;
    isListEmpty: boolean;
    isQueryEmpty: boolean;
    noDataOverlayTitle?: string;
  },
) => (
  <BaseCard.Wrapper>
    {props.isLoading && DASHBOARD_SKELETON_JSX}
    {!props.isLoading && props.children}
    {!props.isLoading && props.isListEmpty && (
      <NoDataOverlay
        className="col-span-4"
        title={
          props.isQueryEmpty
            ? props.noDataOverlayTitle ?? 'No items found'
            : 'Not found'
        }
      />
    )}
  </BaseCard.Wrapper>
);
