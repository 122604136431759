import type { ReportBuilderTemplate } from '@/entities/report/reportBuilder';
import { createContext, useContext } from 'react';

type ReportBuilderTemplateContextType = {
  groupId: string;
  widgetId: string;
  templateId: string;
  kind: ReportBuilderTemplate['kind'];
};
export const ReportBuilderTemplateContext =
  createContext<ReportBuilderTemplateContextType | null>(null);

export const useReportBuilderTemplateContext = () => {
  return useContext(ReportBuilderTemplateContext);
};
