import { Draft, produce } from 'immer';
import { KpiCardWidgetRowForm } from './row.form';
import { KpiCardWidgetConfig } from '../model';
import {
  maxIdGenerator,
  transformPeriodShiftFormToDto,
  transformPeriodTypeFormToDto,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/config';

export const upsertKpiSettings = (
  form: KpiCardWidgetRowForm,
  draft: Draft<KpiCardWidgetConfig>,
) => {
  let rowIndex = draft.viz_config.kpis.findIndex(
    (r) => r.key === form.key?.toString(),
  );
  if (rowIndex === -1) {
    rowIndex = draft.viz_config.kpis.length;
  }
  draft.viz_config.kpis[rowIndex] = produce(
    draft.viz_config.kpis[rowIndex] ?? {
      key: form.key?.toString(),
    },
    (draftRow) => {
      draftRow.value_display_options = form.value_display_options;
      draftRow.cell_renderer = form.text_display_strategy
        ? {
            type: 'text',
            params: {
              variant: form.text_display_strategy,
            },
          }
        : undefined;
    },
  );
};

export const upsertKpi = (
  form: KpiCardWidgetRowForm,
  config: KpiCardWidgetConfig,
) => {
  return produce(config, (draft) => {
    let kpiIndex = draft.kpis.findIndex((k) => k.key === form.key);
    if (kpiIndex === -1) {
      kpiIndex = draft.kpis.length;
    }
    const kpiKey = form.key ?? maxIdGenerator(draft.kpis, 'key');
    draft.kpis[kpiIndex] = {
      type: form.type,
      label: form.label,
      expression: form.expression,
      period_shift: transformPeriodShiftFormToDto(
        form.period_shift ?? undefined,
      ),
      period_type: transformPeriodTypeFormToDto(form.period_type),
      key: kpiKey,
      history_size: 0,
    };

    return upsertKpiSettings(
      {
        ...form,
        key: kpiKey,
      },
      draft,
    );
  });
};

const removeKpi = (key: string, config: KpiCardWidgetConfig) => {
  return produce(config, (draft) => {
    draft.kpis = draft.kpis.filter((k) => k.key.toString() !== key);
    draft.viz_config.kpis = draft.viz_config.kpis.filter(
      (k) => k.key.toString() !== key,
    );
  });
};

const cloneKpi = (key: string, config: KpiCardWidgetConfig) => {
  return produce(config, (draft) => {
    const kpi = draft.kpis.find((k) => k.key.toString() === key);
    const kpiSettings = draft.viz_config.kpis.find(
      (k) => k.key.toString() === key,
    );
    if (kpi && kpiSettings) {
      const newKpiKey = maxIdGenerator(draft.kpis, 'key');
      draft.kpis.push({
        ...kpi,
        key: newKpiKey,
      });
      draft.viz_config.kpis.push({
        ...kpiSettings,
        key: newKpiKey.toString(),
      });
    }
  });
};
export const kpiCardUpdaters = {
  upsertKpi,
  removeKpi,
  cloneKpi,
};
