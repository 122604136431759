/* eslint-disable check-file/no-index */
import { cn } from '@/shared/lib/css/cn';
import SkeletonBlock from '@/stories/ProjectCard/SkeletonBlock';

export const ComparisonDashboardLayout = (props: LayoutProps) => {
  return (
    <div className={cn('relative flex min-h-screen', props.className)}>
      {props.children}
    </div>
  );
};

ComparisonDashboardLayout.SideBar = (props: LayoutProps) => {
  return (
    <div
      className={cn(
        'absolute left-0 top-0 z-0 flex h-screen max-h-screen min-h-screen w-[400px] flex-col bg-neutral-100 pb-tw-4',
        props.className,
      )}
    >
      {props.children}
    </div>
  );
};

ComparisonDashboardLayout.SideBarLoading = (props: LayoutProps) => {
  return (
    <div
      className={cn(
        'flex h-full w-full flex-col items-center gap-tw-6 pt-tw-6',
        props.className,
      )}
    >
      <SkeletonBlock className="h-[64px] w-[352px]" />
      <SkeletonBlock className="h-full w-[352px]" />
    </div>
  );
};
ComparisonDashboardLayout.Body = (props: LayoutProps) => {
  return (
    <div
      className={cn(
        'z-10 flex h-screen w-full flex-col overflow-auto bg-neutral-150 transition-all',
        props.className,
      )}
    >
      {props.children}
    </div>
  );
};

ComparisonDashboardLayout.BoardsBody = (props: LayoutProps) => {
  return (
    <div
      className={cn(
        'flex grow flex-col gap-tw-4 px-tw-8 py-tw-4 pb-tw-8',
        props.className,
      )}
    >
      {props.children}
    </div>
  );
};
