import * as React from 'react';
import { useMemo, useState } from 'react';

import GroupedCheckboxes from 'bundles/Shared/components/groupedSelection/GroupedCheckboxes';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import useSelected from '@/shared/lib/hooks/useSelected';
import { exists } from 'lib/typeHelpers';
import { range, xor } from 'lodash-es';
import pluralize from 'pluralize';
import { Button, Field, Modal, ModalActions, Tumbler } from '@/stories';
import Select, { ISelectOption } from 'stories/FormControls/Select/Select';
import { BudgetYear, BudgetYearMetaLegalEntity } from '../operational/types';
import { dateToYear } from '@/shared/lib/formatting/dates';
import { cn } from '@/shared/lib/css/cn';

type BudgetYearFormValues = {
  year: BudgetYear['year'];
  legalEntities?: BudgetYearMetaLegalEntity[];
};

interface Props extends DialogProps<BudgetYearFormValues> {
  years: BudgetYear['year'][];
  selectProps?: Partial<React.ComponentProps<typeof Select>>;
  legalEntities?: BudgetYearMetaLegalEntity[];
}

function CreateBudgetsForFutureYearsModal({
  onClose,
  onSubmit,
  years,
  selectProps,
  legalEntities,
}: Props) {
  const [selectedYearOption, setSelectedYearOption] =
    useState<ISelectOption<number>>();
  const {
    selectedItems: selectedLEs,
    selectAll,
    deselectAll,
    setSelectedItems,
    allSelected,
  } = useSelected({
    items: legalEntities ?? [],
    initialSelected: legalEntities ?? [],
  });
  const shouldShowLegalEntities = Boolean(legalEntities?.length);

  const yearsOptions = useMemo<ISelectOption<number>[]>(() => {
    if (years.length === 0) {
      const date = new Date();

      const option: ISelectOption<number> = {
        id: dateToYear(date),
        label: dateToYear(date).toString(),
      };

      return [option];
    }

    const maxYear = Math.max(...years);
    const minYear = Math.min(...years);
    const rangeOfYear = range(minYear, maxYear + 2);
    const xorOfYears = xor(rangeOfYear, years);
    const options: ISelectOption<number>[] = xorOfYears.map((y) => ({
      id: y,
      label: y.toString(),
    }));

    return options;
  }, []);

  const canSubmit = () =>
    (!shouldShowLegalEntities || selectedLEs.length > 0) && selectedYearOption;

  const handleSubmit = () => {
    if (!exists(selectedYearOption)) return;

    onSubmit?.({
      year: selectedYearOption.id,
      legalEntities: selectedLEs,
    });
  };

  const countableButtonText = !shouldShowLegalEntities
    ? 'Create Budgets'
    : `Create Budgets for ${
        allSelected ? 'All' : selectedLEs.length
      } Legal ${pluralize('Entities', selectedLEs.length)}`;

  return (
    <Modal
      header="Create Budgets"
      toggle={onClose}
      size={shouldShowLegalEntities ? '900' : '400'}
      classes={{
        body: cn('p-0', shouldShowLegalEntities ? 'h-[60vh] flex' : ''),
      }}
      bodyPadding="0"
      actions={
        <ModalActions>
          <Button onClick={onClose} variant="secondary">
            Cancel
          </Button>
          <Button
            disabled={!canSubmit()}
            onClick={handleSubmit}
            variant="success"
          >
            {countableButtonText}
          </Button>
        </ModalActions>
      }
    >
      <div className="sticky top-0 flex h-max flex-col gap-tw-4 p-tw-6">
        <Field labelText="Select year" required className="flex flex-col">
          <Select
            classes={{
              input: 'w-full',
            }}
            placeholder={'Search/Create Year'}
            selected={selectedYearOption}
            onSelectedChange={(selected) =>
              setSelectedYearOption(selected as ISelectOption<number>)
            }
            options={yearsOptions}
            {...selectProps}
          />
        </Field>
        {shouldShowLegalEntities && (
          <Field
            labelText="Select Legal Entities"
            required
            note="Which Legal Entities do you want to include for budgeting for selected year?"
          >
            <Tumbler
              onChange={() => (allSelected ? deselectAll() : selectAll())}
              checked={allSelected}
            >
              All Legal Entites
            </Tumbler>
          </Field>
        )}
      </div>
      {shouldShowLegalEntities && legalEntities?.length && (
        <div className="h-fit min-h-full w-full border-l border-solid border-neutral-150 bg-neutral-050 p-tw-6">
          <GroupedCheckboxes
            withSearch
            searchProps={{
              size: 'm',
            }}
            items={legalEntities}
            getLabelFromItem={(le) => le.name}
            selectedItems={selectedLEs}
            setSelectedItems={setSelectedItems}
          />
        </div>
      )}
    </Modal>
  );
}

export default CreateBudgetsForFutureYearsModal;
