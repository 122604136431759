import { formatUnixDate } from '@/shared/lib/formatting/dates';
import { CssVar } from '@/shared/config/cssVar';
import { cn } from '@/shared/lib/css/cn';

import { Icon, StatisticLine } from '@/stories';
import { Link } from '@reach/router';
import { memo } from 'react';
import { ForecastingForecastYear } from '@/entities/reconcile/forecasting/model';

type ForecastYearRowComponentProps = {
  row: ForecastingForecastYear;
} & PropsWithClassName;

const ForecastYearRowComponent = ({
  row,
  className,
}: ForecastYearRowComponentProps) => (
  <Link to={row.link}>
    <div
      className={cn(
        'flex gap-tw-2 rounded-xl bg-white p-tw-4 pr-tw-12 transition-shadow duration-200 hover:shadow-sm',
        className,
      )}
    >
      {/* TODO: FE-3617 fix icons */}
      {row.percentOfCompletion === 100 ? (
        <span className="my-tw-1 flex size-tw-4 shrink-0 items-center justify-center gap-tw-1 rounded-full bg-success-055 text-white">
          <Icon iconName="checkSmall" />
        </span>
      ) : (
        <span className="my-tw-1 flex size-tw-4 shrink-0 items-center justify-center gap-tw-1 rounded-full bg-danger-055 text-white">
          <Icon iconName="attention" className="text-[10px]" />
        </span>
      )}
      <div className="flex flex-grow flex-col gap-tw-4 text-xs text-neutral-550">
        <div className="flex flex-col gap-tw-2">
          <div className="text-base font-bold text-neutral-850">{row.year}</div>
          <div className="flex flex-col gap-tw-1">
            <div>{row.percentOfCompletion}% published Budgets</div>
            <StatisticLine
              statistics={{
                forecast: {
                  value: row.percentOfCompletion,
                  color: CssVar.green,
                },
                rest: {
                  value: 100 - row.percentOfCompletion,
                  color: CssVar.light10,
                },
              }}
            />
          </div>
        </div>
        <div>
          Last touched:{' '}
          <span className="text-neutral-850">
            {/* @ts-expect-error TODO: fix by FE-3653 */}
            {formatUnixDate(row.updatedAt, 'MMMM DD, YYYY h:mm A')}
          </span>
        </div>
      </div>
    </div>
  </Link>
);

export const ForecastYearRow = memo(ForecastYearRowComponent);
