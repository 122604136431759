import React, { useRef } from 'react';
import { cn } from '@/shared/lib/css/cn';
import bgPattern from './bg-pattern.png';

import { IconButton, Tooltip } from 'stories';
import { useNavigate } from '@reach/router';
import SkeletonBlock from 'stories/ProjectCard/SkeletonBlock';
import { twMergeExtended } from '@/shared/lib/css/tw-merge';
import { useIsTextTruncated } from '@/shared/lib/hooks/useIsTextTruncated';

const Grid = ({
  children,
  className,
}: React.PropsWithChildren<PropsWithClassName>) => (
  <div
    className={cn(
      'flex min-h-full flex-col bg-repeat px-tw-7 py-tw-8',
      className,
    )}
    style={{
      backgroundImage: `url("${bgPattern}")`,
    }}
  >
    {children}
  </div>
);

function Header({
  children,
  className,
}: React.PropsWithChildren<PropsWithClassName>) {
  return (
    <div
      className={cn(
        'flex h-[88px] items-center gap-tw-4 px-tw-6 py-tw-4',
        className,
      )}
    >
      {children}
    </div>
  );
}

const Title = ({
  title,
  subtitle,
  classes,
  withoutBackButton,
  className,
  onBackClick,
}: React.PropsWithChildren<
  PropsWithClassName & {
    title: React.ReactNode;
    withoutBackButton?: boolean;
    subtitle?: React.ReactNode;
    classes?: {
      title?: string;
      subtitle?: string;
    };
    className?: string;
    onBackClick?: () => void;
  }
>) => {
  const navigate = useNavigate();
  const ref = useRef(null);
  const isTextTruncated = useIsTextTruncated(ref, title);

  const handleBack = () => {
    if (onBackClick) {
      onBackClick();
    } else {
      navigate(-1);
    }
  };

  return (
    <div className={cn('flex h-full min-w-0 items-center gap-tw-4', className)}>
      {!withoutBackButton && (
        <IconButton
          className="h-auto shrink-0 self-stretch"
          onClick={handleBack}
          iconName="arrowLeft"
        />
      )}
      <div className="flex min-w-0 flex-col gap-tw-1">
        <span
          className={twMergeExtended(
            'secondary-semibold flex gap-tw-1 text-neutral-550',
            classes?.subtitle,
          )}
        >
          {subtitle}
        </span>
        <span
          ref={ref}
          className={twMergeExtended(
            'header6-bold text-ellipsis whitespace-nowrap text-neutral-800',
            classes?.title,
          )}
        >
          {title}
        </span>
        <Tooltip reference={ref} disabled={!isTextTruncated} mainText={title} />
      </div>
    </div>
  );
};

const Body = ({
  children,
  className,
}: React.PropsWithChildren<PropsWithClassName>) => (
  <div className={cn('flex grow flex-col gap-tw-6 p-tw-6 pt-0', className)}>
    {children}
  </div>
);

const LoadingPlaceholder = () => (
  <div className="flex h-screen flex-col">
    <Header>
      <SkeletonBlock className="h-full w-full " />
    </Header>
    <Body>
      <SkeletonBlock className="h-full w-full " />
    </Body>
  </div>
);

export function DashboardLayout({
  children,
  className,
}: React.PropsWithChildren<PropsWithClassName>) {
  return (
    <div className={cn('relative flex min-h-screen flex-col', className)}>
      {children}
    </div>
  );
}

export function ObjectLevelDashboardLayout({
  children,
  className,
}: React.PropsWithChildren<PropsWithClassName>) {
  return (
    <div className={cn('grid min-h-screen grid-cols-[400px,auto]', className)}>
      {children}
    </div>
  );
}

ObjectLevelDashboardLayout.Body = ({ children }: React.PropsWithChildren) => (
  <div className="flex h-screen flex-col overflow-auto">{children}</div>
);
ObjectLevelDashboardLayout.Sidebar = ({
  children,
  className,
}: React.PropsWithChildren<PropsWithClassName>) => (
  <div
    className={cn(
      'relative flex h-screen flex-col overflow-y-auto bg-neutral-100 pb-tw-6',
      className,
    )}
  >
    {children}
  </div>
);

ObjectLevelDashboardLayout.LoadingPlaceholder = () => (
  <div className={cn('grid min-h-screen grid-cols-[360px,auto]')}>
    <ObjectLevelDashboardLayout.Sidebar>
      <Header>
        <SkeletonBlock className="h-full w-full" />
      </Header>
      <div className="grow p-tw-4">
        <SkeletonBlock className="h-full w-full" />
      </div>
    </ObjectLevelDashboardLayout.Sidebar>
    <ObjectLevelDashboardLayout.Body>
      <SkeletonBlock className="h-full w-full" />
    </ObjectLevelDashboardLayout.Body>
  </div>
);

const StickyHeader = ({
  children,
  className,
}: React.PropsWithChildren<PropsWithClassName>) => (
  <div
    className={cn(
      'sticky top-0 z-[10] flex flex-col bg-neutral-150',
      className,
    )}
  >
    {children}
  </div>
);

Header.Title = Title;
DashboardLayout.LoadingPlaceholder = LoadingPlaceholder;
DashboardLayout.Body = Body;
DashboardLayout.Grid = Grid;
DashboardLayout.Header = Header;
DashboardLayout.StickyHeader = StickyHeader;
