import { OLD_ROUTES } from '@/shared/lib/hooks/navigation/deprecatedNavigation';
import { useLocation } from '@reach/router';
import { TDevelopmentCategorizableType } from 'bundles/REconcile/actions/developmentBudgetVariance';
import { CapitalInvestmentEntryType } from 'bundles/REturn/actions/types';
import { ReturnInvestmentObjectTab } from 'bundles/REturn/components/Home/investmentObjectDetailsModal/utils';
import QueryString, { parse, stringify } from 'qs';

interface Route {
  /**
   * Kebab cased
   * @example financial-formulas
   */
  path: string;
  /**
   * Camel cased 🐪
   * @example financialFormulas
   */
  name: string;
  children?: readonly Route[];
}

type PathObj<Path extends string, CurrentPath extends string> = {
  /**
   * @example "syncing"
   */
  path: CurrentPath;
  /**
   * @example "/settings/report/syncing"
   */
  fullPath: Path;
};

type MergeArrayOfObjects<T, Path extends string = ''> = T extends readonly [
  infer R,
  ...infer Rest,
]
  ? RecursiveValues<R, Path> & MergeArrayOfObjects<Rest, Path>
  : unknown;

type ReplaceTrailingSlash<T extends string> = T extends `//${infer R}`
  ? `/${R}`
  : T;

type GetPath<
  Path extends string,
  CurrentPath extends string,
> = ReplaceTrailingSlash<`${Path}/${CurrentPath}`>;

type RecursiveValues<T, Path extends string = ''> = T extends {
  name: infer Name extends string;
  path: infer CurrentPath extends string;
}
  ? {
      [Prop in Name as Name]: T extends { children: infer Children }
        ? MergeArrayOfObjects<Children, GetPath<Path, CurrentPath>> &
            PathObj<GetPath<Path, CurrentPath>, CurrentPath>
        : PathObj<GetPath<Path, CurrentPath>, CurrentPath>;
    }
  : object;

type ExtractParam<Path, NextPart> = Path extends `:${infer Param}`
  ? Record<Param, string> & NextPart
  : NextPart;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ExtractParams<Path> = Path extends `${infer Segment}/${infer Rest}`
  ? ExtractParam<Segment, ExtractParams<Rest>>
  : ExtractParam<Path, Record<string, unknown>>;

const MOBILE_ROUTES = [
  {
    path: 'use-desktop-version',
    name: 'useDesktopVersion',
  },
] as const;

const NEW_ROUTES = {
  name: 'root',
  path: '',
  children: [
    ...MOBILE_ROUTES,
    {
      path: 'assets',
      name: 'assets',
      children: [
        {
          name: 'asset',
          path: ':assetSlug',
          children: [
            {
              name: 'media',
              path: 'media',
              children: [
                {
                  name: 'albums',
                  path: 'albums',
                  children: [{ name: 'album', path: ':id' }],
                },
              ],
            },
            { name: 'documents', path: 'documents' },
            {
              name: 'reports',
              path: 'reports',
            },
          ],
        },
      ],
    },
    {
      path: 'funds',
      name: 'funds',
      children: [
        {
          name: 'fund',
          path: ':fundSlug',
          children: [{ name: 'documents', path: 'documents' }],
        },
      ],
    },
    {
      path: 'documents',
      name: 'documents',
    },
    {
      path: 'documentation',
      name: 'documentation',
      children: [
        { name: 'doc', path: ':id' },
        { name: 'new', path: ':id' },
      ],
    },
    {
      path: 'portfolio',
      name: 'portfolio',
    },
    {
      path: 'settings',
      name: 'settings',
      children: [
        {
          name: 'report',
          path: 'report',
          children: [
            {
              name: 'syncing',
              path: 'syncing',
              children: [{ name: 'syncingKind', path: ':syncingKindId' }],
            },
            {
              name: 'connections',
              path: 'connections',
              children: [{ name: 'connection', path: ':connectionId' }],
            },
            {
              name: 'unitTypes',
              path: 'unit-types',
            },
            {
              name: 'chargeCodes',
              path: 'charge-codes',
            },
            {
              name: 'nonRevenueUnits',
              path: 'non-revenue-units',
            },
            { name: 'jobCostCodes', path: 'job-cost-codes' },
            { name: 'dataPeriods', path: 'data-periods' },
            { name: 'financial', path: 'financial' },
            {
              name: 'tableBuilder',
              path: 'table-builder',
              children: [{ name: 'tableSlug', path: ':tableSlug' }],
            },
            { name: 'files', path: 'files' },
            { name: 'dataImports', path: 'data-imports' },
            {
              name: 'dashboards',
              path: 'dashboards',
              children: [
                {
                  name: 'new',
                  path: 'new',
                },
                {
                  name: 'dashboard',
                  path: ':dashboardId',
                  children: [
                    { name: 'widget', path: ':boardId/widgets/:widgetId' },
                  ],
                },
              ],
            },
            { name: 'segments', path: 'segments' },
            { name: 'vendors', path: 'vendors' },
            {
              name: 'financialFormulas',
              path: 'financial-formulas',
              children: [
                {
                  name: 'formula',
                  path: ':formulaSlug',
                },
              ],
            },
            {
              name: 'manualVariables',
              path: 'manual-variables',
              children: [
                {
                  name: 'manualVariable',
                  path: ':manualVariableSlug',
                },
              ],
            },
            {
              name: 'thresholds',
              path: 'thresholds',
              children: [
                {
                  name: 'threshold',
                  path: ':thresholdKind',
                },
              ],
            },
            {
              name: 'reportTemplates',
              path: 'report-templates',
            },
          ],
        },
        {
          name: 'portal',
          path: 'portal',
          children: [
            {
              name: 'legalEntities',
              path: 'legal-entities',
              children: [{ name: 'periods', path: ':id/periods' }],
            },
            { name: 'core', path: 'core' },
            { name: 'funds', path: 'funds' },
            { name: 'assets', path: 'assets' },
            { name: 'investmentEntities', path: 'investment-entities' },
          ],
        },
        {
          name: 'pipeline',
          path: 'pipeline',
          children: [
            { name: 'core', path: 'core' },
            { name: 'fields', path: 'fields' },
            { name: 'employees', path: 'employees' },
            { name: 'cards', path: 'cards' },
          ],
        },
        {
          name: 'emails',
          path: 'emails',
          children: [
            {
              name: 'templates',
              path: 'templates',
              children: [
                {
                  name: 'custom',
                  path: 'custom',
                  children: [
                    {
                      name: 'new',
                      path: 'new',
                    },
                    {
                      name: 'customTemplate',
                      path: ':id',
                      children: [{ name: 'edit', path: 'edit' }],
                    },
                  ],
                },
                {
                  name: 'feedMessages',
                  path: 'feed-messages',
                  children: [
                    {
                      name: 'feedMessage',
                      path: ':feedMessageTemplateId',
                      children: [{ name: 'edit', path: 'edit' }],
                    },
                  ],
                },
                {
                  name: 'forgotPassword',
                  path: 'forgot-password',
                  children: [
                    {
                      name: 'forgotPassword',
                      path: ':forgotPasswordTemplateId',
                      children: [{ name: 'edit', path: 'edit' }],
                    },
                  ],
                },
                {
                  name: 'invitations',
                  path: 'invitations',
                  children: [
                    {
                      name: 'new',
                      path: 'new',
                    },
                    {
                      name: 'invitation',
                      path: ':invitationTemplateId',
                      children: [{ name: 'edit', path: 'edit' }],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'reconcile',
      path: 'reconcile',
      children: [
        {
          name: 'forecasting',
          path: 'forecasting',
          children: [
            {
              name: 'forecast',
              path: ':forecastName',
            },
            {
              name: 'budgets',
              path: ':forecastName/:year',
              children: [
                {
                  name: 'budget',
                  path: ':legalEntityCode',
                },
              ],
            },
          ],
        },
        {
          name: 'underwriting',
          path: 'underwriting',
          children: [
            {
              name: 'legalEntity',
              path: ':legalEntityId',
            },
          ],
        },
        {
          name: 'operational',
          path: 'operational',
          children: [
            {
              name: 'budgetTable',
              path: ':budgetId',
            },
          ],
        },
        {
          name: 'development',
          path: 'development',
          children: [
            {
              name: 'legalEntity',
              path: ':legalEntityCode',
              children: [
                { name: 'snapshot', path: 'snapshot' },
                { name: 'summary', path: 'summary' },
                { name: 'reallocations', path: 'reallocations' },
                { name: 'payApps', path: 'pay-apps' },
                { name: 'changeOrderLogs', path: 'change-order-logs' },
                { name: 'changeEventLogs', path: 'change-event-logs' },
                {
                  name: 'requisitions',
                  path: 'requisitions',
                  children: [
                    {
                      name: 'requisition',
                      path: ':requisitionId',
                      children: [
                        {
                          name: 'invoices',
                          path: 'invoices',
                          children: [{ name: 'invoice', path: ':invoiceId' }],
                        },
                        {
                          name: 'lineItems',
                          path: 'line-items',
                          children: [
                            {
                              name: 'transactions',
                              path: ':lineItemId/transactions',
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  name: 'requisitionInvoices',
                  path: 'requisition_invoices',
                  children: [
                    {
                      name: 'requisition',
                      path: ':requisitionId',
                      children: [{ name: 'invoice', path: ':invoiceId' }],
                    },
                  ],
                },
                {
                  name: 'invoices',
                  path: 'invoices',
                  children: [
                    {
                      name: 'approve',
                      path: 'to-approve/:invoiceId',
                    },
                    {
                      name: 'pay',
                      path: 'to-pay/:invoiceId',
                      children: [
                        {
                          name: 'markForPayment',
                          path: 'mark-for-payment',
                        },
                        {
                          name: 'markAsPaid',
                          path: 'mark-as-paid',
                        },
                      ],
                    },
                    {
                      name: 'edit',
                      path: 'edit/:invoiceId',
                    },
                    {
                      name: 'verify',
                      path: 'verify/:invoiceId',
                    },
                  ],
                },

                {
                  name: 'payableInvoices',
                  path: 'payable_invoices',
                  children: [
                    {
                      name: 'category',
                      path: ':categoryId/category/:type/categoryType',
                      children: [{ name: 'invoice', path: ':invoiceId' }],
                    },
                  ],
                },
                {
                  name: 'draws',
                  path: 'draws',
                  children: [
                    {
                      path: ':drawId',
                      name: 'draw',
                      children: [
                        {
                          name: 'invoices',
                          path: 'invoices',
                          children: [{ name: 'invoice', path: ':invoiceId' }],
                        },
                      ],
                    },
                  ],
                },
                { name: 'reallocation', path: 'reallocation/:reallocationId' },
                {
                  name: 'changeOrder',
                  path: 'change-order/:changeOrderId',
                  children: [{ name: 'approve', path: 'approve' }],
                },
                {
                  name: 'changeEvent',
                  path: 'change-event/:id',
                  children: [
                    {
                      name: 'mobile',
                      path: 'mobile',
                    },
                  ],
                },
                { name: 'budgetEditor', path: 'budgets/:budgetId/editor' },
                {
                  name: 'settings',
                  path: 'settings/:tab',
                  children: [
                    {
                      name: 'approvers',
                      path: 'approvers',
                    },
                  ],
                },
              ],
            },
          ],
        },
        { name: 'developmentSettings', path: 'development_settings/:tab' },
      ],
    },
    {
      name: 'report',
      path: 'report',
      children: [
        { name: 'dashboards', path: 'dashboards/:dashboardId' },
        { name: 'financials', path: 'financials/:reportName' },
        {
          name: 'comparisonDashboards',
          path: 'comparison-mode-dashboards/:dashboardId',
        },
      ],
    },
    {
      name: 'deals',
      path: 'deals',
      children: [{ name: 'deal', path: ':dealId' }],
    },
    {
      name: 'scoreboards',
      path: 'scoreboards',
      children: [
        { name: 'pipeline', path: 'pipeline' },
        // todo server-side path
        { name: 'report', path: 'report' },
        { name: 'scoreboard', path: ':slug' },
      ],
    },
    {
      name: 'return',
      path: 'return',
      children: [
        {
          name: 'object',
          path: ':objectId',
          children: [
            {
              name: 'createEntry',
              path: 'create-entry',
            },
            {
              name: 'createTransfer',
              path: 'create-transfer',
            },
          ],
        },
      ],
    },
    {
      name: 'userManagement',
      path: 'user-management',
      children: [
        {
          name: 'activity',
          path: 'activity',
        },
      ],
    },
    {
      name: 'reports',
      path: 'reports',
      children: [
        {
          name: 'report',
          path: ':id',
          children: [{ name: 'view', path: 'view' }],
        },
      ],
    },
    {
      name: 'reportBuilderTemplates',
      path: 'report-builder-templates',
      children: [
        {
          name: 'reportBuilderTemplate',
          path: ':templateId',
          children: [
            { name: 'view', path: 'view' },
            {
              name: 'widgets',
              path: 'widgets',
              children: [{ name: 'widget', path: ':widgetId' }],
            },
          ],
        },
      ],
    },
  ],
} as const satisfies Route;

export const transformRoutes = <T extends Route>(routes: T) => {
  const traverse = (
    current: Route,
    fullPath: string = current.path,
  ): {
    [key: string]: RecursiveValues<Route> | { path: string; fullPath: string };
  } => {
    const { path, children, name } = current;
    if (children == null) {
      return {
        [name]: {
          path,
          fullPath,
        },
      };
    }

    return {
      [name]: children
        .map((route) => traverse(route, `${fullPath}/${route.path}`))
        .reduce(
          (previousValue, currentValue) => ({
            ...previousValue,
            ...currentValue,
          }),
          {
            path,
            fullPath,
          },
        ),
    };
  };
  return traverse(routes) as RecursiveValues<T>;
};

export const ROUTES = transformRoutes(NEW_ROUTES);
export const { root: ROUTES_ROOT } = ROUTES;

type TRoutePaths = typeof OLD_ROUTES;
type TScreens = keyof TRoutePaths;

export type ReconcileDevelopmentPathParams = {
  legalEntityCode: string;
};

type QueryRecord<K extends string, V extends string = string> = Record<K, V>;

export type TRouteQueryParams = {
  [ROUTES_ROOT.report.comparisonDashboards.fullPath]: {
    boardId?: string;
  };
  [ROUTES_ROOT.settings.report.unitTypes.fullPath]: {
    mapped?: 'true' | 'false';
  };
  [ROUTES_ROOT.reconcile.operational.fullPath]: QueryRecord<'yearId'>;
  [ROUTES_ROOT.reports.report.fullPath]: QueryRecord<'assetId'>;
  [ROUTES_ROOT.reports.report.view.fullPath]: QueryRecord<
    'assetId' | 'hidePlaceholders'
  >;
  [ROUTES_ROOT.reconcile.development.legalEntity.requisitions.requisition
    .invoices.fullPath]: {
    categoryId: string;
    categoryType: TDevelopmentCategorizableType;
    generalLedgers: string;
  };
  [ROUTES_ROOT.return.fullPath]: {
    detailsId?: string;
    tab?: ReturnInvestmentObjectTab;
  };
  [ROUTES_ROOT.return.object.createEntry.fullPath]: {
    entryType?: CapitalInvestmentEntryType;
    capitalInvestmentId?: string;
  };
  [ROUTES_ROOT.reconcile.underwriting.legalEntity.fullPath]: {
    budgetId?: string;
  };
  [ROUTES_ROOT.settings.report.manualVariables.manualVariable.fullPath]: {
    manualVariableId?: string;
  };
};

export type TRouteParams = {
  RETURN_OWNERSHIPS_SCREEN: { objectId: string };
  RECONCILE_DEVELOPMENT: { projectId?: string };
  RECONCILE_DEVELOPMENT_SETTINGS: {
    tab?: 'companies' | 'contacts' | 'sovcs' | 'cors' | 'jccs';
  };
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY: ReconcileDevelopmentPathParams;
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_REALLOCATIONS: ReconcileDevelopmentPathParams;
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_SNAPSHOT: ReconcileDevelopmentPathParams;
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_SUMMARY: ReconcileDevelopmentPathParams;
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_CHANGE_EVENT_MOBILE: ReconcileDevelopmentPathParams;
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_REALLOCATION: ReconcileDevelopmentPathParams & {
    reallocationId: number;
  };
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_PAY_APPS: ReconcileDevelopmentPathParams;
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_CHANGE_ORDERS: ReconcileDevelopmentPathParams;
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_CHANGE_EVENTS: ReconcileDevelopmentPathParams;
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_INVOICES: ReconcileDevelopmentPathParams;
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_INVOICES_PAYMENT: ReconcileDevelopmentPathParams & {
    invoiceId: UUID;
  };
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_PAYABLE_INVOICES: ReconcileDevelopmentPathParams;
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_PAYABLE_INVOICES_CATEGORY: ReconcileDevelopmentPathParams & {
    categoryId: number;
  };
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_PAYABLE_INVOICES_CATEGORY_ITEM: ReconcileDevelopmentPathParams & {
    categoryId: number;
    invoiceId: string;
  };
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_CHANGE_ORDER: ReconcileDevelopmentPathParams & {
    changeOrderId: number;
  };
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_CHANGE_EVENT: ReconcileDevelopmentPathParams & {
    id: string;
  };
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_BUDGET_EDITOR: ReconcileDevelopmentPathParams & {
    budgetId: string;
  };
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_REQUISITIONS: ReconcileDevelopmentPathParams & {
    budgetId?: string;
  };
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_REQUISITIONS_INVOICES: ReconcileDevelopmentPathParams & {
    requisitionId: string;
  };
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_DRAWS: ReconcileDevelopmentPathParams;
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_DRAWS_INVOICES: ReconcileDevelopmentPathParams & {
    drawId: string;
  };
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_DRAWS_INVOICES_ITEM: ReconcileDevelopmentPathParams & {
    drawId: string;
    invoiceId: string;
  };
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_REQUISITIONS_INVOICES_ITEM: ReconcileDevelopmentPathParams & {
    requisitionId: string;
    invoiceId: string;
  };
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_DEVELOPMENT_LINE_ITEMS: ReconcileDevelopmentPathParams & {
    requisitionId: string;
    categoryId: string;
    categoryType: TDevelopmentCategorizableType;
    generalLedgers: string;
  };
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_SETTINGS: ReconcileDevelopmentPathParams & {
    tab:
      | 'legacy'
      | 'jcc'
      | 'sovc'
      | 'markups'
      | 'fees'
      | 'pay_apps'
      | 'workflow'
      | 'snapshot'
      | 'funding_sources';
  };
} & Record<TScreens, unknown>;

export const generatePath = <Path extends string>(
  path: Path,
  params: Record<string, string | number>,
) =>
  Object.keys(params).reduce(
    (finalPath, paramKey) =>
      finalPath.replace(`:${paramKey}`, params[paramKey].toString() ?? ''),
    path,
  );

export const generateUrl = <
  T extends string,
  QueryParams = T extends keyof TRouteQueryParams
    ? TRouteQueryParams[T]
    : Record<string, unknown>,
>(
  path: T,
  params: {
    pathParams?: ExtractParams<T>;
    queryParams?: QueryParams;
  } = {},
  options: QueryString.IStringifyOptions = {},
) => {
  const generatedPath = generatePath(
    path,
    params.pathParams ?? ({} as ExtractParams<T>),
  );
  return `${generatedPath}${stringify(params.queryParams, {
    addQueryPrefix: true,
    ...options,
  })}`;
};

const stripTrailingSlash = (str: string) =>
  str.endsWith('/') ? str.slice(0, -1) : str;

const SCREENS: {
  [K in TScreens]: (params: TRouteParams[K]) => string;
} = {
  ASSETS_ROOT: () => OLD_ROUTES.ASSETS_ROOT,
  FUNDS_ROOT: () => OLD_ROUTES.FUNDS_ROOT,
  RECONCILE_ROOT: () => OLD_ROUTES.RECONCILE_ROOT,
  RECONCILE_OPERATIONAL: () => OLD_ROUTES.RECONCILE_OPERATIONAL,
  RECONCILE_DEVELOPMENT: (params) =>
    `${OLD_ROUTES.RECONCILE_DEVELOPMENT}${stringify(params, {
      addQueryPrefix: true,
    })}`,
  RECONCILE_DEVELOPMENT_SETTINGS: (params) =>
    stripTrailingSlash(
      generatePath(OLD_ROUTES.RECONCILE_DEVELOPMENT_SETTINGS, params),
    ),
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY: (params) =>
    generatePath(OLD_ROUTES.RECONCILE_DEVELOPMENT_LEGAL_ENTITY, params),
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_SNAPSHOT: (params) =>
    generatePath(
      OLD_ROUTES.RECONCILE_DEVELOPMENT_LEGAL_ENTITY_SNAPSHOT,
      params,
    ),
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_SUMMARY: (params) =>
    generatePath(OLD_ROUTES.RECONCILE_DEVELOPMENT_LEGAL_ENTITY_SUMMARY, params),
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_REALLOCATIONS: (params) =>
    generatePath(
      OLD_ROUTES.RECONCILE_DEVELOPMENT_LEGAL_ENTITY_REALLOCATIONS,
      params,
    ),
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_REALLOCATION: (params) =>
    generatePath(
      OLD_ROUTES.RECONCILE_DEVELOPMENT_LEGAL_ENTITY_REALLOCATION,
      params,
    ),
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_PAY_APPS: (params) =>
    generatePath(
      OLD_ROUTES.RECONCILE_DEVELOPMENT_LEGAL_ENTITY_PAY_APPS,
      params,
    ),
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_CHANGE_ORDERS: (params) =>
    generatePath(
      OLD_ROUTES.RECONCILE_DEVELOPMENT_LEGAL_ENTITY_CHANGE_ORDERS,
      params,
    ),
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_CHANGE_ORDER: (params) =>
    generatePath(
      OLD_ROUTES.RECONCILE_DEVELOPMENT_LEGAL_ENTITY_CHANGE_ORDER,
      params,
    ),
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_CHANGE_EVENTS: (params) =>
    generatePath(
      OLD_ROUTES.RECONCILE_DEVELOPMENT_LEGAL_ENTITY_CHANGE_EVENTS,
      params,
    ),
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_CHANGE_EVENT: (params) =>
    generatePath(
      OLD_ROUTES.RECONCILE_DEVELOPMENT_LEGAL_ENTITY_CHANGE_EVENT,
      params,
    ),
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_INVOICES: (params) =>
    generatePath(
      OLD_ROUTES.RECONCILE_DEVELOPMENT_LEGAL_ENTITY_INVOICES,
      params,
    ),
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_PAYABLE_INVOICES_CATEGORY: (params) =>
    generatePath(
      OLD_ROUTES.RECONCILE_DEVELOPMENT_LEGAL_ENTITY_PAYABLE_INVOICES_CATEGORY,
      params,
    ),
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_PAYABLE_INVOICES_CATEGORY_ITEM: (params) =>
    generatePath(
      OLD_ROUTES.RECONCILE_DEVELOPMENT_LEGAL_ENTITY_PAYABLE_INVOICES_CATEGORY_ITEM,
      params,
    ),
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_PAYABLE_INVOICES: (params) =>
    generatePath(
      OLD_ROUTES.RECONCILE_DEVELOPMENT_LEGAL_ENTITY_PAYABLE_INVOICES,
      params,
    ),
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_DRAWS: (params) =>
    generatePath(OLD_ROUTES.RECONCILE_DEVELOPMENT_LEGAL_ENTITY_DRAWS, params),
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_DRAWS_INVOICES: (params) =>
    generatePath(
      OLD_ROUTES.RECONCILE_DEVELOPMENT_LEGAL_ENTITY_DRAWS_INVOICES,
      params,
    ),
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_DRAWS_INVOICES_ITEM: (params) =>
    generatePath(
      OLD_ROUTES.RECONCILE_DEVELOPMENT_LEGAL_ENTITY_DRAWS_INVOICES_ITEM,
      params,
    ),
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_CHANGE_EVENT_MOBILE: (params) =>
    generatePath(
      OLD_ROUTES.RECONCILE_DEVELOPMENT_LEGAL_ENTITY_CHANGE_EVENT_MOBILE,
      params,
    ),
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_BUDGET_EDITOR: (params) =>
    generatePath(
      OLD_ROUTES.RECONCILE_DEVELOPMENT_LEGAL_ENTITY_BUDGET_EDITOR,
      params,
    ),
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_REQUISITIONS: ({
    legalEntityCode,
    ...params
  }) =>
    `${generatePath(
      OLD_ROUTES.RECONCILE_DEVELOPMENT_LEGAL_ENTITY_REQUISITIONS,
      {
        legalEntityCode,
      },
    )}${stringify(params, { addQueryPrefix: true })}`,
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_REQUISITIONS_INVOICES: ({
    legalEntityCode,
    requisitionId,
    ...params
  }) =>
    `${generatePath(
      OLD_ROUTES.RECONCILE_DEVELOPMENT_LEGAL_ENTITY_REQUISITIONS_INVOICES,
      {
        legalEntityCode,
        requisitionId,
      },
    )}${stringify(params, { addQueryPrefix: true })}`,
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_REQUISITIONS_INVOICES_ITEM: (params) =>
    generatePath(
      OLD_ROUTES.RECONCILE_DEVELOPMENT_LEGAL_ENTITY_REQUISITIONS_INVOICES_ITEM,
      params,
    ),
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_DEVELOPMENT_LINE_ITEMS: ({
    legalEntityCode,
    requisitionId,
    ...params
  }) =>
    `${generatePath(
      OLD_ROUTES.RECONCILE_DEVELOPMENT_LEGAL_ENTITY_DEVELOPMENT_LINE_ITEMS,
      {
        legalEntityCode,
        requisitionId,
      },
    )}${stringify(params, { addQueryPrefix: true })}`,
  RECONCILE_DEVELOPMENT_LEGAL_ENTITY_SETTINGS: (params) =>
    generatePath(
      OLD_ROUTES.RECONCILE_DEVELOPMENT_LEGAL_ENTITY_SETTINGS,
      params,
    ),
};

export const allSubPathMatches = <T extends string>(path: T) =>
  `${path}/*` as const;

/**
 * @deprecated Use `generateUrl` instead
 */
export const useNavigation = () => {
  const getUrl = <T extends TScreens>(screen: T, params?: TRouteParams[T]) =>
    SCREENS[screen](params as TRouteParams[T]);

  return {
    getUrl,
  };
};

// todo replace parse with manual mapping
/**
 * @deprecated Use useSearchParams from `src/shared/lib/hooks/navigation/useSearchParams.ts`
 */
export const useQueryParams = <T = Record<string, unknown>>() => {
  const location = useLocation();
  return parse(location.search, { ignoreQueryPrefix: true }) as T;
};
