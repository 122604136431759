import { ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { Page } from '@/widgets/spotlight/model';

export const INTERNAL_PAGES = [
  {
    category: 'settings',
    label: 'Settings - User Management',
    url: ROUTES_ROOT.userManagement.fullPath,
  },
  {
    category: 'settings',
    label: 'Settings - User Tags',
    url: '/user-management/tags',
  },
  {
    category: 'settings',
    label: 'Settings - User Roles',
    url: '/user-management/roles',
  },
  {
    category: 'settings',
    label: 'Settings - Investment Entities',
    url: '/user-management/investment-entities',
  },
  {
    category: 'settings',
    label: 'Settings - Core - General',
    url: '/settings/portal/core',
  },
  {
    category: 'settings',
    label: 'Settings - Core - Funds',
    url: '/settings/portal/funds',
  },
  {
    category: 'settings',
    label: 'Settings - Core - Assets',
    url: '/settings/portal/assets',
  },
  {
    category: 'settings',
    label: 'Settings - Core - Investment Entities',
    url: '/settings/portal/investment-entities',
  },
  {
    category: 'settings',
    label: 'Settings - Core - Legal Entities',
    url: '/settings/portal/legal-entities',
  },
  {
    category: 'settings',
    label: 'Settings - Pipeline - Dashboard',
    url: '/settings/pipeline',
  },
  {
    category: 'settings',
    label: 'Settings - Pipeline - Core',
    url: '/settings/pipeline/core',
  },
  {
    category: 'settings',
    label: 'Settings - Pipeline - Dealpath Fields',
    url: '/settings/pipeline/fields',
  },
  {
    category: 'settings',
    label: 'Settings - Pipeline - Employees',
    url: '/settings/pipeline/employees',
  },
  {
    category: 'settings',
    label: 'Settings - Pipeline - Manage Charts',
    url: '/settings/pipeline/cards',
  },

  {
    category: 'settings',
    label: 'Settings - Report - General Ledgers',
    url: ROUTES_ROOT.settings.report.financial.fullPath,
  },
  {
    category: 'settings',
    label: 'Settings - Report - Unit Types',
    url: ROUTES_ROOT.settings.report.unitTypes.fullPath,
  },
  {
    category: 'settings',
    label: 'Settings - Report - Charge Codes',
    url: ROUTES_ROOT.settings.report.chargeCodes.fullPath,
  },
  {
    category: 'settings',
    label: 'Settings - Report - Connections',
    url: ROUTES_ROOT.settings.report.connections.fullPath,
  },

  {
    category: 'settings',
    label: 'Settings - Report - Dashboards',
    url: ROUTES_ROOT.settings.report.dashboards.fullPath,
  },
  {
    category: 'settings',
    label: 'Settings - Report - Report Templates',
    url: ROUTES_ROOT.settings.report.reportTemplates.fullPath,
  },
  {
    category: 'settings',
    label: 'Settings - Report - Tables',
    url: ROUTES_ROOT.settings.report.tableBuilder.fullPath,
  },
  {
    category: 'settings',
    label: 'Settings - Report - Formulas',
    url: ROUTES_ROOT.settings.report.financialFormulas.fullPath,
  },
  {
    category: 'settings',
    label: 'Settings - Report - Manual Variables',
    url: ROUTES_ROOT.settings.report.manualVariables.fullPath,
  },
  {
    category: 'settings',
    label: 'Settings - Report - Periods',
    url: '/settings/report/data-periods',
  },
  {
    category: 'settings',
    label: 'Settings - Report - Segments',
    url: '/settings/report/segments',
  },
  {
    category: 'settings',
    label: 'Settings - Report - Syncing',
    url: ROUTES_ROOT.settings.report.syncing.fullPath,
  },
  {
    category: 'settings',
    label: 'Settings - Report - Thresholds',
    url: ROUTES_ROOT.settings.report.thresholds.fullPath,
  },
  {
    category: 'settings',
    label: 'Settings - Emails - Templates - Invitations',
    url: '/settings/emails/templates/invitations',
  },
  {
    category: 'settings',
    label: 'Settings - Emails - Templates - Feed Messages',
    url: '/settings/emails/templates/feed-messages',
  },
  {
    category: 'settings',
    label: 'Settings - Emails - Templates - Forgot Password',
    url: '/settings/emails/templates/forgot-password',
  },
  {
    category: 'settings',
    label: 'Settings - Emails - Templates - Custom Templates',
    url: '/settings/emails/templates/custom',
  },
  {
    category: 'settings',
    label: 'Settings - Emails - Campaigns - All',
    url: '/settings/emails/campaigns',
  },
  {
    category: 'return',
    label: 'REturn',
    url: ROUTES_ROOT.return.fullPath,
  },
] as const satisfies readonly Page[];
