import { cn } from '@/shared/lib/css/cn';
import { IConnection } from 'bundles/Shared/entities/connection/model';
import { CopyTextItem } from 'stories/index';
import { ConnectionStatus } from 'bundles/Shared/entities/connection/ui/ConnectionStatus';
import { ConnectionReportType } from 'bundles/Shared/entities/connection/ui/ConnectionReportType';
import { ERPSystemLabel } from '@/entities/erpsystem/ui/ERPSystemLabel';

interface IConnectionPreviewCard extends IConnection {
  onClick: VoidFunction;
  className?: string;
}

export const ConnectionPreviewCard = ({
  className,
  connectionStatus,
  reportType,
  erpSystem,
  name,
  onClick,
  emailAddress,
}: IConnectionPreviewCard) => (
  <div
    className={cn(
      'flex h-[150px] w-full cursor-pointer flex-col justify-between rounded-2xl bg-tw-white p-tw-4 shadow-z-040',
      className,
    )}
    onClick={onClick}
  >
    <div className="flex justify-between">
      <div className="flex flex-col gap-tw-1">
        <div className="font-bold text-neutral-800">{name}</div>
        <CopyTextItem text="testAdress@ffa.com">
          <div className="label-semibold w-[250px] text-ellipsis text-neutral-500">
            {emailAddress}
          </div>
        </CopyTextItem>
      </div>
      <ConnectionStatus status={connectionStatus} />
    </div>
    <div className="flex items-center justify-between">
      <ERPSystemLabel system={erpSystem} />
      <ConnectionReportType type={reportType} />
    </div>
  </div>
);
