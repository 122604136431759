import { CssVar } from '@/shared/config/cssVar';
import { cn } from '@/shared/lib/css/cn';
import NavigationCardList, {
  Img,
} from 'bundles/Shared/components/layouts/screenWithListNavigationLayout/NavigationCardList';
import { capitalize } from 'lodash-es';
import React, { ReactNode } from 'react';
import { Icon, Label } from 'stories/index';

import { IconsId } from 'types/sre-icons';
import { Maybe } from 'yup/lib/types';

function ScreenWithListNavigationLayout({
  children,
  className,
}: React.PropsWithChildren & PropsWithClassName) {
  return (
    <div
      className={cn(
        'grid h-screen grid-cols-[420px_minmax(auto,1fr)] 2xl:grid-cols-[584px_minmax(auto,1fr)]',
        className,
      )}
    >
      {children}
    </div>
  );
}

ScreenWithListNavigationLayout.Navigation = ({
  children,
  className,
}: React.PropsWithChildren & PropsWithClassName) => (
  <div
    className={cn(
      'flex h-full flex-col gap-tw-4 overflow-auto border-r border-solid border-light-10 bg-neutral-100 p-tw-6',
      className,
    )}
  >
    {children}
  </div>
);

ScreenWithListNavigationLayout.NavigationHeaderGroup = ({
  children,
  className,
}: LayoutProps) => (
  <div className={cn('flex flex-col gap-tw-4', className)}>{children}</div>
);
ScreenWithListNavigationLayout.ReportFinancialsNavigationHeaderGroup = ({
  children,
}: React.PropsWithChildren) => (
  <div className="flex justify-between">{children}</div>
);

ScreenWithListNavigationLayout.NavigationHeader = ({
  title,
  subtitle,
}: {
  title: string;
  subtitle?: string;
}) => (
  <div className="flex flex-col">
    <span className="inline-semibold text-light-80">{subtitle}</span>
    <span className="header4-bold text-dark-60">{title}</span>
  </div>
);

ScreenWithListNavigationLayout.Content = ({
  children,
}: React.PropsWithChildren) => (
  <div className="flex h-full flex-col gap-tw-4 overflow-auto bg-light-5 px-tw-8 pb-tw-4 pt-tw-6">
    {children}
  </div>
);

ScreenWithListNavigationLayout.ContentHeader = ({
  children,
}: React.PropsWithChildren) => (
  <>
    <div className="flex items-center justify-between">{children}</div>
    <div className="h-[1px] w-full bg-neutral-150" />
  </>
);

ScreenWithListNavigationLayout.DashboardHeader = ({
  children,
  slot,
}: React.PropsWithChildren & { slot: ReactNode }) => (
  <div className="flex h-full w-full flex-col bg-neutral-100">
    <div className="radius000 flex h-max flex-col gap-tw-4 border-none bg-white px-tw-6 pt-tw-4">
      {slot}
    </div>
    {children}
  </div>
);

ScreenWithListNavigationLayout.DashboardHeaderInfo = ({
  subtitle,
  title,
  label,
}: {
  title: string;
  label?: Maybe<{
    text: string;
    color: CssVar | string;
  }>;
  subtitle?: string;
}) => (
  <div className="flex flex-col justify-center gap-tw-0.5 self-center">
    {subtitle && (
      <p className="secondary-semibold uppercase text-neutral-500">
        {subtitle}
      </p>
    )}
    <p className="header5-bold text-neutral-800">{title}</p>
    {label && (
      <Label className="!p-0" color={label.color}>
        {capitalize(label.text)}
      </Label>
    )}
  </div>
);

ScreenWithListNavigationLayout.HeaderImg = ({
  imgSrc,
  placeholderIcon,
}: {
  imgSrc: Maybe<string>;
  placeholderIcon: IconsId;
}) => {
  const fallback = (
    <Icon
      iconName={placeholderIcon}
      className="header2-regular text-neutral-550"
    />
  );
  return (
    <div className="flex h-[72px] w-[112px] rounded-[8px] border border-solid border-x-neutral-100 p-tw-1 shadow-z-020">
      <div className="flex h-full w-full items-center justify-center overflow-hidden rounded bg-neutral-200 ">
        {imgSrc ? (
          <Img
            src={imgSrc}
            fallbackNode={fallback}
            alt="financial asset"
            className="h-full w-full object-cover"
          />
        ) : (
          fallback
        )}
      </div>
    </div>
  );
};

ScreenWithListNavigationLayout.NavigationCardList = NavigationCardList;

export default ScreenWithListNavigationLayout;
