import { transformFormulaLabelToReference } from '@/bundles/Shared/entities/formula';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import { cn } from '@/shared/lib/css/cn';
import { IconButton, Input } from '@/stories';
import { useRef } from 'react';
import {
  Control,
  FieldPath,
  FieldValues,
  useController,
  useFormContext,
} from 'react-hook-form';

export const LabelFieldController = <
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  referenceFieldName,
  syncReference = true,
  ...props
}: {
  control: Control<TFieldValues>;
  name: TFieldName;
  referenceFieldName: TFieldName;
  syncReference?: boolean;
} & Pick<HTMLInputElement, 'placeholder'>) => {
  const { setValue, getValues } = useFormContext();
  const { field: nameField } = useController({
    control,
    name,
  });
  const { value: isReferenceSyncEnabled, toggle: toggleReferenceSync } =
    useBoolean(syncReference);
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Input
      {...props}
      ref={inputRef}
      classes={{
        rightIcon: '!right-[6px]',
      }}
      rightIcon={
        <IconButton
          onClick={() => {
            toggleReferenceSync();
            inputRef.current?.focus();
          }}
          iconName="link"
          tooltipProps={{
            mainText: 'Autofill reference from label',
            delay: 600,
          }}
          disabled={!syncReference}
          className={cn(
            'r-[4px]',
            isReferenceSyncEnabled
              ? '!border-info-070 bg-info-070 text-neutral-000 hover:!bg-info-080 hover:!text-neutral-000'
              : 'text-info-070',
          )}
        />
      }
      value={nameField.value}
      onChange={(e) => {
        const nameValue = e.target.value;

        if (syncReference) {
          const reference = isReferenceSyncEnabled
            ? transformFormulaLabelToReference(nameValue)
            : getValues(referenceFieldName);

          setValue(referenceFieldName, reference as TFieldValues[TFieldName]);
        }

        nameField.onChange(nameValue);
      }}
    />
  );
};
