import RecapPageButton from '@/bundles/Shared/components/RecapPageButton';
import { useWidgetFlags } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/useWidgetFlags';
import { useWidgetFullScreen } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/WidgetFullScreen';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import {
  DashboardHistoricalReviewTableWidgetDto,
  ReportDashboardType,
} from 'bundles/Shared/entities/dashboard';
import {
  DashboardWidgetCard,
  DashboardWidgetTableCard,
  DateWidgetState,
  WidgetStateDate,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import {
  ColGroupDefBuilder,
  ColumnDefsBuilder,
  ExcelStyleBuilder,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/ColumnDefsBuilder';
import { usePinColumn } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/updaters';
import { useObjectDashboardWidgetTableExport } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/useTableWidgetExportFeature';
import {
  WidgetTable,
  WidgetTablePlaceholder,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/WidgetTable';
import {
  handleHistoricalWidgetFirstDataRendered,
  RowColDefBuilder,
} from 'bundles/Shared/widgets/dashboard/widgets/historicalTable/lib';
import {
  HistoricalReviewTableWidgetConfigModel,
  HistoricalReviewTableWidgetSection,
} from 'bundles/Shared/widgets/dashboard/widgets/historicalTable/model';
import {
  WidgetConfigProps,
  WidgetContextProps,
  WidgetProps,
  WidgetStateProps,
} from 'bundles/Shared/widgets/dashboard/widgets/model';
import { ObjectDashboardWidgetContext } from 'bundles/Shared/widgets/dashboard/widgetsHelpers';
import { useLoadingOverlayEffect } from 'lib/ag-grid/utils';
import { useMemo, useRef } from 'react';

export type HistoricalTableWidgetState = DateWidgetState;

export function HistoricalTableWidget(
  props: WidgetProps<
    DashboardHistoricalReviewTableWidgetDto,
    HistoricalReviewTableWidgetSection
  > &
    WidgetConfigProps<HistoricalReviewTableWidgetConfigModel> &
    WidgetStateProps<HistoricalTableWidgetState> &
    WidgetContextProps<ObjectDashboardWidgetContext>,
) {
  const {
    widgetSection,
    data,
    state,
    onStateChange,
    isFetching,
    mode = 'view',
    settings,
    onSettingsChange,
    dashboardType,
    context,
  } = props;

  const { shouldDisplayPlaceholder, shouldDisplayData } = useWidgetFlags(props);

  const gridRef = useRef<AgGridReact>(null);
  const wrapperDivRef = useRef<HTMLDivElement>(null);

  useLoadingOverlayEffect({
    isLoading: isFetching,
    grid: gridRef.current,
  });
  const exportFeature = useObjectDashboardWidgetTableExport({
    gridRef,
    mode,
    widgetTitle: widgetSection.title,
    widgetId: widgetSection.id,
    state,
    context,
  });

  const widgetStateFullScreenFeature = useWidgetFullScreen(wrapperDivRef);

  const onPinColumn = usePinColumn({
    settings,
    onSettingsChange,
  });
  const rowData = useMemo(() => {
    if (data == null) {
      return undefined;
    }
    const rows = data.data;

    if (rows.length === 0) {
      return [];
    }

    return rows;
  }, [data]);

  const minMaxValues = useMemo(() => {
    const rows = data?.data ?? [];
    const columns = data?.columns ?? [];

    if (rows.length === 0) {
      return {};
    }

    return rows.reduce((acc, row) => {
      const values = columns.map((column) => row[column.key.toString()]);
      const valuesWithoutZero = values.filter((v) => v !== 0);
      return {
        ...acc,
        [row.key?.toString()]: {
          min: Math.min(...values),
          max: Math.max(...values),
          minWithoutZero: Math.min(...valuesWithoutZero),
          maxWithoutZero: Math.max(...valuesWithoutZero),
        },
      };
    }, {});
  }, [data]);

  const columnDefsBuilder = useMemo(() => {
    const colDefBuilder = new RowColDefBuilder({
      mode,
      onPinColumn,
      rows: widgetSection.widgetConfig.viz_config!.rows,
      columnsConfig: widgetSection.widgetConfig.columns,
    });
    const colGroupDefBuilder = new ColGroupDefBuilder({
      mode,
    });
    const excelStyleBuilder = new ExcelStyleBuilder({
      vizConfig: widgetSection.widgetConfig.viz_config!,
      mode,
    }).withSource('rows');

    return new ColumnDefsBuilder({
      colDefBuilder,
      excelStyleBuilder,
      mode,
      colGroupDefBuilder,
      vizConfig: widgetSection.widgetConfig.viz_config,
    });
  }, [mode, onPinColumn, widgetSection.widgetConfig.viz_config]);

  const columnDefs = useMemo<ColDef[]>(() => {
    const columns = data?.columns ?? [];
    const rows = data?.data ?? [];

    if (columns.length === 0 || rows.length === 0) {
      return [];
    }

    return [
      ...columnDefsBuilder.build({
        columns,
      }),
    ].filter((column) => column !== null);
  }, [JSON.stringify(data?.columns), columnDefsBuilder]);

  return (
    <DashboardWidgetTableCard {...props} ref={wrapperDivRef}>
      {mode === 'pdf' && (
        <DashboardWidgetCard.PDFHeader>
          {widgetSection.title}
        </DashboardWidgetCard.PDFHeader>
      )}
      {mode !== 'pdf' && (
        <DashboardWidgetCard.Header>
          <div className="flex">
            <DashboardWidgetCard.Header.Title>
              {widgetSection.title}
            </DashboardWidgetCard.Header.Title>
            <RecapPageButton
              name={widgetSection.recapPage?.name}
              slug={widgetSection.recapPage?.slug}
            />
          </div>
          <GrowDiv />

          <WidgetStateDate state={state} onStateChange={onStateChange} />

          <exportFeature.ExportButtonComponent />
          <widgetStateFullScreenFeature.IconButton />
        </DashboardWidgetCard.Header>
      )}
      {shouldDisplayPlaceholder && <WidgetTablePlaceholder />}
      {shouldDisplayData && (
        <WidgetTable
          domLayout={
            dashboardType === ReportDashboardType.OBJECT || mode === 'pdf'
              ? 'autoHeight'
              : 'normal'
          }
          expensivePropSuppressColumnVirtualisation={mode === 'pdf'}
          context={{
            minMaxValues,
            mode,
          }}
          treeData={false}
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          autoGroupColumnDef={undefined}
          mode={mode}
          excelStyles={columnDefsBuilder.buildExcelStyles()}
          defaultParams={{
            sizeColumnsToFit: false,
          }}
          onFirstDataRendered={handleHistoricalWidgetFirstDataRendered}
        />
      )}
    </DashboardWidgetTableCard>
  );
}
