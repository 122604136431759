import { isSafari } from '@/shared/lib/browser';
import { cn } from '@/shared/lib/css/cn';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import useMediaQuery from '@/shared/lib/hooks/useMediaQuery';
import { ROUTES, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { useNavigate } from '@reach/router';
import { useMenuItemsPermissions } from 'bundles/Shared/components/LeftSidebar/hooks/useMenuItemsPermissions';
import { useScrollBarOverflowShadows } from 'bundles/Shared/components/LeftSidebar/hooks/useScrollBarOverflowShadows';
import FireStationMenuItem from 'bundles/Shared/components/LeftSidebar/menuItems/FireStationMenuItem';
import MirrorModeMenuItem from 'bundles/Shared/components/LeftSidebar/menuItems/MirrorModeMenuItem';
import NotificationsItem from 'bundles/Shared/components/LeftSidebar/menuItems/NotificationsMenuItem';
import ReportMenuItem from 'bundles/Shared/components/LeftSidebar/menuItems/ReportMenuItem';
import UserMenuItem from 'bundles/Shared/components/LeftSidebar/menuItems/UserMenuItem';
import { LEFT_SIDEBAR_Z_INDEX } from 'bundles/Shared/constants';
import { MODULE_LABELS } from 'lib/dictionaries';
import {
  currentUserIsSreAdmin,
  currentUserIsSuperAdmin,
} from 'lib/permissions';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useLayoutEffect, useRef } from 'react';
import { LeftSidebarLogo } from './Logo/Logo';
import MainMenuItem from './items/MainMenuItem';
import SecondaryMenuItem from './items/SecondaryMenuItem';
import AdviseesMenuItem from './menuItems/AdviseesMenuItem';
import SettingsMenuItem from './menuItems/SettingsMenuItem';
import {
  collapseSidebar,
  expandSidebar,
  isLeftSidebarExpanded,
  isLeftSidebarPinned,
} from './reducers/leftSidebarSlice';

function LeftSidebar() {
  const dispatch = useAppDispatch();
  const isDesktop = useMediaQuery('(pointer:fine)');
  const navigate = useNavigate();
  const isPinned = useAppSelector(isLeftSidebarPinned);
  const expanded = useAppSelector(isLeftSidebarExpanded);
  const scrollBarComponentRef = useRef(null);
  const menuItemsPermissions = useMenuItemsPermissions();
  const menuRef = useRef<HTMLDivElement>(null);

  const { onOverflowChanged, onScroll } = useScrollBarOverflowShadows(
    scrollBarComponentRef,
  );

  useLayoutEffect(() => {
    if (isSafari() && menuRef.current) {
      menuRef.current.style.height = '100vh';
    }
  }, []);

  return (
    <div
      className={cn('left-sidebar', {
        'left-sidebar_expanded': expanded,
        'left-sidebar_pinned': isPinned,
      })}
      onMouseLeave={() => isDesktop && !isPinned && dispatch(collapseSidebar())}
      onMouseEnter={(e) => {
        if (
          isDesktop &&
          (menuRef.current?.contains(e.target) || menuRef.current === e.target)
        ) {
          dispatch(expandSidebar());
        }
      }}
      ref={menuRef}
      style={{
        zIndex: LEFT_SIDEBAR_Z_INDEX,
      }}
    >
      <LeftSidebarLogo />
      <OverlayScrollbarsComponent
        className={cn(
          'os-theme-dark sidebar-menu-scrollable-area os-host-flexbox mb-[0.5rem] w-full rounded-2xl',
          { 'sidebar-menu-scrollable-area_hidden-scrollbar': !expanded },
        )}
        ref={scrollBarComponentRef}
        events={{
          scroll: onScroll,
          updated: onOverflowChanged,
        }}
        options={{
          overflow: {
            x: 'hidden',
          },
          scrollbars: {
            clickScroll: false,
            dragScroll: false,
          },
        }}
      >
        {menuItemsPermissions.adviseesListVisible && (
          <MainMenuItem iconName="users" label="Advisees" path="/" />
        )}
        {menuItemsPermissions.reportVisible && <ReportMenuItem />}
        {menuItemsPermissions.reconcileVisible && (
          <MainMenuItem
            label="REconcile"
            iconName="calculationsAlt"
            path="/reconcile"
            disabled
          >
            {menuItemsPermissions.developmentBudgetVisible && (
              <SecondaryMenuItem
                path={ROUTES.root.reconcile.development.fullPath}
                label="Development"
              />
            )}
            {menuItemsPermissions.operationalBudgetVisible && (
              <SecondaryMenuItem
                path={ROUTES.root.reconcile.operational.fullPath}
                label="Operational"
              />
            )}
            {currentUserIsSreAdmin() && (
              <SecondaryMenuItem
                path={ROUTES.root.reconcile.underwriting.fullPath}
                label="Underwriting"
                isActive={({ location: { pathname } }) => {
                  return (
                    pathname.includes(
                      ROUTES.root.reconcile.underwriting.path,
                    ) && pathname.includes(ROUTES.root.reconcile.path)
                  );
                }}
              />
            )}
            {currentUserIsSreAdmin() && (
              <SecondaryMenuItem
                path={ROUTES.root.reconcile.forecasting.fullPath}
                label="Forecasting"
                isActive={({ location: { pathname } }) => {
                  return (
                    pathname.includes(ROUTES.root.reconcile.forecasting.path) &&
                    pathname.includes(ROUTES.root.reconcile.path)
                  );
                }}
              />
            )}
          </MainMenuItem>
        )}
        {menuItemsPermissions.returnVisible && (
          <MainMenuItem
            path={ROUTES.root.return.fullPath}
            label={MODULE_LABELS.Return}
            iconName="capitalization"
            classes={{ label: 'left-sidebar__menu-item-label_blue' }}
          />
        )}
        {menuItemsPermissions.coreVisible && (
          <MainMenuItem
            iconName="partsAlt"
            label="coRE"
            isActive={({ location }) =>
              [ROUTES_ROOT.assets.fullPath, ROUTES_ROOT.funds.fullPath].some(
                (p) => location.pathname.includes(p),
              )
            }
            disabled
          >
            {menuItemsPermissions.assetsVisible && (
              <SecondaryMenuItem
                label="Assets"
                path={ROUTES_ROOT.assets.fullPath}
                isActive={({ location }) =>
                  location.pathname.includes(ROUTES_ROOT.assets.fullPath)
                }
              />
            )}
            {menuItemsPermissions.fundsVisible && (
              <SecondaryMenuItem
                label="Funds"
                path={ROUTES_ROOT.funds.fullPath}
                isActive={({ location }) =>
                  location.pathname.includes(ROUTES_ROOT.funds.fullPath)
                }
              />
            )}
          </MainMenuItem>
        )}
        {menuItemsPermissions.documentsVisible && (
          <MainMenuItem
            onClick={() => navigate(ROUTES_ROOT.documents.fullPath)}
            path="/documents"
            label="Documents"
            iconName="folder"
            classes={{ label: 'left-sidebar__menu-item-label_blue' }}
          />
        )}
        {menuItemsPermissions.settingsVisible && <SettingsMenuItem />}
      </OverlayScrollbarsComponent>
      {menuItemsPermissions.fireStationVisible && <FireStationMenuItem />}
      <div
        className={cn('w-full', {
          'mt-auto': !currentUserIsSreAdmin() && !currentUserIsSuperAdmin(),
        })}
      >
        <NotificationsItem />
        {menuItemsPermissions.mirrorModeVisible && <MirrorModeMenuItem />}
        {menuItemsPermissions.adviseesVisible && <AdviseesMenuItem />}
        <MainMenuItem
          label="Help"
          iconName="question"
          path={ROUTES_ROOT.documentation.fullPath}
          onClick={() => navigate(ROUTES_ROOT.documentation.fullPath)}
        />
        <UserMenuItem />
      </div>
    </div>
  );
}

export default LeftSidebar;
