import { useGetApiCapitalInvestmentAccruedPreferredReturnsQuery } from '@/entities/return/api/capitalInvestmentObjectsEnhancedApi';
import {
  GetApiCapitalInvestmentAccruedPreferredReturnsApiArg,
  GetApiCapitalInvestmentAccruedPreferredReturnsApiResponse,
} from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { createSelector } from '@reduxjs/toolkit';
import { useMemo } from 'react';
import { convertCentsToDollars } from '@/shared/lib/converters';
import { AccruedPreferredReturn } from '@/entities/return/model/types/accruedPreferredReturn';

export function useCapitalAccruedPreferredReturns(
  arg: GetApiCapitalInvestmentAccruedPreferredReturnsApiArg,
) {
  const prepareData = useMemo(() => {
    const emptyArray: GetApiCapitalInvestmentAccruedPreferredReturnsApiResponse =
      [];
    return createSelector(
      (
        data:
          | GetApiCapitalInvestmentAccruedPreferredReturnsApiResponse
          | undefined,
      ) => {
        return data ?? emptyArray;
      },
      (accruedPreferredReturn) => {
        return accruedPreferredReturn.map(
          (item) =>
            ({
              ...item,
              amount: convertCentsToDollars(item.amountCents),
              accruedEntries: item.accruedEntries.map((entry) => ({
                ...entry,
                amount: convertCentsToDollars(entry.amountCents),
                overriddenAmount: convertCentsToDollars(
                  entry.overriddenAmountCents,
                ),
                calculatedAmount: convertCentsToDollars(
                  entry.calculatedAmountCents,
                ),
                periodType: item.periodType,
              })),
              adjustments: item.adjustments.map((entry) => ({
                ...entry,
                amount: convertCentsToDollars(entry.amountCents),
              })),
            }) as AccruedPreferredReturn,
        );
      },
    );
  }, []);

  return useGetApiCapitalInvestmentAccruedPreferredReturnsQuery(arg, {
    selectFromResult: (res) => {
      return {
        ...res,
        items: prepareData(res.data),
      };
    },
  });
}
