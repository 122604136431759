import { cn } from '@/shared/lib/css/cn';
import {
  getOptionsValues,
  mapItemsToListOption,
} from '@/shared/lib/listHelpers';
import { Checkbox } from '@/stories';
import React, { useId } from 'react';
import {
  Control,
  FieldPath,
  FieldValues,
  useController,
} from 'react-hook-form';
import SkeletonBlock from 'stories/ProjectCard/SkeletonBlock';

export type BaseId = number | string;

export interface ListOption<ID extends BaseId | null = BaseId> {
  label: React.ReactNode;
  value: ID | React.ReactNode;
  shorthand?: string;
}

interface Props<ID extends BaseId = BaseId> {
  items: ListOption<ID>[] | readonly ListOption<ID>[];
  value: ListOption<ID>[];
  onChange: (items: ListOption<ID>[], item?: ListOption<ID>) => void;
  getItemProps?: (
    item: ListOption<ID>,
    checked: boolean,
  ) => Omit<React.ComponentProps<typeof Checkbox>, 'checked'> & {
    afterCheckbox?: React.ReactNode;
  };
  className?: string;
  disabled?: boolean;
  selectAll?: boolean;
  selectAllLabel?: string;
  atLeastOneChecked?: boolean;
  unselectable?: ListOption<ID> | ListOption<ID>[];
}

/**
 * @param {boolean} atLeastOneChecked checklist will not allow to uncheck the last active checkbox.
 */
export function CheckList<ID extends BaseId = BaseId>({
  items,
  value,
  onChange,
  className,
  disabled,
  selectAll,
  selectAllLabel,
  getItemProps,
  atLeastOneChecked: atLeastOneCheckedFlag,
  unselectable = [],
}: Props<ID>) {
  const handleCheckboxChange = (checked: boolean, item: ListOption<ID>) => {
    onChange(
      checked
        ? [...value, item]
        : value.filter((checkedItem) => checkedItem.value !== item.value),
      item,
    );
  };
  const id = useId();

  const handleSelectAll = (checked: boolean) => {
    const unselectOptions = Array.isArray(unselectable)
      ? unselectable
      : [unselectable];
    onChange(checked ? items : unselectOptions);
  };
  const isItemChecked = (item: ListOption<ID>) =>
    value.some((checkedItem) => checkedItem.value === item.value);

  const isDisallowedToUncheckItem = (item: ListOption<ID>): boolean => {
    return Boolean(
      atLeastOneCheckedFlag && isItemChecked(item) && value.length === 1,
    );
  };
  return (
    <div className={cn('flex flex-col gap-tw-2', className)}>
      {selectAll && (
        <Checkbox
          onChange={(e) => handleSelectAll(e.target.checked)}
          checked={value.length === items.length}
          indeterminate={value.length > 0}
          classes={{
            children: 'font-weight-600',
          }}
        >
          {selectAllLabel ?? 'Select All'}
        </Checkbox>
      )}
      {items.map((item, index) => {
        const itemProps = getItemProps?.(item, isItemChecked(item));

        return (
          <div
            key={`${id}-${index}`}
            className="group/option flex items-center gap-tw-2"
          >
            <Checkbox
              onChange={(e) => handleCheckboxChange(e.target.checked, item)}
              checked={isItemChecked(item)}
              disabled={disabled ?? isDisallowedToUncheckItem(item)}
              {...itemProps}
            >
              {item.label}
            </Checkbox>

            {itemProps?.afterCheckbox}
          </div>
        );
      })}
    </div>
  );
}

CheckList.Skeleton = () => (
  <div className={cn('flex flex-col gap-tw-2')}>
    {Array.from({ length: 10 }).map((_, i) => (
      <SkeletonBlock key={i} className="h-[20px] w-full" />
    ))}
  </div>
);

export const CheckListController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  onChange,
  ...props
}: {
  control: Control<TFieldValues>;
  name: TName;
} & Omit<Props, 'value' | 'onChange'> &
  Partial<Pick<Props, 'onChange'>>) => {
  const { field } = useController({ name, control });
  return (
    <CheckList
      value={mapItemsToListOption(field.value ?? [])}
      selectAll
      onChange={(options) => {
        if (onChange) {
          onChange(o);
          return;
        }
        field.onChange(getOptionsValues(options));
      }}
      {...props}
    />
  );
};

export default CheckList;
