import {
  useGetApiSettingsReportThirdPartyExtractionsConnectionsByIdQuery,
  usePutApiSettingsReportThirdPartyExtractionsConnectionsByIdMutation,
} from '@/bundles/Settings/components/REport/Connections/api/settingsReportConnectionsEnhancedApi';
import { IConnection } from '@/bundles/Shared/entities/connection/model';
import { useParams } from '@reach/router';
import { useState } from 'react';
import { Button, IconButton } from 'stories/index';
import { convertToMilliseconds } from '@/shared/lib/converters';
import ReactDatePicker from 'react-datepicker';
import { STATIC_DATE_PICKER_PROPS } from '@/bundles/REturn/components/Ownership/modals/consts';
import dayjs from 'dayjs';
import pluralize from 'pluralize';

export const ConnectionUpdatedAtCard = () => {
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const params = useParams();

  const { data: connectionData } =
    useGetApiSettingsReportThirdPartyExtractionsConnectionsByIdQuery({
      id: Number(params.connectionId),
    });

  const connection = connectionData?.item as IConnection | undefined;
  const connectionUpdatedAt = connection?.connectionUpdatedAt
    ? new Date(convertToMilliseconds(connection.connectionUpdatedAt))
    : null;

  const [selectedDate, setSelectedDate] = useState<Date | null>(
    connectionUpdatedAt,
  );

  const [updateConnection, { isLoading }] =
    usePutApiSettingsReportThirdPartyExtractionsConnectionsByIdMutation();

  const handleUpdateDate = async () => {
    if (!selectedDate) return;

    const res = await updateConnection({
      id: Number(params.connectionId),
      body: {
        connection_updated_at: selectedDate,
      },
    });

    if ('data' in res && res.data) {
      setShowDatePicker(false);
    }
  };

  const getRelativeTimeText = (date: Date) => {
    const daysPassed = dayjs().diff(dayjs(date), 'day');
    return `${daysPassed} ${pluralize('day', daysPassed)} ago`;
  };

  return (
    <div className="flex flex-col gap-tw-4 rounded-2xl bg-tw-white p-tw-4">
      <div className="flex items-center justify-between">
        <div className="font-semibold text-neutral-800">Renewed (Days Ago)</div>
        {showDatePicker && (
          <Button
            variant="secondary"
            size="xs"
            onClick={handleUpdateDate}
            disabled={isLoading || !selectedDate}
          >
            Update Date
          </Button>
        )}
        {!showDatePicker && (
          <IconButton
            iconName="editAlt"
            onClick={() => setShowDatePicker(true)}
          />
        )}
      </div>
      {showDatePicker && (
        <ReactDatePicker
          {...STATIC_DATE_PICKER_PROPS}
          selected={selectedDate}
          onChange={(newDate) => {
            setSelectedDate(newDate);
          }}
          maxDate={new Date()}
          showYearDropdown={false}
          showTimeInput
        />
      )}
      {!showDatePicker && connectionUpdatedAt && (
        <div className="flex flex-col gap-tw-2">
          <div className="inline-regular text-neutral-900">
            {getRelativeTimeText(connectionUpdatedAt)}
          </div>
          <div className="secondary-regular">
            {dayjs(connectionUpdatedAt).format('MMMM D, YYYY h:mm A')}
          </div>
        </div>
      )}
      {!showDatePicker && !connectionUpdatedAt && (
        <div className="inline-regular">Date not set</div>
      )}
    </div>
  );
};
