import { TABLE_CELL_FONT_SIZE_CSS_VAR_REF } from '@/bundles/Shared/components/AgGrid/Table/utils/getTableCellStyle';
import { HEADER_CELL_WRAPPER_BG_COLOR_CSS_CUSTOM_PROPERTY } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/HeaderComponentWithSubHeader';
import { cn } from '@/shared/lib/css/cn';
import { IHeaderGroupParams, IHeaderParams } from 'ag-grid-community';
import useHeaderSorting from 'lib/ag-grid/useHeaderSorting';
import {
  CSSProperties,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
import { IconButton } from 'stories/index';
import { CELL_CLASS_NAMES } from '../classNames';
import { CellRendererClasses } from '../types';

export type HeaderComponentClasses = {
  reactNode?: React.ReactNode;
} & CellRendererClasses;

export type HeaderComponentProps = IHeaderGroupParams &
  HeaderComponentClasses & {
    style?: CSSProperties;
  };

export function BasicHeaderComponent({
  classes,
  children,
  style,
}: PropsWithChildren & HeaderComponentProps) {
  return (
    <div
      style={style}
      className={cn(CELL_CLASS_NAMES.Header.wrapper.default, classes?.wrapper)}
    >
      <div
        style={{ fontSize: `calc(${TABLE_CELL_FONT_SIZE_CSS_VAR_REF} - 2px)` }}
        className={cn(CELL_CLASS_NAMES.Header.inner.default, classes?.inner)}
      >
        {children}
      </div>
    </div>
  );
}

const HIGHLIHTED_CLASS_NAME = 'bg-neutral-000 hover:!text-neutral-850';
const getActiveStyle = (isActive: boolean) =>
  isActive
    ? {
        color: HEADER_CELL_WRAPPER_BG_COLOR_CSS_CUSTOM_PROPERTY.ref,
      }
    : {};
export function HeaderSortButton(params: IHeaderParams & PropsWithClassName) {
  const { onSortRequested, activeSort } = useHeaderSorting(params);

  const getSortIcon = () => {
    switch (activeSort) {
      case 'asc':
        return 'sortDesc';
      case 'desc':
        return 'sortAsc';
      default:
        return 'sortOff';
    }
  };

  const isHighlighted = activeSort != null;

  return (
    <IconButton
      className={cn(params.className, isHighlighted && HIGHLIHTED_CLASS_NAME)}
      style={getActiveStyle(isHighlighted)}
      onClick={onSortRequested}
      onTouchEnd={onSortRequested}
      variant="ghost"
      iconName={getSortIcon()}
    />
  );
}

export function HeaderFilterButton(params: IHeaderParams & PropsWithClassName) {
  const filterRef = useRef<HTMLButtonElement>(null);
  const [isFilterActive, setIsFilterActive] = useState(
    params.column.isFilterActive(),
  );
  const handleShowFilter = () =>
    // our grid version doesn't support https://ag-grid.com/react-data-grid//grid-api/#reference-accessories-showColumnFilter
    // workaround
    params.api.menuFactory?.showMenuAfterButtonClick(
      params.column,
      filterRef.current,
      'columnMenu',
      'filterMenuTab',
      ['filterMenuTab'],
    );

  useEffect(() => {
    const syncFilterWithModel = () => {
      const filterModel = params.api.getFilterModel();
      setIsFilterActive(filterModel?.[params.column.getColId() ?? ''] != null);
    };

    params.api.addEventListener('filterChanged', syncFilterWithModel);

    syncFilterWithModel();

    return () => {
      params.api.removeEventListener('filterChanged', syncFilterWithModel);
    };
  }, [params.api]);

  return (
    params.column.isFilterAllowed() && (
      <IconButton
        className={cn(
          params.className,
          'shrink-0',
          isFilterActive && HIGHLIHTED_CLASS_NAME,
        )}
        style={getActiveStyle(isFilterActive)}
        ref={filterRef}
        onClick={handleShowFilter}
        variant="ghost"
        iconName="filtersFilledAlt"
      />
    )
  );
}

export function HeaderComponent({ reactNode, ...props }: HeaderComponentProps) {
  return (
    <BasicHeaderComponent {...props}>
      {reactNode}
      {props.displayName}
    </BasicHeaderComponent>
  );
}
