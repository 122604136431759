import { useGetApiReconcileForecastingForecastsByForecastIdYearsQuery } from '@/entities/reconcile/forecasting/api/reconcileForecastingEntitiesEnchancedApi';
import {
  ForecastingForecast,
  ForecastingForecastYear,
} from '@/entities/reconcile/forecasting/model';
import { generateUrl, ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';

export const useFetchForecastYears = (
  id: ForecastingForecast['id'] | undefined,
) => {
  const { budgets, budgetsCount, ...requestProps } =
    useGetApiReconcileForecastingForecastsByForecastIdYearsQuery(
      {
        forecastId: id ?? '',
      },
      {
        skip: !id,
        selectFromResult: ({ data, ...props }) => ({
          budgets: (data?.items ?? []).map<ForecastingForecastYear>((item) => {
            const totalBudgets =
              item.noBudgetCount + item.draftCount + item.publishedCount;
            const percentOfCompletion = Math.ceil(
              (item.publishedCount / totalBudgets) * 100,
            );
            const link = generateUrl(
              ROUTES_ROOT.reconcile.forecasting.budgets.fullPath,
              {
                pathParams: {
                  forecastName: item.forecast.name,
                  year: item.year.toString(),
                },
              },
            );

            return {
              ...item,
              totalBudgets,
              percentOfCompletion,
              link,
            };
          }),
          budgetsCount: data?.items?.length ?? 0,
          ...props,
        }),
      },
    );

  return {
    budgets,
    budgetsCount,
    ...requestProps,
  };
};
