import { SettingsReportConnectionsPage } from '@/pages/settings/report/connections/page';
import { currentUserAllowedToSeeConnectionsPages } from '@/pages/settings/report/connections/permissions';
import { currentUserAllowedToSeeReportOperationalPages } from '@/pages/settings/report/operational/permissions';
import { ReportTemplatesPage } from '@/pages/settings/report/reportTemplates/page';
import { currentUserAllowedToSeeReportTemplatesPage } from '@/pages/settings/report/reportTemplates/permissions';
import { currentUserAllowedToSeeReportThresholdsPage } from '@/pages/settings/report/thresholds/lib/permissions';
import { ReportThresholdsPage } from '@/pages/settings/report/thresholds/page';
import { SettingsSyncingPage } from '@/pages/settings/syncing/page';
import { currentUserAllowedToSeeSyncingPage } from '@/pages/settings/syncing/permissions';
import { ROUTES_ROOT } from '@/shared/lib/hooks/useNavigation';
import { ManualVariablesPage } from '@/widgets/report/manualVariable/settings';
import { navigate, RouteComponentProps } from '@reach/router';
import SettingsReportDashboards from 'bundles/Settings/components/REport/Dashboards/SettingsReportDashboards';
import ReportDataPeriodBulks from 'bundles/Settings/components/REport/DataPeriods/ReportDataPeriodBulks';
import GeneralLedgers from 'bundles/Settings/components/REport/GeneralLedgers/GeneralLedgers';
import ReportTableConfigs from 'bundles/Settings/components/REport/TableBuilder/ReportTableConfigs';
import ReportTableSegments from 'bundles/Settings/components/REport/TableSegment/ReportTableSegments';
import { FormulasAndVariablesWorkspace } from 'bundles/Shared/widgets/formula/panel';
import {
  currentUserAllowedTo,
  currentUserIsExternal,
  currentUserIsSreAdmin,
  TProductNames,
} from 'lib/permissions';
import React from 'react';
import { UnknownRecord } from 'type-fest/source/internal';
import { SettingsReportOperationalUnitTypesPage } from '@/pages/settings/report/operational/unitTypes/page';
import { SettingsReportOperationalChargeCodesPage } from '@/pages/settings/report/operational/chargeCodes/page';
import { SettingsReportOperationalNonRevenueUnitsPage } from '@/pages/settings/report/operational/nonRevenueUnits/page';

export const ROUTES = ROUTES_ROOT.settings.report;
export type ScreenSettings =
  | {
      path: string;
      linkPath: string;
      name: string;
      Component: React.FC<RouteComponentProps>;
      isVisible: (() => boolean) | boolean;
      ignoreLayoutWrapper?: boolean;
      componentProps?: UnknownRecord;
    }
  | {
      name: string;
      children: ScreenSettings[];
    };

export const MENU_GROUPS = {
  Onboarding: [
    {
      name: 'Financial',
      Component: GeneralLedgers,
      path: ROUTES.financial.path,
      linkPath: ROUTES.financial.path,
      isVisible: true,
    },
    {
      name: 'Connections',
      Component: SettingsReportConnectionsPage,
      path: ROUTES.connections.path,
      linkPath: ROUTES.connections.path,
      isVisible: currentUserAllowedToSeeConnectionsPages,
    },
  ],
  Operational: [
    {
      name: 'Unit Types',
      Component: SettingsReportOperationalUnitTypesPage,
      path: ROUTES.unitTypes.path,
      linkPath: ROUTES.unitTypes.path,
      isVisible: currentUserAllowedToSeeReportOperationalPages,
    },
    {
      name: 'Charge Codes',
      Component: SettingsReportOperationalChargeCodesPage,
      path: ROUTES.chargeCodes.path,
      linkPath: ROUTES.chargeCodes.path,
      isVisible: currentUserAllowedToSeeReportOperationalPages,
    },
    {
      name: 'Non-Revenue Units',
      Component: SettingsReportOperationalNonRevenueUnitsPage,
      path: ROUTES.nonRevenueUnits.path,
      linkPath: ROUTES.nonRevenueUnits.path,
      isVisible: currentUserAllowedToSeeReportOperationalPages,
    },
  ],
  Builders: [
    {
      name: 'Dashboards',
      Component: SettingsReportDashboards,
      path: ROUTES.dashboards.path,
      linkPath: ROUTES.dashboards.path,
      isVisible: true,
    },
    {
      name: 'Tables',
      Component: ReportTableConfigs,
      path: ROUTES.tableBuilder.path,
      linkPath: ROUTES.tableBuilder.path,
      isVisible: true,
    },
    {
      name: 'Formulas',
      Component: () => (
        <FormulasAndVariablesWorkspace
          initialOpen
          editMode
          onClose={() => {
            navigate(-1);
          }}
        />
      ),
      path: ROUTES.financialFormulas.path,
      linkPath: ROUTES.financialFormulas.path,
      isVisible: () => currentUserIsSreAdmin(),
    },
    {
      name: 'Manual Variables',
      Component: ManualVariablesPage,
      path: ROUTES.manualVariables.path,
      linkPath: ROUTES.manualVariables.path,
      isVisible: () =>
        currentUserIsSreAdmin() || currentUserAllowedTo('configure', 'report'),
      ignoreLayoutWrapper: true,
    },
    {
      name: 'Report Templates',
      Component: ReportTemplatesPage,
      linkPath: ROUTES.reportTemplates.path,
      path: ROUTES.reportTemplates.path,
      isVisible: currentUserAllowedToSeeReportTemplatesPage,
    },
  ],
  Data: [
    {
      name: 'Periods',
      Component: ReportDataPeriodBulks,
      path: ROUTES.dataPeriods.path,
      linkPath: ROUTES.dataPeriods.path,
      isVisible: true,
    },
    {
      name: 'Segments',
      Component: ReportTableSegments,
      path: ROUTES.segments.path,
      linkPath: ROUTES.segments.path,
      isVisible: () =>
        !currentUserIsExternal() &&
        currentUserAllowedTo('configure', TProductNames.REPORT),
    },
    {
      name: 'Thresholds',
      Component: ReportThresholdsPage,
      path: ROUTES.thresholds.path,
      linkPath: ROUTES.thresholds.path,
      isVisible: () => currentUserAllowedToSeeReportThresholdsPage(),
      ignoreLayoutWrapper: true,
    },
  ],
  UTILITIES: [
    {
      name: 'Syncing',
      Component: SettingsSyncingPage,
      path: ROUTES_ROOT.settings.report.syncing.path,
      linkPath: ROUTES_ROOT.settings.report.syncing.path,
      isVisible: () => currentUserAllowedToSeeSyncingPage(),
      ignoreLayoutWrapper: true,
    },
  ],
} as const satisfies Record<string, readonly ScreenSettings[]>;
