import { DialogProps } from '@/shared/lib/hooks/useModal';
import { assertsType } from 'lib/typeHelpers/assertsType';
import { startCase } from 'lodash-es';
import React, { ComponentProps, PropsWithChildren, useState } from 'react';
import {
  Button,
  Field,
  Modal,
  ModalActions,
  ModalHeaderWithSubtitle,
} from 'stories';
import { FieldsContainer } from 'stories/Field/Field';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { UseFormProps } from 'react-hook-form/dist/types';

type BaseForm = Record<string, unknown>;

export type SettingsModalField<Form extends BaseForm> = {
  name: string;
  renderer: ({
    form,
  }: {
    form: Form;
    setForm: React.Dispatch<React.SetStateAction<Form>>;
  }) => React.JSX.Element;
  fieldProps?:
    | ((f: Form) => Omit<ComponentProps<typeof Field>, 'children'>)
    | Omit<ComponentProps<typeof Field>, 'children'>;
  label?: string;
  isHidden?: ((f: Form) => boolean) | boolean;
  optional?: boolean;
};

export const SettingsFormModal = <TFieldValues extends FieldValues>({
  formProps,
  onClose,
  onSubmit,
  modalProps,
  modalHeaderProps,
  submitButtonText = 'Submit',
  fields,
}: {
  formProps: UseFormProps<TFieldValues>;
  fields: React.ReactNode;
  modalProps?: Omit<
    ComponentProps<typeof Modal>,
    'toggle' | 'header' | 'actions' | 'children'
  >;
  modalHeaderProps?: Partial<ComponentProps<typeof ModalHeaderWithSubtitle>>;
  submitButtonText?: React.ReactNode;
} & DialogProps<TFieldValues>) => {
  const methods = useForm(formProps);
  const {
    formState: { isValid, isDirty, isSubmitting },
  } = methods;
  const handleSubmit = methods.handleSubmit((values) => {
    onSubmit?.(values);
  });

  return (
    <Modal
      toggle={onClose}
      header={
        <ModalHeaderWithSubtitle
          title="Settings"
          order="subtitle-title"
          {...modalHeaderProps}
        />
      }
      actions={
        <ModalActions alignItems="space-between">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="success"
            onClick={handleSubmit}
            disabled={!isValid || !isDirty || isSubmitting}
          >
            {submitButtonText}
          </Button>
        </ModalActions>
      }
      {...modalProps}
    >
      <FormProvider {...methods}>{fields}</FormProvider>
    </Modal>
  );
};

/**
 *  @deprecated Use `SettingsFormModal` instead
 */
export function SettingsModal<
  Form extends BaseForm,
  ReturnForm extends Form = Form,
>({
  fields,
  initialForm,
  onClose,
  onSubmit,
  isDisabled,
  classes,
  modalHeaderProps,
  submitButtonText = 'Submit',
  children,
  modalProps,
}: {
  fields: SettingsModalField<Form>[];
  initialForm: Form;
  isDisabled?: (f: Form) => boolean;
  classes?: {
    fieldsContainer?: string;
  };
  submitButtonText?: React.ReactNode;
  modalProps?: Omit<
    ComponentProps<typeof Modal>,
    'toggle' | 'header' | 'actions' | 'children'
  >;
  modalHeaderProps?: Partial<ComponentProps<typeof ModalHeaderWithSubtitle>>;
} & DialogProps<ReturnForm> &
  PropsWithChildren) {
  const [form, setForm] = useState(initialForm);

  const handleSubmit = () => {
    // pass a validation function
    assertsType<ReturnForm>(form, true);

    onSubmit?.(form);
  };

  return (
    <Modal
      toggle={onClose}
      header={
        <ModalHeaderWithSubtitle
          title="Settings"
          order="subtitle-title"
          {...modalHeaderProps}
        />
      }
      actions={
        <ModalActions alignItems="space-between">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="success"
            onClick={handleSubmit}
            disabled={isDisabled?.(form) ?? false}
          >
            {submitButtonText}
          </Button>
        </ModalActions>
      }
      {...modalProps}
    >
      <FieldsContainer className={classes?.fieldsContainer}>
        {fields.map(({ fieldProps, ...field }) => {
          const resolveFieldProps = (): Omit<
            ComponentProps<typeof Field>,
            'children'
          > => {
            if (fieldProps == null) return {};

            if (fieldProps instanceof Function) return fieldProps(form);

            return fieldProps;
          };

          if (
            typeof field.isHidden === 'function'
              ? field.isHidden(form)
              : field.isHidden
          ) {
            return null;
          }
          return (
            <Field
              key={field.name}
              labelText={field.label ?? startCase(field.name)}
              required={!field.optional}
              {...resolveFieldProps()}
            >
              {field.renderer({ form, setForm })}
            </Field>
          );
        })}
      </FieldsContainer>
      {children}
    </Modal>
  );
}
