import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import useDebounce from '@/shared/lib/hooks/useDebounce';
import { ROUTES_ROOT, generatePath } from '@/shared/lib/hooks/useNavigation';
import { useNavigate } from '@reach/router';
import AssetCard from 'bundles/Assets/components/AssetCard/AssetCard';
import CreateAssetButton from 'bundles/Assets/components/CreateAssetButton';
import AssetsTable from 'bundles/Assets/components/Table/AssetsTable';
import {
  fetchAssetsWithMeta,
  updatePageParams,
  updateSelectedStageNames,
  updateSelectedStatusNames,
} from 'bundles/Assets/reducers/AssetsSlice';
import {
  StagesSwitch,
  StatusesSwitch,
} from 'bundles/Shared/components/BadgeSwitch';
import NoDataOverlay from 'bundles/Shared/components/NoDataOverlay';
import TablePagination from 'bundles/Shared/components/Table/pagination/TablePagination';
import {
  ASSET_PORTAL_PRODUCT_NAME,
  currentUserAllowedTo,
  currentUserIsExternal,
} from 'lib/permissions';
import { useEffect, useState } from 'react';
import { AnimationLoader } from 'stories';

export type TAssetsView = 'table' | 'tile';

interface Props {
  view?: TAssetsView;
  fundId?: number;
  isEdit?: boolean;
  isCreate?: boolean;
}

export interface ActionColumn {
  hidden: boolean;
  formatterParams: {
    loadAssets: () => Promise<void>;
  };
}

function Assets({ view = 'tile', fundId, isEdit = false, isCreate }: Props) {
  const dispatch = useAppDispatch();
  const assetsReducer = useAppSelector((state) => state.assetsSlice);
  const { assets, meta, selectedStageNames, selectedStatusNames, pageParams } =
    assetsReducer;
  const { stages: displayedStages, totalSize } = meta;

  const debouncedStageNames = useDebounce(selectedStageNames, 500);
  const debouncedStatusNames = useDebounce(selectedStatusNames, 500);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const loadAssets = async () => {
    const preparedParams = {
      page: pageParams.page,
      per_page: pageParams.perPage,
      stages: debouncedStageNames,
      statuses: debouncedStatusNames,
      fund_id: fundId,
    };
    setIsLoading(true);

    await dispatch(fetchAssetsWithMeta(preparedParams));

    setIsLoading(false);
  };

  useEffect(() => {
    loadAssets();
  }, [debouncedStageNames, debouncedStatusNames, pageParams]);

  const setPagination = (perPage: number, page: number) => {
    dispatch(
      updatePageParams({
        page,
        perPage,
      }),
    );
  };

  const setCurrentPage = (page: number, perPage: number) => {
    dispatch(
      updatePageParams({
        page,
        perPage: perPage ?? pageParams.perPage,
      }),
    );
  };

  const actions: ActionColumn = {
    hidden: !isEdit,
    formatterParams: {
      loadAssets,
    },
  };

  const table = (
    <AssetsTable
      classes={{
        container: 'w-full',
      }}
      columns={{
        actions,
      }}
      assets={assets}
    />
  );

  const showFilters = displayedStages.length > 0;

  return (
    <>
      <div className="my-tw-6 flex items-center gap-tw-8">
        <div className="flex flex-grow justify-between">
          {showFilters && (
            <div className="mr-tw-2 flex flex-wrap items-center">
              <span className="inline-regular mr-tw-2">Stage</span>
              <div className="w-lg-auto w-full" />
              <StagesSwitch
                disabled={isLoading}
                selectedStages={selectedStageNames}
                displayedStages={displayedStages}
                onChange={(stgs) => {
                  dispatch(updateSelectedStageNames(stgs));
                }}
                multiple
              />
            </div>
          )}
          {showFilters && !currentUserIsExternal() && (
            <div className="mr-tw-2 hidden flex-wrap items-center lg:flex">
              <span className="inline-regular mr-tw-2">Status</span>
              <div className="w-lg-auto w-full" />
              <StatusesSwitch
                disabled={isLoading}
                selectedStatuses={selectedStatusNames}
                onChange={(status) => {
                  dispatch(updateSelectedStatusNames([status]));
                }}
              />
            </div>
          )}
        </div>
        {isCreate &&
          currentUserAllowedTo('manage', ASSET_PORTAL_PRODUCT_NAME) && (
            <CreateAssetButton refetchAssets={loadAssets} />
          )}
      </div>

      {!isLoading && (
        <TablePagination
          loading={isLoading}
          currentPage={pageParams.page}
          setCurrentPage={setCurrentPage}
          sizePerPage={pageParams.perPage}
          totalSize={totalSize}
          onSizePerPageChange={(size) => setPagination(size, 1)}
        />
      )}

      {isLoading && (
        <div className="relative h-[60vh] w-full">
          <AnimationLoader />
        </div>
      )}

      {!isLoading && assets.length === 0 && (
        <NoDataOverlay
          title={assets.length === 0 ? 'No Assets yet' : 'Nothing found'}
          subTitle={
            assets.length === 0 ? '' : 'Reset filters to see all assets'
          }
        />
      )}
      {!isLoading && assets.length !== 0 && (
        <div className="mt-tw-4">
          {view === 'table' ? (
            table
          ) : (
            <div className="mt-tw-4 grid gap-tw-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              {assets.map((asset) => (
                <div key={asset.id} className="grid">
                  <AssetCard
                    onClick={() => {
                      const assetUrl = generatePath(
                        ROUTES_ROOT.assets.asset.fullPath,
                        { assetSlug: asset.slug },
                      );
                      navigate(assetUrl);
                    }}
                    stages={displayedStages}
                    asset={asset}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default Assets;
