import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import { Icon } from 'stories';

interface Props extends PropsWithClassName {
  isSuccessful: boolean;
}

const BadgeWithStatus = ({
  children,
  isSuccessful,
  className,
}: React.PropsWithChildren<Props>) => (
  <div
    className={cn(
      `inline-flex items-center rounded-[1rem] pb-[0.5rem] pl-[0.5rem] pr-m pt-[0.5rem]`,
      className,
      {
        'bg-light-5 text-dark-60': !isSuccessful,
        'bg-green-light-4 text-green-dark-2': isSuccessful,
      },
    )}
  >
    {isSuccessful && (
      <div className="mr-[0.5rem] inline-flex h-[1rem] w-[1rem] items-center justify-center rounded-[075rem] bg-green">
        <Icon className="light" iconName="checkSmall" />
      </div>
    )}
    {!isSuccessful && (
      <div
        className="mr-[0.5rem] inline-block h-[1rem] w-[1rem] rounded-[075rem]"
        style={{ border: '0.25rem solid var(--yellow-dark-3)' }}
      />
    )}
    {children}
  </div>
);

export default BadgeWithStatus;
