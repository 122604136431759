import { cn } from '@/shared/lib/css/cn';
import type React from 'react';

export const MediaWidgetPreviewUI = (props: LayoutProps) => (
  <div className="flex flex-col gap-tw-4">
    <p className="inline-semibold z-[1051] text-neutral-550">
      Media Widget Preview
    </p>
    {props.children}
  </div>
);

MediaWidgetPreviewUI.Form = (props: LayoutProps) => (
  <div className={cn('flex flex-wrap items-center gap-tw-2', props.className)}>
    {props.children}
  </div>
);

MediaWidgetPreviewUI.FormField = (
  props: LayoutProps & {
    label: React.ReactNode;
    slot?: React.ReactNode;
  },
) => (
  <div className={cn('flex items-center gap-tw-2', props.className)}>
    <div className="flex items-center gap-tw-2">
      <p className="inline-semibold whitespace-nowrap text-neutral-850">
        {props.label}
      </p>
      {props.slot}
    </div>
    {props.children}
  </div>
);
