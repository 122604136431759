import {
  PERIOD_SHIFT_SCHEMA,
  PERIOD_TYPE_SCHEMA,
  TOTAL_CALCULATION_STRATEGIES,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/config';
import {
  transformPeriodShiftDtoToForm,
  transformPeriodTypeDtoToForm,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/config';
import {
  NUMERIC_COLUMN_SETTINGS_FORM_SCHEMA,
  transformColumnSettingsToForm,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/form';
import { TableVizConfigColumn } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import { UnitTypeTableWidgetConfigColumn } from '@/bundles/Shared/widgets/dashboard/widgets/unitTypeTable/model';
import { transformEmptyObjectToUndefined } from '@/shared/lib/yup';
import * as yup from 'yup';

export const UNIT_TYPE_TABLE_WIDGET_CONFIG_COLUMN_SCHEMA =
  NUMERIC_COLUMN_SETTINGS_FORM_SCHEMA.shape({
    key: yup.number(),
    label: yup.string().required(),
    expression: yup.string().required(),
    period_type: PERIOD_TYPE_SCHEMA.required(),
    period_shift: PERIOD_SHIFT_SCHEMA.nullable()
      .optional()
      .transform(transformEmptyObjectToUndefined)
      .default(undefined),
    unit_type_calculation_strategy: yup
      .string()
      .oneOf(Object.values(TOTAL_CALCULATION_STRATEGIES))
      .nullable()
      .optional()
      .default(undefined),
    total_calculation_strategy: yup
      .string()
      .oneOf(Object.values(TOTAL_CALCULATION_STRATEGIES))
      .nullable()
      .optional()
      .default(undefined),
  });

export type UnitTypeTableWidgetConfigColumnForm = yup.InferType<
  typeof UNIT_TYPE_TABLE_WIDGET_CONFIG_COLUMN_SCHEMA
>;

export const transformColumnConfigToForm = (
  columnConfig: UnitTypeTableWidgetConfigColumn,
  columnSettings: TableVizConfigColumn,
): UnitTypeTableWidgetConfigColumnForm => {
  return {
    key: columnConfig.key,
    label: columnConfig.label,
    expression: columnConfig.expression,
    period_shift: transformPeriodShiftDtoToForm(columnConfig.period_shift),
    period_type: transformPeriodTypeDtoToForm(columnConfig.period_type),
    unit_type_calculation_strategy:
      columnConfig.unit_type_calculation_strategy === 'evaluate'
        ? undefined
        : columnConfig.unit_type_calculation_strategy,
    total_calculation_strategy:
      columnConfig.total_calculation_strategy === 'evaluate'
        ? undefined
        : columnConfig.total_calculation_strategy,
    ...transformColumnSettingsToForm(columnSettings),
  };
};
