import * as React from 'react';
import { useState } from 'react';
import { cn } from '@/shared/lib/css/cn';
import { IconButton } from 'stories';
import { ISharedDocument } from 'types/SharedDocument';
import DocumentType from '../../fieldRenderers/DocumentType';
import FileInfo from '../../../FileInfo';
import InlineFolder from '../../../../Folders/InlineFolder';
import { Actions, FileLink } from './ExtendedDetails';

interface Props {
  row: ISharedDocument;
  actions: Actions;
}

function ExtendedDetailsPopover({ row, actions }: Props) {
  const [expanded, setExpanded] = useState(false);

  const toggleExtend = () => setExpanded(!expanded);

  return (
    <>
      <div className="mb-auto 2xl:hidden">
        <IconButton
          className="hidden lg:block"
          onClick={toggleExtend}
          iconName="bottom"
        />
      </div>
      <div
        className={cn('expanded-table-column', {
          hidden: !expanded,
        })}
      >
        <div className="flex flex-col gap-tw-2">
          <FileLink row={row} />
          <div className="flex flex-wrap items-center gap-tw-4">
            <div className="1.5xl:hidden">
              <DocumentType row={row} />
            </div>
            <div>
              <FileInfo size={row.size} extension={row.extension} />
            </div>
          </div>
          <div className="2xl:hidden">
            {actions.handleFolderClick && (
              <InlineFolder
                folder={row.folder}
                onClick={() => {
                  actions.handleFolderClick(row.folder);
                }}
              />
            )}
          </div>
        </div>
        <IconButton
          className="hidden lg:block"
          onClick={toggleExtend}
          iconName="top"
        />
      </div>
    </>
  );
}

export default ExtendedDetailsPopover;
