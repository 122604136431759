import type { TRootState } from '@/app/stores';
import {
  mapAssetToObject,
  mapSegmentToObject,
} from '@/bundles/Shared/entities/dashboard/api';
import {
  getInitialStateForReportChartWidget,
  getUpdatedWidgetStateForReportChartWidget,
} from '@/bundles/Shared/entities/dashboard/lib';
import type { LayoutsState } from '@/bundles/Shared/entities/dashboard/model/slices/layouts';
import {
  ReportDashboardType,
  type ReportBuilderTemplateEagleEyeWidgetTypes,
  type ReportBuilderTemplateWidgetSectionTypes,
} from '@/bundles/Shared/entities/dashboard/model/types';
import type { ReportDashboardFilterObject } from '@/bundles/Shared/features/dashboard/object/filter/byObject/ui/ReportDashboardFilterByObjectModal';
import { getReckonerPeriodByPeriodTypeAndDate } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/utils';
import type { ReportBuilderTemplateWidgetStateMap } from '@/bundles/Shared/widgets/dashboard/widgets/config';
import { getInitialStateForGlobalLeaseTableWidget } from '@/bundles/Shared/widgets/dashboard/widgets/globalLeaseTable/lib';
import type { KpiTableWidgetState } from '@/bundles/Shared/widgets/dashboard/widgets/kpiTable';
import { isChartCategorical } from '@/bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/lib/common';
import type { XYChartSingleKpiWidgetSection } from '@/bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/model';
import { REPORT_BUILDER_WIDGET_TYPES } from '@/entities/report/reportBuilder';
import { settingsReportBuilderTemplatesEnhancedApi as api } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesEnhancedApi';
import type { ReportBuilderTemplateGroupWidgetSectionDto } from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';
import { formatToDateStringForRequest } from '@/shared/lib/converters';
import {
  endOf,
  shiftDateBackward,
  startOf,
  yesterdayDate,
} from '@/shared/lib/date';
import { joinWithDash } from '@/shared/lib/string';
import type { IAsset } from '@/types/Asset';
import {
  createAction,
  createEntityAdapter,
  createSlice,
  isAnyOf,
  type EntityState,
  type PayloadAction,
} from '@reduxjs/toolkit';
import type { Dayjs } from 'dayjs';
import type { UnknownRecord } from 'type-fest';

type WidgetsState = Record<
  string,
  {
    type: ReportBuilderTemplateWidgetSectionTypes;
  } & ReportBuilderTemplateWidgetStateMap[keyof ReportBuilderTemplateWidgetStateMap]
>;

export type ReportBuilderTemplateState = LayoutsState<{
  id: string;
  date: string;
  widgetsState: WidgetsState;
}>;

const resolveReportBuilderTemplateDefaultOptionsDate = (
  defaultOptionsDate?: string | null,
): DateString => {
  return formatToDateStringForRequest(
    defaultOptionsDate
      ? shiftDateBackward(new Date(defaultOptionsDate), 1, 'day')
      : yesterdayDate(),
  );
};

export const getInitialReportBuilderTemplateWidgetState = (
  widgetSection: ReportBuilderTemplateGroupWidgetSectionDto,
  dashboardDate: string | Dayjs,
) => {
  switch (
    widgetSection.widgetType as
      | ReportBuilderTemplateWidgetSectionTypes
      | ReportBuilderTemplateEagleEyeWidgetTypes
  ) {
    case 'text_area':
    case 'media': {
      return {};
    }
    case 'xy_chart': {
      return getInitialStateForReportChartWidget(widgetSection, dashboardDate);
    }
    case 'global_lease_table': {
      return getInitialStateForGlobalLeaseTableWidget(widgetSection, {
        date: dashboardDate,
        dashboardType: ReportDashboardType.REPORT_BUILDER_TEMPLATE,
      });
    }
    case 'kpi_table_single_date': {
      return {
        groupingType: widgetSection?.defaultOptions?.groupingType ?? 'assets',
        date: widgetSection?.defaultOptions?.date ?? dashboardDate,
      };
    }
    case 'kpi_table': {
      return {
        groupingType: widgetSection?.defaultOptions?.groupingType ?? 'assets',
        date: widgetSection?.defaultOptions?.date ?? dashboardDate,
        period: getReckonerPeriodByPeriodTypeAndDate(
          widgetSection?.defaultOptions?.periodType ?? 't3',
          widgetSection?.defaultOptions?.date ?? dashboardDate,
        ),
      } satisfies KpiTableWidgetState;
    }
    case 'xy_chart_single_kpi': {
      const xyChartSingleKpiWidgetSection =
        widgetSection as unknown as XYChartSingleKpiWidgetSection;

      const { dateTo, granularity, groupingType } =
        xyChartSingleKpiWidgetSection.defaultOptions ?? {};

      const isCategorical = isChartCategorical(
        xyChartSingleKpiWidgetSection.widgetConfig?.am_chart_config,
      );

      const dateFrom = resolveReportBuilderTemplateDefaultOptionsDate(
        widgetSection?.defaultOptions?.dateFrom,
      );

      return {
        kpi: isCategorical
          ? null
          : xyChartSingleKpiWidgetSection.widgetConfig?.kpis?.[0]?.key,
        dateFrom: isCategorical
          ? formatToDateStringForRequest(startOf(dateFrom, granularity))
          : dateFrom,
        dateTo: isCategorical
          ? formatToDateStringForRequest(endOf(dateFrom, granularity))
          : dateTo ?? formatToDateStringForRequest(new Date()),
        groupingType: groupingType ?? 'assets',
        granularity: granularity ?? 'day',
      };
    }
    default: {
      return {
        date: resolveReportBuilderTemplateDefaultOptionsDate(
          widgetSection?.defaultOptions?.date,
        ),
      };
    }
  }
};

const adapter = createEntityAdapter<ReportBuilderTemplateState>();

const initialStateAdapter = adapter.getInitialState();

export const updateReportBuilderTemplateWidgetState = createAction<{
  /**
   * @param id - widget section id
   */
  id: string;
  /**
   * @param groupId - widget's group id
   */
  groupId: string;
  templateId: string;
  widgetState: UnknownRecord;
}>('updateReportBuilderTemplateWidgetState');

const updateWidgetStateReducer = (
  state: EntityState<ReportBuilderTemplateState>,
  action: ReturnType<typeof updateReportBuilderTemplateWidgetState>,
) => {
  const {
    templateId,
    id: widgetSectionId,
    groupId,
    widgetState: newWidgetState,
  } = action.payload;

  const templateAndGroupId = joinWithDash([templateId, groupId]);

  const templateAndGroup = state.entities[templateAndGroupId];

  if (templateAndGroup == null) return;

  if (templateAndGroup.widgetsState?.[widgetSectionId] == null) return;

  const widgetSectionState = templateAndGroup.widgetsState[widgetSectionId];

  templateAndGroup.widgetsState[widgetSectionId] = {
    ...widgetSectionState,
    ...newWidgetState,
  };
};

interface State {
  pendingRequestIds: string[];
  selectedObjects: ReportDashboardFilterObject[];
  objectLevelAssetId: IAsset['id'] | null;
}

const {
  getApiSettingsReportBuilderGotenbergTemplatesById: getReportBuilderTemplate,
  postApiSettingsReportBuilderGotenbergWidgetSectionsByIdEvaluate:
    postEvaluateWidget,
  getApiSettingsReportBuilderGotenbergTemplatesMeta:
    getReportBuilderTemplatesMeta,
} = api.endpoints;
/**
 * @property pendingRequestIds - If there are any "evaluate" or "report builder template" requests are pending then "preview" request waits until they are all resolved
 */
const initialState: State = {
  pendingRequestIds: [],
  selectedObjects: [],
  objectLevelAssetId: null,
};

export const DEFAULT_OBJECT_LEVEL_ASSET_ID_PARAM_KEY = '__fe_state_assetId';

export const reportBuilderTemplateSlice = createSlice({
  name: 'reportBuilderTemplate',
  initialState: {
    ...initialState,
    ...initialStateAdapter,
  },
  reducers: {
    updateReportBuilderTemplateDate: (
      state,
      action: PayloadAction<{
        date: string;
        templateId: string;
      }>,
    ) => {
      const { templateId, date } = action.payload;
      if (state.entities[templateId] == null) return;

      const templateGroupStateIds = state.ids
        .filter((id) => id.toString().includes(templateId))
        .map((id) => id.toString());

      if (templateGroupStateIds.length > 0) {
        for (const templateGroupStateId of templateGroupStateIds) {
          const templateGroupState = state.entities[templateGroupStateId];

          if (templateGroupState) {
            templateGroupState.date = date;

            for (const widgetId in templateGroupState.widgetsState) {
              const widgetState = templateGroupState.widgetsState[widgetId];
              widgetState.date = date;

              if (widgetState.type === REPORT_BUILDER_WIDGET_TYPES.XY_CHART) {
                const chartWidgetState =
                  getUpdatedWidgetStateForReportChartWidget(widgetState, date);

                widgetState.dateFrom = chartWidgetState.dateFrom;
                widgetState.dateTo = chartWidgetState.dateTo;
              }
            }
          }
        }
      }

      state.entities[templateId].date = date;
    },
    updateSelectedObjects: (
      state,
      action: PayloadAction<ReportDashboardFilterObject[]>,
    ) => {
      state.selectedObjects = action.payload;
    },
    updateObjectLevelAssetId: (
      state,
      action: PayloadAction<IAsset['id'] | null>,
    ) => {
      state.objectLevelAssetId = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(
      updateReportBuilderTemplateWidgetState,
      updateWidgetStateReducer,
    );

    builder.addMatcher(
      getReportBuilderTemplate.matchFulfilled,
      (state, action) => {
        const { id: templateId } = action.meta.arg.originalArgs;
        const template = action.payload;

        const templateState = state.entities[templateId];

        const initialDate =
          resolveReportBuilderTemplateDefaultOptionsDate(null);

        if (templateState == null) {
          adapter.upsertOne(state, {
            id: templateId,
            date: initialDate,
            widgetsState: {},
          });
        }

        for (const templateGroup of template.groups) {
          const templateAndGroupStateId = joinWithDash([
            templateId,
            templateGroup.id,
          ]);

          const templateAndGroupState = state.entities[templateAndGroupStateId];

          if (templateAndGroupState == null) {
            const widgetsState = {} as WidgetsState;

            for (const widgetSection of templateGroup.widgetSections) {
              const intialWidgetState =
                getInitialReportBuilderTemplateWidgetState(
                  widgetSection,
                  initialDate,
                );

              widgetsState[widgetSection.id] = {
                type: widgetSection.widgetType,
                ...intialWidgetState,
              };
            }

            adapter.upsertOne(state, {
              id: templateAndGroupStateId,
              date: initialDate,
              widgetsState,
            });
          } else {
            const widgetsState = {} as WidgetsState;

            for (const widgetSection of templateGroup.widgetSections) {
              const intialWidgetState =
                getInitialReportBuilderTemplateWidgetState(
                  widgetSection,
                  initialDate,
                );

              widgetsState[widgetSection.id] = {
                type: widgetSection.widgetType,
                ...intialWidgetState,
              };
            }

            adapter.updateOne(state, {
              id: templateAndGroupStateId,
              changes: {
                widgetsState: {
                  ...templateAndGroupState.widgetsState,
                  ...widgetsState,
                },
              },
            });
          }
        }
      },
    );

    builder.addMatcher(
      isAnyOf(
        getReportBuilderTemplate.matchPending,
        postEvaluateWidget.matchPending,
      ),
      (state, { meta }) => {
        state.pendingRequestIds.push(meta.requestId);
      },
    );
    builder.addMatcher(
      isAnyOf(
        getReportBuilderTemplate.matchFulfilled,
        getReportBuilderTemplate.matchRejected,

        postEvaluateWidget.matchFulfilled,
        postEvaluateWidget.matchRejected,
      ),
      (state, { meta }) => {
        state.pendingRequestIds = state.pendingRequestIds.filter(
          (id) => id !== meta.requestId,
        );
      },
    );

    builder.addMatcher(
      getReportBuilderTemplatesMeta.matchFulfilled,
      (state, action) => {
        if (state.objectLevelAssetId == null) {
          state.objectLevelAssetId =
            // @ts-expect-error
            action.meta.arg.originalArgs[
              DEFAULT_OBJECT_LEVEL_ASSET_ID_PARAM_KEY
            ] ?? action.payload.assets[0].id;
        }
        if (state.selectedObjects.length === 0) {
          const segments = action.payload.segments.map(mapSegmentToObject);
          const assets = action.payload.assets.map(mapAssetToObject);

          state.selectedObjects = [
            ...segments,
            ...assets,
          ] as ReportDashboardFilterObject[];
        }
      },
    );
  },
});

export const { updateReportBuilderTemplateDate } =
  reportBuilderTemplateSlice.actions;

export const { actions: reportBuilderTemplateSliceActions } =
  reportBuilderTemplateSlice;

export const { selectById: selectReportBuilderTemplateMetadataById } =
  adapter.getSelectors((state: TRootState) => state.reportBuilderTemplate);
