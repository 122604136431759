import { CssVar } from '@/shared/config/cssVar';
import { cn } from '@/shared/lib/css/cn';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import useElementSize, {
  ElementSizeContext,
} from '@/shared/lib/hooks/useElementSize';

import { capitalize } from 'lodash-es';
import {
  OverlayScrollbarsComponent,
  OverlayScrollbarsComponentRef,
} from 'overlayscrollbars-react';
import pluralize from 'pluralize';
import { forwardRef, HTMLProps, useRef } from 'react';
import { Icon, Label as BaseLabel } from 'stories/index';
import { IconsId } from 'types/sre-icons';
import type { Maybe } from 'yup';

export type NavigationCardListRef = OverlayScrollbarsComponentRef;

export function Img({
  alt,
  fallbackNode,
  ...props
}: HTMLProps<HTMLImageElement> & {
  fallbackNode?: React.ReactNode;
}) {
  const { value: error, setTrue } = useBoolean(false);

  if (error || props.src == null) {
    return <>{fallbackNode}</>;
  }
  return <img onError={setTrue} alt={alt} {...props} />;
}

const ItemImg = ({
  imgSrc,
  placeholderIcon,
}: {
  imgSrc: Maybe<string>;
  placeholderIcon: IconsId;
}) => {
  const fallBack = (
    <Icon iconName={placeholderIcon} className="header4-regular" />
  );

  return (
    <div className="flex h-[56px] w-[56px] rounded-[8px] border border-solid border-x-neutral-100 p-tw-1 shadow-z-020">
      <div className="flex h-full w-full items-center justify-center overflow-hidden rounded bg-neutral-200 ">
        {imgSrc ? <Img src={imgSrc} fallbackNode={fallBack} /> : fallBack}
      </div>
    </div>
  );
};

const Label = ({ text, color }: { text?: string; color?: CssVar | string }) => (
  <BaseLabel className="!p-0" color={color ?? ''}>
    {capitalize(text)}
  </BaseLabel>
);

const ShownItemsInfo = ({ length }: { length: number }) => (
  <p className="secondary-regular uppercase text-neutral-500">
    Shown <span className="text-neutral-900">{length}</span>{' '}
    {pluralize('items', length)}
  </p>
);

const NavigationCardList = forwardRef<NavigationCardListRef, LayoutProps>(
  ({ children, className }, ref) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const size = useElementSize(containerRef);

    return (
      <OverlayScrollbarsComponent
        ref={ref}
        className={cn('shadow-z-000', className)}
      >
        <div ref={containerRef} className="flex flex-col gap overflow-hidden ">
          <ElementSizeContext.Provider value={size}>
            {children}
          </ElementSizeContext.Provider>
        </div>
      </OverlayScrollbarsComponent>
    );
  },
);

interface NavigationCardListComponent
  extends React.ForwardRefExoticComponent<
    LayoutProps & React.RefAttributes<NavigationCardListRef>
  > {
  ItemImg: typeof ItemImg;
  Label: typeof Label;
  ShownItemsInfo: typeof ShownItemsInfo;
}

export default {
  ...NavigationCardList,
  ItemImg,
  Label,
  ShownItemsInfo,
} as NavigationCardListComponent;
