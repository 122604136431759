import { cn } from '@/shared/lib/css/cn';
import { BaseCard } from '@/shared/ui/BaseCard';
import {
  DasbhoardCardTypeLabel,
  DashboardCardStatusLabel,
  DashboardIcon,
} from 'bundles/Shared/entities/dashboard';
import React, { useMemo } from 'react';

import { ReportDashboardType, ReportEagleEyeDashboard } from '../../model';

interface Props extends PropsWithClassName {
  status: ReportEagleEyeDashboard['status'];
  type: ReportDashboardType | `${ReportDashboardType}`;
  onClick?: VoidFunction;
}

export function DashboardCard({
  children,
  status,
  type,
  onClick,
  className,
}: React.PropsWithChildren<Props>) {
  const icon = useMemo(() => {
    switch (type) {
      case ReportDashboardType.OBJECT: {
        return 'objectLevelDashboardGreyscale';
      }
      case ReportDashboardType.EAGLE_EYE: {
        return 'eagleEyeDashboardGreyscale';
      }
      case ReportDashboardType.COMPARISON_MODE: {
        return 'compare';
      }
      default: {
        return 'objectLevelDashboardGreyscale';
      }
    }
  }, [type]);

  return (
    <BaseCard
      className={className}
      imgWrapperProps={{
        onClick,
      }}
      iconSlot={<DashboardIcon className="h-[48px] w-[48px]" iconName={icon} />}
    >
      <div className="flex flex-col gap-tw-2 px-tw-2">
        <div className="flex gap-tw-2">
          <DashboardCardStatusLabel status={status} />
          <DasbhoardCardTypeLabel type={type} />
        </div>
        {children}
      </div>
    </BaseCard>
  );
}

DashboardCard.Content = ({
  children,
  className,
}: React.PropsWithChildren<PropsWithClassName>) => (
  <div className={cn(className)}>{children}</div>
);
