import { cn } from '@/shared/lib/css/cn';

import { ReactComponent as CornerSvg } from 'bundles/Shared/widgets/formula/panel/ui/Corner.svg';
import { Icon } from '@/stories';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

export const FormulaTabs = ({ children }: React.PropsWithChildren) => (
  <OverlayScrollbarsComponent
    className="min-h-[30px] shrink-0"
    options={{
      overflow: {
        y: 'hidden',
      },
      scrollbars: {
        visibility: 'hidden',
      },
    }}
  >
    <div className="flex">{children}</div>
  </OverlayScrollbarsComponent>
);
export const FormulaTabsTab = ({
  children,
  isActive,
  onClick,
  onClose,
}: React.PropsWithChildren<{
  isActive: boolean;
  onClick?: VoidFunction;
  onClose?: VoidFunction;
}>) => {
  const Corner = ({
    side,
  }: PropsWithClassName & {
    side: 'left' | 'right';
  }) => (
    <CornerSvg
      style={{
        transform:
          side === 'left' ? 'scale(-1,1) translateX(100%)' : 'translateX(100%)',
      }}
      className={cn(
        'absolute -bottom-[1px] z-[1]',
        side === 'left' ? 'left-0 group-first/item:hidden' : 'right-0',
      )}
    />
  );
  return (
    <div
      onClick={onClick}
      className={cn(
        'group/item relative flex cursor-pointer items-center gap-tw-1 whitespace-nowrap rounded-t-xl bg-neutral-300 px-tw-3 py-tw-1',
        isActive && 'bg-neutral-000',
      )}
    >
      {isActive && <Corner side="left" />}
      {children}
      {onClose && (
        <Icon
          className="hover:text-neutral-900"
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
          iconName="closeSmall"
        />
      )}
      {isActive && <Corner side="right" />}
    </div>
  );
};

export const FormulaTabsTabLabel = ({
  children,
  className,
}: React.PropsWithChildren<PropsWithClassName>) => (
  <span className={cn('inline-semibold text-neutral-800', className)}>
    {children}
  </span>
);

FormulaTabs.Tab = FormulaTabsTab;
FormulaTabsTab.Label = FormulaTabsTabLabel;
