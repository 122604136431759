export const ERP_SYSTEMS = {
  SYMMETRE: 'symmetre',
  QUICKBOOKS_DESKTOP: 'quickbooks_desktop',
  QUICKBOOKS_ONLINE: 'quickbooks_online',
  YARDI: 'yardi',
  MRI: 'mri',
  ENTRATA: 'entrata',
  M3: 'm3',
  REALPAGE: 'realpage',
  RESMAN: 'resman',
  CYMA: 'cyma',
  SAGE: 'sage',
  RENTEGI: 'rentegi',
  NET_SUITE: 'net_suite',
  FORTRESS: 'fortress',
  APP_FOLIO: 'app_folio',
  RASI: 'rasi',
  XERO: 'xero',
  RENT_MANAGER: 'rent_manager',
  BUILDIUM: 'buildium',
  PROPERTYWARE: 'propertyware',
  RENTVINE: 'rentvine',
  ZOHO: 'zoho',
  MICROSOFT_DYNAMICS_365: 'microsoft_dynamics_365',
  ACUMATICA: 'acumatica',
  INFOR: 'infor',
  RADIX: 'radix',
  ANYONE_HOME: 'anyone_home',
} as const;

export type ERPSystem = (typeof ERP_SYSTEMS)[keyof typeof ERP_SYSTEMS];
