import { reconcileForecastingEntitiesGeneratedApi } from '@/entities/reconcile/forecasting/api/reconcileForecastingEntitiesGeneratedApi';

export const RECONCILE_FORECASTING_ENTITIES_TAG =
  'ReconcileForecastingEntities';
export const RECONCILE_FORECASTING_ENTITIES_YEARS_TAG =
  'ReconcileForecastingEntitiesYears';
export const RECONCILE_FORECASTING_ENTITIES_BUDGET_TAG =
  'ReconcileForecastingEntitiesBudget';
export const RECONCILE_FORECASTING_ENTITIES_BUDGETS_TAG =
  'ReconcileForecastingEntitiesBudgets';
export const RECONCILE_FORECASTING_ENTITIES_BUDGET_METRICS_TAG =
  'ReconcileForecastingEntitiesBudgetsMetrics';

export const reconcileForecastingEntitiesEnhancedApi =
  reconcileForecastingEntitiesGeneratedApi.enhanceEndpoints({
    addTagTypes: [
      RECONCILE_FORECASTING_ENTITIES_TAG,
      RECONCILE_FORECASTING_ENTITIES_YEARS_TAG,
      RECONCILE_FORECASTING_ENTITIES_BUDGETS_TAG,
      RECONCILE_FORECASTING_ENTITIES_BUDGET_TAG,
      RECONCILE_FORECASTING_ENTITIES_BUDGET_METRICS_TAG,
    ],
    endpoints: {
      getApiReconcileForecastingForecasts: {
        providesTags: [RECONCILE_FORECASTING_ENTITIES_TAG],
      },
      getApiReconcileForecastingForecastsByForecastIdYears: {
        providesTags: (_, __, args) => [
          {
            type: RECONCILE_FORECASTING_ENTITIES_YEARS_TAG,
            id: args.forecastId,
          },
        ],
      },
      getApiReconcileForecastingForecastsByForecastIdBudgets: {
        providesTags: (_, __, args) => [
          {
            type: RECONCILE_FORECASTING_ENTITIES_BUDGETS_TAG,
            id: args.forecastId,
          },
        ],
      },
      getApiReconcileForecastingForecastsByForecastIdBudgetsAndId: {
        providesTags: (_, __, args) => [
          {
            type: RECONCILE_FORECASTING_ENTITIES_BUDGET_TAG,
            id: args.id,
          },
        ],
      },
      getApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdMetrics:
        {
          providesTags: (_, __, args) => [
            {
              type: RECONCILE_FORECASTING_ENTITIES_BUDGET_METRICS_TAG,
              id: args.budgetId,
            },
          ],
        },
      postApiReconcileForecastingForecasts: {
        invalidatesTags: [RECONCILE_FORECASTING_ENTITIES_TAG],
      },
      putApiReconcileForecastingForecastsById: {
        invalidatesTags: [RECONCILE_FORECASTING_ENTITIES_TAG],
      },
      deleteApiReconcileForecastingForecastsById: {
        invalidatesTags: [RECONCILE_FORECASTING_ENTITIES_TAG],
        onQueryStarted({ id }, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            reconcileForecastingEntitiesEnhancedApi.util.updateQueryData(
              'getApiReconcileForecastingForecastsByForecastIdYears',
              { forecastId: id },
              () => undefined,
            ),
          );

          queryFulfilled.catch(patchResult.undo);
        },
      },
      postApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdImport:
        {
          invalidatesTags: (_, __, args) => [
            RECONCILE_FORECASTING_ENTITIES_TAG,
            {
              type: RECONCILE_FORECASTING_ENTITIES_BUDGETS_TAG,
              id: args.forecastId,
            },
            {
              type: RECONCILE_FORECASTING_ENTITIES_BUDGET_TAG,
              id: args.budgetId,
            },
          ],
        },
      postApiReconcileForecastingForecastsByForecastIdYears: {
        invalidatesTags: (_, __, args) => [
          RECONCILE_FORECASTING_ENTITIES_TAG,
          {
            type: RECONCILE_FORECASTING_ENTITIES_YEARS_TAG,
            id: args.forecastId,
          },
        ],
      },
      putApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdFinancialCategories:
        {
          invalidatesTags: (_, __, args) => [
            RECONCILE_FORECASTING_ENTITIES_TAG,
            {
              type: RECONCILE_FORECASTING_ENTITIES_BUDGETS_TAG,
              id: args.forecastId,
            },
            {
              type: RECONCILE_FORECASTING_ENTITIES_BUDGET_TAG,
              id: args.budgetId,
            },
          ],
        },
      postApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdMetrics:
        {
          invalidatesTags: (_, __, args) => [
            {
              type: RECONCILE_FORECASTING_ENTITIES_BUDGET_METRICS_TAG,
              id: args.budgetId,
            },
            {
              type: RECONCILE_FORECASTING_ENTITIES_BUDGETS_TAG,
              id: args.forecastId,
            },
            {
              type: RECONCILE_FORECASTING_ENTITIES_BUDGET_TAG,
              id: args.budgetId,
            },
          ],
        },
      deleteApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdMetricsBudgetMetricId:
        {
          invalidatesTags: (_, __, args) => [
            {
              type: RECONCILE_FORECASTING_ENTITIES_BUDGET_METRICS_TAG,
              id: args.budgetId,
            },
            {
              type: RECONCILE_FORECASTING_ENTITIES_BUDGETS_TAG,
              id: args.forecastId,
            },
            {
              type: RECONCILE_FORECASTING_ENTITIES_BUDGET_TAG,
              id: args.budgetId,
            },
          ],
        },
      putApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdValuesBulk:
        {
          invalidatesTags: (_, __, args) => [
            {
              type: RECONCILE_FORECASTING_ENTITIES_BUDGET_TAG,
              id: args.budgetId,
            },
          ],
          async onQueryStarted({ budgetId }, { dispatch, queryFulfilled }) {
            try {
              const { data: updatedBudget } = await queryFulfilled;

              dispatch(
                reconcileForecastingEntitiesEnhancedApi.util.updateQueryData(
                  'getApiReconcileForecastingForecastsByForecastIdBudgets',
                  {
                    forecastId: updatedBudget.forecast.id,
                    year: updatedBudget.year,
                  },
                  (draft) => {
                    const budget = draft?.items?.find((b) => b.id === budgetId);

                    if (!budget || budget.state === updatedBudget.state) {
                      return;
                    }

                    budget.state = updatedBudget.state;

                    dispatch(
                      reconcileForecastingEntitiesEnhancedApi.util.invalidateTags(
                        [
                          {
                            type: RECONCILE_FORECASTING_ENTITIES_YEARS_TAG,
                            id: budget.forecast.id,
                          },
                        ],
                      ),
                    );
                  },
                ),
              );
            } catch (error) {
              console.log(error);
            }
          },
        },
      putApiReconcileForecastingForecastsByForecastIdBudgetsAndId: {
        invalidatesTags: (_, __, args) => [
          {
            type: RECONCILE_FORECASTING_ENTITIES_YEARS_TAG,
            id: args.forecastId,
          },
        ],
        async onQueryStarted(
          { id, forecastId, ...patch },
          { dispatch, queryFulfilled },
        ) {
          // Optimistic update of the budget
          const patchResult = dispatch(
            reconcileForecastingEntitiesEnhancedApi.util.updateQueryData(
              'getApiReconcileForecastingForecastsByForecastIdBudgetsAndId',
              {
                forecastId,
                id,
              },
              (draft) => {
                draft.state = patch.body.budget.state;
              },
            ),
          );

          try {
            const { data: updatedBudget } = await queryFulfilled;

            // Pessimistic update of the budgets list, request does not contain year
            dispatch(
              reconcileForecastingEntitiesEnhancedApi.util.updateQueryData(
                'getApiReconcileForecastingForecastsByForecastIdBudgets',
                {
                  forecastId: updatedBudget.forecast.id,
                  year: updatedBudget.year,
                },
                (draft) => {
                  const budget = draft?.items?.find((b) => b.id === id);

                  if (!budget) return;

                  budget.state = patch.body.budget.state;
                },
              ),
            );
          } catch {
            patchResult.undo();
          }
        },
      },
      putApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdClean: {
        invalidatesTags: (_, __, args) => [
          {
            type: RECONCILE_FORECASTING_ENTITIES_BUDGET_TAG,
            id: args.budgetId,
          },
          {
            type: RECONCILE_FORECASTING_ENTITIES_YEARS_TAG,
            id: args.forecastId,
          },
        ],
        async onQueryStarted({ budgetId }, { dispatch, queryFulfilled }) {
          try {
            const { data: updatedBudget } = await queryFulfilled;

            dispatch(
              reconcileForecastingEntitiesEnhancedApi.util.updateQueryData(
                'getApiReconcileForecastingForecastsByForecastIdBudgets',
                {
                  forecastId: updatedBudget.forecast.id,
                  year: updatedBudget.year,
                },
                (draft) => {
                  const budget = draft?.items?.find((b) => b.id === budgetId);

                  if (!budget) return;

                  budget.state = updatedBudget.state;
                },
              ),
            );
          } catch (error) {
            console.log(error);
          }
        },
      },
      putApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdCopyFromSource:
        {},
    },
  });

export const {
  useGetApiReconcileForecastingForecastsQuery,
  useGetApiReconcileForecastingForecastsByIdQuery,
  useGetApiReconcileForecastingForecastsByForecastIdYearsQuery,
  useGetApiReconcileForecastingForecastsByForecastIdBudgetsQuery,
  useGetApiReconcileForecastingForecastsByForecastIdBudgetsAndIdQuery,
  useGetApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdMetricsQuery,
  useLazyGetApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdFinancialCategoriesQuery,

  usePostApiReconcileForecastingForecastsMutation,
  usePostApiReconcileForecastingForecastsByForecastIdYearsMutation,
  usePostApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdMetricsMutation,

  usePutApiReconcileForecastingForecastsByIdMutation,
  usePutApiReconcileForecastingForecastsByForecastIdBudgetsAndIdMutation,
  usePutApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdValuesBulkMutation,
  usePutApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdCopyFromSourceMutation,
  usePutApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdCleanMutation,
  usePutApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdFinancialCategoriesMutation,

  useDeleteApiReconcileForecastingForecastsByIdMutation,
  useDeleteApiReconcileForecastingForecastsByForecastIdBudgetsAndIdMutation,
  useDeleteApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdMetricsBudgetMetricIdMutation,
} = reconcileForecastingEntitiesEnhancedApi;
