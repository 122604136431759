import { cn } from '@/shared/lib/css/cn';
import React, { useState } from 'react';
import { Control, FieldValues, useController } from 'react-hook-form';
import { FieldPath } from 'react-hook-form/dist/types/path/eager';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { Icon } from 'stories';
import { IconsId } from 'types/sre-icons';

type InputState = {
  isDirty: boolean;
};

export type InputNumberProps = Omit<NumberFormatProps, 'onBlur' | 'size'> & {
  leftIcon?: IconsId;
  rightIcon?: IconsId;
  size?: 's' | 'm' | 'l';
  error?: boolean | ((value: number, inputState: InputState) => boolean);
  onBlur?: (
    value: number | undefined,
    inputState: InputState,
    e: React.FocusEvent<HTMLInputElement>,
  ) => void;
  selectOnFocus?: boolean;
  classes?: {
    outer?: string;
  };
};

function InputNumber({
  size,
  leftIcon,
  rightIcon,
  error,
  className,
  value: externalValue,
  defaultValue,
  onValueChange,
  onBlur,
  classes,
  selectOnFocus,
  ...props
}: InputNumberProps) {
  const [isDirty, setIsDirty] = useState(false);
  const [internalValue, setInternalValue] = useState<number | undefined>(
    defaultValue,
  );
  const value = externalValue === undefined ? internalValue : externalValue;

  const handleChange: NumberFormatProps['onValueChange'] = (e, sourceInfo) => {
    setIsDirty(true);
    setInternalValue(e.value ? e.floatValue : undefined);
    onValueChange?.(e, sourceInfo);
  };

  const inputState = {
    isDirty,
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    onBlur?.(internalValue ?? undefined, inputState, e);
  };

  const isAllowed = (values: { floatValue: number | undefined }) => {
    const { floatValue } = values;
    const { max } = props;
    if (floatValue === undefined || max === undefined) {
      return true;
    }
    return floatValue <= max;
  };

  const isValid =
    typeof error === 'function' ? error?.(value, inputState) : error;

  return (
    <>
      <div
        className={cn(
          'sre-input',
          size ? `sre-input_size-${size}` : '',
          classes?.outer,
        )}
      >
        <NumberFormat
          className={cn(
            'sre-input__field',
            'form-item__number',
            leftIcon ? 'form-item__date_left' : '',
            isValid && 'sre-input__field_error',
            props.disabled && '!bg-neutral-200',
            className,
          )}
          // https://github.com/s-yadav/react-number-format/issues/727
          value={value === null ? '' : value}
          onValueChange={handleChange}
          onBlur={handleBlur}
          isAllowed={isAllowed}
          onFocus={(e) => selectOnFocus && e.target.select()}
          {...props}
        />
        <div className="sre-input__back-layer" />
        {leftIcon && (
          <div className="sre-input__side-icon sre-input__side-icon_left">
            <Icon iconName={leftIcon} />
          </div>
        )}
        {rightIcon && (
          <div className="sre-input__side-icon sre-input__side-icon_right">
            <Icon iconName={rightIcon} />
          </div>
        )}
      </div>
      {error && <div className="red">{error}</div>}
    </>
  );
}

export const InputNumberController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  onValueChange,
  ...props
}: {
  control: Control<TFieldValues>;
  name: TName;
} & InputNumberProps) => {
  const { field } = useController({ control, name });
  return (
    <InputNumber
      name={name}
      value={field.value}
      onValueChange={(e, i) => {
        if (onValueChange) {
          onValueChange(e, i);
          return;
        }
        field.onChange(e.floatValue);
      }}
      {...props}
    />
  );
};

export default InputNumber;
