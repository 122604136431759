import { cn } from '@/shared/lib/css/cn';
import React, { CSSProperties } from 'react';

export const Corner = ({
  className,
  transform,
  classes,
}: PropsWithClassName & {
  transform?: CSSProperties['transform'];
  classes?: {
    corner?: string;
  };
}) => (
  <div
    style={{
      transform,
    }}
    className={cn(
      'absolute h-[10px] w-[10px] bg-light-10 print:hidden',
      className,
    )}
  >
    <div
      className={cn('h-full w-full rounded-tl-2xl bg-white', classes?.corner)}
    />
  </div>
);

export const LabelWithCorners = ({
  children,
  placement,
  classes,
}: React.PropsWithChildren<
  {
    placement:
      | 'top-left'
      | 'top-right'
      | 'bottom-left'
      | 'bottom-right'
      | 'top-center';
  } & PropsWithClassName & {
      classes?: {
        text?: string;
        bg?: string;
      };
    }
>) => {
  const getCornerPositions = (): {
    className: string;
    first: React.ComponentProps<typeof Corner>;
    second: React.ComponentProps<typeof Corner>;
  } => {
    const classesProp = {
      classes: {
        corner: classes?.bg,
      },
    };
    switch (placement) {
      case 'top-left':
        return {
          className: 'rounded-tl-2xl rounded-br-2xl',
          first: {
            className: 'left-0 -bottom-[10px]',
            ...classesProp,
          },
          second: {
            className: '-right-[10px] top-0',
            ...classesProp,
          },
        };
    }
    return {
      className: 'rounded-b-2xl',
      first: {
        transform: 'scale(-1,1)',
        className: '-left-[10px] top-0',
        ...classesProp,
      },
      second: {
        className: '-right-[10px] top-0',
        ...classesProp,
      },
    };
  };
  const cornerPositions = getCornerPositions();
  return (
    <div
      className={cn(
        'relative bg-light-10 px-[0.75rem] py-tw-0.5',
        cornerPositions.className,
      )}
    >
      <Corner {...cornerPositions.first} />
      <span className={cn('inline-semibold text-neutral-650', classes?.text)}>
        {children}
      </span>
      <Corner {...cornerPositions.second} />
    </div>
  );
};

interface Props extends React.PropsWithChildren, PropsWithClassName {
  title?: string;
  style?: CSSProperties;
  classes?: {
    wrapper?: string;
    children?: string;
  };
}

export const TitledCard = React.forwardRef<HTMLDivElement, Props>(
  ({ title, className, children, classes, style }, ref) => (
    <div
      ref={ref}
      style={style}
      className={cn(
        'flex flex-col items-center rounded-2xl bg-white p-tw-4 pt-0 shadow-m',
        className,
      )}
    >
      <div
        className={cn(
          'flex w-full flex-grow flex-col items-center gap-tw-2',
          classes?.wrapper,
        )}
      >
        {title && (
          <LabelWithCorners placement="top-center">{title}</LabelWithCorners>
        )}
        <div
          className={cn(
            'flex w-full flex-grow items-center justify-center',
            classes?.children,
          )}
        >
          {children}
        </div>
      </div>
    </div>
  ),
);
