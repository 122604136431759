/* eslint-disable new-cap */
import AlbumsList, {
  IFolder,
} from '@/bundles/Assets/components/Folders/AlbumsList';
import {
  useGetObjectFoldersQuery,
  useGetSharedFilesQuery,
  type SharedFilesResponse,
} from '@/bundles/Assets/components/Media/api/objectFoldersApi';
import MediaItem from '@/bundles/Assets/components/SharedFiles/MediaItem';
import { snakeCaseKeys } from '@/bundles/Construction/components/helpers';
import InputNumber from '@/bundles/Shared/components/GroupForm/FormItems/InputNumber';
import { SharedSelect } from '@/bundles/Shared/components/GroupForm/FormItems/new/SharedSelect';
import NoDataOverlay from '@/bundles/Shared/components/NoDataOverlay';
import VerticalSeparator from '@/bundles/Shared/components/VerticalSeparator/VerticalSeparator';
import { resolveWidgetSectionPositionClassName } from '@/bundles/Shared/widgets/dashboard/widgets/common';
import { widgetHasData } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/lib';
import {
  MEDIA_WIDGET_OBJECT_FIT_OPTIONS,
  MEDIA_WIDGET_OBJECT_POSITION_OPTIONS,
  type MediaWidgetDisplayOptions,
} from '@/bundles/Shared/widgets/dashboard/widgets/media/config';
import { getMediaWidgetDisplayOptions } from '@/bundles/Shared/widgets/dashboard/widgets/media/lib';
import { CaptionSettingsModal } from '@/bundles/Shared/widgets/dashboard/widgets/media/ui/CaptionSettingsModal';
import { MediaWidget } from '@/bundles/Shared/widgets/dashboard/widgets/media/ui/MediaWidget';
import { MediaWidgetFigure } from '@/bundles/Shared/widgets/dashboard/widgets/media/ui/MediaWidgetFigure';
import { MediaWidgetPreviewUI } from '@/bundles/Shared/widgets/dashboard/widgets/media/ui/MediaWidgetPreviewUI';
import { WidgetConfigCardProps } from '@/bundles/Shared/widgets/dashboard/widgets/model';
import { usePutApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetSectionsIdSnapshotMutation } from '@/entities/report/reportBuilder';
import type {
  AssetsReportBuilderMediaWidgetDto,
  AssetsReportBuilderReportWidgetDto,
} from '@/entities/report/reportBuilder/api/coreAssetsReportBuilderReportsGeneratedApi';
import { BaseWidgetConfigCard } from '@/entities/report/reportBuilder/ui/BaseWidgetConfigCard';
import { cn } from '@/shared/lib/css/cn';
import useBoolean from '@/shared/lib/hooks/useBoolean';
import { DialogProps, useModal } from '@/shared/lib/hooks/useModal';
import { PdfUI } from '@/shared/ui/PdfUI';
import {
  Button,
  Icon,
  IconButton,
  ImageCard,
  Modal,
  ModalHeaderWithSubtitle,
  ThinTabGroup,
  Tumbler,
} from '@/stories';
import SkeletonBlock from '@/stories/ProjectCard/SkeletonBlock';
import {
  TabsContextProvider,
  useTabs,
  useTabsContext,
} from '@/stories/Tabs/useTabs';
import { IAsset } from '@/types/Asset';
import { ISharedFolder } from '@/types/SharedFolder';
import { arrayMoveImmutable } from 'array-move';
import pluralize from 'pluralize';
import { useState } from 'react';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';

const TABS = [
  {
    id: 'select-media',
    label: 'Select Media',
  },
  {
    id: 'media-settings',
    label: 'Media Settings',
  },
] as const;

const MediaFinder = ({
  assetId,
  isItemSelected,
  onItemSelect,
}: {
  assetId: IAsset['id'];
  isItemSelected: (id: string) => boolean;
  onItemSelect: (media: SharedFilesResponse['sharedFiles'][number]) => void;
}) => {
  const { thinTabGroupProps, tab } = useTabsContext();
  const [currentFolder, setCurrentFolder] = useState<ISharedFolder | null>(
    null,
  );
  const { data, isFetching: isFoldersDataLoading } = useGetObjectFoldersQuery({
    entityId: assetId,
    entityType: 'Asset',
    folderType: 'media',
    parentId: currentFolder?.id,
  });

  const { data: sharedFilesData, isFetching: isSharedFilesLoading } =
    useGetSharedFilesQuery(
      {
        folder_id: currentFolder?.id,
        entity_id: assetId,
        entity_type: 'Asset',
        document_types: 'media',
      },
      {
        skip: currentFolder == null,
        refetchOnMountOrArgChange: true,
      },
    );

  const folders = data ?? [];
  const sharedFiles = sharedFilesData?.sharedFiles ?? [];

  const hasFolders = folders.length > 0;
  const hasSharedFiles = sharedFiles.length > 0;
  const noFolders =
    !hasFolders &&
    !hasSharedFiles &&
    !isFoldersDataLoading &&
    !isSharedFilesLoading;

  const noSharedFilesInThisFolder = !hasSharedFiles && !isSharedFilesLoading;

  return (
    <div className="flex flex-col gap-tw-4">
      <div className="flex">
        <ThinTabGroup fullWidth {...thinTabGroupProps} />
      </div>

      {tab?.id === TABS[0].id && (
        <>
          <div className="flex items-center gap-tw-4">
            <Button
              size="s"
              iconName="arrowLeft"
              disabled={currentFolder == null}
              className="max-w-max"
              onClick={() => {
                setCurrentFolder(null);
              }}
            >
              All Albums
            </Button>
          </div>
          {!isFoldersDataLoading && hasFolders && (
            <div
              className={cn('flex flex-col gap-tw-2 pb-tw-4', {
                'border-b-[1px] border-solid border-neutral-150 ': noFolders,
              })}
            >
              <p className="inline-semibold text-neutral-550">
                {folders.length} {pluralize('Album', folders.length)}
              </p>

              <AlbumsList
                folders={folders as unknown as IFolder[]}
                onClick={(id) => {
                  setCurrentFolder(folders.find((f) => f.id === id)!);
                }}
              />
            </div>
          )}
          {noFolders && (
            <NoDataOverlay title="There is no media and albums available, you can upload to the gallery" />
          )}
          {noSharedFilesInThisFolder && !noFolders && currentFolder != null && (
            <NoDataOverlay
              title="There is no media available in this album, you can upload to the
          gallery"
            />
          )}
          {isFoldersDataLoading && (
            <div className="flex flex-col gap-tw-2 border-b-[1px] border-solid border-neutral-150 pb-tw-4">
              <p className="inline-semibold text-neutral-550">Albums</p>

              <div className="flex gap-tw-6">
                <SkeletonBlock className="h-[224px] w-[320px] rounded-[16px]" />
                <SkeletonBlock className="h-[224px] w-[320px] rounded-[16px]" />
                <SkeletonBlock className="h-[224px] w-[320px] rounded-[16px]" />
                <SkeletonBlock className="h-[224px] w-[320px] rounded-[16px]" />
              </div>
            </div>
          )}

          {!isSharedFilesLoading && currentFolder != null && hasSharedFiles && (
            <>
              <p className="inline-semibold text-neutral-550">
                {sharedFiles.length} Media{' '}
                {pluralize('file', sharedFiles.length)}
              </p>

              <div className="grid gap-tw-6 border-b-[1px] border-solid border-neutral-150 pb-tw-4 md:grid-cols-4 3xl:grid-cols-5 4xl:grid-cols-6">
                {sharedFiles.map((m) => (
                  <div key={m.id}>
                    <ImageCard
                      selected={isItemSelected(m.id)}
                      image={m.smallImageUrl ?? m.url}
                      subtitle={m.description ?? ''}
                      onSelect={() => {
                        onItemSelect(m);
                      }}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
          {isSharedFilesLoading && currentFolder != null && (
            <>
              <p className="inline-semibold text-neutral-550">Media files</p>

              <div className="flex gap-tw-4">
                {Array.from({ length: currentFolder.childrenCount }).map(
                  (_, i) => (
                    <SkeletonBlock
                      key={i}
                      className="h-[160px] w-[240px] rounded-[16px]"
                    />
                  ),
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

type SelectedMedia = Pick<
  AssetsReportBuilderMediaWidgetDto['items'][number],
  'id' | 'caption' | 'dataUri'
>;

const MediaModal = (
  props: DialogProps<{
    items: SelectedMedia[];
    displayOptions: MediaWidgetDisplayOptions;
    initialSelectedFiles?: SelectedMedia[];
  }> & {
    assetId: IAsset['id'];
    widgetSection: AssetsReportBuilderReportWidgetDto;
    initialSelectedFiles?: SelectedMedia[];
    initialDisplayOptions?: MediaWidgetDisplayOptions;
  },
) => {
  const [displayOptions, setDisplayOptions] =
    useState<MediaWidgetDisplayOptions>(
      getMediaWidgetDisplayOptions(props.initialDisplayOptions),
    );
  const { value: showContainerBorder, toggle } = useBoolean(true);
  const { tab, thinTabGroupProps, setTab } = useTabs(TABS, 0);
  const { openModal } = useModal();
  const [selectedFiles, setSelectedFiles] = useState<{ id: string }[]>(
    props.initialSelectedFiles ?? [],
  );

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    newIndex: number;
    oldIndex: number;
  }) => {
    const visibleSorted = arrayMoveImmutable(selectedFiles, oldIndex, newIndex);
    document.body.style.cursor = 'default';
    setSelectedFiles([...visibleSorted]);
  };

  const DragHandle = SortableHandle(() => (
    <IconButton variant="secondary" iconName="move" aria-hidden="true" />
  ));

  const SortableItem = SortableElement(
    ({ value }: { value: SelectedMedia }) => (
      <MediaWidgetFigure
        className="relative max-w-[240px]"
        caption={value.caption}
      >
        <MediaItem
          media={{ ...value, smallImageUrl: value.url ?? value.dataUri }}
          actions={
            <div className="absolute bottom-tw-4 left-tw-4 flex gap-tw-2">
              <DragHandle />
              <IconButton
                iconName="trash"
                onClick={() =>
                  setSelectedFiles((prev) =>
                    prev.filter((item) => item.id !== value.id),
                  )
                }
              />
              <IconButton
                iconName="edit"
                onClick={async () => {
                  const res = await openModal(CaptionSettingsModal, {
                    initialForm: {
                      alignment: value.caption?.alignment ?? 'left',
                      captionText: value.caption?.text ?? '',
                    },
                  });

                  if (res == null) return;

                  setSelectedFiles((prev) => {
                    return prev.map((item) => {
                      if (item.id === value.id) {
                        return {
                          ...item,
                          caption: {
                            text: res.captionText,
                            alignment: res.alignment,
                          },
                        };
                      }
                      return item;
                    });
                  });
                }}
              />
            </div>
          }
          hideActions
          selectedItems={[]}
          size="m"
        />
      </MediaWidgetFigure>
    ),
  );

  const SortableList = SortableContainer(({ items }: { items: unknown[] }) => (
    <div className="flex flex-wrap gap-tw-4">
      {items.map((value, index) => (
        // @ts-expect-error
        <SortableItem index={index} key={index} value={value} />
      ))}
    </div>
  ));

  const handleItemSelect = (m: SharedFilesResponse['sharedFiles'][number]) => {
    setSelectedFiles((prev) => {
      if (prev.find((i) => i.id === m.id)) {
        return prev.filter((i) => i.id !== m.id);
      }
      return [...prev, m];
    });
  };

  return (
    <Modal
      header={<ModalHeaderWithSubtitle title="Add Media" />}
      size="huge"
      toggle={props.onClose}
      classes={{
        body: 'flex flex-col gap-tw-4',
      }}
      actions={
        <div className="flex w-full justify-between">
          <Button variant="secondary" onClick={props.onClose}>
            Cancel
          </Button>
          <Button
            variant="success"
            onClick={() => {
              props.onSubmit?.({
                items: selectedFiles,
                displayOptions,
              });
            }}
          >
            Save
          </Button>
        </div>
      }
    >
      <TabsContextProvider
        value={{
          thinTabGroupProps,
          tab,
          setTab,
        }}
      >
        <MediaFinder
          assetId={props.assetId}
          isItemSelected={(id) =>
            Boolean(selectedFiles.find((i) => i.id === id))
          }
          onItemSelect={handleItemSelect}
        />
        {tab?.id === TABS[1].id && (
          <MediaWidgetPreviewUI>
            <MediaWidgetPreviewUI.Form>
              <MediaWidgetPreviewUI.FormField
                label="Media Container Border"
                slot={
                  <Icon
                    iconName="info"
                    tooltipProps={{
                      mainText: (
                        <div className="flex flex-col gap-tw-2">
                          <p>Applies only to the Media Widget Preview.</p>
                        </div>
                      ),
                    }}
                  />
                }
              >
                <Tumbler
                  checked={showContainerBorder}
                  onChange={() => {
                    toggle();
                  }}
                />
              </MediaWidgetPreviewUI.FormField>

              <VerticalSeparator />

              <MediaWidgetPreviewUI.FormField label="Scaling Behaviour">
                <SharedSelect
                  className="w-[260px]"
                  isMulti={false}
                  menuPortalTarget={document.body}
                  value={MEDIA_WIDGET_OBJECT_FIT_OPTIONS.find(
                    (option) => option.value === displayOptions.objectFit,
                  )}
                  options={MEDIA_WIDGET_OBJECT_FIT_OPTIONS}
                  onChange={(o) => {
                    setDisplayOptions((prev) => ({
                      ...prev,
                      objectFit: o?.value ?? 'cover',
                    }));
                  }}
                />
              </MediaWidgetPreviewUI.FormField>
              <VerticalSeparator />

              <MediaWidgetPreviewUI.FormField label="Media Position">
                <SharedSelect
                  className="w-[160px]"
                  isMulti={false}
                  menuPortalTarget={document.body}
                  value={MEDIA_WIDGET_OBJECT_POSITION_OPTIONS.find(
                    (option) => option.value === displayOptions.objectPosition,
                  )}
                  options={MEDIA_WIDGET_OBJECT_POSITION_OPTIONS}
                  onChange={(o) => {
                    setDisplayOptions((prev) => ({
                      ...prev,
                      objectPosition: o?.value ?? 'center',
                    }));
                  }}
                />
              </MediaWidgetPreviewUI.FormField>

              <VerticalSeparator />

              <MediaWidgetPreviewUI.FormField
                label="Media Height (px)"
                slot={
                  <Icon
                    iconName="info"
                    tooltipProps={{
                      mainText: (
                        <div className="flex flex-col gap-tw-2">
                          <p>Minimum height is 200px</p>
                          <p>Maximum height is 1000px</p>
                        </div>
                      ),
                    }}
                  />
                }
              >
                <InputNumber
                  size="s"
                  className="w-[64px]"
                  allowNegative={false}
                  max={1000}
                  disabled={displayOptions.isFullHeight}
                  min={200}
                  value={displayOptions.height}
                  onValueChange={(e) => {
                    setDisplayOptions((prev) => ({
                      ...prev,
                      height: Math.round(e.floatValue ?? 0),
                    }));
                  }}
                />
              </MediaWidgetPreviewUI.FormField>
              <VerticalSeparator />

              <MediaWidgetPreviewUI.FormField
                label="Responsive height"
                slot={
                  <Icon
                    iconName="info"
                    tooltipProps={{
                      mainText: (
                        <div className="flex flex-col gap-tw-2">
                          <p>
                            Media height will adjust to match the height of
                            adjacent elements.
                          </p>
                          <p>
                            It’s best to use Responsive Height with a single
                            image.
                          </p>
                        </div>
                      ),
                    }}
                  />
                }
              >
                <Tumbler
                  checked={displayOptions.isFullHeight}
                  onChange={() => {
                    setDisplayOptions((prev) => ({
                      ...prev,
                      isFullHeight: !prev.isFullHeight,
                    }));
                  }}
                />
              </MediaWidgetPreviewUI.FormField>
              <VerticalSeparator />

              <MediaWidgetPreviewUI.FormField
                label="Media Width (%)"
                slot={
                  <Icon
                    iconName="info"
                    tooltipProps={{
                      mainText: (
                        <div className="flex flex-col gap-tw-2">
                          <p>Minimum width is 20%</p>
                          <p>Maximum width is 100%</p>
                        </div>
                      ),
                    }}
                  />
                }
              />
              <InputNumber
                size="s"
                className="w-[64px]"
                allowNegative={false}
                max={100}
                min={0}
                value={displayOptions.widthPercentage}
                onValueChange={(e) => {
                  setDisplayOptions((prev) => ({
                    ...prev,
                    widthPercentage: Math.round(e.floatValue ?? 0),
                  }));
                }}
              />
            </MediaWidgetPreviewUI.Form>

            <PdfUI.WidgetGroup
              id="1"
              hidePlaceholders={false}
              pageOrientation={'landscape'}
              className="!w-full max-w-[initial] rounded-[8px]"
            >
              <MediaWidget
                showContainerBorder={showContainerBorder}
                isLoading={false}
                data={{
                  items: selectedFiles,
                  widgetType: 'media',
                  displayOptions,
                }}
                className={cn(
                  resolveWidgetSectionPositionClassName(
                    props.widgetSection.position,
                  ),
                )}
                pageOrientation="landscape"
                widgetSection={{
                  id: '1',
                  position: props.widgetSection.position,
                  widgetType: 'media',
                  title: 'Media',
                }}
              />
            </PdfUI.WidgetGroup>
          </MediaWidgetPreviewUI>
        )}

        {selectedFiles.length > 0 && (
          <>
            <p className="inline-semibold z-[1051] text-neutral-550">
              {selectedFiles.length} Selected Media{' '}
              {pluralize('file', selectedFiles.length)}
            </p>

            <SortableList
              axis="xy"
              lockToContainerEdges
              items={selectedFiles}
              onSortStart={() => {
                document.body.style.cursor = 'grabbing';
              }}
              onSortEnd={onSortEnd}
              helperClass="field_drag__active z-[1051]"
              useDragHandle
            >
              {selectedFiles.map((item, index) => (
                <SortableItem index={index} key={index} value={item} />
              ))}
            </SortableList>
          </>
        )}
      </TabsContextProvider>
    </Modal>
  );
};

export const MediaWidgetConfigCard = (props: WidgetConfigCardProps) => {
  const { openModal } = useModal();
  const [updateWidget] =
    usePutApiCoreAssetsByAssetIdReportBuilderReportsAndReportIdWidgetSectionsIdSnapshotMutation();

  const items =
    props.data?.widget != null && 'items' in props.data.widget
      ? props.data?.widget?.items ?? []
      : [];

  return (
    <BaseWidgetConfigCard
      {...props}
      title={
        <div className="flex items-center gap-tw-2">
          <p>{props.title}</p>
          {(items.length ?? 0) > 0 && (
            <div className="secondary-semibold flex h-[24px] w-[24px] items-center justify-center rounded-[8px] bg-neutral-100 text-neutral-900">
              {items.length}
            </div>
          )}
        </div>
      }
      hasData={widgetHasData(props.data)}
      classes={{
        label: '',
      }}
      labelSlot={
        <BaseWidgetConfigCard.Actions>
          <IconButton
            disabled={props.disabled}
            onClick={async () => {
              const res = await openModal(MediaModal, {
                assetId: props.assetId,
                initialSelectedFiles: items,
                widgetSection: props.widgetSection,
                initialDisplayOptions: props.data?.widget?.displayOptions,
              });

              if (res == null) return;

              updateWidget({
                id: props.widgetSection.id,
                assetId: Number(props.assetId),
                body: {
                  snapshot_data: {
                    items: res.items.map((i) => ({
                      id: i.id,
                      type: 'shared_file',
                      caption: i.caption,
                    })),
                    display_options: snakeCaseKeys(res.displayOptions),
                  },
                },
                reportId: props.reportId,
              });
            }}
            iconName="edit"
          />
        </BaseWidgetConfigCard.Actions>
      }
    />
  );
};
