import { WidgetConfiguration } from 'bundles/Shared/widgets/dashboard/widgets/model';
import { ICellRendererParams } from 'ag-grid-community';
import { GlobalLeaseTableWidget } from 'bundles/Shared/widgets/dashboard/widgets/globalLeaseTable/widget';
import Icon from 'stories/Icon/Icon';
import { BasicCellRenderer } from 'bundles/Shared/components/AgGrid/Table/cellComponents/BasicCellRenderer';
import { CELL_CLASS_NAMES } from 'bundles/Shared/components/AgGrid/Table/classNames';
import { cn } from '@/shared/lib/css/cn';
import { BasicAutoGroupCellRendererProps } from 'bundles/Shared/components/AgGrid/Table/cellComponents/AutoGroupCellRenderer';
import React from 'react';
import { startCase } from 'lodash-es';
import { TableVizConfig } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import { GlobalLeaseTableWidgetDto } from '@/bundles/Shared/entities/dashboard';
import { LeaseDetailsModal } from '@/bundles/Shared/widgets/dashboard/widgets/masterUnitTable/ui/LeaseDetailsModal';
import { WidgetContextCalendarRangeCard } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/state/WidgetContextCalendarRangeCard';
import { OverrideColDefFunction } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/ColumnDefsBuilder';

export const GLOBAL_LEASE_TABLE_WIDGET_CONFIG = {
  layout: {
    w: 2,
    h: 13,
    maxH: 12,
    minH: 6,
    minW: 1,
    maxW: 2,
  },
  title: 'Global Lease Table',
  icon: 'table',
  Component: GlobalLeaseTableWidget,
  CardConfigComponent: WidgetContextCalendarRangeCard,
} as const satisfies WidgetConfiguration;

export const OBJECT_LEVEL_GLOBAL_LEASE_TABLE_WIDGET_CONFIG = {
  ...GLOBAL_LEASE_TABLE_WIDGET_CONFIG,
  title: 'Lease Table',
} as const satisfies WidgetConfiguration;

export const UNIT_COL_KEY = 'unit_label';
export const ASSET_NAME_COL_KEY = 'asset_name';
export const LEASE_TYPE_COL_KEY = 'lease_type';
export const UNIT_TYPE_COL_KEY = 'unit_type';
export const FILTERED_TRADEOUT_COL_KEY = 'filtered_tradeout';

export const GLOBAL_LEASE_VIZ_CONFIG = {
  column_groups: [
    {
      group_id: 'general',
      header_name: 'General',
      order: 0,
      background: 'rgba(11, 23, 48, 1)',
      border_color: 'rgba(38, 51, 79, 1)',
      icon: null,
    },
    {
      group_id: 'unitDetails',
      header_name: 'Unit Details',
      order: 1,
      background: 'rgba(11, 98, 79, 1)',
      border_color: 'rgba(19, 137, 117, 1)',
      icon: null,
    },
    {
      group_id: 'leaseDetails',
      header_name: 'Lease Details',
      order: 2,
      background: 'rgba(3, 40, 98, 1)',
      border_color: 'rgba(56, 83, 169, 1)',
      icon: null,
    },
    {
      group_id: 'previousLeaseDetails',
      header_name: 'Previous Lease Details',
      order: 3,
      background: 'rgba(3, 40, 98, 1)',
      border_color: 'rgba(56, 83, 169, 1)',
      icon: null,
    },
  ],
  columns: [
    {
      col_id: '0',
      key: 'tradeout',
      order: 0,
      group_id: 'general',
      value_display_options: {
        type: 'currency',
        precision: 2,
        kilo_formatting: false,
        hide_comma_separator: false,
      },
    },
    {
      col_id: '1',
      key: 'prior_rent',
      order: 1,
      group_id: 'general',
      value_display_options: {
        type: 'currency',
        precision: 2,
        kilo_formatting: false,
        hide_comma_separator: false,
      },
    },
    {
      col_id: '2',
      key: 'current_rent',
      order: 2,
      group_id: 'general',
      value_display_options: {
        type: 'currency',
        precision: 2,
        kilo_formatting: false,
        hide_comma_separator: false,
      },
    },
    {
      col_id: '3',
      key: LEASE_TYPE_COL_KEY,
      order: 3,
      group_id: 'general',
    },
    {
      col_id: '4',
      key: 'lease_status',
      order: 4,
      group_id: 'general',
    },
    {
      col_id: '5',
      key: UNIT_TYPE_COL_KEY,
      order: 5,
      group_id: 'unitDetails',
    },
    {
      col_id: '6',
      key: ASSET_NAME_COL_KEY,
      order: 6,
      group_id: 'unitDetails',
    },
    {
      col_id: '7',
      key: UNIT_COL_KEY,
      order: 7,
      group_id: 'unitDetails',
    },
    {
      col_id: '8',
      key: 'unit_sqft',
      order: 8,
      group_id: 'unitDetails',
      value_display_options: {
        type: 'sqft',
        precision: 2,
        kilo_formatting: false,
        hide_comma_separator: false,
      },
    },
    {
      col_id: '9',
      key: 'lease_signed_date',
      order: 9,
      group_id: 'leaseDetails',
      value_display_options: {
        type: 'date',
        date_format: 'MM/DD/YYYY',
        precision: 0,
        kilo_formatting: false,
      },
    },
    {
      col_id: '10',
      key: 'lease_start',
      order: 10,
      group_id: 'leaseDetails',
      value_display_options: {
        type: 'date',
        date_format: 'MM/DD/YYYY',
        precision: 0,
        kilo_formatting: false,
      },
    },
    {
      col_id: '11',
      key: 'lease_end',
      order: 11,
      group_id: 'leaseDetails',
      value_display_options: {
        type: 'date',
        date_format: 'MM/DD/YYYY',
        precision: 0,
        kilo_formatting: false,
      },
    },
    {
      col_id: '12',
      key: 'resident_name',
      order: 12,
      group_id: 'leaseDetails',
    },
    {
      col_id: '13',
      key: 'term_months',
      order: 13,
      group_id: 'leaseDetails',
    },
    {
      col_id: '14',
      key: 'previous_lease_resident_name',
      order: 14,
      group_id: 'previousLeaseDetails',
    },
    {
      col_id: '15',
      key: 'previous_lease_type',
      order: 15,
      group_id: 'previousLeaseDetails',
    },
    {
      col_id: '16',
      key: 'previous_lease_start_date',
      order: 16,
      group_id: 'previousLeaseDetails',
      value_display_options: {
        type: 'date',
        date_format: 'MM/DD/YYYY',
        precision: 0,
        kilo_formatting: false,
      },
    },
    {
      col_id: '17',
      key: 'previous_lease_end_date',
      order: 17,
      group_id: 'previousLeaseDetails',
      value_display_options: {
        type: 'date',
        date_format: 'MM/DD/YYYY',
        precision: 0,
        kilo_formatting: false,
      },
    },
    {
      col_id: '18',
      key: 'previous_lease_term_months',
      order: 18,
      group_id: 'previousLeaseDetails',
    },
    {
      col_id: '19',
      key: FILTERED_TRADEOUT_COL_KEY,
      order: 19,
      group_id: null,
    },
  ],
  rows: [],
  grid_options: {
    group_default_expanded: 1,
  },
} as const satisfies TableVizConfig;

export const DEFAULT_GLOBAL_LEASE_TABLE_WIDGET_CONFIG = {
  viz_type: 'table',
  viz_config: GLOBAL_LEASE_VIZ_CONFIG,
} as const;

export const DEFAULT_OBJECT_LEVEL_GLOBAL_LEASE_TABLE_WIDGET_CONFIG = {
  ...DEFAULT_GLOBAL_LEASE_TABLE_WIDGET_CONFIG,
  viz_config: {
    ...GLOBAL_LEASE_VIZ_CONFIG,
    columns: GLOBAL_LEASE_VIZ_CONFIG.columns.filter(
      (c) => c.key !== ASSET_NAME_COL_KEY,
    ),
    rows: [],
  },
} as const;

export const FilteredTradeoutCellRenderer = (params: ICellRendererParams) => (
  <BasicCellRenderer {...params}>
    <Icon
      className={params.value ? 'text-success-055' : 'text-danger-055'}
      iconName={params.value ? 'check' : 'closeSmall'}
    />
  </BasicCellRenderer>
);

export const COL_DEF_OVERRIDES: Record<string, OverrideColDefFunction> = {
  [UNIT_COL_KEY]: ({ mode }) => ({
    cellRendererParams: {
      linkable: mode !== 'pdf',
    } satisfies BasicAutoGroupCellRendererProps,
    onCellClicked: (params) => {
      params.context.openModal(LeaseDetailsModal, {
        unitId: params.data.unit_id,
        unitLabel: params.value,
      });
    },
  }),
  [LEASE_TYPE_COL_KEY]: () => ({
    valueFormatter: (params) => startCase(params.value),
  }),
  [UNIT_TYPE_COL_KEY]: () => ({
    valueFormatter: (params) => startCase(params.value),
  }),
  [ASSET_NAME_COL_KEY]: () => ({
    valueFormatter: (params) => startCase(params.value),
  }),
  [FILTERED_TRADEOUT_COL_KEY]: () => ({
    cellRendererParams: {
      classes: {
        inner: cn(CELL_CLASS_NAMES.CommonCell.inner.basic, '!justify-center'),
      },
    },
    tooltipValueGetter: (params) => {
      const row: GlobalLeaseTableWidgetDto['rows'][number] = params.data;

      return row.filtered_reasons?.join(',');
    },
    cellRendererSelector: (params: ICellRendererParams) => {
      return {
        component: FilteredTradeoutCellRenderer,
        params,
      };
    },
  }),
};

export const FILTER_COLUMN_LABELS = {
  [FILTERED_TRADEOUT_COL_KEY]: {
    Yes: 'Included',
    No: 'Excluded',
  },
};
