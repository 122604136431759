import { useGetApiSettingsReportThirdPartyExtractionsConnectionsByIdQuery } from '@/bundles/Settings/components/REport/Connections/api/settingsReportConnectionsEnhancedApi';
import { IConnection } from '@/bundles/Shared/entities/connection/model';
import { useModal } from '@/shared/lib/hooks/useModal';
import { useParams } from '@reach/router';
import { PMCCardLine } from '@/entities/propertyManagementCompany/ui/PMCCardLine';
import { EmailLink } from 'bundles/Shared/shared/EmailLink';
import { PhoneLink } from 'bundles/Shared/shared/PhoneLink';
import KeyContactsModal from './KeyContactsModal';
import { Icon, IconButton, Tooltip } from 'stories/index';

export const KeyContactsCard = () => {
  const { openModal } = useModal();
  const params = useParams();

  const { data: connectionData } =
    useGetApiSettingsReportThirdPartyExtractionsConnectionsByIdQuery({
      id: params.connectionId,
    });

  const connection = connectionData?.item as IConnection | undefined;
  const contactPersons = connection?.company?.contactPersons ?? [];

  const handleEdit = () => {
    openModal(KeyContactsModal, {
      connectionId: params.connectionId,
    });
  };

  return (
    <div className="flex flex-col gap-tw-4 rounded-2xl bg-tw-white p-tw-4">
      <div className="flex items-center justify-between">
        <div className="font-semibold text-neutral-800">PMC Key Contacts</div>
        <IconButton
          iconName={contactPersons.length ? 'editAlt' : 'addSmall'}
          onClick={handleEdit}
        />
      </div>
      {!contactPersons.length && (
        <div className="inline-regular">No PMC Key Contacts</div>
      )}
      {contactPersons.length > 0 && (
        <div className="flex flex-col gap-tw-3">
          {contactPersons.map((person, index) => (
            <div key={person.id || index} className="flex flex-col gap-tw-2">
              {person.name && (
                <PMCCardLine iconName="userAlt">
                  <div>
                    <div className="inline-regular text-neutral-900">
                      <div className="items-top flex gap-tw-1">
                        {person.name}
                        {person.note && (
                          <Tooltip mainText={person.note}>
                            <Icon iconName="info" className="font-xs mt-3" />
                          </Tooltip>
                        )}
                      </div>
                    </div>
                    {person.role && (
                      <div className="inline-regular">{person.role}</div>
                    )}
                  </div>
                </PMCCardLine>
              )}
              {person.email && (
                <PMCCardLine iconName="email">
                  <EmailLink
                    email={person.email}
                    className="text-sm font-medium"
                  >
                    {person.email}
                  </EmailLink>
                </PMCCardLine>
              )}
              {person.phone && (
                <PMCCardLine iconName="phone">
                  <PhoneLink
                    phone={person.phone}
                    className="text-sm font-medium"
                  >
                    {person.phone}
                  </PhoneLink>
                </PMCCardLine>
              )}
              {index < contactPersons.length - 1 && (
                <div className="border-b border-solid border-neutral-100" />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
