import { BudgetDateFromAndFinancialFirstPeriodFireIcon } from '@/bundles/REconcile/underwritting/dumbJSX';
import { isBudgetDateAndFirstFinancialPeriodMismatched } from '@/bundles/REconcile/underwritting/util';
import { cn } from '@/shared/lib/css/cn';
import { formatDate } from '@/shared/lib/formatting/dates';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { useDebouncedQuery } from '@/shared/lib/hooks/useDebouncedQuery';
import { ROUTES_ROOT, generateUrl } from '@/shared/lib/hooks/useNavigation';
import { LinkWrapper } from '@/shared/ui/LinkWrapper';
import { InfoList } from '@/stories/InfoList/component';
import { RouteComponentProps } from '@reach/router';
import { useGetApiReconcileUnderwritingBudgetsLegalEntitiesQuery } from 'bundles/REconcile/underwritting/api/reconcileUnderwritingBudgetEnhancedApi';
import { LegalEntityUnderwritingBudget } from 'bundles/REconcile/underwritting/api/reconcileUnderwritingBudgetGeneratedApi';
import { BudgetContextProvider } from 'bundles/REconcile/underwritting/ctx/useBudgetContext';
import {
  showUnderwritingBudgetMismatchFireIcon,
  useNavigateToFirstItem,
} from 'bundles/REconcile/underwritting/lib';
import NoDataOverlay from 'bundles/Shared/components/NoDataOverlay';
import TablePagination from 'bundles/Shared/components/Table/pagination/TablePagination';
import ListNavLayout from 'bundles/Shared/components/layouts/screenWithListNavigationLayout/ScreenWithListNavigationLayout';
import { FilterLegalEntityState } from 'bundles/Shared/features/underwritingBudget/filter/legalEntity/FilterLegalEntityState';
import { range } from 'lodash-es';
import { FC, PropsWithChildren, useMemo, useState } from 'react';
import SkeletonBlock from 'stories/ProjectCard/SkeletonBlock';
import { Icon, ProjectCard, SearchInput } from 'stories/index';
import { IconsId } from 'types/sre-icons';

export const LEGAL_ENTITY_STATUS_MAP = {
  draft: {
    icon: 'draft',
    className: 'text-yellow-dark-2',
  },
  published: {
    icon: 'public',
    className: 'text-green-dark-2',
  },
  no_budget: {
    icon: null,
    className: '',
  },
} as const satisfies Record<
  LegalEntityUnderwritingBudget['state'],
  {
    icon: IconsId | null;
    className: string;
  }
>;

const INITIAL_PER_PAGE = 999;

const UnderwritingBudgetPage: FC<RouteComponentProps & PropsWithChildren> = ({
  children,
}) => {
  const { query, debouncedQuery, handleChange, resetQuery } =
    useDebouncedQuery(''); // TODO replace with PageParamsSearch
  const uwbSlice = useAppSelector((state) => state.underwritingBudgetSlice);
  const { isTableExpanded, leFilter } = uwbSlice;

  const [{ page, perPage }, setPagination] = useState({
    page: 1,
    perPage: INITIAL_PER_PAGE,
  });

  const { data, isFetching } =
    useGetApiReconcileUnderwritingBudgetsLegalEntitiesQuery({
      page,
      perPage,
      state: leFilter,
      query: debouncedQuery,
    });

  const { legalEntityIdFromURL } = useNavigateToFirstItem({
    underwritingBudgetData: data,
  });

  const selectedLegalEntity = useMemo(() => {
    return data?.items.find((le) => le.id === legalEntityIdFromURL) ?? null;
  }, [legalEntityIdFromURL, data]);

  return (
    <ListNavLayout
      className={cn(
        isTableExpanded
          ? 'flex h-[initial]'
          : 'grid-cols-[350px_minmax(auto,1fr)]',
      )}
    >
      <ListNavLayout.Navigation
        className={cn('next-div-w-full relative', isTableExpanded && 'hidden')}
      >
        <ListNavLayout.NavigationHeaderGroup>
          <ListNavLayout.NavigationHeader
            title="Underwriting"
            subtitle="Reconcile"
          />
        </ListNavLayout.NavigationHeaderGroup>

        <SearchInput
          size="s"
          placeholder="Search"
          suggestions={['Legal Entity Name']}
          value={query}
          onChange={handleChange}
          className="ml-auto w-full"
          resetValue={resetQuery}
        />

        <FilterLegalEntityState />

        {!isFetching && (
          <TablePagination
            currentPage={page}
            totalSize={data?.meta.totalSize ?? 0}
            sizePerPage={perPage}
            loading={isFetching}
            setCurrentPage={(_page, maybePerPage) => {
              setPagination({
                page: _page,
                perPage: maybePerPage ?? INITIAL_PER_PAGE,
              });
            }}
          />
        )}
        {isFetching && (
          <div className="flex flex-col gap-tw-4">
            <SkeletonBlock className="h-[24px] w-full" />
            <div className="flex flex-col gap overflow-hidden rounded-2xl">
              {range(0, 6).map((i) => (
                <div key={i} className="grid gap-tw-4 bg-light-5">
                  <SkeletonBlock className="h-[72px]" />
                </div>
              ))}
            </div>
          </div>
        )}
        <ListNavLayout.NavigationCardList className="rounded-2xl">
          {!isFetching &&
            data?.items.map((item) => {
              const status = LEGAL_ENTITY_STATUS_MAP[item.state];
              const url = generateUrl(
                ROUTES_ROOT.reconcile.underwriting.legalEntity.fullPath,
                {
                  pathParams: {
                    legalEntityId: item.id,
                  },
                  queryParams: {
                    budgetId: item.budget?.id ?? undefined,
                  },
                },
              );

              const isMismatched =
                isBudgetDateAndFirstFinancialPeriodMismatched(
                  item.budget?.dateFrom,
                  item.financial?.firstPeriod,
                );
              const showMismatchedFire = showUnderwritingBudgetMismatchFireIcon(
                isMismatched,
                item.state,
              );

              const getInfoListItems = () => {
                const res: React.ComponentProps<typeof InfoList>['items'] = [];
                if (item.financial?.firstPeriod != null) {
                  res.push({
                    label: 'Financial data',
                    value: formatDate(item.financial.firstPeriod, 'MMM-YY'),
                  });
                }
                if (item.budget?.dateFrom != null) {
                  res.push({
                    label: 'Underwriting Budget data',
                    value: formatDate(item.budget.dateFrom, 'MMM-YY'),
                  });
                }
                return res;
              };
              const infoListItems = getInfoListItems();

              return (
                <LinkWrapper to={url} key={item.id}>
                  <ProjectCard
                    selected={item.id === legalEntityIdFromURL}
                    header={
                      <div className="flex w-full items-start justify-between gap-tw-2">
                        <div className="flex grow flex-col gap-tw-3">
                          <div className="flex flex-col gap-tw-1">
                            <p className="inline-semibold text-neutral-800">
                              {item.name}
                            </p>
                            <p className="label-regular text-neutral-500">
                              {item.asset.name}
                            </p>
                          </div>
                        </div>

                        {showMismatchedFire && (
                          <BudgetDateFromAndFinancialFirstPeriodFireIcon />
                        )}
                        {status.icon && (
                          <Icon
                            iconName={status.icon}
                            className={cn(status.className)}
                          />
                        )}
                      </div>
                    }
                  >
                    {infoListItems.length > 0 && (
                      <InfoList
                        items={infoListItems}
                        getItemProps={() => ({
                          classes: {
                            value: showMismatchedFire
                              ? 'text-danger-070'
                              : undefined,
                          },
                        })}
                      />
                    )}
                  </ProjectCard>
                </LinkWrapper>
              );
            })}

          {!isFetching && data?.items.length === 0 && (
            <NoDataOverlay title="Nothing found" />
          )}
        </ListNavLayout.NavigationCardList>
      </ListNavLayout.Navigation>

      <BudgetContextProvider
        ctxParams={{
          selectedLegalEntity,
          items: data?.items ?? [],
        }}
      >
        {children}
      </BudgetContextProvider>
    </ListNavLayout>
  );
};

export default UnderwritingBudgetPage;
