import { XYChartWidgetConfig } from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/model';
import {
  listUpdater,
  useUpdateWidgetConfig,
  useWidgetConfig,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { ColumnSeries } from '@amcharts/amcharts5/xy';
import * as am5 from '@amcharts/amcharts5';
import { Settings } from '@amcharts/amcharts5/.internal/core/util/Entity';
import { XyChartSingleKpiWidgetDto } from 'bundles/Shared/shared/api/dashboardsGeneratedApi';
import { orderBy } from 'lodash-es';
import { XYChartWidgetConfigRowForm } from 'bundles/Shared/widgets/dashboard/widgets/xyChart/config/row.form';
import {
  DEFAULT_LINE_SERIES_TEMPLATE,
  DEFAULT_SERIES_REF_KEY,
  DEFAULT_TEMPLATE_SERIES_REF_KEY,
} from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/config/amchartDefaults';
import { produce } from 'immer';
import {
  formatVariableString,
  getAmchartNumberFormatByDisplayOptions,
} from '@/shared/lib/formatting/charts';
import { OBJECT_DASHBOARD_SECTION_TYPE } from 'bundles/Shared/entities/dashboard';
import { IThinTabItem } from 'stories/Tabs/ThinTabGroup/ThinTabGroup';
import { sleep } from '@amcharts/amcharts5/.internal/core/util/Time';

type Series = {
  settings: {
    name: string;
    valueYField: string;
    tooltip: {
      settings: {
        labelText: string;
      };
    };
    fill: {
      type: string;
      value: string;
    };
    stroke: {
      type: string;
      value: string;
    };
  };
};
export type AmChartConfig = {
  refs: Record<string, Series[]>[];
};
export const isChartCategorical = (amchartConfig: AmChartConfig) => {
  return amchartConfig.refs.some(
    (ref) => 'xAxis' in ref && ref.xAxis.type === 'CategoryAxis',
  );
};
export const hasSeriesRef = (amchartConfig: AmChartConfig) => {
  return amchartConfig.refs.some((ref) => DEFAULT_SERIES_REF_KEY in ref);
};
export const findSeriesByKpiKey = (seriesArr: Series[], kpiKey: number) => {
  return seriesArr.find(
    (series) => series.settings.valueYField === kpiKey.toString(),
  );
};
export const findSeriesRefInConfig = (
  amchartConfig: AmChartConfig,
  seriesKey = DEFAULT_SERIES_REF_KEY,
) => {
  return amchartConfig.refs.find((ref) => seriesKey in ref);
};
const getAmchartJsonColorFromString = (color: string) => {
  return {
    type: 'Color',
    value: color,
  };
};
export const removeKpiFromConfig = (
  config: XYChartWidgetConfig,
  kpiKey: number,
) => {
  const { removeItem: removeRow } = listUpdater(config.kpis, {
    key: 'key',
  });

  // eslint-disable-next-line no-param-reassign
  config.kpis = removeRow(kpiKey);
};
export const useRemoveKpiFromConfig = () => {
  const { widget } =
    useWidgetConfig<typeof OBJECT_DASHBOARD_SECTION_TYPE.XY_CHART>();
  const [updateConfig] = useUpdateWidgetConfig(
    OBJECT_DASHBOARD_SECTION_TYPE.XY_CHART,
  );

  return (kpiKey: number) => {
    updateConfig({
      config: produce(widget.widgetConfig, (draft) => {
        removeKpiFromConfig(draft, kpiKey);
      }),
    });
  };
};
export const upsertSeriesInConfig = (
  amchartConfig: AmChartConfig,
  seriesConfig: XYChartWidgetConfigRowForm,
) => {
  const seriesRef = findSeriesRefInConfig(amchartConfig);
  if (!seriesRef) {
    return;
  }

  const series = findSeriesByKpiKey(
    seriesRef[DEFAULT_SERIES_REF_KEY],
    seriesConfig.key,
  );

  const updatedSeries = produce(
    series ?? DEFAULT_LINE_SERIES_TEMPLATE,
    (draft) => {
      draft.settings.name = seriesConfig.label;
      draft.settings.valueYField = seriesConfig.key.toString();
      draft.settings.tooltip.settings.labelText = formatVariableString(
        'valueY',
        getAmchartNumberFormatByDisplayOptions(
          seriesConfig.value_display_options,
        ),
      );
      if (seriesConfig.color) {
        draft.settings.fill = getAmchartJsonColorFromString(seriesConfig.color);
        draft.settings.stroke = getAmchartJsonColorFromString(
          seriesConfig.color,
        );
      }
    },
  );

  if (series) {
    const seriesIndex = seriesRef[DEFAULT_SERIES_REF_KEY].indexOf(series);
    seriesRef[DEFAULT_SERIES_REF_KEY][seriesIndex] = updatedSeries;
  } else {
    seriesRef[DEFAULT_SERIES_REF_KEY].push(updatedSeries);
  }
};
export const TAB_ITEMS = [
  {
    label: 'Assets',
    id: 'assets',
  },
  {
    label: 'Segments',
    id: 'segments',
  },
  {
    label: 'Mixed',
    id: 'mixed',
  },
] as const satisfies IThinTabItem[];
export type GroupingType = (typeof TAB_ITEMS)[number]['id'];
export type ObjectType = 'asset' | 'segment';
export type GroupingObject = {
  id: number;
  type: ObjectType;
};
export const GROUPING_TYPE_TO_OBJECT_TYPE: Record<
  Exclude<GroupingType, 'mixed'>,
  ObjectType
> = {
  assets: 'asset',
  segments: 'segment',
};
export const idToObjectMapper = (type: ObjectType) => (id: number) => ({
  id,
  type,
});
export const ID_DELIMITER = '-';
export const buildObjectId = ({
  type,
  id,
}: {
  type: 'asset' | 'segment';
  id: number | string;
}) => {
  return `${type}${ID_DELIMITER}${id}`;
};
export const parseObjectId = (stringId: string) => {
  const [type, id] = stringId.split(ID_DELIMITER);
  return { type, id: Number(id) };
};
export const isAssetId = (stringId: string) => {
  return stringId.startsWith(`asset${ID_DELIMITER}`);
};
export const isSegmentId = (stringId: string) => {
  return stringId.startsWith(`segment${ID_DELIMITER}`);
};
export const addAdaptersToColumnSeries = (columnSeries: ColumnSeries) => {
  const adapters = columnSeries.columns.template._settings.adapters as {
    property: string;
    condition: {
      key: string;
      value: string;
    };
    value: string;
  }[];
  if (adapters == null || adapters.length === 0) {
    return;
  }
  adapters.forEach((adapter) => {
    const adapterHandler = (value, target) => {
      const conditionValue = target.dataItem?.get(adapter.condition.key);
      if (conditionValue && adapter.condition.value.includes(conditionValue)) {
        return am5.color(adapter.value);
      }
      return value;
    };
    columnSeries.columns.each((c) =>
      c.adapters.add(adapter.property as 'fill', adapterHandler),
    );
  });
  const labelYPositionAdapter = function (centerY: number, target: Settings) {
    const { dataItem } = target;
    if (dataItem) {
      const value = dataItem.get('valueY', 0);
      if (value < 0) {
        return 0;
      }
    }

    return centerY;
  };
  columnSeries.bulletsContainer.children.each((l) => {
    l.adapters.add('centerY', labelYPositionAdapter);
  });
};
export const orderItems = (items: XyChartSingleKpiWidgetDto['data']) => {
  if (items.length === 0) {
    return items;
  }
  const orderedByKpiValue = orderBy(
    items,
    ['kpiKey', 'kpiValue'],
    ['asc', 'desc'],
  );
  const firstKpiKey = orderedByKpiValue[0].kpiKey;
  const objectNamesOrder = orderedByKpiValue
    .filter((o) => o.kpiKey === firstKpiKey)
    .map((o) => o.objectName);
  const getOrderIndex = (objectName: string) => {
    const index = objectNamesOrder.indexOf(objectName);
    return index === -1 ? objectNamesOrder.length : index; // If not found, place it at the end
  };
  const orderedItems = orderBy(
    items,
    ['kpiKey', (item) => getOrderIndex(item.objectName)],
    ['asc', 'asc'],
  );

  return orderedItems;
};
export const findTemplateSeriesRef = (amchartConfig: AmChartConfig) => {
  return amchartConfig.refs.find(
    (ref) => DEFAULT_TEMPLATE_SERIES_REF_KEY in ref,
  );
};

export const findXAxisRef = (amchartConfig: AmChartConfig) => {
  return amchartConfig.refs.find((ref) => 'xAxis' in ref);
};

export const sleepUntilSeriesAreReady = async () => {
  await sleep(100);
};

export const DEFAULT_GRID_INVERVALS = [
  { timeUnit: 'day', count: 1 },
  { timeUnit: 'day', count: 2 },
  { timeUnit: 'day', count: 3 },
  { timeUnit: 'day', count: 4 },
  { timeUnit: 'day', count: 5 },
  { timeUnit: 'day', count: 20 },
  { timeUnit: 'week', count: 1 },
  { timeUnit: 'week', count: 12 },
  { timeUnit: 'month', count: 1 },
  { timeUnit: 'month', count: 2 },
  { timeUnit: 'month', count: 3 },
  { timeUnit: 'month', count: 6 },
  { timeUnit: 'year', count: 1 },
] as const;
