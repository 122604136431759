import {
  allSubPathMatches,
  ROUTES_ROOT,
} from '@/shared/lib/hooks/useNavigation';
import { useMatch } from '@reach/router';
import { importFile } from '@/shared/lib/importFile';
import {
  UWBCellRendererParams,
  isFinancialRow,
  isMetricRow,
  isUWBCellEditable,
  valueGetter,
} from '@/bundles/REconcile/underwritting/lib';
import { HeaderGroupComponentWithShortYear } from '@/bundles/Shared/components/AgGrid/Table/cellComponents/HeaderGroupComponent';
import { ValueTypeCellEditor } from '@/entities/valueType/ui/CellEditor/ValueTypeCellEditor';
import { formatDate } from '@/shared/lib/formatting/dates';
import { ColGroupDef, ValueSetterParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import { useMemo, ComponentProps } from 'react';
import {
  BudgetRow,
  CategoryRow,
} from '@/bundles/REconcile/underwritting/model';
import { convertDollarsToCents } from '@/shared/lib/converters';
import { parseNumberFromCurrencyInput } from '@/shared/lib/formatting/number';
import {
  ForecastingBudget,
  PutApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdValuesBulkApiArg,
} from '@/entities/reconcile/forecasting/api/reconcileForecastingEntitiesGeneratedApi';
import { reconcileForecastingEntitiesEnhancedApi } from '@/entities/reconcile/forecasting/api/reconcileForecastingEntitiesEnchancedApi';
import { dispatch } from '@/app/stores';

export const useGetForecastNameFromURL = () => {
  const match = useMatch(
    allSubPathMatches(ROUTES_ROOT.reconcile.forecasting.forecast.fullPath),
  );

  return match?.forecastName;
};

export const useGetLegalEntityCodeFromURL = () => {
  const match = useMatch(
    allSubPathMatches(
      ROUTES_ROOT.reconcile.forecasting.budgets.budget.fullPath,
    ),
  );

  return match?.legalEntityCode;
};

export const useGetYearFromURL = () => {
  const match = useMatch(
    allSubPathMatches(ROUTES_ROOT.reconcile.forecasting.budgets.fullPath),
  );

  return match?.year;
};

export function importForecastingBudget(params: {
  file: File;
  forecastId: string;
  budgetId: string;
}) {
  return importFile({
    file: params.file,
    url: `/api/reconcile/forecasting/forecasts/${params.forecastId}/budgets/${params.budgetId}/import`,
  });
}

export async function putApiReconcileForecastingBudgetsByIdValuesBulk(
  args: PutApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdValuesBulkApiArg,
) {
  await dispatch(
    reconcileForecastingEntitiesEnhancedApi.endpoints.putApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdValuesBulk.initiate(
      args,
    ),
  );
}

export const getValueSetter: (
  budgetId: string,
  forecastId: string,
) => (params: ValueSetterParams) => boolean = (budgetId, forecastId) => {
  return (params) => {
    const data = params.data as BudgetRow | null;
    const key = params.colDef.colId!;

    const getValue = () => {
      // @ts-expect-error
      if (data.valueType === 'date') return params.newValue;

      return params.newValue
        ? parseNumberFromCurrencyInput(params.newValue)
        : null;
    };
    const newValue = getValue();

    if (data == null) return false;

    let args: PutApiReconcileForecastingForecastsByForecastIdBudgetsAndBudgetIdValuesBulkApiArg;

    if (isFinancialRow(data)) {
      if (data.id == null) return false;

      const converted = convertDollarsToCents(Number(newValue));
      const value = newValue == null ? null : converted;

      args = {
        forecastId,
        budgetId,
        body: [
          {
            cents: value,
            budget_financial_category_id: data.financialCategoryId,
            period_key: key,
          },
        ],
      };

      data.values = {
        ...data.values,
        [key]: value,
      };

      const updatedRelatives = [...data.ancestors, ...data.descendants].map(
        (row) => {
          // @ts-expect-error
          const _rowData = params.api.getRowNode(row.categoryId)
            .data as CategoryRow;

          const newDisabledValueKeys = _rowData.disabledValueKeys ?? new Set();

          if (value != null) {
            newDisabledValueKeys.add(key);
          } else {
            newDisabledValueKeys.delete(key);
          }

          return {
            ..._rowData,
            values: {
              ..._rowData.values,
              [key]: value == null ? undefined : 'empty',
            },
            disabledValueKeys: newDisabledValueKeys,
          } as CategoryRow;
        },
      );

      params.api.applyTransaction({
        update: updatedRelatives,
      });
      putApiReconcileForecastingBudgetsByIdValuesBulk(args);

      return true;
    }

    if (isMetricRow(data)) {
      const value = newValue == null ? null : Number(newValue);
      args = {
        forecastId,
        budgetId,
        body: [
          {
            value,
            budget_metric_id: data.id,
            period_key: key,
          },
        ],
      };

      putApiReconcileForecastingBudgetsByIdValuesBulk(args);

      data.values = {
        ...data.values,
        [key]: value,
      };
      return true;
    }

    return false;
  };
};

export const useColumnDefs = ({
  budget,
  deps = [],
}: {
  budget: ForecastingBudget | undefined;
  deps?: React.DependencyList;
}) => {
  const budgetId = budget?.id;
  const budgetPeriods = budget?.periods;
  const forecastId = budget?.forecast.id;

  const res = useMemo(() => {
    if (!budgetPeriods || !budgetId || !forecastId) return [];

    return budgetPeriods.map((period) => ({
      colId: period.key,
      headerName: formatDate(period.date as DateString, 'MMMM'),
      headerGroupComponent: HeaderGroupComponentWithShortYear,
      headerGroupComponentParams: {
        year: dayjs(period.date).year(),
      } satisfies Partial<
        ComponentProps<typeof HeaderGroupComponentWithShortYear>
      >,
      children: [
        {
          editable: isUWBCellEditable,
          cellRendererParams: UWBCellRendererParams,
          colId: period.key,
          cellEditor: ValueTypeCellEditor,
          headerName: 'Budget',
          label: 'Budget',
          period: period.date,
          valueSetter: getValueSetter(budgetId, forecastId),
          valueGetter,
        },
      ],
    })) satisfies ColGroupDef[];
  }, [budgetId, budgetPeriods, ...deps]);

  return res;
};
