import {
  AutoGroupCellRenderer,
  AutoGroupCellRendererProps,
} from '@/bundles/Shared/components/AgGrid/Table/cellComponents/AutoGroupCellRenderer';
import { AutoGroupHeaderComponent } from '@/bundles/Shared/components/AgGrid/Table/cellComponents/AutoGroupHeaderComponent';
import { CellRendererStylesProps } from '@/bundles/Shared/components/AgGrid/Table/cellComponents/BasicCellRenderer';
import {
  CurrencyCellRenderer,
  CurrencyCellRendererProps,
} from '@/bundles/Shared/components/AgGrid/Table/cellComponents/CurrencyCellRenderer';
import { HeaderComponent } from '@/bundles/Shared/components/AgGrid/Table/cellComponents/HeaderComponent';
import {
  ComparisonObjectHeaderGroupComponent,
  HeaderGroupComponent,
  HeaderGroupComponentProps,
} from '@/bundles/Shared/components/AgGrid/Table/cellComponents/HeaderGroupComponent';
import { SourceTypeImageWrapper } from '@/bundles/Shared/components/AgGrid/Table/cellComponents/SourceTypeImageWrapper';
import {
  DEFAULT_TABLE_ROW_PATH_DELIMITER,
  NEXT_GEN_TABLE_CONFIG,
} from '@/bundles/Shared/components/AgGrid/Table/consts';
import { buildTableCellStyles } from '@/bundles/Shared/components/AgGrid/Table/utils/useTableCellStyleApplier';
import {
  AUTO_COLUM_DEF_MAX_WIDTH,
  AUTO_COLUM_DEF_MIN_WIDTH,
  formatDateRangeForWidgetColumnSubHeader,
  resolveDefaultColumnMinWidth,
} from '@/bundles/Shared/widgets/dashboard/widgets/common';
import { getFinancialTableWidgetDefaultUngroupedGroup } from '@/bundles/Shared/widgets/dashboard/widgets/common/financialTable/ui/financialTableWidgetColumnGroups/lib';
import { HeaderComponentWithSubHeader } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/HeaderComponentWithSubHeader';
import {
  isNumericTableVizConfigRow,
  isTextTableVizConfigRow,
  TableVizConfig,
  TableVizConfigColumn,
  TableVizConfigColumnGroup,
  TableVizConfigRow,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import {
  FinancialTableSingeDateWidgetContext,
  FinancialTableSingeDateWidgetNS,
} from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate';
import { insertBetweenPlaceholderColumnGroupDefFinancialTableWidget } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/lib';
import { isGLRowData } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/lib/typeGuards';
import { FinancialTableSingePeriodWidgetContext } from '@/bundles/Shared/widgets/dashboard/widgets/financialTableSingePeriod/widget';
import { WidgetProps } from '@/bundles/Shared/widgets/dashboard/widgets/model';
import { formatToDateStringForRequest } from '@/shared/lib/converters';
import { shiftDateBackward, yesterdayDate } from '@/shared/lib/date';
import {
  getDefaultAgGridNumberColDef,
  StyledBasicCellRendererProps,
} from '@/shared/lib/formatting/table';
import {
  ColDef,
  ColGroupDef,
  ICellRendererParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { ReportDashboardType } from 'bundles/Shared/entities/dashboard';
import {
  AVAILABLE_PERIOD_TYPES,
  AvailablePeriodType,
} from 'bundles/Shared/widgets/dashboard/widgets/common/config';
import {
  createMapByKey,
  resolveHeaderWithSubheaderComponentProps,
  sortByOrder,
} from 'bundles/Shared/widgets/dashboard/widgets/common/lib/utils';
import { buildExcelStyleId } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/useTableWidgetExportFeature';
import dayjs from 'dayjs';
import { groupBy } from 'lodash-es';
import React, { ComponentProps, useMemo } from 'react';
import { resolveComparisonTextColorForColDef } from 'bundles/Shared/widgets/dashboard/widgets/common/lib/comparison';
import { CssVar } from '@/shared/config/cssVar';
import { WidgetTableTextCellRenderer } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/renderers/TextCellRenderer';
import { ValueDisplayOptions } from '@/shared/lib/formatting/displayOptions';

const buildRowValueId = (
  groupKey: string | number,
  columnKey: string | number,
) => `${groupKey}_${columnKey}`;

export const resolveFinancialTableWidgetDefaultOptionsDate = (
  defaultOptionsDate?: string | null,
): DateString => {
  return formatToDateStringForRequest(
    defaultOptionsDate
      ? shiftDateBackward(new Date(defaultOptionsDate), 1, 'day')
      : yesterdayDate(),
  );
};

export const showLegalEntitiesDropdownByWidgetContext = (
  context: (
    | FinancialTableSingeDateWidgetContext
    | FinancialTableSingePeriodWidgetContext
  ) & {
    dashboardType: ReportDashboardType;
  },
): boolean =>
  Boolean(
    context.dashboardType === ReportDashboardType.COMPARISON_MODE &&
      (context.assets?.length ?? 0) > 0,
  );

export const COL_DEF_MAX_WIDTH = 240;

export const COL_DEF_ID = {
  Delimiter: '_',
  IdKeyIdValueDelimiter: ':',
  ObjectIdKey: 'objectId',
  GroupIdKey: 'groupId',
  ColumnIdKey: 'columnId',
  NoneIdValue: 'none',
} as const;

const joinIdKeyAndIdValue = (key: string, value: string) =>
  `${key}${COL_DEF_ID.IdKeyIdValueDelimiter}${value}`;

export const parseColDefId = (colDefId: string) => {
  const splitColDefId = colDefId.split(COL_DEF_ID.Delimiter);
  if (splitColDefId.length !== 3) {
    throw new Error('`colDefId` has wrong format');
  }
  const [objectId, groupId, colId] = splitColDefId.map(
    (id) => id.split(COL_DEF_ID.IdKeyIdValueDelimiter)[1],
  );

  return {
    [COL_DEF_ID.ObjectIdKey]: objectId,
    [COL_DEF_ID.GroupIdKey]: groupId,
    [COL_DEF_ID.ColumnIdKey]: colId,
  } as const;
};
export const stringifyColDefId = ({
  objectId = COL_DEF_ID.NoneIdValue,
  groupId = COL_DEF_ID.NoneIdValue,
  columnId,
}: {
  [COL_DEF_ID.ColumnIdKey]: string;
  [COL_DEF_ID.ObjectIdKey]?: string | undefined;
  [COL_DEF_ID.GroupIdKey]?: string | undefined;
}) => {
  return [
    joinIdKeyAndIdValue(COL_DEF_ID.ObjectIdKey, objectId),
    joinIdKeyAndIdValue(COL_DEF_ID.GroupIdKey, groupId),
    joinIdKeyAndIdValue(COL_DEF_ID.ColumnIdKey, columnId),
  ].join(COL_DEF_ID.Delimiter);
};

type ColDefContext = {
  widgetSectionFontSize: number | undefined;
  vizConfig: TableVizConfig;
  dashboardType: ReportDashboardType;
};

const buildHeaderProperties = (
  column: FinancialTableSingeDateWidgetNS.Column,
  ctx: Pick<ColDefContext, 'widgetSectionFontSize' | 'vizConfig'> & {
    objectGroup?: { key: string; name: string };
    group?: TableVizConfigColumnGroup;
  },
): Pick<
  ColDef,
  'headerComponentParams' | 'headerComponent' | 'headerClass' | 'headerName'
> => {
  const { widgetSectionFontSize, vizConfig } = ctx;
  const headerBackground = ctx?.vizConfig?.header_background;
  const col_vz = vizConfig.columns.find((c) => c.key === String(column.key));
  const dateSubHeaderName = formatDateRangeForWidgetColumnSubHeader(
    column,
    column,
    col_vz,
  );

  const { hide_subtitle, hide_title, subtitle } = col_vz?.header ?? {};
  const headerComponentParams = {
    style: buildTableCellStyles({
      background: headerBackground ?? ctx?.group?.background,
      fontSize: widgetSectionFontSize,
    }),
  };
  return {
    headerName: column.label,
    headerComponent: (params) => <HeaderComponentWithSubHeader {...params} />,
    headerComponentParams: (params) =>
      ({
        ...headerComponentParams,
        classes: {
          header: params.context.mode === 'pdf' ? 'whitespace-normal' : '',
          subHeader: params.context.mode === 'pdf' ? 'whitespace-normal' : '',
        },
        ...resolveHeaderWithSubheaderComponentProps({
          headerName: column.label,
          subHeaderName: subtitle ?? dateSubHeaderName,
          hide_subtitle,
          hide_title,
        }),
      }) satisfies Partial<ComponentProps<typeof HeaderComponentWithSubHeader>>,
  };
};

const DEFAULT_VALUE_DISPLAY_OPTIONS = {
  precision: 0,
  type: 'currency',
  hide_comma_separator: false,
};

const resolveValueDisplayOptions = (
  row: TableVizConfigRow | undefined,
  column: TableVizConfigColumn | undefined,
): ValueDisplayOptions => {
  return (
    column?.value_display_options ??
    row?.value_display_options ??
    DEFAULT_VALUE_DISPLAY_OPTIONS
  );
};

const createColDef = (
  column: FinancialTableSingeDateWidgetNS.Column,
  ctx: ColDefContext & {
    objectGroup?: { key: string; name: string };
    group?: TableVizConfigColumnGroup;
  },
): ColDef => {
  const { widgetSectionFontSize, vizConfig, dashboardType } = ctx;
  const col_vz = vizConfig.columns.find((c) => c.key === String(column.key));

  const getColumnDataProperty = (
    rowData: FinancialTableSingeDateWidgetNS.TableRowData,
    key: string,
    field: keyof FinancialTableSingeDateWidgetNS.Value = 'value',
  ) => {
    if (dashboardType === ReportDashboardType.COMPARISON_MODE) {
      const objectGroupKeyOrNothing = ctx?.objectGroup?.key ?? '-1';
      const rowValueId = buildRowValueId(objectGroupKeyOrNothing, key);

      return (
        rowData?.valuesGroupedByObjGroupAndColumnKey?.[rowValueId]?.[field] ??
        null
      );
    }
    return rowData?.valuesGroupedByColumnKey?.[key]?.[field] ?? null;
  };

  const getColumnDataHistory = (
    rowData: FinancialTableSingeDateWidgetNS.TableRowData,
    key: string,
  ) => getColumnDataProperty(rowData, key, 'history');

  const getColumnDataValue = (
    rowData: FinancialTableSingeDateWidgetNS.TableRowData,
    key: string,
  ) => getColumnDataProperty(rowData, key, 'value');

  return {
    colId: stringifyColDefId({
      objectId: ctx?.objectGroup?.key,
      groupId: ctx?.group?.group_id,
      columnId: String(column.key),
    }),
    cellClass: (params) => {
      if (col_vz?.value_display_options) {
        return buildExcelStyleId({ id: col_vz.col_id });
      }
      if (params.data.key) {
        return buildExcelStyleId({ id: params.data.key, type: 'row' });
      }
      return;
    },
    hide: col_vz?.hidden,
    maxWidth: col_vz?.max_width,
    ...buildHeaderProperties(column, ctx),
    cellRendererSelector: (
      params: OverrideField<
        ICellRendererParams,
        'data',
        FinancialTableSingeDateWidgetNS.TableRowData
      > &
        CurrencyCellRendererProps,
    ) => {
      const row = params.data;
      const rowSettings = vizConfig.rows.find(
        (r) => r.key === String(row.path),
      );

      if (rowSettings && isTextTableVizConfigRow(rowSettings)) {
        return {
          component: WidgetTableTextCellRenderer,
          params: {
            ...params,
            fallbackValue: '',
            config: rowSettings.cell_renderer!,
            historyValueGetter: (params) =>
              getColumnDataHistory(params.data, String(column.key)),
          } satisfies React.ComponentProps<typeof WidgetTableTextCellRenderer>,
        };
      }

      const valueDisplayOptions = resolveValueDisplayOptions(
        rowSettings,
        col_vz,
      );
      const numberColDef = getDefaultAgGridNumberColDef(valueDisplayOptions);

      return {
        component: numberColDef.cellRenderer,
        params,
      };
    },
    cellRendererParams: (params: ICellRendererParams) => {
      const row = params.data;
      const rowSettings = vizConfig.rows.find(
        (r) => r.key === String(row.path),
      );

      const { displayOptions } = params.data;
      const comparisonColor =
        rowSettings &&
        isNumericTableVizConfigRow(rowSettings) &&
        col_vz?.comparison
          ? resolveComparisonTextColorForColDef(
              col_vz.comparison,
              params,
              getColumnDataValue,
            )
          : undefined;
      const { background } = displayOptions;
      const hasLabelColor = background && comparisonColor;
      const color = hasLabelColor
        ? CssVar.neutral000
        : comparisonColor ?? displayOptions.color;

      const styles = buildTableCellStyles({
        ...displayOptions,
        fontSize: widgetSectionFontSize,
        color,
      });
      return {
        labelColor: hasLabelColor && comparisonColor,
        formatterParams: {
          fallbackValue: '',
        },
        styles,
      } satisfies StyledBasicCellRendererProps;
    },
    valueGetter: (
      params: OverrideField<
        ValueGetterParams,
        'data',
        FinancialTableSingeDateWidgetNS.TableRowData
      >,
    ) => getColumnDataValue(params.data, String(column.key)),
  };
};

const getUngroupedColumns = ({
  columns,
  vizConfig,
}: {
  columns: FinancialTableSingeDateWidgetNS.Column[];
  vizConfig: TableVizConfig;
}) => {
  const columnsDataMap = createMapByKey(columns);
  return vizConfig.columns
    .filter((column) => column.group_id == null)
    .toSorted(sortByOrder)
    .map((col_vz) => {
      if (!columnsDataMap.has(String(col_vz.col_id))) return null;
      const columnData = columnsDataMap.get(String(col_vz.col_id));

      return {
        ...columnData,
      };
    })
    .filter(Boolean);
};

const getGroupChildrenFromGroupedColumns = ({
  group_id,
  vizConfig,
  columns,
}: {
  group_id: string;
  vizConfig: TableVizConfig;
  columns: FinancialTableSingeDateWidgetNS.Column[];
}) => {
  const columnsDataMap = createMapByKey(columns);
  const groupedColumns = groupBy(vizConfig.columns, 'group_id');
  const groupChildren = groupedColumns[group_id] ?? [];

  return groupChildren
    .toSorted(sortByOrder)
    .map((col_vz) => {
      if (!columnsDataMap.has(String(col_vz.col_id))) return null;
      const columnData = columnsDataMap.get(String(col_vz.col_id));

      return {
        ...columnData,
      };
    })
    .filter(Boolean);
};

const getDefaultHeaderComponentParams = ({
  widgetSectionFontSize,
  headerBackground,
}: {
  widgetSectionFontSize: number | undefined;
  headerBackground: React.CSSProperties['color'] | undefined;
}) => ({
  style: buildTableCellStyles({
    background: headerBackground,
    fontSize: widgetSectionFontSize,
  }),
});

const buildColumnDefsForComparisonMode = ({
  data,
  vizConfig,
  defaultColDefContext,
  widgetSectionFontSize,
}: {
  data: FinancialTableSingeDateWidgetNS.SnapshotData;
  vizConfig: TableVizConfig;
  defaultColDefContext: ColDefContext;
  widgetSectionFontSize: number | undefined;
}) => {
  const ungroupedColumns = getUngroupedColumns({
    columns: data.columns,
    vizConfig,
  });
  const headerBackground = vizConfig?.header_background;

  const vizConfigColumnGroups = vizConfig?.column_groups ?? [];
  const colGroupDefs = data.groups.map((objectGroup) => {
    const ungroupedChildrenColDefs = ungroupedColumns.map((column) => {
      return createColDef(column, {
        ...defaultColDefContext,
        objectGroup: {
          key: String(objectGroup.key),
          name: objectGroup.asset.name,
        },
        group: getFinancialTableWidgetDefaultUngroupedGroup({}),
      });
    });
    const ungroupedColGroupDef = {
      headerName: '',
      headerGroupComponent: HeaderGroupComponent,
      headerGroupComponentParams: getDefaultHeaderComponentParams({
        widgetSectionFontSize,
        headerBackground,
      }),
      children: ungroupedChildrenColDefs,
    };

    const groupedChildren = vizConfigColumnGroups.map((group) => {
      const children = getGroupChildrenFromGroupedColumns({
        group_id: group.group_id,
        vizConfig,
        columns: data.columns,
      });
      const headerGroupComponentParams = {
        style: buildTableCellStyles({
          background: headerBackground ?? group.background,
          fontSize: widgetSectionFontSize,
        }),
      };
      return {
        headerName: group.header_name,
        headerGroupComponent: HeaderGroupComponent,
        headerGroupComponentParams,
        children: children.map((column) => {
          return createColDef(column, {
            ...defaultColDefContext,
            objectGroup: {
              key: String(objectGroup.key),
              name: objectGroup.asset.name,
            },
            group: {
              ...group,
              key: String(group.group_id),
              name: group.header_name,
            },
          });
        }),
      };
    });

    return {
      marryChildren: true,
      headerName: objectGroup.asset.name,
      headerGroupComponent: ComparisonObjectHeaderGroupComponent,
      headerGroupComponentParams: {
        ...getDefaultHeaderComponentParams({
          widgetSectionFontSize,
          headerBackground,
        }),
        entityCounterProps: {
          selectedCount: objectGroup.legalEntities.length,
          totalCount: objectGroup.legalEntities.length,
        },
      } satisfies Partial<
        ComponentProps<typeof ComparisonObjectHeaderGroupComponent>
      >,
      children: [ungroupedColGroupDef, ...groupedChildren],
    } satisfies ColGroupDef;
  });
  return insertBetweenPlaceholderColumnGroupDefFinancialTableWidget(
    colGroupDefs,
  );
};

export const useFinancialTableWidgetColDef = ({
  data,
  widgetSection,
  vizConfig,
  dashboardType,
}: {
  dashboardType: ReportDashboardType;
  data: FinancialTableSingeDateWidgetNS.SnapshotData | undefined;
  vizConfig: TableVizConfig;
} & Pick<
  WidgetProps<FinancialTableSingeDateWidgetNS.SnapshotData>,
  'widgetSection'
>) => {
  const columns = data?.columns ?? [];

  const headerBackground = vizConfig?.header_background;
  const vizConfigColumnGroups = vizConfig?.column_groups ?? [];

  const widgetSectionFontSize = widgetSection.fontSize ?? undefined;
  const defaultColDefContext = useMemo(
    () => ({
      widgetSectionFontSize,
      vizConfig,
      dashboardType,
    }),
    [widgetSectionFontSize, vizConfig, dashboardType],
  );
  const defaultHeaderComponentParams = useMemo(
    () =>
      ({
        style: buildTableCellStyles({
          background: headerBackground,
          fontSize: widgetSectionFontSize,
        }),
      }) satisfies Partial<HeaderGroupComponentProps>,
    [headerBackground, widgetSectionFontSize],
  );

  const columnDefs = useMemo<(ColGroupDef | ColDef)[]>(() => {
    if (data == null) return [];

    if (dashboardType === ReportDashboardType.COMPARISON_MODE) {
      return buildColumnDefsForComparisonMode({
        data,
        vizConfig,
        defaultColDefContext,
        widgetSectionFontSize,
      });
    }

    const ungroupedColumns = getUngroupedColumns({
      columns: data?.columns ?? [],
      vizConfig,
    });

    if (vizConfigColumnGroups.length === 0) {
      return columns.map((c) => createColDef(c, defaultColDefContext));
    }
    const ungroupedChildrenColDefs = ungroupedColumns.map((column) => {
      return createColDef(column, {
        ...defaultColDefContext,
        group: getFinancialTableWidgetDefaultUngroupedGroup({}),
      });
    });

    const ungroupedColGroupDef = {
      headerName: '',
      headerGroupComponent: HeaderGroupComponent,
      headerGroupComponentParams: defaultHeaderComponentParams,
      children: ungroupedChildrenColDefs,
    };

    return insertBetweenPlaceholderColumnGroupDefFinancialTableWidget([
      ungroupedColGroupDef,
      ...vizConfigColumnGroups.map((group) => {
        const children = getGroupChildrenFromGroupedColumns({
          group_id: group.group_id,
          vizConfig,
          columns,
        });
        const headerGroupComponentParams = {
          style: buildTableCellStyles({
            background: headerBackground ?? group.background,
            fontSize: widgetSectionFontSize,
          }),
        };
        return {
          headerName: group.header_name,
          headerGroupComponent: HeaderGroupComponent,
          headerGroupComponentParams,
          children: children.map((column) => {
            return createColDef(column, {
              ...defaultColDefContext,
              group,
            });
          }),
        };
      }),
    ]);
  }, [
    headerBackground,
    widgetSectionFontSize,
    data?.columns,
    defaultColDefContext,
  ]);

  return columnDefs;
};

export const useFinancialTableWidgetAutoGroupColumDef = (
  props: {
    headerBackground: React.CSSProperties['color'] | undefined;
    widgetSectionFontSize: number | undefined;
    data: FinancialTableSingeDateWidgetNS.SnapshotData | undefined;
  } & ColDef,
) => {
  const { data, headerBackground, widgetSectionFontSize, ...colDef } = props;
  const headerTitle = data?.vizConfig?.auto_column?.header?.title ?? '';

  const autoColumnDef = React.useMemo<ColDef>(() => {
    return {
      flex: 1,
      pinned: true,
      headerName: '',
      resizable: true,
      minWidth: AUTO_COLUM_DEF_MIN_WIDTH,
      maxWidth: AUTO_COLUM_DEF_MAX_WIDTH,
      suppressMenu: true,
      headerComponent: AutoGroupHeaderComponent,
      headerComponentParams: {
        subHeaderName: '',
        displayName: headerTitle,
        style: buildTableCellStyles({
          background: headerBackground,
          fontSize: widgetSectionFontSize,
        }),
      } satisfies Partial<ComponentProps<typeof AutoGroupHeaderComponent>>,
      cellRendererParams: (
        params: OverrideField<
          ICellRendererParams,
          'data',
          FinancialTableSingeDateWidgetNS.TableRowData
        > &
          AutoGroupCellRendererProps,
      ) => {
        if (params.data == null) return {};
        const { displayOptions } = params.data;

        return {
          styles: {
            wrapper: buildTableCellStyles({
              ...displayOptions,
              fontSize: widgetSectionFontSize,
            }),
          },
          tooltipProps: isGLRowData(params.data)
            ? {
                disabled: params.data.legalEntityCode == null,
                mainText: params.data.legalEntityCode,
              }
            : undefined,
          ...params,
        } satisfies ComponentProps<typeof AutoGroupCellRenderer> &
          ICellRendererParams &
          AutoGroupCellRendererProps &
          CellRendererStylesProps;
      },
      cellRenderer: (
        params: OverrideField<
          ICellRendererParams,
          'data',
          FinancialTableSingeDateWidgetNS.TableRowData
        >,
      ) => (
        <SourceTypeImageWrapper
          source={isGLRowData(params.data) ? params.data.erpSystem : null}
          isGLRow={isGLRowData(params.data)}
        >
          <AutoGroupCellRenderer {...params} />
        </SourceTypeImageWrapper>
      ),
      valueGetter: (
        params: OverrideField<
          ValueGetterParams,
          'data',
          FinancialTableSingeDateWidgetNS.TableRowData
        >,
      ) => {
        if (isGLRowData(params.data)) {
          return `${params.data?.label} | ${params.data.legalEntityCode}`;
        }
        return params.data?.label ?? '';
      },
      ...colDef,
    };
  }, [...Object.values(props)]);

  return autoColumnDef;
};

export const useFinancialTableWidgetDefaultColDef = ({
  isCompactSelected = false,
  deps = [],
}: {
  isCompactSelected?: boolean;
  deps?: React.DependencyList;
}) => {
  return React.useMemo<ColDef>(
    () => ({
      suppressMenu: true,
      suppressMovable: true,
      resizable: true,
      headerComponent: HeaderComponent,
      cellRenderer: CurrencyCellRenderer,
      minWidth: resolveDefaultColumnMinWidth(isCompactSelected),
      width: NEXT_GEN_TABLE_CONFIG.column.default.width,
    }),
    [isCompactSelected, ...deps],
  );
};

export const useFinancialTableWidgetRowData = ({
  data,
}: {
  data: FinancialTableSingeDateWidgetNS.SnapshotData | undefined;
}) => {
  return React.useMemo<
    OverrideField<
      FinancialTableSingeDateWidgetNS.TableRowData,
      'path',
      string[]
    >[]
  >(() => {
    if (data == null) return [];
    return data.rows.map((r) => {
      return {
        ...r,
        valuesGroupedByColumnKey: Object.fromEntries(
          r.values.map((v) => [v.columnKey, v]),
        ),
        valuesGroupedByObjGroupAndColumnKey: Object.fromEntries(
          r.values.map((v) => [buildRowValueId(v.groupKey, v.columnKey), v]),
        ),
        display: {},
        label: r.label,
        key: r.path,
        path: r.path.split(DEFAULT_TABLE_ROW_PATH_DELIMITER),
        children: [],
      };
    });
  }, [data]);
};

export const resolveActualStateDate = (
  date: string,
  periodType: AvailablePeriodType,
) => {
  if (
    periodType === AVAILABLE_PERIOD_TYPES.MTD ||
    periodType === AVAILABLE_PERIOD_TYPES.YTD
  ) {
    return formatToDateStringForRequest(dayjs(date).endOf('month'));
  }
  return date;
};
