import {
  ColDef,
  ICellRendererParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import {
  getUWBCategoryRows,
  getUWBMetricRows,
  useProcessDataFromClipboard,
} from 'bundles/REconcile/underwritting/lib';
import { BudgetRow } from 'bundles/REconcile/underwritting/model';
import { SymmetreAgGridTable } from '@/bundles/Shared/components/AgGrid/Table/SymmetreAgGridTable';
import { AutoGroupCellRenderer } from 'bundles/Shared/components/AgGrid/Table/cellComponents/AutoGroupCellRenderer';
import { AutoGroupHeaderComponent } from 'bundles/Shared/components/AgGrid/Table/cellComponents/AutoGroupHeaderComponent';
import { CurrencyCellRenderer } from 'bundles/Shared/components/AgGrid/Table/cellComponents/CurrencyCellRenderer';
import { HeaderComponent } from 'bundles/Shared/components/AgGrid/Table/cellComponents/HeaderComponent';
import {
  DIVIDER_ROW,
  FINANCIAL_ROOT_ROW,
  METRICS_ROOT_ROW,
} from 'bundles/Shared/entities/undewriting/config';
import { useGridDefaultParams } from 'lib/ag-grid/useGridDefaultParams';
import { assertsType } from 'lib/typeHelpers/assertsType';
import { useMemo, useRef } from 'react';
import { AnimationLoader } from 'stories/index';

import { getDefaultAgGridNumberColDef } from '@/shared/lib/formatting/table';
import { UpdateForecastingFinancialRow } from '@/entities/reconcile/forecasting/features/ui/UpdateForecastingFinancialRow';
import { ForecastingBudgetUpdateMetrics } from '@/entities/reconcile/forecasting/features/ui/ForecastingBudgetUpdateMetrics';
import { useColumnDefs } from '@/entities/reconcile/forecasting/lib';
import { useForecastBudget } from '@/entities/reconcile/forecasting/features/api/forecast/useForecastBudget';

export const ForecastionBudgetTable = ({
  isLoading,
  forecastId,
  budgetId,
}: {
  forecastId: string | undefined;
  budgetId: string | undefined;
  isLoading?: boolean;
}) => {
  const gridRef = useRef<AgGridReact>(null);

  const { onGridReady } = useGridDefaultParams({
    enableExpandOnClick: true,
  });

  const {
    budget: budgetData,
    isFetching,
    isLoading: isLoadingBudgetData,
  } = useForecastBudget(forecastId ?? '', budgetId ?? '');

  const columnDefs = useColumnDefs({
    budget: budgetData,
    deps: [budgetData],
  });

  const rowData = useMemo(() => {
    if (!forecastId || !budgetId) return [];

    const categories = getUWBCategoryRows({
      budgetFinancialCategoryRows:
        budgetData?.budgetFinancialCategoryRows ?? [],
    });
    const metrics = getUWBMetricRows({
      budgetMetricRows: budgetData?.budgetMetricRows ?? [],
    });

    return [
      {
        ...FINANCIAL_ROOT_ROW,
        label: (
          <UpdateForecastingFinancialRow
            forecastId={forecastId}
            budgetId={budgetId}
          />
        ),
      },
      ...categories,
      DIVIDER_ROW,
      {
        ...METRICS_ROOT_ROW,
        label: budgetData && (
          <ForecastingBudgetUpdateMetrics budget={budgetData} />
        ),
      },
      ...metrics,
    ];
  }, [budgetData]);

  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      cellRenderer: (params: ICellRendererParams) => {
        const { data } = params;
        if (data == null) return;
        assertsType<BudgetRow>(data, true);

        if (data.kind !== 'metric') {
          return <CurrencyCellRenderer {...params} />;
        }

        const colDef = getDefaultAgGridNumberColDef({
          type: data.valueType,
        });

        return colDef.cellRenderer(params);
      },
      headerComponent: HeaderComponent,
      suppressMovable: true,
      minWidth: 124,
    }),
    [],
  );

  const processDataFromClipboard = useProcessDataFromClipboard();

  const autoGroupColumnDef = useMemo(
    () => ({
      flex: 1,
      pinned: true,
      resizable: true,
      headerName: '',
      suppressMenu: true,
      minWidth: 232,
      initialWidth: 232,
      headerComponent: AutoGroupHeaderComponent,
      cellRenderer: AutoGroupCellRenderer,
      valueGetter: (p: ValueGetterParams) => {
        return p.data?.label as string;
      },
    }),
    [rowData, columnDefs],
  );

  if (isLoading ?? isLoadingBudgetData) {
    return <AnimationLoader className="static h-full w-full" />;
  }
  return (
    <div className="relative h-full">
      {isFetching && (
        <AnimationLoader className="z-10 h-full w-full bg-neutral-000/50" />
      )}
      <SymmetreAgGridTable
        ref={gridRef}
        defaultColDef={defaultColDef}
        rowData={rowData}
        columnDefs={columnDefs}
        onGridReady={onGridReady}
        groupIncludeTotalFooter={false}
        groupIncludeFooter={false}
        processDataFromClipboard={processDataFromClipboard}
        className="[&_.ag-center-cols-viewport]:bg-neutral-100 [&_.ag-pinned-left-cols-container]:!h-auto"
        autoGroupColumnDef={autoGroupColumnDef}
      />
    </div>
  );
};
