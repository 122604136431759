import { SharedSelectController } from '@/bundles/Shared/components/GroupForm/FormItems/new/SharedSelect';
import { Input } from '@/stories';
import Field, { FieldsContainer } from '@/stories/Field/Field';
import { useFormContext } from 'react-hook-form';
import * as yup from 'yup';

export const CAPTION_SCHEMA = yup.object().shape({
  captionText: yup.string(),
  alignment: yup.string().oneOf(['center', 'left', 'right']).default('left'),
});

export type CaptionSchemaForm = yup.InferType<typeof CAPTION_SCHEMA>;

export const CaptionFormFields = () => {
  const { control, register } = useFormContext<CaptionSchemaForm>();
  return (
    <FieldsContainer>
      <Field labelText="Caption Text">
        <Input
          type="text"
          maxLength={64}
          placeholder=""
          {...register('captionText')}
        />
      </Field>
      <Field labelText="Caption alignment">
        <SharedSelectController
          control={control}
          name="alignment"
          options={[
            {
              id: 'left',
              value: 'left',
              label: 'Left',
            },
            {
              id: 'center',
              value: 'center',
              label: 'Center',
            },
            {
              id: 'right',
              value: 'right',
              label: 'Right',
            },
          ]}
        />
      </Field>
    </FieldsContainer>
  );
};
