import React from 'react';
import { ActionItem } from '../ActionItem';
import { GroupContainer } from '../GroupContainer';

export const GroupContainerItem = ({
  title,
  children,
  className,
}: React.PropsWithChildren<
  {
    title: string;
  } & PropsWithClassName
>) => (
  <ActionItem className={className}>
    <GroupContainer title={title}>{children}</GroupContainer>
  </ActionItem>
);
