import { Icon } from 'stories/index';
import {
  IConnection,
  TConnectionReportType,
} from 'bundles/Shared/entities/connection/model';
import { IconsId } from '@/types/sre-icons';

import { cn } from '@/shared/lib/css/cn';

export const CONNECTION_REPORT_TYPE_LABELS = {
  [TConnectionReportType.OPERATIONAL]: 'Operational',
  [TConnectionReportType.FINANCIAL]: 'Financial',
  [TConnectionReportType.COMBINED]: 'Combined',
  [TConnectionReportType.MARKET_DATA]: 'Market Data',
  [TConnectionReportType.CRM]: 'CRM',
} as const satisfies Record<TConnectionReportType, string>;

export const CONNECTION_REPORT_TYPE_ICONS = {
  [TConnectionReportType.OPERATIONAL]: 'bag',
  [TConnectionReportType.COMBINED]: 'bag',
  [TConnectionReportType.FINANCIAL]: 'roundSumbolUSD',
  [TConnectionReportType.MARKET_DATA]: 'analys',
  [TConnectionReportType.CRM]: 'extension',
} as const satisfies Record<TConnectionReportType, IconsId>;

export const ConnectionReportTypeLabel = ({
  type,
  className,
}: {
  type: IConnection['reportType'];
} & PropsWithClassName) => {
  const reportType = type as TConnectionReportType;

  return (
    <div className={cn('flex items-center gap-tw-2 text-sm', className)}>
      <Icon
        iconName={CONNECTION_REPORT_TYPE_ICONS[reportType]}
        className="text-neutral-500"
      />
      <span className="font-medium">
        {CONNECTION_REPORT_TYPE_LABELS[reportType]}
      </span>
    </div>
  );
};
