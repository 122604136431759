import { usePostApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdAttachmentsParseMutation } from '@/bundles/Settings/components/REport/Connections/api/settingsReportConnectionsEnhancedApi';
import { ReportThirdPartyExtractionEmailConnectionInboundEmailAttachmentDto } from '@/bundles/Settings/components/REport/Connections/api/settingsReportConnectionsGeneratedApi';
import { IConnection } from 'bundles/Shared/entities/connection/model';
import IconButton from 'stories/IconButton/IconButton';

interface Props extends PropsWithClassName {
  connectionId: IConnection['id'];
  attachmentIds: ReportThirdPartyExtractionEmailConnectionInboundEmailAttachmentDto['id'][];
  manual?: boolean;
}

export function ReparseFailedExtractionButton({
  connectionId,
  manual,
  attachmentIds,
  className,
}: Props) {
  // eslint-disable-next-line max-len
  const [reparse, { isLoading }] =
    usePostApiSettingsReportThirdPartyExtractionsConnectionsByConnectionIdAttachmentsParseMutation();
  return (
    <IconButton
      className={className}
      disabled={isLoading}
      onClick={() =>
        reparse({
          connectionId,
          body: {
            attachment_ids: attachmentIds,
            manual_attachments: manual,
          },
        })
      }
      iconName="sync"
    />
  );
}
