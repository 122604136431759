import {
  EmptyWidget,
  resolveWidgetSectionPositionClassName,
} from '@/bundles/Shared/widgets/dashboard/widgets/common';
import { isWidgetSectionPositionWidthFullSize } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilder';
import type { MediaWidgetDisplayOptions } from '@/bundles/Shared/widgets/dashboard/widgets/media/config';
import {
  getContainerBorderClass,
  getMediaWidgetDisplayOptions,
  getMediaWidgetFigureStyle,
  getObjectFitClass,
  getObjectPositionClass,
} from '@/bundles/Shared/widgets/dashboard/widgets/media/lib';
import { MediaWidgetFigure } from '@/bundles/Shared/widgets/dashboard/widgets/media/ui/MediaWidgetFigure';
import { WidgetProps } from '@/bundles/Shared/widgets/dashboard/widgets/model';
import { REPORT_BUILDER_ORIENTATION_CLASSES } from '@/entities/report/reportBuilder';
import {
  AssetsReportBuilderMediaWidgetDto,
  AssetsReportBuilderReportGroupDto,
} from '@/entities/report/reportBuilder/api/coreAssetsReportBuilderReportsGeneratedApi';
import { cn } from '@/shared/lib/css/cn';

export const MediaWidget = ({
  data,
  className,
  pageOrientation,
  isLoading,
  widgetSection,
  showContainerBorder,
}: WidgetProps<AssetsReportBuilderMediaWidgetDto> & {
  pageOrientation: AssetsReportBuilderReportGroupDto['pageOrientation'];
  showContainerBorder?: boolean;
}) => {
  const initialDisplayOptions = data.displayOptions as
    | MediaWidgetDisplayOptions
    | undefined;

  const displayOptions = getMediaWidgetDisplayOptions(initialDisplayOptions);

  const { mediaWidgetClassName } =
    REPORT_BUILDER_ORIENTATION_CLASSES[pageOrientation ?? 'portrait'];

  if (!isLoading && (data?.items.length ?? 0) === 0) {
    return (
      <EmptyWidget
        className={cn(
          resolveWidgetSectionPositionClassName(
            widgetSection.position ?? {
              w: 100,
            },
          ),
          'h-full max-h-[initial] min-h-[220px]',
        )}
      >
        Media
      </EmptyWidget>
    );
  }

  return (
    <div
      className={cn(
        'grid break-inside-avoid-page gap-tw-4 overflow-hidden',
        mediaWidgetClassName,

        {
          'grid-cols-1': !isWidgetSectionPositionWidthFullSize(
            widgetSection.position,
          ),
        },
        className,
      )}
    >
      {data?.items.map((i) => (
        <MediaWidgetFigure
          key={i.id}
          caption={i.caption}
          styles={getMediaWidgetFigureStyle(displayOptions)}
          className={cn(getContainerBorderClass(showContainerBorder))}
        >
          <img
            alt="media widget"
            className={cn(
              'h-full w-full rounded-[8px]',
              getObjectFitClass(displayOptions.objectFit),
              getObjectPositionClass(displayOptions.objectPosition),
            )}
            src={i.url ?? i.dataUri}
          />
        </MediaWidgetFigure>
      ))}
    </div>
  );
};
