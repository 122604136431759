import { NotFound } from '@/bundles/Errors';
import {
  DashboardContext,
  ReportDashboardType,
} from '@/bundles/Shared/entities/dashboard';
import { ReportBuilderTemplateContext } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilderTemplateContext';
import {
  useGetApiSettingsReportBuilderGotenbergTemplatesByIdQuery,
  useGetApiSettingsReportBuilderGotenbergTemplatesMetaQuery,
} from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesEnhancedApi';
import type {
  GetApiSettingsReportBuilderGotenbergTemplatesByIdApiArg,
  GetApiSettingsReportBuilderGotenbergTemplatesMetaApiArg,
} from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';
import { ReportBuilderTemplateMetaContext } from '@/entities/report/reportBuilder/context/reportBuilderTemplateMetaContext';
import { resolveReportBuilderGroupHeader } from '@/entities/report/reportBuilder/lib/resolveReportBuilderGroupHeader';
import { ReportPDFHeader } from '@/entities/report/reportBuilder/ui/ReportPDFHeader';
import { useReportBuilderTemplateScreenParams } from '@/shared/lib/hooks/navigation/dashboardsNavitation';
import { useSearchParams } from '@/shared/lib/hooks/navigation/useSearchParams';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { PdfUI } from '@/shared/ui/PdfUI';
import { AnimationLoader } from '@/stories';
import { type RouteComponentProps } from '@reach/router';
import { ReportBuilderTemplateWidget } from 'bundles/Shared/widgets/dashboard/widgetsHelpers/ui/ReportBuilderWidget';
import { useMemo, type FC } from 'react';

export const ReportBuilderTemplateViewPage: FC<RouteComponentProps> = () => {
  const params = useReportBuilderTemplateScreenParams();
  const [searchParams] = useSearchParams();
  const assetId = useAppSelector(
    (state) => state.reportBuilderTemplate.objectLevelAssetId,
  );
  const hidePlaceholders = Boolean(searchParams.get('hidePlaceholders'));
  const { data, isLoading } =
    useGetApiSettingsReportBuilderGotenbergTemplatesByIdQuery({
      id: params.templateId,
    } as GetApiSettingsReportBuilderGotenbergTemplatesByIdApiArg);
  const { data: metaData, isLoading: isMetaDataLoading } =
    useGetApiSettingsReportBuilderGotenbergTemplatesMetaQuery(
      {} as GetApiSettingsReportBuilderGotenbergTemplatesMetaApiArg,
    );

  const asset = useMemo(() => {
    return metaData?.assets.find((a) => a.id === Number(assetId));
  }, [metaData, assetId]);

  if (isLoading || isMetaDataLoading) return <AnimationLoader />;

  if (data == null || metaData == null) {
    return <NotFound />;
  }
  return (
    <DashboardContext.Provider
      value={{
        boardId: '',
        dashboardId: '',
        boardSlug: '',
        dashboardSlug: '',
        dashboardType: ReportDashboardType.REPORT_BUILDER_TEMPLATE,
      }}
    >
      <ReportBuilderTemplateMetaContext.Provider value={metaData}>
        <PdfUI>
          {data.groups.map((g, gIdx) => (
            <PdfUI.WidgetGroup
              hidePlaceholders={hidePlaceholders}
              key={g.id}
              {...g}
            >
              {resolveReportBuilderGroupHeader({
                displayHeaderGlobal: data.displayHeader,
                displayHeaderGroup: g.displayHeader,
              }) && (
                <ReportPDFHeader
                  objectName={
                    data.kind === 'object_level'
                      ? asset?.name ?? undefined
                      : undefined
                  }
                  reportTitle={data.name}
                />
              )}
              {g.widgetSections.map((w, wIdx) => (
                <ReportBuilderTemplateContext.Provider
                  key={w.id}
                  value={{
                    groupId: g.id,
                    templateId: data.id,
                    kind: data.kind,
                    widgetId: w.id,
                  }}
                >
                  <ReportBuilderTemplateWidget
                    widgetSection={{ ...w, groupId: g.id }}
                    pageOrientation={g.pageOrientation}
                    isLastInGroup={
                      gIdx === data.groups.length - 1 &&
                      wIdx === g.widgetSections.length - 1
                    }
                  />
                </ReportBuilderTemplateContext.Provider>
              ))}
            </PdfUI.WidgetGroup>
          ))}
        </PdfUI>
      </ReportBuilderTemplateMetaContext.Provider>
    </DashboardContext.Provider>
  );
};
