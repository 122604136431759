import { DialogProps } from '@/shared/lib/hooks/useModal';
import { Button, Modal } from '@/stories';
import { SendDataButton } from 'bundles/DrawPackage/components/SendDataButton/SendDataButton';
import {
  useGetApiSettingsReportThirdPartyExtractionsConnectionsByIdQuery,
  usePostApiSettingsReportThirdPartyExtractionsConnectionsMutation,
  usePutApiSettingsReportThirdPartyExtractionsConnectionsByIdMutation,
} from '@/bundles/Settings/components/REport/Connections/api/settingsReportConnectionsEnhancedApi';
import { ConnectionModalEmailFlow } from 'bundles/Settings/components/REport/Connections/ui/ConnectionModalEmailFlow';
import { ConnectionModalSFTPFlow } from 'bundles/Settings/components/REport/Connections/ui/ConnectionModalSFTPFlow';
import { SharedInput } from 'bundles/Shared/components/GroupForm/FormItems/new/SharedInput';
import {
  extractConnectionEmailPostfix,
  generateConnectionEmailPostfix,
  isSftpExtractions,
} from 'bundles/Shared/entities/connection/lib';
import {
  ConnectionEmailAttr,
  ConnectionSFTPAttr,
  IConnection,
  TConnectionType,
} from 'bundles/Shared/entities/connection/model';
import { ConnectionReportTypeSelect } from 'bundles/Shared/entities/connection/ui/ConnectionReportTypeSelect';
import { ConnectionTypeSelect } from 'bundles/Shared/entities/connection/ui/ConnectionTypeSelect';
import { ERPSystem } from '@/entities/erpsystem/model';
import { ERPSystemSelect } from '@/entities/erpsystem/ui/ERPSystemSelect';
import { PropertyManagementCompanySelect } from '@/entities/propertyManagementCompany/ui/PropertyManagementCompanySelect';
import {
  CrudConnectionForm,
  useErpSystemWatchEffect,
  useReportTypeWatchEffect,
} from 'bundles/Shared/features/connection/crud';
import {
  EDITABLE_EMAIL_REQUIRED_FIELD,
  EDITABLE_SFTP_REQUIRED_FIELD,
  EMAIL_REQUIRED_FIELD,
  SFTP_REQUIRED_FIELD,
} from 'bundles/Shared/features/connection/crud/config';
import { SOURCE_TYPE_SETTINGS } from 'lib/sourceType';
import { FC, useCallback, useMemo } from 'react';
import { Controller, FormProvider, Resolver, useForm } from 'react-hook-form';
import Field, { FieldsContainer } from 'stories/Field/Field';
import { ReportThirdPartyExtractionConnectionDto } from '@/bundles/Settings/components/REport/Connections/api/settingsReportConnectionsGeneratedApi';
import { omit, pick } from 'lodash-es';

interface IConnectionModalProps extends DialogProps {
  connectionId?: IConnection['id'];
}

type ConnectionProps = Pick<
  IConnection,
  'erpSystem' | 'name' | 'reportType' | 'companyName' | 'type'
>;

export interface EmailConnectionProps extends ConnectionProps {
  emailPostfix?: ConnectionEmailAttr['emailPostfix'];
}

export type EditableEmailConnectionProps = Pick<
  EmailConnectionProps,
  'name' | 'reportType'
>;

export interface SftpConnectionProps extends ConnectionProps {
  username?: ConnectionSFTPAttr['username'];
  host?: ConnectionSFTPAttr['host'];
  password?: ConnectionSFTPAttr['password'];
  folderPath?: ConnectionSFTPAttr['folderPath'];
}

export type EditableSftpConnectionProps = Pick<
  SftpConnectionProps,
  'name' | 'reportType'
>;

export const ConnectionCrudModal: FC<IConnectionModalProps> = ({
  onClose,
  connectionId,
}) => {
  const { data: connection } =
    useGetApiSettingsReportThirdPartyExtractionsConnectionsByIdQuery(
      {
        id: connectionId!,
      },
      { skip: !connectionId },
    );
  const [
    createConnection,
    { isLoading: isLoadingCreate, isSuccess: isSuccessCreating },
  ] = usePostApiSettingsReportThirdPartyExtractionsConnectionsMutation();

  const [
    updateConnection,
    { isLoading: isLoadingUpdate, isSuccess: isSuccessUpdating },
  ] = usePutApiSettingsReportThirdPartyExtractionsConnectionsByIdMutation();

  if (isSuccessCreating || isSuccessUpdating) {
    onClose();
  }

  const resolver: Resolver<CrudConnectionForm, unknown> = useCallback(
    (values: CrudConnectionForm) => {
      const errors: Partial<Record<keyof CrudConnectionForm, string>> = {};

      let fields: readonly string[] = isSftpExtractions(
        values as unknown as IConnection,
      )
        ? SFTP_REQUIRED_FIELD
        : EMAIL_REQUIRED_FIELD;

      if (connectionId) {
        fields = isSftpExtractions(values as unknown as IConnection)
          ? EDITABLE_SFTP_REQUIRED_FIELD
          : EDITABLE_EMAIL_REQUIRED_FIELD;
      }

      fields.forEach((key: keyof CrudConnectionForm) => {
        if (!values[key]) {
          errors[key] = 'Field is required';
        }
      });

      return {
        values,
        errors,
      };
    },
    [],
  );

  const methods = useForm<CrudConnectionForm>({
    mode: 'onChange',
    defaultValues: {
      name: connection?.item.name,
      companyName: connection?.item.companyName,
      reportType: connection?.item.reportType,
      type: connection?.item.type,
      erpSystem: connection?.item.erpSystem,
      emailPostfix: connectionId
        ? extractConnectionEmailPostfix(connection?.item.emailAddress)
        : generateConnectionEmailPostfix(),
    },
    resolver,
  });

  useErpSystemWatchEffect(methods);
  useReportTypeWatchEffect(methods);

  const {
    control,
    getValues,
    watch,
    formState: { isValid },
  } = methods;
  const { type, reportType } = watch();

  const handleConnection = () => {
    const connectionValues = getValues();
    if (connectionId) {
      updateConnection({
        id: connectionId,
        body: {
          name: connectionValues.name,
          report_type:
            connectionValues.reportType as ReportThirdPartyExtractionConnectionDto['reportType'],
        },
      });
    } else {
      createConnection({
        body: {
          active: true,
          company_name: connectionValues.companyName,
          ...(connectionValues.type === 'email' && {
            email_connection_attributes: {
              email_postfix: connectionValues.emailPostfix,
            },
          }),
          ...(connectionValues.type === 'sftp' && {
            sftp_connection_attributes: {
              username: connectionValues.username,
              host: connectionValues.host,
              password: connectionValues.password,
              folder_path: '/',
            },
          }),
          name: connectionValues.name,
          erp_system:
            connectionValues.erpSystem as ReportThirdPartyExtractionConnectionDto['erpSystem'],
          report_type:
            connectionValues.reportType as ReportThirdPartyExtractionConnectionDto['reportType'],
        },
      });
    }
  };

  const erpSystemOptions = useMemo(() => {
    switch (reportType) {
      case 'market_data': {
        return Object.entries(
          pick(SOURCE_TYPE_SETTINGS, 'radix', 'realpage'),
        ).map(([value, option]) => ({
          label: option.title,
          value: value as ERPSystem,
        }));
      }
      case 'crm': {
        return Object.entries(pick(SOURCE_TYPE_SETTINGS, 'anyone_home')).map(
          ([value, option]) => ({
            label: option.title,
            value: value as ERPSystem,
          }),
        );
      }
      default: {
        return Object.entries(
          omit(SOURCE_TYPE_SETTINGS, 'symmetre', 'radix'),
        ).map(([value, option]) => ({
          label: option.title,
          value: value as ERPSystem,
        }));
      }
    }
  }, [reportType]);

  return (
    <Modal
      toggle={onClose}
      header={`${connectionId ? 'Update' : 'Create'} connection`}
      classes={{
        body: 'bg-neutral-100',
      }}
      actions={
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <SendDataButton
            variant="success"
            onClick={handleConnection}
            isLoading={isLoadingCreate || isLoadingUpdate}
            disabled={!isValid || isLoadingCreate || isLoadingUpdate}
          >
            {connectionId ? 'Update' : 'Create'} connection
          </SendDataButton>
        </>
      }
    >
      <FormProvider {...methods}>
        <FieldsContainer>
          <Field labelText="Connection name" required>
            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <SharedInput<IConnection['name']>
                  value={field.value}
                  onChange={field.onChange}
                  placeholder="Enter Name"
                  newGen
                />
              )}
            />
          </Field>
          <Field labelText="Company" required>
            <Controller
              control={control}
              name="companyName"
              render={({ field }) => (
                <PropertyManagementCompanySelect
                  // @ts-ignore
                  value={field.value}
                  typeValue="name"
                  // @ts-ignore
                  onChange={(e) => field.onChange(e.value)}
                  disabled={Boolean(connectionId)}
                />
              )}
            />
          </Field>
          <Field labelText="Connection type" required>
            <Controller
              control={control}
              name="type"
              render={({ field }) => (
                <ConnectionTypeSelect
                  value={field.value as TConnectionType}
                  // @ts-ignore
                  onChange={(e) => field.onChange(e.value)}
                  disabled={Boolean(connectionId)}
                />
              )}
            />
          </Field>
          {type === 'email' && (
            <ConnectionModalEmailFlow
              control={control}
              connectionId={connectionId}
            />
          )}
          {type === 'sftp' && !connectionId && (
            <ConnectionModalSFTPFlow control={control} />
          )}
          {!type && (
            <Controller
              control={control}
              name="emailPostfix"
              render={() => (
                <Field labelText="Connection address" required>
                  {/* @ts-ignore */}
                  <SharedInput
                    className="flex-1"
                    classNameError="text-success-080"
                    placeholder="Enter connection address"
                    error="Select Connection Type"
                    disabled
                    newGen
                  />
                </Field>
              )}
            />
          )}
          <Field labelText="Report type" required>
            <Controller
              control={control}
              name="reportType"
              render={({ field }) => (
                <ConnectionReportTypeSelect
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                />
              )}
            />
          </Field>
          <Field labelText="ERP System" required>
            <Controller
              control={control}
              name="erpSystem"
              render={({ field }) => (
                <ERPSystemSelect
                  value={field.value}
                  // @ts-ignore
                  onChange={(e) => field.onChange(e.value)}
                  options={erpSystemOptions}
                  disabled={Boolean(connectionId)}
                />
              )}
            />
          </Field>
        </FieldsContainer>
      </FormProvider>
    </Modal>
  );
};
