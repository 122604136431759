import ErrorBoundary from '@/app/ui/ErrorBoundary';
import { SettingsReportWidgetConfigEditor } from '@/bundles/Settings/components/REport/Dashboards/Dashboard/Widget/SettingsReportDashboardWidgetConfigEditor';
import { DashboardLayout } from '@/bundles/Shared/components/layouts/dashboard/DashboardLayout';
import { WidgetFlyPanel } from '@/bundles/Shared/components/layouts/dashboard/WidgetLayout';
import NoDataOverlay from '@/bundles/Shared/components/NoDataOverlay';
import {
  DashboardContext,
  ReportDashboardType,
} from '@/bundles/Shared/entities/dashboard';
import {
  selectReportBuilderTemplateMetadataById,
  updateReportBuilderTemplateWidgetState,
} from '@/bundles/Shared/entities/dashboard/model/slices/reportBuilderTemplatesSlice';
import { ReportDashboardDateFilterBlock } from '@/bundles/Shared/features/dashboard/object/filter/byDate';
import { ReportBuilderTemplateEagleEyeObjectsFilter } from '@/bundles/Shared/features/dashboard/object/filter/byObject/ui/ReportBuilderTemplateEagleEyeObjectsFilter';
import { ReportBuilderTemplateObjectLevelObjectsFilter } from '@/bundles/Shared/features/dashboard/object/filter/byObject/ui/ReportBuilderTemplateObjectLevelObjectsFilter';
import { useUpdateWidgetConfig } from '@/bundles/Shared/widgets/dashboard/widgets/common';
import { ReportBuilderTemplateContext } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilderTemplateContext';
import {
  JsonEditorUI,
  useJsonEditorButtonFeature,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/state/jsonEditorButtonFeature';
import { WIDGETS_CONFIG_MAP } from '@/bundles/Shared/widgets/dashboard/widgets/config';
import { WidgetErrorBoundaryFallback } from '@/bundles/Shared/widgets/dashboard/widgetsHelpers/ui/WidgetErrorBoundary';
import { FormulasAndVariablesWorkspace } from '@/bundles/Shared/widgets/formula/panel';
import {
  useGetApiSettingsReportBuilderGotenbergTemplatesByIdQuery,
  useGetApiSettingsReportBuilderGotenbergTemplatesMetaQuery,
  useGetApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdQuery,
  usePostApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsPreviewQuery,
} from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesEnhancedApi';
import type {
  GetApiSettingsReportBuilderGotenbergTemplatesMetaApiArg,
  ReportBuilderTemplateDto,
  ReportBuilderTemplateGroupWidgetSectionDto,
} from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';
import {
  ReportBuilderTemplateMetaContext,
  useReportBuilderTemplateMetaContext,
} from '@/entities/report/reportBuilder/context/reportBuilderTemplateMetaContext';
import { useGetSelectedObjectsSearchQuery } from '@/entities/report/reportBuilder/lib/useGetApiReportBuilderTemplatePreviewPdfQuery';
import { formatToDateStringForRequest } from '@/shared/lib/converters';
import { useReportBuilderTemplateScreenParams } from '@/shared/lib/hooks/navigation/dashboardsNavitation';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { joinWithDash } from '@/shared/lib/string';
import { GrowDiv } from '@/shared/ui/GrowDiv';
import { AnimationLoader } from '@/stories';
import { type RouteComponentProps } from '@reach/router';
import { ReactComponent as WidgetSvg } from 'bundles/Shared/components/layouts/dashboard/WidgetIcon.svg';
import { useMemo, type FC } from 'react';
import type { UnknownRecord } from 'type-fest';

const SettingsReportBuilderTemplateWidget = ({
  template,
  widget,
}: {
  template: ReportBuilderTemplateDto;
  widget: ReportBuilderTemplateGroupWidgetSectionDto & {
    groupId: string;
  };
}) => {
  const meta = useReportBuilderTemplateMetaContext();
  const jsonEditoButtonFeature = useJsonEditorButtonFeature();

  const dispatch = useAppDispatch();
  const [updateConfig] = useUpdateWidgetConfig(widget.widgetType);

  useGetApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdQuery(
    {
      groupId: widget.groupId,
      id: widget.id,
      templateId: template.id,
    },
  );

  const templateGroupState = useAppSelector((state) =>
    selectReportBuilderTemplateMetadataById(
      state,
      joinWithDash([template.id, widget.groupId]),
    ),
  );
  const templateStateDate = useAppSelector(
    (state) => state.reportBuilderTemplate.entities[template.id]?.date,
  );
  // XYChartWidget expects data to be in data prop
  const context = {
    assets: meta.assets,
    segments: meta.segments,
  };

  const payloadParams = useGetSelectedObjectsSearchQuery();

  const widgetSectionState = templateGroupState?.widgetsState[widget.id] ?? {};

  const {
    isLoading: isWidgetDataLoading,
    isFetching: isWidgetDataFetching,
    isError: isWidgetDataError,
    data: widgetData,
  } = usePostApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsPreviewQuery(
    {
      body: {
        config: widget.widgetConfig,
        context: {
          date: formatToDateStringForRequest(
            widgetSectionState.date ?? new Date(),
          ),
          date_from: widgetSectionState.dateFrom,
          date_to: widgetSectionState.dateTo,
          granularity: widgetSectionState.granularity,
          grouping_type: widgetSectionState.groupingType,
          period: widgetSectionState.period,
          asset_ids: payloadParams.assetIds,
          segment_ids: payloadParams.segmentIds,
        },
        widget_type: widget.widgetType,
      },
      groupId: widget.groupId,
      templateId: template.id,
    },
  );

  const { ConfigComponent, Component, title } =
    WIDGETS_CONFIG_MAP[widget.widgetType];

  const handleStateChange = (newState: UnknownRecord) => {
    dispatch(
      updateReportBuilderTemplateWidgetState({
        id: widget.id,
        groupId: widget.groupId,
        templateId: template.id,
        widgetState: newState,
      }),
    );
  };

  return (
    <ReportBuilderTemplateContext.Provider
      value={{
        kind: template.kind,
        widgetId: widget.id,
        groupId: widget.groupId,
        templateId: template.id,
      }}
    >
      <FormulasAndVariablesWorkspace>
        <DashboardLayout className="h-full">
          <DashboardLayout.Header className="h-auto bg-neutral-000">
            <DashboardLayout.Header.Title
              classes={{
                subtitle: 'flex items-center gap-tw-2 text-neutral-550',
              }}
              subtitle={template.name}
              title={widget.title}
            />
          </DashboardLayout.Header>
          <DashboardLayout.Body className="relative">
            <DashboardLayout.Grid>
              <jsonEditoButtonFeature.ButtonWrapper>
                {template.kind === 'eagle_eye' && (
                  <ReportBuilderTemplateEagleEyeObjectsFilter />
                )}
                {template.kind === 'object_level' && (
                  <>
                    <ReportBuilderTemplateObjectLevelObjectsFilter />
                    <ReportDashboardDateFilterBlock date={templateStateDate} />
                  </>
                )}
                <jsonEditoButtonFeature.Button />
              </jsonEditoButtonFeature.ButtonWrapper>
              <JsonEditorUI
                editor={<SettingsReportWidgetConfigEditor />}
                isCodeEditorOpen={jsonEditoButtonFeature.isCodeEditorOpen}
              >
                <GrowDiv>
                  <ErrorBoundary
                    fallback={
                      <WidgetErrorBoundaryFallback title={widget.title} />
                    }
                  >
                    <Component
                      widgetId={widget.id}
                      className="h-[500px]"
                      widgetSection={widget}
                      context={context}
                      mode="edit"
                      state={{
                        ...widgetSectionState,
                        // XYChartWidget expects data to be in data prop
                        assets: payloadParams.assetIds,
                        segments: payloadParams.segmentIds,
                      }}
                      onStateChange={handleStateChange}
                      settings={widget.widgetConfig}
                      onSettingsChange={(config) => updateConfig({ config })}
                      data={widgetData?.widget}
                      isLoading={isWidgetDataLoading}
                      isError={isWidgetDataError}
                      isFetching={isWidgetDataFetching}
                    />
                  </ErrorBoundary>
                </GrowDiv>
              </JsonEditorUI>
            </DashboardLayout.Grid>

            <WidgetFlyPanel className="top-tw-6">
              <WidgetFlyPanel.Header
                label="Widget: "
                title={title}
                icon={<WidgetSvg />}
              />
              {ConfigComponent && <ConfigComponent />}
            </WidgetFlyPanel>
          </DashboardLayout.Body>
        </DashboardLayout>
      </FormulasAndVariablesWorkspace>
    </ReportBuilderTemplateContext.Provider>
  );
};
export const SettingsReportBuilderTemplateWidgetPage: FC<
  RouteComponentProps
> = () => {
  const params = useReportBuilderTemplateScreenParams();
  const { data: templateData, isLoading: isTemplateLoading } =
    useGetApiSettingsReportBuilderGotenbergTemplatesByIdQuery({
      id: params.templateId,
    });
  const { data: metaData, isLoading: isMetaDataLoading } =
    useGetApiSettingsReportBuilderGotenbergTemplatesMetaQuery(
      {} as GetApiSettingsReportBuilderGotenbergTemplatesMetaApiArg,
    );
  const isLoading = isMetaDataLoading || isTemplateLoading;

  const widget = useMemo(() => {
    if (templateData == null) return null;

    const allWidgets = templateData.groups.flatMap((g) =>
      g.widgetSections.map((ws) => ({ ...ws, groupId: g.id })),
    );

    return allWidgets.find((w) => w.id === params.widgetId);
  }, [templateData]);

  if (isLoading) {
    return <AnimationLoader className="static min-h-screen" />;
  }

  if (templateData == null || widget == null || metaData == null) {
    return <NoDataOverlay title="Not found" />;
  }
  return (
    <DashboardContext.Provider
      value={{
        boardId: '',
        dashboardId: '',
        boardSlug: '',
        dashboardSlug: '',
        dashboardType: ReportDashboardType.REPORT_BUILDER_TEMPLATE,
      }}
    >
      <ReportBuilderTemplateContext.Provider
        value={{
          groupId: widget.groupId,
          widgetId: widget.id,
          kind: templateData.kind,
          templateId: templateData.id,
        }}
      >
        <ReportBuilderTemplateMetaContext.Provider value={metaData}>
          <SettingsReportBuilderTemplateWidget
            template={templateData}
            widget={widget}
          />
        </ReportBuilderTemplateMetaContext.Provider>
      </ReportBuilderTemplateContext.Provider>
    </DashboardContext.Provider>
  );
};
