import { cn } from '@/shared/lib/css/cn';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import useOutsideClick from '@/shared/lib/hooks/useOutsideClick';

import { isLeftSidebarPinned } from 'bundles/Shared/components/LeftSidebar/reducers/leftSidebarSlice';
import {
  HUGE_MODAL_Z_INDEX,
  LEFT_POSITIONED_MODAL_Z_INDEX,
} from 'bundles/Shared/constants';
import React, { PropsWithChildren } from 'react';
import { IconButton } from 'stories';
import NewReactstrapModal from './ModalOrigins/component';
import './modal.css';

interface Props {
  children: React.ReactNode;
  scrollable?: boolean;
  bodyPadding?: string;
  bodyHeight?: number | string;
  header?: React.ReactNode;
  size?:
    | 'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | 'huge'
    | '380'
    | '272'
    | '500'
    | '400'
    | '600'
    | '650'
    | '700'
    | '900'
    | '960'
    | '1000'
    | '1400'
    | '80p'
    | 'permission-lg';
  backdrop?: boolean | 'static';
  actions?: React.ReactNode;
  showDivider?: boolean;
  additionalActions?: React.ReactNode;
  classes?: {
    overlay?: string;
    wrapper?: string;
    header?: string;
    headerContent?: string;
    body?: string;
    footer?: string;
    footerAdditionalActions?: string;
    close?: string;
  };
  contentClassName?: string;
  maxHeight?: boolean;
  disabledClose?: boolean;
  zIndex?: number;
  closeOnOutsideClick?: boolean;
  centered?: boolean;
  customClose?: React.ReactNode;
  /**
   * If true, the header will be wrapped in a div with class 'modal-header'
   *
   * @default true
   * @note It will be deprecated in the future
   */
  defaultHeaderContainer?: boolean;
  toggle?: (opened: boolean) => void;
}

const SIZE_PROP_MAP = {
  '380': 'max-w-[380px]',
  '272': 'max-w-[272px]',
  '500': 'max-w-[500px]',
  '400': 'max-w-[400px]',
  '600': 'max-w-[600px]',
  '650': 'max-w-[650px]',
  '700': 'max-w-[700px]',
  '900': 'max-w-[900px]',
  '960': 'max-w-[960px]',
  '1000': 'max-w-[1000px]',
  '1400': 'max-w-[1400px]',
  '80p': 'max-w-[80%]',
  'permission-lg': 'max-w-[500px]',
  xs: 'max-w-[380px]',
  sm: 'max-w-[500px]',
  md: 'max-w-[600px]',
  lg: 'max-w-[900px]',
  xl: 'max-w-[1400px]',
} as const satisfies Partial<Record<NonNullable<Props['size']>, string>>;

export type ModalProps = Props;

const DefaultHeader = ({
  children,
  className,
}: React.PropsWithChildren<PropsWithClassName>) => (
  <h6
    className={cn(
      'weight-700 header6-bold text-[1rem] text-dark-60',
      className,
    )}
  >
    {children}
  </h6>
);

const DefaultClose = ({
  toggle,
  disabled,
  className,
}: {
  toggle: (opened: boolean) => void;
  disabled?: boolean;
} & PropsWithClassName) => (
  <IconButton
    iconName="close"
    size="l"
    onClick={() => toggle(false)}
    variant="secondary"
    disabled={disabled}
    className={className}
  />
);

export const Modal = ({
  toggle,
  children,
  scrollable = true,
  bodyPadding = '1.5rem',
  header,
  size = 'md',
  backdrop = 'static',
  actions,
  additionalActions,
  classes,
  maxHeight,
  bodyHeight,
  disabledClose,
  customClose,
  showDivider = true,
  defaultHeaderContainer = true,
  zIndex,
  closeOnOutsideClick,
  ...props
}: ModalProps) => {
  const modalRef = React.useRef<HTMLDivElement>(null);
  useOutsideClick(modalRef, () => {
    if (toggle && closeOnOutsideClick) {
      toggle(false);
    }
  });

  const leftSidebarPinned = useAppSelector(isLeftSidebarPinned);

  return (
    <NewReactstrapModal
      innerRef={modalRef}
      centered
      className={cn(
        cn(
          {
            'modal-dialog-max-height': maxHeight,
          },
          cn(SIZE_PROP_MAP[size] ?? ''),
          classes?.wrapper,
        ),
      )}
      modalClassName={cn(
        cn(
          'sre-modal-v2',
          {
            huge: size === 'huge',
            'pinned-sidebar': leftSidebarPinned,
          },
          classes?.overlay,
        ),
      )}
      zIndex={zIndex ?? (size === 'huge' ? HUGE_MODAL_Z_INDEX : undefined)}
      isOpen
      backdrop={size !== 'huge' && backdrop}
      scrollable={scrollable}
      size={size}
      {...props}
    >
      {header && defaultHeaderContainer ? (
        <>
          <div className={cn('modal-header gap-tw-4', classes?.header)}>
            <div className={cn('flex-grow', classes?.headerContent)}>
              {React.isValidElement(header) ? (
                header
              ) : (
                <DefaultHeader>{header}</DefaultHeader>
              )}
            </div>
            {customClose === undefined && (
              <DefaultClose
                toggle={toggle}
                disabled={disabledClose}
                className={classes?.close}
              />
            )}
            {customClose}
          </div>
          <hr className="m-0" />
        </>
      ) : (
        header
      )}
      <div
        className={cn('modal-body relative bg-neutral-100', classes?.body)}
        style={{
          padding: parseInt(bodyPadding) > 0 ? bodyPadding : undefined,
          height: bodyHeight,
        }}
      >
        {children}
      </div>

      {actions && (
        <>
          {showDivider && <hr className="m-0" />}
          <div className={cn('modal-footer', classes?.footer)}>{actions}</div>
        </>
      )}

      {additionalActions && (
        <div
          className={cn(
            'modal-footer-additional-action',
            classes?.footerAdditionalActions,
          )}
        >
          {additionalActions}
        </div>
      )}
    </NewReactstrapModal>
  );
};

export const SidePanel = ({
  position = 'right',
  classes,
  ...props
}: ModalProps & {
  position?: 'left' | 'right';
}) => {
  return (
    <Modal
      centered={false}
      backdrop={false}
      classes={{
        ...classes,
        body: cn('bg-neutral-100', classes?.body),
        overlay: cn(
          position,
          position === 'right' && 'left-auto',
          'max-w-[600px]',
          classes?.overlay,
        ),
      }}
      size="600"
      zIndex={position === 'left' ? LEFT_POSITIONED_MODAL_Z_INDEX : undefined}
      {...props}
    />
  );
};

Modal.Actions = ({
  children,
  className,
}: PropsWithChildren & PropsWithClassName) => (
  <div className={cn('flex w-full justify-between gap-m', className)}>
    {children}
  </div>
);

Modal.DefaultHeader = DefaultHeader;
Modal.DefaultClose = DefaultClose;

export default Modal;
