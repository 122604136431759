import { resolveIsDistributionEntry } from '@/bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/utils';
import { TransactionSource } from '@/entities/return/api/capitalInvestmentObjectsGeneratedApi';
import { CapitalInvestmentEntryBase } from 'bundles/REturn/actions/types';
import SourceTypeImage from 'bundles/Shared/components/SourceType/SourceTypeImage';
import { useModal } from '@/shared/lib/hooks/useModal';
import pluralize from 'pluralize';
import React from 'react';
import { LinkButton } from 'stories/index';
import { SplitTransactionListModal } from '@/entities/return/ui/SplitTransactionListModal';
import SplittedEntryModal from '../SplittedEntryModal';
import { DistributionNoteTooltip } from '@/bundles/REturn/components/Ownership/modals/manageCapitalInvestmentModal/Columns/DistributionNoteTooltip';

type Props = {
  entry: NonNullishFields<CapitalInvestmentEntryBase>;
  removeButton: React.ReactNode;
  createInfo: React.ReactNode;
  type: TransactionSource['kind'];
};

function mappedTransactionsLen(
  ci: NonNullishFields<CapitalInvestmentEntryBase>,
) {
  return ci.accountingTransaction.mappedTransactions.length;
}

export default function LinkedComponent({
  removeButton,
  createInfo,
  entry,
  type,
}: Props) {
  const { openModal } = useModal();
  const { accTitle, accNumber, legalEntity } =
    entry.accountingTransaction.generalLedger;
  const { source: sourceType } = legalEntity;

  const mappedLen = mappedTransactionsLen(entry);

  return (
    <div className="flex flex-col gap-tw-2">
      <div className="flex items-center justify-between">
        <div className="flex w-full justify-between">
          <div className="flex flex-col gap-tw-1">
            <p className="secondary-regular text-light-60">General Ledger</p>
            <p className="secondary-semibold text-dark-60">
              {accTitle} - {accNumber}
            </p>
          </div>
          <div className="flex items-center gap-tw-2.5">
            {sourceType && (
              <SourceTypeImage
                className="h-tw-5 w-tw-5"
                sourceType={sourceType ?? ''}
              />
            )}
            <DistributionNoteTooltip note={entry.note} classes="d-flex mr-5" />
            {removeButton}
          </div>
        </div>
      </div>
      {Boolean(mappedLen) && (
        <p>
          <span className="secondary-regular text-light-60">Split by </span>
          <button
            type="button"
            className="secondary-semibold border-none bg-transparent p-0 text-blue"
            onClick={() => {
              openModal(SplittedEntryModal, {
                entry,
                type,
              });
            }}
          >
            {mappedLen} {pluralize('transaction', mappedLen)}
            {resolveIsDistributionEntry(entry) &&
              entry.splitItems?.length > 0 && (
                <>
                  {', '}
                  <LinkButton
                    className="text-xs font-semibold leading-4"
                    onClick={() =>
                      openModal(SplitTransactionListModal, {
                        investmentEntityName: entry.investmentEntity.name,
                        date: entry.date,
                        splitItems: entry.splitItems,
                      })
                    }
                  >
                    {entry.splitItems.length} types
                  </LinkButton>
                </>
              )}
          </button>
        </p>
      )}
      {createInfo}
    </div>
  );
}
