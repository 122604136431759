import { useReportBuilderTemplateContext } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/reportBuilderTemplateContext';
import { plainPreviewReportTemplatePdf } from '@/entities/report/reportBuilder/lib/plainPreviewReportTemplatePdf';
import {
  useFetchPreviewPdf,
  usePdfBlobCreateObjectURL,
} from '@/entities/report/reportBuilder/lib/previewPdf';
import { useReportBuilderTemplateScreenParams } from '@/shared/lib/hooks/navigation/dashboardsNavitation';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import { useHostedUrl } from '@/shared/lib/hooks/useHostedUrl';
import { useMemo } from 'react';

export const useGetSelectedObjectsSearchQuery = () => {
  const templateCtx = useReportBuilderTemplateContext()!;
  const selectedObjects = useAppSelector(
    (state) => state.reportBuilderTemplate.selectedObjects,
  );
  const objectLevelAssetId = useAppSelector(
    (state) => state.reportBuilderTemplate.objectLevelAssetId,
  );

  return useMemo(() => {
    if (templateCtx.kind === 'object_level') {
      if (objectLevelAssetId == null) {
        console.error('"objectLevelAssetId" is not set');
        return {
          assetIds: [],
        };
      }
      return {
        assetIds: [objectLevelAssetId],
      };
    }

    const assetIds = selectedObjects
      .filter((o) => o.type === 'asset')
      .map((o) => o.id);

    const segmentIds = selectedObjects
      .filter((o) => o.type === 'segment')
      .map((o) => o.id);

    return {
      assetIds,
      segmentIds,
    };
  }, [selectedObjects, objectLevelAssetId]);
};
export const useGetApiReportBuilderTemplatePreviewPdfQuery = () => {
  const params = useReportBuilderTemplateScreenParams();
  const pendingRequestIds = useAppSelector(
    (s) => s.reportBuilderTemplate.pendingRequestIds,
  );

  const hostedUrl = useHostedUrl();
  const templateCtx = useReportBuilderTemplateContext();
  const templateStateDate = useAppSelector(
    (state) =>
      state.reportBuilderTemplate.entities[templateCtx?.templateId ?? '']?.date,
  );

  const payloadParams = useGetSelectedObjectsSearchQuery();

  const { isLoading, pdfBlob } = useFetchPreviewPdf({
    effectDeps: [pendingRequestIds, templateStateDate, payloadParams],
    waitIfCallback: () => {
      return (
        pendingRequestIds.length !== 0 ||
        params.templateId == null ||
        payloadParams.assetIds.length === 0
      );
    },
    fetchCallback: async () => {
      return await plainPreviewReportTemplatePdf({
        templateId: params.templateId,
        date: templateStateDate as DateString,
        renderPdfHost: hostedUrl,
        ...payloadParams,
      });
    },
  });

  const dataObjectURL = usePdfBlobCreateObjectURL(pdfBlob);

  return {
    dataObjectURL,
    data: pdfBlob,
    isLoading,
  };
};
