import { clipboardWriteText } from '@/shared/lib/browser/clipboardApi';
import { cn } from '@/shared/lib/css/cn';
import { useIsTextTruncated } from '@/shared/lib/hooks/useIsTextTruncated';
import { Formula } from 'bundles/Shared/entities/formula';
import { useRef } from 'react';
import { Icon, Tooltip } from 'stories';

export function FormulaReferenceLabel({
  reference,
  className,
}: {
  reference: Formula['reference'];
} & PropsWithClassName) {
  const ref = useRef<HTMLSpanElement>(null);
  const isTruncated = useIsTextTruncated(ref, reference);

  return (
    <Tooltip
      classes={{
        spanContainer: 'inline-flex min-w-0 max-w-full',
      }}
      mainText={reference}
      disabled={!isTruncated}
    >
      <span
        onClick={(e) => {
          e.stopPropagation();
          clipboardWriteText(reference);
        }}
        ref={ref}
        className={cn(
          'group/label secondary-semibold flex cursor-pointer gap-tw-1 self-start text-ellipsis rounded-lg px-tw-1.5 py-tw-0.5',
          'bg-neutral-050 text-neutral-500 hover:bg-neutral-150 hover:text-neutral-700 hover:shadow-s',
          className,
        )}
      >
        {reference}
        <Icon className="hidden group-hover/label:block" iconName={'copyAlt'} />
      </span>
    </Tooltip>
  );
}
