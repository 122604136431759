import {
  isSparklineTableVizConfigRow,
  isTextTableVizConfigRow,
} from './../common/ui/table/model';
import {
  AgCartesianSeriesTooltipRendererParams,
  CellClassParams,
  ColDef,
  FirstDataRenderedEvent,
  ICellRendererParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { DashboardHistoricalReviewTableWidgetDto } from 'bundles/Shared/shared/api/dashboardsGeneratedApi';
import {
  ColDefBuilder,
  resolveBackgroundAndTextColor,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/ColumnDefsBuilder';
import {
  TableVizConfigColumn,
  TableVizConfigRow,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';

import {
  convertValueByDisplayType,
  getDefaultAgGridNumberColDef,
  StyledBasicCellRendererProps,
} from '@/shared/lib/formatting/table';
import { WidgetViewMode } from 'bundles/Shared/widgets/dashboard/widgets/model';
import { sleepUntilAgGridSetsRowDataInTime } from 'bundles/Shared/widgets/dashboard/widgets/financialTableSingeDate/lib/utils';
import { buildExcelStyleId } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/useTableWidgetExportFeature';
import { WidgetTableTextCellRenderer } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/renderers/TextCellRenderer';
import { WidgetDataRowHistoryColumnModel } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/history';
import { ValueDisplayOptions } from '@/shared/lib/formatting/displayOptions';
import { formatDateRangeForWidgetColumnSubHeader } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/lib';
import { HistoricalReviewTableWidgetConfigModel } from '@/bundles/Shared/widgets/dashboard/widgets/historicalTable/model';
import { CssVar } from '@/shared/config/cssVar';

type HistoryRow = {
  value: string;
  dateFrom: DateString;
  dateTo: DateString;
};

type HistoricalTableWidgetDtoRow =
  DashboardHistoricalReviewTableWidgetDto['data'][0] & {
    key?: number;
    history?: {
      [key: number]: HistoryRow[];
    };
  };

const isLabelColumn = (columnSettings: TableVizConfigColumn) => {
  return columnSettings.key == 'label';
};

export class RowColDefBuilder<
  Column extends {
    label: string;
    key: number;
  },
> extends ColDefBuilder<Column> {
  rows: TableVizConfigRow[];
  columnsConfig: HistoricalReviewTableWidgetConfigModel['columns'];
  constructor({
    mode,
    onPinColumn,
    rows,
    columnsConfig,
  }: {
    mode: WidgetViewMode;
    rows: TableVizConfigRow[];
    columnsConfig: HistoricalReviewTableWidgetConfigModel['columns'];
    onPinColumn?: (colId: string) => unknown;
  }) {
    super({
      mode,
      onPinColumn,
    });
    this.withSubHeaderName((params) => {
      const colConfig = columnsConfig?.find(
        (c) => c.key.toString() === params.columnSettings.key,
      );
      return formatDateRangeForWidgetColumnSubHeader(
        params.column,
        colConfig,
        params.columnSettings,
      );
    });
    this.columnsConfig = columnsConfig;
    this.rows = rows ?? [];
  }

  findRowSettings(rowKey: string | undefined) {
    return this.rows.find((r) => r.key === rowKey);
  }

  buildLabelCellRenderer({ params }: { params: ICellRendererParams }) {
    const { cellRenderer } = getDefaultAgGridNumberColDef({
      type: 'text',
    });
    return {
      component: cellRenderer,
      params,
    };
  }

  getRowValueDisplayOptionsOrDefault(
    rowSettings?: TableVizConfigRow,
  ): ValueDisplayOptions {
    if (rowSettings?.value_display_options) {
      return rowSettings.value_display_options;
    }
    return {
      type: 'number',
    };
  }

  buildCustomCellRenderer({
    rowSettings,
    params,
    column,
  }: {
    rowSettings: TableVizConfigRow;
    params: ICellRendererParams;
    column?: Column;
  }) {
    const row = params.data as HistoricalTableWidgetDtoRow;
    const hasMoreThanOneNonNullEntry =
      row != null &&
      WidgetDataRowHistoryColumnModel.hasMoreThanOneNonNullEntry(
        WidgetDataRowHistoryColumnModel.getHistoryForColumn(
          row,
          column?.key ?? 0,
        ),
      );
    if (
      isSparklineTableVizConfigRow(rowSettings) &&
      !hasMoreThanOneNonNullEntry
    ) {
      const { cellRenderer } = getDefaultAgGridNumberColDef({
        type: 'number',
      });

      return {
        component: cellRenderer,
        params,
      };
    }
    if (
      isSparklineTableVizConfigRow(rowSettings) &&
      hasMoreThanOneNonNullEntry
    ) {
      const tooltipRenderer = (
        tooltipParams: AgCartesianSeriesTooltipRendererParams,
      ) => {
        const { yValue, xValue } = tooltipParams;
        const { precision } =
          this.getRowValueDisplayOptionsOrDefault(rowSettings);
        return {
          title: xValue,
          content: yValue.toFixed(precision),
        };
      };
      return {
        component: 'agSparklineCellRenderer',
        params: {
          sparklineOptions: {
            type: 'line',
            xKey: 'dateFrom',
            yKey: 'value',
            line: {
              strokeWidth: 2,
              stroke: '#085080',
            },
            tooltip: {
              container: document.body,
              renderer: tooltipRenderer,
            },
          },
        },
      };
    }
    return {
      component: WidgetTableTextCellRenderer,
      params: {
        ...params,
        config: rowSettings.cell_renderer,
      },
    };
  }

  build({
    column,
    columnSettings,
  }: {
    columnSettings: TableVizConfigColumn;
    column?: Column;
  }): ColDef {
    const { ...inheritedColDef } = super.build({
      column,
      columnSettings,
    });
    return {
      ...inheritedColDef,
      // sparkline don't use BasicCellRenderer so we need to add a border
      cellStyle: (params) => {
        const rowSettings = this.findRowSettings(params.data?.key?.toString());
        if (!rowSettings || !isSparklineTableVizConfigRow(rowSettings)) {
          return undefined;
        }
        return isSparklineTableVizConfigRow(rowSettings)
          ? {
              ...inheritedColDef.cellStyle,
              border: `1px solid ${CssVar.neutral150}`,
            }
          : undefined;
      },
      valueGetter: (params: ValueGetterParams<HistoricalTableWidgetDtoRow>) => {
        const row = params.data;
        if (!row) {
          return undefined;
        }
        const rowSettings = this.findRowSettings(row.key?.toString());
        const columnHistory =
          column != null
            ? WidgetDataRowHistoryColumnModel.getHistoryForColumn(
                row,
                column?.key ?? 0,
              )
            : [];
        if (
          rowSettings &&
          isSparklineTableVizConfigRow(rowSettings) &&
          !isLabelColumn(columnSettings)
        ) {
          const colConfig = this.columnsConfig?.find(
            (c) => c.key.toString() === columnSettings.key,
          );
          if (
            WidgetDataRowHistoryColumnModel.hasMoreThanOneNonNullEntry(
              columnHistory,
            )
          ) {
            return WidgetDataRowHistoryColumnModel.orderByDateFromAsc(
              columnHistory,
            ).map((entry) => ({
              ...entry,
              // can't get entry in tooltip renderer, so we need to format the date manually
              // TODO: move it to the tooltip renderer after ag-grid update
              dateFrom: formatDateRangeForWidgetColumnSubHeader(
                entry,
                colConfig,
              ),
              value: convertValueByDisplayType(
                entry.value,
                this.getRowValueDisplayOptionsOrDefault(rowSettings).type,
              ),
            }));
          }
          // don't render anything if there is no history and sparkline
          return null;
        }
        return row[inheritedColDef.field!];
      },
      sortable: false,
      comparator: undefined,
      headerName: isLabelColumn(columnSettings)
        ? ''
        : inheritedColDef.headerName,
      // row excel styles works only with cellClassRules
      cellClassRules: Object.fromEntries(
        this.rows.map((row) => [
          buildExcelStyleId({
            id: row.key,
            type: 'row',
          }),
          (params: CellClassParams) => {
            const currentRow = params.data as HistoricalTableWidgetDtoRow;
            return (
              currentRow.key?.toString() === row.key &&
              !isLabelColumn(columnSettings)
            );
          },
        ]),
      ),
      cellRendererParams: (
        params: ICellRendererParams<HistoricalTableWidgetDtoRow>,
      ) => {
        const row = params.data;
        const rowSettings = this.findRowSettings(row?.key?.toString());

        const inheritedParams = super
          .build({ column, columnSettings })
          .cellRendererParams(params);

        const resolveAlignment = () => {
          if (isLabelColumn(columnSettings)) {
            return 'left';
          }
          return columnSettings.align ?? 'right';
        };

        return {
          ...inheritedParams,
          ...this.buildAlignmentCellParams(resolveAlignment(), params),
          styles: {
            ...inheritedParams.styles,
            ...(isLabelColumn(columnSettings)
              ? {}
              : resolveBackgroundAndTextColor({
                  params,
                  direction: 'row',
                  shouldApplyGradient: () => !isLabelColumn(columnSettings),
                  background: rowSettings?.background,
                  comparison: rowSettings?.comparison,
                })),
            fontWeight: rowSettings?.font_weight ?? 'normal',
          },
        } satisfies StyledBasicCellRendererProps;
      },
      cellRendererSelector: (params: ICellRendererParams) => {
        if (isLabelColumn(columnSettings)) {
          return this.buildLabelCellRenderer({
            params,
          });
        }

        const row = params.data as HistoricalTableWidgetDtoRow;
        const rowSettings = this.findRowSettings(row.key?.toString());

        if (
          rowSettings &&
          (isTextTableVizConfigRow(rowSettings) ||
            isSparklineTableVizConfigRow(rowSettings))
        ) {
          return this.buildCustomCellRenderer({
            rowSettings,
            params,
            column,
          });
        }

        const { cellRenderer } = getDefaultAgGridNumberColDef(
          this.getRowValueDisplayOptionsOrDefault(rowSettings),
        );
        return {
          component: cellRenderer,
          params,
        };
      },
    };
  }
}

export const handleHistoricalWidgetFirstDataRendered = async (
  e: FirstDataRenderedEvent,
) => {
  await sleepUntilAgGridSetsRowDataInTime();
  e.columnApi.autoSizeColumn('label');
  e.api.sizeColumnsToFit();
};
