// todo: rename to NoItems
import * as React from 'react';
import { cn } from '@/shared/lib/css/cn';
import { RouteComponentProps } from '@reach/router';

interface Props {
  title: string;
  subTitle?: string;
  customDocumentsComponent?: React.ReactNode;
  className?: string;
}

function NoDataOverlay({
  title,
  subTitle = '',
  customDocumentsComponent,
  className,
}: Props & RouteComponentProps) {
  return (
    <div className={cn('no-data-overlay w-full text-center', className)}>
      <p className="light-60 header4-regular mb-s">{title}</p>
      <p className="light-60 body-regular">{subTitle}</p>
      {customDocumentsComponent && customDocumentsComponent}
    </div>
  );
}

export const NoDataOverlayUpdated = ({
  title,
  subTitle = '',
  children,
  className,
}: React.PropsWithChildren<Props & RouteComponentProps>) => (
  <div
    className={cn(
      'flex h-full w-full flex-col items-center justify-center gap-y-tw-6 px-tw-6 py-tw-12 text-center',
      className,
    )}
  >
    {/* TODO: FE-3607 space-y-2 better, but it's not working */}
    <div className="flex flex-col items-center gap-y-tw-4 text-neutral-550">
      <div className="text-xl">{title}</div>
      {subTitle && <div className="text-sm">{subTitle}</div>}
    </div>
    {children}
  </div>
);

export default NoDataOverlay;
