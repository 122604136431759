import {
  transformPeriodShiftDtoToForm,
  transformPeriodTypeDtoToForm,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/config';
import { transformEmptyObjectToUndefined } from '@/shared/lib/yup';
import { HistoricalReviewTableWidgetConfigDto } from 'bundles/Shared/shared/api/dashboardsSettingsGeneratedApi';
import {
  ADJUSTMENT_EXPRESSION_SCHEMA,
  PERIOD_SHIFT_SCHEMA,
  PERIOD_TYPE_SCHEMA,
} from 'bundles/Shared/widgets/dashboard/widgets/common/config';
import {
  NUMERIC_COLUMN_SETTINGS_FORM_SCHEMA,
  transformColumnSettingsToForm,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/form';
import { TableVizConfigColumn } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import * as yup from 'yup';

export const TIME_PERIODS = [
  'day',
  'week',
  'month',
  'quarter',
  'qtd',
  'year',
  'ytd',
] as const;

export type HistoricalTableWidgetColumnTimePeriod =
  (typeof TIME_PERIODS)[number];

const TIME_PERIOD_LABELS = {
  day: 'Day',
  week: 'Week',
  month: 'Month',
  quarter: 'Quarter',
  qtd: 'Quarter to Date',
  year: 'Year',
  ytd: 'Year to Date',
} as const satisfies Record<HistoricalTableWidgetColumnTimePeriod, string>;

export const TIME_PERIOD_OPTIONS = TIME_PERIODS.map((value) => ({
  label: TIME_PERIOD_LABELS[value],
  value,
}));

export const HISTORICAL_TABLE_WIDGET_CONFIG_COLUMN_SCHEMA =
  NUMERIC_COLUMN_SETTINGS_FORM_SCHEMA.shape({
    key: yup.number(),
    label: yup.string().required(),
    period_type: PERIOD_TYPE_SCHEMA.required(),
    period_shift: PERIOD_SHIFT_SCHEMA.nullable()
      .optional()
      .transform(transformEmptyObjectToUndefined)
      .default(undefined),
    adjustment: ADJUSTMENT_EXPRESSION_SCHEMA.nullable().optional(),
  });

export type HistoricalTableWidgetColumnForm = yup.InferType<
  typeof HISTORICAL_TABLE_WIDGET_CONFIG_COLUMN_SCHEMA
>;

export const HISTORICAL_TABLE_WIDGET_CONFIG_DEFAULT: Partial<HistoricalTableWidgetColumnForm> =
  {
    period_type: 'day',
    period_shift: {
      key: 'days',
      value: 0,
    },
  };
export type HistoricalReviewTableWidgetConfigColumn =
  HistoricalReviewTableWidgetConfigDto['columns'][number];
export const transformColumnConfigToForm = (
  columnConfig: HistoricalReviewTableWidgetConfigColumn,
  columnSettings: TableVizConfigColumn,
): HistoricalTableWidgetColumnForm => {
  return {
    key: columnConfig.key,
    label: columnConfig.label,
    period_type: transformPeriodTypeDtoToForm(columnConfig.period_type),
    period_shift: transformPeriodShiftDtoToForm(columnConfig.period_shift),
    ...transformColumnSettingsToForm(columnSettings),
  };
};
