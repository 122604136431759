import React from 'react';
import { Field, Input } from 'stories';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { FormProvider, useForm } from 'react-hook-form';
import { FieldsContainer } from 'stories/Field/Field';
import {
  AdjustmentField,
  CreateNewForm,
  EditPanelActions,
  FormulaField,
  TextVariableField,
  useUpdateListPropertyWidgetConfig,
  useUpdateWidgetConfig,
  useWidgetConfig,
  ValueDisplayOptionsFields,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { SidePanel } from 'stories/Modals/Modal/Modal';
import { OBJECT_DASHBOARD_SECTION_TYPE } from 'bundles/Shared/entities/dashboard';
import {
  HistoricalReviewTableWidgetConfigRow,
  HistoricalTableWidgetNumericRowForm,
  transformRowConfigToForm,
  getHistoricalTableResolver,
  getHistoricalTableDefaultRowForm,
} from './row.form';
import { upsertRow } from 'bundles/Shared/widgets/dashboard/widgets/historicalTable/config/updaters';
import { TableVizConfigRow } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import { FontField } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/FontField';
import { HighlightingRulesField } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/HighlightingRulesField';
import { TextDisplayStrategyField } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/TextDisplayStrategyField';
import { WidgetTableRowSettingsRowType } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/form';
interface Props extends DialogProps<CreateNewForm> {
  type: 'create' | 'edit';
  rowType: WidgetTableRowSettingsRowType;
  rowConfig?: HistoricalReviewTableWidgetConfigRow;
  rowSettings?: TableVizConfigRow;
}

export function HistoricalTableWidgetConfigRow({
  type,
  rowConfig,
  rowSettings,
  rowType,
  onClose,
  onSubmit,
}: Props) {
  const methods = useForm<HistoricalTableWidgetNumericRowForm & CreateNewForm>({
    resolver: getHistoricalTableResolver(rowType),
    mode: 'all',
    defaultValues: {
      ...getHistoricalTableDefaultRowForm(rowType),
      ...(rowConfig && rowSettings
        ? transformRowConfigToForm(rowConfig, rowSettings)
        : {}),
    },
  });
  const { control, register, handleSubmit } = methods;

  const { widget } =
    useWidgetConfig<
      typeof OBJECT_DASHBOARD_SECTION_TYPE.HISTORICAL_REVIEW_TABLE
    >();
  const [updateWidgetConfig] = useUpdateWidgetConfig(
    OBJECT_DASHBOARD_SECTION_TYPE.HISTORICAL_REVIEW_TABLE,
  );
  const { removeItem: removeRow } = useUpdateListPropertyWidgetConfig(
    OBJECT_DASHBOARD_SECTION_TYPE.HISTORICAL_REVIEW_TABLE,
    'rows',
  );

  const handleRemove = async () => {
    await removeRow(rowConfig!.key);
    onClose();
  };

  const handleSubmitClick = handleSubmit(async (values) => {
    await updateWidgetConfig({
      config: upsertRow(values, widget.widgetConfig),
    });
    onSubmit?.({
      createNew: values.createNew,
    });
  });

  return (
    <FormProvider {...methods}>
      <SidePanel
        header={type === 'create' ? 'Create' : 'Edit Row'}
        toggle={onClose}
        actions={
          <EditPanelActions
            type={type}
            onClose={onClose}
            onSubmit={handleSubmitClick}
            onRemove={handleRemove}
          />
        }
      >
        <FieldsContainer>
          <Field
            note="Will appear as the primary label in the row cell"
            labelText="Title"
          >
            <Input
              placeholder="Enter Primary Title"
              className="w-full"
              {...register('label')}
            />
          </Field>
          {rowType === 'numeric' && (
            <>
              <FormulaField control={control} name="expression" required />
              <AdjustmentField control={control} name="adjustment" />
              <ValueDisplayOptionsFields
                control={control}
                name="value_display_options"
              />
              <FontField name="font_weight" control={control} />
              <HighlightingRulesField
                fieldType="row"
                name="highlighting_rules"
                control={control}
              />
            </>
          )}
          {rowType === 'sparkline' && (
            <>
              <FormulaField control={control} name="expression" required />
              <Field
                note="The number of historical values to display"
                labelText="History Size"
              >
                <Input type="number" {...register('history_size')} />
              </Field>
              <ValueDisplayOptionsFields
                control={control}
                name="value_display_options"
              />
            </>
          )}
          {rowType === 'text' && (
            <>
              <TextVariableField control={control} name="expression" required />
              <TextDisplayStrategyField
                control={control}
                name="text_display_strategy"
              />
              <FontField name="font_weight" control={control} />
            </>
          )}
        </FieldsContainer>
      </SidePanel>
    </FormProvider>
  );
}
