import {
  UpdateWidgetTitle,
  useUpdateListPropertyWidgetConfig,
  useUpdateWidgetConfig,
  useWidgetConfig,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import React, { useState } from 'react';
import {
  IThinTabItem,
  ThinTabGroup,
} from 'stories/Tabs/ThinTabGroup/ThinTabGroup';
import { OBJECT_DASHBOARD_SECTION_TYPE } from 'bundles/Shared/entities/dashboard';
import { WidgetSettingsListLayout } from 'bundles/Shared/components/layouts/dashboard/WidgetLayout';
import Button from 'stories/Button/Button';
import { DragDropContext } from '@hello-pangea/dnd';
import { Dropdown, DropdownItem, IconButton } from '@/stories';
import { isFormulaByExpression } from 'bundles/Shared/entities/formula';
import { InspectFormulaButton } from 'bundles/Shared/features/formula/editFormula';
import { useTabs } from 'stories/Tabs/useTabs';
import { KpiCardWidgetConfigRow } from 'bundles/Shared/widgets/dashboard/widgets/kpiCard/config/row';
import { kpiCardUpdaters } from '@/bundles/Shared/widgets/dashboard/widgets/kpiCard/config/updaters';

const TABS = [
  {
    id: 'basic',
    label: 'Basic',
  },
  {
    id: 'data',
    label: 'Data',
  },
] as const satisfies readonly IThinTabItem[];

// todo DRY historical table
const DataTab = () => {
  const [modalState, setModalState] = useState<Omit<
    React.ComponentProps<typeof KpiCardWidgetConfigRow>,
    'onClose' | 'onSubmit'
  > | null>(null);
  const { widget } =
    useWidgetConfig<typeof OBJECT_DASHBOARD_SECTION_TYPE.KPI_CARD>();
  const [updateWidgetConfig] = useUpdateWidgetConfig(
    OBJECT_DASHBOARD_SECTION_TYPE.KPI_CARD,
  );
  const { kpis = [] } = widget.widgetConfig;
  const { moveItem: moveRow } = useUpdateListPropertyWidgetConfig(
    OBJECT_DASHBOARD_SECTION_TYPE.KPI_CARD,
    'kpis',
  );

  return (
    <WidgetSettingsListLayout>
      <WidgetSettingsListLayout.Header
        label={kpis.length === 0 ? 'No Items Yet' : `${kpis.length} ITEMS`}
      >
        <Dropdown
          items={
            <>
              <DropdownItem
                onClick={() => {
                  setModalState({
                    type: 'create',
                    rowType: 'text',
                  });
                }}
              >
                Text
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  setModalState({
                    type: 'create',
                    rowType: 'numeric',
                  });
                }}
              >
                Numeric
              </DropdownItem>
            </>
          }
        >
          <Button
            size="xs"
            variant="secondary"
            iconName="bottom"
            iconPosition="right"
          >
            Add
          </Button>
        </Dropdown>
      </WidgetSettingsListLayout.Header>
      <DragDropContext
        onDragEnd={({ source, destination }) => {
          if (!destination) return;
          moveRow(source.index, destination.index);
        }}
      >
        <WidgetSettingsListLayout.SortableList>
          {kpis.map((row, index) => (
            <WidgetSettingsListLayout.SortableListItem
              index={index}
              draggableId={row.key.toString()}
              key={row.key}
              listIndex={index}
              className="group/item"
            >
              <WidgetSettingsListLayout.ListItem.Text>
                {row.label}
              </WidgetSettingsListLayout.ListItem.Text>
              <div className="grow" />
              <WidgetSettingsListLayout.ListItem.ActionsPanel>
                <IconButton
                  iconName="copy"
                  onClick={() => {
                    updateWidgetConfig({
                      config: kpiCardUpdaters.cloneKpi(
                        row.key.toString(),
                        widget.widgetConfig,
                      ),
                    });
                  }}
                />
                {isFormulaByExpression(row.expression) && (
                  <InspectFormulaButton reference={row.expression} />
                )}
                <IconButton
                  onClick={() => {
                    setModalState({
                      type: 'edit',
                      rowConfig: row,
                      rowSettings: widget.widgetConfig.viz_config.kpis.find(
                        (kpi) => kpi.key.toString() === row.key.toString(),
                      ),
                      rowType: row.type,
                    });
                  }}
                  iconName="edit"
                />
                <IconButton
                  onClick={() => {
                    updateWidgetConfig({
                      config: kpiCardUpdaters.removeKpi(
                        row.key.toString(),
                        widget.widgetConfig,
                      ),
                    });
                  }}
                  iconName="trash"
                />
              </WidgetSettingsListLayout.ListItem.ActionsPanel>
            </WidgetSettingsListLayout.SortableListItem>
          ))}
        </WidgetSettingsListLayout.SortableList>
      </DragDropContext>
      {modalState != null && (
        <KpiCardWidgetConfigRow
          {...modalState}
          onClose={() => setModalState(null)}
          onSubmit={(res) => {
            setModalState(null);
            if (res?.createNew) {
              setTimeout(() => {
                setModalState({
                  type: 'create',
                  rowType: 'numeric',
                });
              });
            }
          }}
        />
      )}
    </WidgetSettingsListLayout>
  );
};
const BasicTab = () => {
  return <UpdateWidgetTitle labelText="Card Label" />;
};

export function KpiCardWidgetConfig() {
  const { tab, thinTabGroupProps } = useTabs(TABS, TABS[0]);

  return (
    <div className="flex flex-col gap-tw-4">
      <ThinTabGroup fullWidth {...thinTabGroupProps} />
      <div className="p-tw-1">
        {tab?.id === 'basic' && <BasicTab />}
        {tab?.id === 'data' && <DataTab />}
      </div>
    </div>
  );
}
