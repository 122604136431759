import { ColDef, IHeaderColumn, IHeaderGroupParams } from 'ag-grid-community';
import { useState } from 'react';
import {
  HeaderGroupComponent,
  HeaderGroupComponentProps,
} from 'bundles/Shared/components/AgGrid/Table/cellComponents/HeaderGroupComponent';
import { Icon, IconButton } from '@/stories';
import { WidgetProps } from 'bundles/Shared/widgets/dashboard/widgets/model';

export type WidgetTableHeaderGroupComponentParams = Pick<
  WidgetProps,
  'mode'
> & {
  icon?: string;
} & HeaderGroupComponentProps;

export const WidgetTableGroupHeaderGroup = (
  params: IHeaderGroupParams & WidgetTableHeaderGroupComponentParams,
) => {
  const children = params.columnGroup.getChildren() as (IHeaderColumn & {
    colDef: ColDef;
  })[];
  const { icon } =
    params.columnGroup.getColGroupDef()!.headerGroupComponentParams ?? {};
  const [expanded, setExpanded] = useState(params.columnGroup.isExpanded());

  const allColumnsSameGroupShow = children?.every(
    (col, _, self) =>
      col.colDef.columnGroupShow === self[0].colDef.columnGroupShow,
  );

  return (
    <HeaderGroupComponent {...params}>
      {icon && (
        <Icon className="order-[-1] mr-tw-1 text-white" iconName={icon} />
      )}
      {children &&
        !allColumnsSameGroupShow &&
        children.length > 1 &&
        params.mode !== 'pdf' && (
          <IconButton
            onClick={() => {
              const newExpandedValue = !params.columnGroup.isExpanded();
              params.columnApi.setColumnGroupOpened(
                params.columnGroup.getProvidedColumnGroup(),
                newExpandedValue,
              );
              setExpanded(newExpandedValue);
            }}
            size="m"
            iconName={expanded ? 'columnCollapse' : 'columnExpand'}
            variant="ghost"
          />
        )}
    </HeaderGroupComponent>
  );
};
