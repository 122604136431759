import { getDateMaskOptions } from '@/lib/maskito/date';
import { isValidDate, toDate } from '@/shared/lib/date';
import { FormatDate, formatDate } from '@/shared/lib/formatting/dates';
import Input from '@/stories/FormControls/Inputs/Input/Input';
import Popover from '@/stories/Popover/Popover';
import { useMaskito } from '@maskito/react';
import { useState } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';

export type DatePickerInputProps = Omit<
  ReactDatePickerProps,
  'value' | 'onChange' | 'inline' | 'selected'
> & {
  value?: Date | null;
  onChange?: (date: Date | null) => void;
  dateFormat?: FormatDate;
};

export const DatePickerInput = (props: DatePickerInputProps) => {
  const {
    value,
    onChange,
    minDate = new Date(1980, 1, 1),
    maxDate = new Date(2100, 1, 1),
    dateFormat = 'MM/DD/YYYY',
    ...datePickerProps
  } = props;
  const [inputValue, setInputValue] = useState(() => {
    if (!value) return '';
    return formatDate(value, dateFormat);
  });

  const handleDatePickerSelect = (date: Date | null) => {
    if (!date) {
      setInputValue('');
      onChange?.(null);
    } else {
      onChange?.(date);
      setInputValue(formatDate(date, dateFormat));
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { value } = e.target;
    setInputValue(value);

    if (!value) {
      onChange?.(null);
      return;
    }

    if (value && isValidDate(value, dateFormat)) {
      onChange?.(toDate(e.target.value, dateFormat));
    }
  };
  const maskedInputRef = useMaskito({
    options: getDateMaskOptions(minDate ?? undefined, maxDate ?? undefined),
  });

  return (
    <Popover
      appendTo={document.body}
      classes={{
        spanContainer: 'w-full flex-1',
      }}
      className="p-0"
      template={
        <ReactDatePicker
          portalId="datepicker-portal"
          selected={value}
          onChange={handleDatePickerSelect}
          minDate={minDate}
          maxDate={maxDate}
          dateFormat={dateFormat}
          wrapperClassName="w-full"
          inline
          {...datePickerProps}
        />
      }
      maxWidth="37.5rem"
      placement="top-start"
      hiddenArrow
      trigger="click"
      offset={[0, 5]}
    >
      <Input
        ref={maskedInputRef}
        placeholder="Select Date"
        leftIcon="calendar"
        value={inputValue}
        classes={{
          input:
            'bg-white shadow-z-020 border-2 border-solid border-neutral-250 h-tw-5',
        }}
        onInput={handleInputChange}
        autoComplete="off"
      />
    </Popover>
  );
};
