import { ERPSystem } from '@/entities/erpsystem/model';
import { cn } from '@/shared/lib/css/cn';
import { ComponentProps, FC, useEffect, useRef, useState } from 'react';

const useLazySvgImport = (name: string) => {
  const importRef = useRef<FC<ComponentProps<'svg'>>>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const importIcon = async () => {
      try {
        setLoading(true);

        importRef.current = (
          await import(`@/entities/erpsystem/shared/assets/${name}.svg`)
        ).ReactComponent;
      } catch {
        // Default with other icon
        importRef.current = (
          await import(`@/entities/erpsystem/shared/assets/symmetre.svg`)
        ).ReactComponent;
      } finally {
        setLoading(false);
      }
    };
    importIcon();
  }, [name]);

  return {
    loading,
    Svg: importRef.current,
  };
};

export type IERPSystemIcon = {
  system: ERPSystem;
} & ComponentProps<'svg'>;

export const ERPSystemIcon = ({
  system,
  className,
  ...props
}: IERPSystemIcon) => {
  const { Svg } = useLazySvgImport(system);

  if (!Svg) return <div className="size-tw-4" />;

  return <Svg {...props} className={cn('size-tw-4', className)} />;
};
