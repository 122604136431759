import FireIcon from '@/bundles/FireStation/components/FireIcon/FireIcon';
import {
  PageParamsProvider,
  PageParamsSearch,
} from '@/bundles/Shared/components/pageParams';
import {
  FormulaCard,
  FormulaInUse,
  filterFormulaOrVariableByTags,
} from '@/bundles/Shared/entities/formula';
import { LegalEntitiesCounter } from '@/bundles/Shared/entities/legalEntity/ui/LegalEntityCounter';
import {
  GetApiReportManualVariablesParams,
  addManualVariableReferencePrefix,
  useReportManualVariableQuery,
} from '@/entities/report/manualVariable';
import {
  CreateManualVariable,
  FilterByFire,
} from '@/features/report/manualVariable';
import { currentUserIsSreAdmin } from '@/lib/permissions';
import { useNavigateToFirst } from '@/shared/lib/hooks/useNavigateToFirst';
import { ROUTES_ROOT, generateUrl } from '@/shared/lib/hooks/useNavigation';
import { useVirtuosoOverlayScrollbarsScroller } from '@/shared/lib/virtuoso';
import { ItemTableCounter } from '@/shared/ui/ItemTableCounter';
import { ResizablePanel } from '@/shared/ui/Resizable';
import { ManageTagsButton } from '@/widgets/report/manageTags';
import {
  EntityItemList,
  SettingsReportManualVariables,
} from '@/widgets/report/manualVariable/settings';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { UntaggedFormulaTag } from 'bundles/Shared/entities/formula/config';
import { EntityTag, TAG_ENTITIES } from 'bundles/Shared/entities/tag';
import { EntityTagFilterDropdown } from 'bundles/Shared/features/tag/filter';
import { FormulaPopover } from 'bundles/Shared/widgets/formula/panel/ui/FormulaAndVariablesPanel';
import { FC, useCallback, useMemo, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';

export const ManualVariablesPage: FC<RouteComponentProps> = () => {
  const [pageParams, setPageParams] = useState<
    Required<GetApiReportManualVariablesParams>
  >({
    query: '',
    filterByNotConfigured: false,
  });
  const [selectedTags, setSelectedTags] = useState<
    (EntityTag | UntaggedFormulaTag)[]
  >([]);
  const { virtuosoProps, rootRef } = useVirtuosoOverlayScrollbarsScroller();
  const { manualVariables, meta, isLoading, isFetching } =
    useReportManualVariableQuery(pageParams);
  const navigate = useNavigate();

  const { notFullyConfiguredVariables } = meta;

  const handleFilterByNotConfiguredChange = (val: boolean) => {
    setPageParams((prev) => ({
      ...prev,
      filterByNotConfigured: val,
    }));
  };

  const toggleFilterByNotConfigured = () => {
    handleFilterByNotConfiguredChange(!pageParams.filterByNotConfigured);
  };

  const filteredManualVariables = useMemo(() => {
    return manualVariables.filter((manVar) => {
      return (
        selectedTags.length === 0 ||
        filterFormulaOrVariableByTags(manVar, selectedTags)
      );
    });
  }, [manualVariables, selectedTags]);

  const items = filteredManualVariables;

  const { idFromURL: manualVariableSlugFromURL } = useNavigateToFirst({
    items,
    matchParamKey: 'manualVariableSlug',
    path: ROUTES_ROOT.settings.report.manualVariables.manualVariable.fullPath,
    idKey: 'slug',
  });

  const selectedManVar = useMemo(() => {
    return manualVariables.find(
      ({ slug }) => slug === manualVariableSlugFromURL,
    );
  }, [items, manualVariableSlugFromURL]);

  const renderVariableItem = useCallback(
    (index: number) => {
      const v = items[index];
      return (
        <FormulaCard
          className="w-full !cursor-pointer"
          reference={addManualVariableReferencePrefix(v.name)}
          label={v.label}
          key={v.id}
          description={v.description}
          tags={v.tags}
          labelInfo={
            <div className="flex items-center gap-tw-2">
              {v.notConfiguredLegalEntitiesCount > 0 && (
                <FireIcon priority="high" />
              )}
              {v.referencedInEntities.length > 0 && (
                <FormulaPopover
                  formula={{
                    referencedInEntities: v.referencedInEntities,
                    label: v.label,
                    invalidReferences: [],
                  }}
                >
                  <div className="flex items-center gap-tw-1">
                    <FormulaInUse.UsedSvg
                      viewBox="4 4 16 16"
                      className="size-tw-3"
                      used
                    />
                    {v.referencedInEntities.length > 1 && (
                      <span className={'secondary-regular text-neutral-500'}>
                        {v.referencedInEntities.length}
                      </span>
                    )}
                  </div>
                </FormulaPopover>
              )}
              <LegalEntitiesCounter
                legalEntityCount={v.configuredLegalEntitiesCount}
              />
            </div>
          }
          selected={v.slug === manualVariableSlugFromURL}
          buttonProps={{
            onClick: () => {
              navigate(
                generateUrl(
                  ROUTES_ROOT.settings.report.manualVariables.manualVariable
                    .fullPath,
                  {
                    pathParams: {
                      manualVariableSlug: v.slug,
                    },
                  },
                ),
              );
            },
          }}
        />
      );
    },
    [items, manualVariableSlugFromURL, navigate],
  );

  return (
    <PageParamsProvider pageParams={pageParams} setPageParams={setPageParams}>
      <div className="flex">
        <ResizablePanel.Group
          autoSaveId="manual-variables-panel"
          direction="horizontal"
        >
          <ResizablePanel
            defaultSize={0}
            className="min-w-[320px] max-w-[800px] bg-white"
          >
            <EntityItemList.Wrapper>
              <EntityItemList.Header>
                <div className="flex items-center justify-between">
                  <EntityItemList.Title>Manual Variables</EntityItemList.Title>

                  {currentUserIsSreAdmin() && <CreateManualVariable />}
                </div>
                <PageParamsSearch
                  suggestions={['Variable Name', 'Reference']}
                  inputClassName="!bg-transparent"
                />
                <EntityTagFilterDropdown
                  onChange={setSelectedTags}
                  value={selectedTags}
                  entity={TAG_ENTITIES.MANUAL_VARIABLE}
                  panel={
                    currentUserIsSreAdmin() && (
                      <ManageTagsButton entity={TAG_ENTITIES.MANUAL_VARIABLE} />
                    )
                  }
                />
                <div className="flex items-center justify-between">
                  <ItemTableCounter count={items.length} word="Variable" />
                  {notFullyConfiguredVariables > 0 && (
                    <FilterByFire
                      onClick={() => toggleFilterByNotConfigured()}
                      onClose={() => handleFilterByNotConfiguredChange(false)}
                      count={notFullyConfiguredVariables}
                      filtered={pageParams.filterByNotConfigured}
                    />
                  )}
                </div>
              </EntityItemList.Header>

              <EntityItemList
                className="grow"
                isFetching={isFetching}
                isLoading={isLoading}
                ref={rootRef}
              >
                {items.length > 0 && (
                  <Virtuoso
                    {...virtuosoProps}
                    totalCount={items.length}
                    itemContent={renderVariableItem}
                  />
                )}
              </EntityItemList>
            </EntityItemList.Wrapper>
          </ResizablePanel>
          <ResizablePanel.Handle withHandle />
          <ResizablePanel>
            {selectedManVar && (
              <SettingsReportManualVariables
                path={
                  ROUTES_ROOT.settings.report.manualVariables.manualVariable
                    .fullPath
                }
                manualVariable={selectedManVar}
              />
            )}
          </ResizablePanel>
        </ResizablePanel.Group>
      </div>
    </PageParamsProvider>
  );
};
