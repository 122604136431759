import React from 'react';
import { cn } from '@/shared/lib/css/cn';
import styles from './SelectableItem.module.scss';
import Icon from 'stories/Icon/Icon';
import IconButton from 'stories/IconButton/IconButton';
import SecondaryDropdown from 'bundles/Shared/components/SecondaryDropdown';

interface Props extends PropsWithClassName {
  id: number | string | null;
  text: React.ReactNode;
  onSelectedChange?: (id: string | null) => void;
  actions?: React.ComponentProps<typeof SecondaryDropdown>['items'];
  onClick?: (id: number | string | null) => void;
  selected?: boolean;
}

export const SelectableItem = ({
  id,
  text,
  actions,
  onClick,
  selected = false,
  onSelectedChange,
  className,
}: Props) => (
  <div className={cn(styles.label, 'inline-regular', className)}>
    <div
      className="w-full"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick?.(id);
      }}
    >
      {text}
    </div>
    <div className="flex items-center gap-tw-1">
      {selected && (
        <div className={styles.selectedItem}>
          <Icon iconName="checkSmall" />
        </div>
      )}
      {actions && (
        <SecondaryDropdown
          // TODO: should we make it as default or variant?
          className="sre-dropdown-v2"
          items={actions}
          renderButton={() => (
            <IconButton
              iconName="more"
              className={cn(styles.actionsButton, styles.actionsIcon)}
            />
          )}
          onSelectedChange={(itemId) => {
            onSelectedChange?.(itemId);
          }}
        />
      )}
    </div>
  </div>
);
