import React, { ComponentProps } from 'react';
import { cn } from '@/shared/lib/css/cn';
import Icon from '../../Icon/Icon';
import './dropdownItem.scss';
import { IconsId } from 'types/sre-icons';
import Tooltip from '@/stories/Tooltip/Tooltip';
import { OverlaySpinner } from '@/stories';

interface Props {
  onClick: () => void;
  className?: string;
  active?: boolean;
  activeClassName?: string;
  disabled?: boolean;
  tag?: 'div' | 'a';
  iconName?: IconsId;
  iconClasses?: string;
  tooltipProps?: ComponentProps<typeof Tooltip>;
  classes?: {
    text?: string;
  };
  loading?: boolean;
}

export const DropdownItem = ({
  tag = 'div',
  className,
  iconName,
  iconClasses,
  children,
  active,
  activeClassName,
  disabled,
  tooltipProps = {
    disabled: true,
  },
  classes,
  loading,
  ...props
}: React.PropsWithChildren<Props>) => {
  const Tag = tag;
  const rawTag = (
    <Tag
      className={cn(
        'sre-dropdown-item__content',
        className,
        {
          _disabled: disabled,
          _active: active,
        },
        active && activeClassName,
      )}
      {...props}
    >
      {iconName && (
        <Icon
          className={cn('sre-dropdown-item__icon', iconClasses)}
          iconName={iconName}
        />
      )}
      <span className={cn('sre-dropdown-item__text', classes?.text)}>
        {children}
      </span>
      {loading && (
        <OverlaySpinner size="small" overlayClasses="bg-transparent" inline />
      )}
    </Tag>
  );
  return (
    <li className="sre-dropdown-item">
      {tooltipProps.disabled ? (
        rawTag
      ) : (
        <Tooltip classes={{ spanContainer: 'flex' }} {...tooltipProps}>
          {rawTag}
        </Tooltip>
      )}
    </li>
  );
};

export default DropdownItem;
