import {
  defaultHistoryValueGetter,
  WidgetDataRowHistoryColumnModel,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/history';
import { HistoryDataTooltip } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/HistoryDataTooltip';
import { TableVizConfigTextDisplayStrategy } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import { WidgetDataRowHistoryEntry } from '@/bundles/Shared/widgets/dashboard/widgets/model';
import { DEFAULT_NUMBER_FALLBACK } from '@/shared/lib/formatting/number';
import {
  defaultValueFallbackCondition,
  StyledBasicCellRenderer,
  StyledBasicCellRendererProps,
} from '@/shared/lib/formatting/table';
import { Icon } from '@/stories';
import { ICellRendererParams } from 'ag-grid-community';

export type WidgetTableTextCellRendererConfig = {
  type: 'text';
  params: WidgetTableTextCellRendererParams;
};

export type WidgetTableTextCellRendererParams = {
  variant: TableVizConfigTextDisplayStrategy;
};

type TextCellRendererParams = StyledBasicCellRendererProps &
  ICellRendererParams & {
    config: WidgetTableTextCellRendererConfig;
    historyValueGetter: (
      params: ICellRendererParams,
    ) => WidgetDataRowHistoryEntry[];
    fallbackValue?: string;
  };

export const WidgetTextRenderer = (
  props: WidgetTableTextCellRendererParams & {
    value: string | number | null | undefined;
    history: WidgetDataRowHistoryEntry[];
    fallbackValue?: string;
    hideInfoIcon?: boolean;
  },
) => {
  const {
    fallbackValue = DEFAULT_NUMBER_FALLBACK,
    value,
    history,
    variant,
    hideInfoIcon,
  } = props;

  const hasHistory =
    history != null &&
    WidgetDataRowHistoryColumnModel.hasMoreThanOneEntry(history);

  const resolveValue = () => {
    if (defaultValueFallbackCondition(value)) {
      return fallbackValue;
    }
    if (variant === 'text_default' && hasHistory) {
      return WidgetDataRowHistoryColumnModel.orderByDateFromDesc(history)
        .map((entry) => WidgetDataRowHistoryColumnModel.entryFormat(entry))
        .join(', ');
    }

    return value;
  };

  if (hasHistory) {
    return (
      <HistoryDataTooltip
        classes={{
          spanContainer: 'inline-flex items-center min-w-0 max-w-full',
        }}
        history={history}
      >
        <span className="text-ellipsis">{resolveValue()}</span>
        {variant === 'text_current_value' && !hideInfoIcon && (
          <Icon
            className="relative top-1 ml-tw-2  text-neutral-500"
            iconName="info"
          />
        )}
      </HistoryDataTooltip>
    );
  }

  return resolveValue();
};

export const WidgetTableTextCellRenderer = (params: TextCellRendererParams) => {
  const {
    config,
    historyValueGetter = defaultHistoryValueGetter,
    fallbackValue,
  } = params;
  const { variant } = config?.params ?? {};
  const history = historyValueGetter(params);

  return (
    <StyledBasicCellRenderer {...params}>
      <WidgetTextRenderer
        value={params.value}
        history={history}
        variant={variant}
        hideInfoIcon={params.context.mode === 'pdf'}
        fallbackValue={fallbackValue}
      />
    </StyledBasicCellRenderer>
  );
};
