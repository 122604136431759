/* eslint-disable jsx-a11y/anchor-is-valid */
import FeedMessageAvatar from 'bundles/InvestmentObjects/components/Overview/Posts/FeedMessageAvatar';
import {
  FeedMessage,
  useDeleteFeedMessageMutation,
} from 'bundles/InvestmentObjects/components/Overview/Posts/api/feedMessagesApi';
import canManageObject from 'lib/InvestmentObject';
import { formatUnixDate } from '@/shared/lib/formatting/dates';
import { useModal } from '@/shared/lib/hooks/useModal';
import { useState } from 'react';
import { Button, Dropdown, DropdownItem, Icon } from 'stories';
import FeedMessageFormModal from './FeedMessageFormModal';
import EmailCampaignModal from './Post/EmailCampaignModal';

function PostHeader({
  feedMessage,
  setCurrentFeedMessage,
  setSendEmailModalOpened,
  updateFeedMessagesList,
  deleteFeedMessageFromList,
}: {
  feedMessage: FeedMessage;
  setCurrentFeedMessage: (feedMessage: FeedMessage) => void;
  setSendEmailModalOpened: (opened: boolean) => void;
  updateFeedMessagesList?: (feedMessage: FeedMessage) => void;
  deleteFeedMessageFromList?: (id: FeedMessage['id']) => void;
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [isEmailCampaignModalOpen, setIsEmailCampaignModalOpen] =
    useState(false);
  const [deleteFeedMessage] = useDeleteFeedMessageMutation();

  const { confirm } = useModal();

  const investmentEntity = feedMessage.feedMessageable;
  const canManage =
    canManageObject(investmentEntity) &&
    updateFeedMessagesList &&
    deleteFeedMessageFromList;
  const totalEmailsCount = feedMessage.emailMessages.length;

  const archiveMessage = async () => {
    const result = await confirm({
      title: 'Remove feed message',
      subtitle: 'Are you sure you want to remove feed message?',
    });

    if (result) {
      const response = await deleteFeedMessage({
        id: feedMessage.id,
      });
      if (response) deleteFeedMessageFromList(feedMessage.id);
    }
  };

  return (
    <div className="inline-regular flex items-center justify-between">
      <div className="hidden gap-tw-2 lg:flex">
        <FeedMessageAvatar text={feedMessage.user.fullName} />
        <div className="flex flex-col">
          <span className="body-semibold text-neutral-900">
            {feedMessage.user.fullName}
          </span>
          <span className="inline-regular text-neutral-500">
            <div className="hidden lg:inline">
              {formatUnixDate(feedMessage.createdAt, 'LL [at] LT')}
            </div>
          </span>
        </div>
      </div>
      <div className="flex items-center">
        {canManage && totalEmailsCount > 0 && (
          <Button
            variant="secondary"
            size="xs"
            onClick={() => {
              setIsEmailCampaignModalOpen(true);
            }}
          >
            <Icon iconName="email" />
            <span className="label-regular">View Campaign</span>
          </Button>
        )}
        {canManage &&
          totalEmailsCount === 0 &&
          investmentEntity.aasmState !== 'draft' && (
            <Button
              variant="secondary"
              size="xs"
              onClick={() => {
                setCurrentFeedMessage(feedMessage);
                setSendEmailModalOpened(true);
              }}
            >
              <Icon iconName="sendTelegram" />
              <span className="label-regular">Notify</span>
            </Button>
          )}
        {canManage && (
          <Dropdown
            items={
              <>
                <DropdownItem onClick={() => setIsEditing(true)}>
                  Edit
                </DropdownItem>
                <DropdownItem onClick={() => archiveMessage()}>
                  Remove
                </DropdownItem>
              </>
            }
          >
            <Icon iconName="more" className="ml-s" />
          </Dropdown>
        )}
        {isEmailCampaignModalOpen && (
          <EmailCampaignModal
            feedMessageId={feedMessage.id}
            headerSubtitle={investmentEntity.name}
            onClose={() => setIsEmailCampaignModalOpen(false)}
          />
        )}
        {isEditing && (
          <FeedMessageFormModal
            onClose={() => setIsEditing(false)}
            updateFeedMessagesList={updateFeedMessagesList}
            object={investmentEntity}
            feedMessage={feedMessage}
          />
        )}
      </div>
    </div>
  );
}

export default PostHeader;
