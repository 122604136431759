import React from 'react';
import { cn } from '@/shared/lib/css/cn';

import { FormulaInvalidReference } from 'bundles/Shared/entities/formula';

const CardList = ({
  className,
  children,
}: React.PropsWithChildren<PropsWithClassName>) => {
  return (
    <div
      className={cn(
        'border-1 col-span-2 !rounded-lg border-solid border-neutral-100',
        className,
      )}
    >
      {children}
    </div>
  );
};

const Card = ({
  children,
  error,
  replaced,
  index,
}: React.PropsWithChildren<
  {
    index: number;
    error: FormulaInvalidReference;
    replaced?: boolean;
  } & PropsWithClassName
>) => (
  <div className="group/item grid h-[40px] grid-cols-[min-content,auto,min-content] items-center gap-tw-2 p-tw-2 transition-all hover:bg-neutral-100">
    <span
      className={cn(
        'secondary-semibold flex h-[16px] w-[16px] items-center justify-center rounded-full',
        'bg-danger-000 text-danger-080 transition-all group-hover/item:bg-danger-080 group-hover/item:text-neutral-000',
      )}
    >
      {index + 1}
    </span>
    <span
      className={cn(
        'secondary-semibold text-neutral-850',
        replaced && 'line-through',
      )}
    >
      {error.reference}: Missing Expression
    </span>
    {children}
  </div>
);

export const FormulaErrors = () => null;

FormulaErrors.CardList = CardList;
FormulaErrors.Card = Card;
