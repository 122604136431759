import { Icon, IconButton } from '@/stories';
import type { ComponentProps } from 'react';

export const FilterIconButton = (
  props: Omit<ComponentProps<typeof IconButton>, 'iconName' | 'variant'>,
) => {
  return (
    <IconButton iconName="columnExpand" variant="secondary" {...props}>
      <Icon
        style={{
          transform: 'rotate(90deg)',
        }}
        iconName="columnExpand"
      />
    </IconButton>
  );
};
