import { useCapitalDetailRows } from '../hooks/useCapitalDetailRows';
import { cn } from '@/shared/lib/css/cn';
import { ReactComponent as CornerArrowSvg } from 'images/corner-arrow.svg';
import { GetApiPortfolioApiResponse } from 'bundles/Shared/shared/api/portfolioGeneratedApi';
import { SymmetreAgGridTable } from '@/bundles/Shared/components/AgGrid/Table/SymmetreAgGridTable';
import { useMemo, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  ColDef,
  ICellRendererParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { CurrencyCellRenderer } from '@/bundles/Shared/components/AgGrid/Table/cellComponents/CurrencyCellRenderer';
import { getEmptyCellRendererParams } from '@/bundles/Shared/components/AgGrid/Table/utils/getCurrencyCellRendererProps';
import { HeaderComponent } from '@/bundles/Shared/components/AgGrid/Table/cellComponents/HeaderComponent';
import { InvestmentEntityClassesBadges } from '@/bundles/REturn/components/Ownership/ownershipTable/formatters/InvestmentEntityColumn';
import { BasicCellRenderer } from '@/bundles/Shared/components/AgGrid/Table/cellComponents/BasicCellRenderer';

interface Props {
  capitalDetails: GetApiPortfolioApiResponse['capitalDetails'];
  accruedEnabled: boolean;
}

export function CapitalDetailsTable({ capitalDetails, accruedEnabled }: Props) {
  const groupedRows = useCapitalDetailRows(capitalDetails.rows);

  const gridRef = useRef<AgGridReact>(null);

  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      cellRendererParams: getEmptyCellRendererParams,
      headerComponent: HeaderComponent,
      headerComponentParams: {
        classes: {
          inner: '!justify-center text-wrap text-center font-medium',
        },
      },
      suppressMovable: true,
      suppressMenu: true,
      resizable: true,
      suppressSizeToFit: true,
      suppressCellFocus: true,
    }),
    [],
  );

  const getCellClasses = (params: ICellRendererParams) => {
    return cn('!bg-neutral-000 border-neutral-200', {
      '!bg-neutral-100 font-bold': params.data.isFooter,
      '!bg-info-000': params.colDef?.field === 'capitalBalance',
      '!bg-violet-000': params.colDef?.field === 'accruedEndingBalanceAmount',
    });
  };

  const currencyCellRendererParams = (params: ICellRendererParams) => {
    return {
      multiplier:
        params.colDef?.field === 'accruedEndingBalanceAmount' ? -1 : 1,
      formatterParams: {
        fallbackValue: '-',
        toLocalStringOptions: {
          minimumFractionDigits: 0,
        },
      },
      classes: {
        wrapper: getCellClasses(params),
      },
    };
  };

  const onCellClicked = (params: ICellRendererParams) => {
    if (!params.data.onClick) return;

    params.data.onClick(params);
  };

  const ClickableCurrencyCellRenderer = (
    params: ICellRendererParams & { multiplier?: number },
  ) => {
    let isClickable;
    isClickable =
      params.value !== 0 && params.data.onClick && !params.data.isFooter;
    if (
      params?.colDef?.field == 'accruedEndingBalanceAmount' &&
      params.data.accruedStatus !== 'live'
    ) {
      isClickable = false;
    }

    return (
      <span className={cn({ 'cursor-pointer': isClickable })}>
        {isClickable && (
          <CornerArrowSvg className="absolute right-[2px] top-[2.5px] z-10 text-info-055 group-hover:text-info-070" />
        )}
        {typeof params.value === 'number' ? (
          <CurrencyCellRenderer
            {...params}
            {...currencyCellRendererParams}
            value={params.value * (params.multiplier ?? 1)}
          />
        ) : (
          <BasicCellRenderer {...params}>
            <div className="w-full text-center">{params.value}</div>
          </BasicCellRenderer>
        )}
      </span>
    );
  };

  const colDefs = (type: 'assets' | 'funds') =>
    useMemo(
      () => [
        {
          field: 'investmentObjectName',
          headerName: type,
          pinned: 'left',
          headerComponentParams: {
            classes: {
              inner: '!justify-start',
            },
          },
          width: 200,
          cellRenderer: (params: ICellRendererParams) => (
            <BasicCellRenderer
              classes={{
                wrapper: getCellClasses(params),
              }}
            >
              <div className="w-full text-left">{params.value}</div>
            </BasicCellRenderer>
          ),
        },
        {
          field: 'investmentEntityName',
          headerName: 'Investment Entity',
          cellRenderer: (params: ICellRendererParams) => (
            <BasicCellRenderer
              classes={{
                wrapper: getCellClasses(params),
              }}
            >
              <div className="flex w-full justify-between">
                {params.value}
                <div className="flex gap-xs">
                  <InvestmentEntityClassesBadges
                    capitalInvestmentClasses={params.data?.investmentClasses}
                  />
                </div>
              </div>
            </BasicCellRenderer>
          ),
          headerComponentParams: {
            classes: {
              inner: '!justify-start text-wrap font-medium',
            },
          },
          minWidth: 190,
        },
        {
          field: 'yearInvested',
          headerName: 'Year',
          headerComponentParams: {
            classes: {
              inner: '!justify-center',
            },
          },
          minWidth: 70,
          cellRenderer: (params: ICellRendererParams) => (
            <BasicCellRenderer
              classes={{
                wrapper: getCellClasses(params),
              }}
            >
              <div className="w-full text-center">{params.value}</div>
            </BasicCellRenderer>
          ),
        },
        {
          field: 'capitalInvested',
          headerName: 'Capital Invested',
          minWidth: 110,
          cellRenderer: ClickableCurrencyCellRenderer,
          cellRendererParams: currencyCellRendererParams,
          onCellClicked: (params: ICellRendererParams) => {
            params.data.onClick(params);
          },
        },
        {
          field: 'groupedDistributions.return_of_capital.totalAmount',
          headerName: <div>Capital Returned</div>,
          minWidth: 110,
          cellRenderer: ClickableCurrencyCellRenderer,
          cellRendererParams: currencyCellRendererParams,
          valueGetter: (params: ValueGetterParams) => {
            return (
              params.data?.groupedDistributions?.return_of_capital
                ?.totalAmount ?? 0
            );
          },
          onCellClicked,
        },
        {
          field: 'capitalBalance',
          headerName: 'Capital Balance',
          minWidth: 110,
          cellRenderer: CurrencyCellRenderer,
          cellRendererParams: (params: ICellRendererParams) => ({
            ...currencyCellRendererParams(params),
            classes: {
              wrapper: getCellClasses(params),
            },
          }),
          valueGetter: (params: ValueGetterParams) => {
            return params.data?.capitalBalance ?? 0;
          },
          onCellClicked,
        },
        {
          field: 'groupedDistributions.preferred.totalAmount',
          headerName: 'Pref. Paid',
          minWidth: 110,
          cellRenderer: ClickableCurrencyCellRenderer,
          cellRendererParams: currencyCellRendererParams,
          valueGetter: (params: ValueGetterParams) =>
            params.data?.groupedDistributions?.preferred?.totalAmount ?? 0,
          onCellClicked,
        },
        {
          field: 'groupedDistributions.excess_cash.totalAmount',
          headerName: 'Excess Cash',
          minWidth: 110,
          cellRenderer: ClickableCurrencyCellRenderer,
          cellRendererParams: currencyCellRendererParams,
          valueGetter: (params: ValueGetterParams) => {
            return (
              params.data?.groupedDistributions?.excess_cash?.totalAmount ?? 0
            );
          },
          onCellClicked,
        },
        {
          field: 'capitalDistributed',
          headerName: 'Total Returned',
          pinned: 'right',
          width: 110,
          cellRenderer: ClickableCurrencyCellRenderer,
          cellRendererParams: currencyCellRendererParams,
          onCellClicked,
        },
        {
          field: 'accruedEndingBalanceAmount',
          headerName: 'Accrued Pref.',
          pinned: 'right',
          width: 130,
          cellRenderer: ClickableCurrencyCellRenderer,
          cellRendererParams: currencyCellRendererParams,
          onCellClicked,
          valueGetter: (params: ValueGetterParams) => {
            if (params.data.accruedStatus === 'not_applicable') return 'N/A';
            if (params.data.accruedStatus === 'in_process') return 'In Process';

            return params.data.accruedEndingBalanceAmount;
          },
          hide: !accruedEnabled,
        },
        {
          field: 'asOfDate',
          headerName: 'As of date',
          pinned: 'right',
          width: 130,
          cellRenderer: (params: ICellRendererParams) => (
            <BasicCellRenderer
              classes={{
                wrapper: getCellClasses(params),
              }}
            >
              <div className="w-full text-center">{params.value}</div>
            </BasicCellRenderer>
          ),
          cellRendererParams: currencyCellRendererParams,
        },
      ],
      [],
    );

  return (
    <div className="rounded-[16px] bg-white p-m">
      <div className="mb-s flex items-center gap-m">
        <p className="dark-60 header6-bold">Capital Details</p>
      </div>

      {Object.keys(groupedRows).map((key: 'assets' | 'funds') => {
        const rowData = groupedRows[key];

        if (rowData.length === 0) return null;

        return (
          <div className="mb-m overflow-hidden rounded-lg" key={key}>
            <SymmetreAgGridTable
              getRowId={(params) => params?.data?.capitalInvestmentId}
              ref={gridRef}
              rowData={rowData}
              columnDefs={colDefs(key)}
              treeData={false}
              defaultColDef={defaultColDef}
              suppressCellFocus
              suppressRowHoverHighlight
              editable={false}
              domLayout="autoHeight"
              headerHeight={40}
              groupIncludeTotalFooter={false}
            />
          </div>
        );
      })}
    </div>
  );
}
