import { PERIOD_SHIFT_SCHEMA } from '@/bundles/Shared/widgets/dashboard/widgets/common/config';
import { GroupingTypeField } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/GroupingTypeFIeld';
import { RecapPageField } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/RecapPageField';
import { commonTableWidgetUpdaters } from '@/bundles/Shared/widgets/dashboard/widgets/common/updaters';
import { Field } from '@/stories';
import { yupResolver } from '@hookform/resolvers/yup';
import { EAGLE_EYE_DASHBOARD_SECTION_TYPE } from 'bundles/Shared/entities/dashboard';
import {
  DefaultColumnsTab,
  PeriodShiftField,
  UpdateWidgetTitle,
  useUpdateWidgetConfigEffect,
  useWidgetConfig,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { HideTotalField } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/HideTotalField';
import { PeriodTypesField } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/PeriodTypesField';
import {
  DATE_PICKER_SETTINGS_OPTIONS,
  DEFAULT_KPI_TABLE_WIDGET_CONFIG_MONTH,
  DEFAULT_KPI_TABLE_WIDGET_CONFIG_WEEK,
  KpiTableWidgetConfigColumnPanel,
  KpiTableWidgetConfigForm,
  SUPPORTED_KPI_TABLE_PERIOD_TYPES_OPTIONS,
  transformKpiTableWidgetConfigToForm,
  updateDefaultOptions,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiTable';
import React from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { FieldsContainer } from 'stories/Field/Field';
import { RadioGroup } from 'stories/RadioButton/RadioGroup';
import ThinTabGroup, {
  IThinTabItem,
} from 'stories/Tabs/ThinTabGroup/ThinTabGroup';
import { useTabs } from 'stories/Tabs/useTabs';

const TABS = [
  {
    id: 'basic',
    label: 'Basic',
  },
  {
    id: 'columns',
    label: 'Columns',
  },
] as const satisfies readonly IThinTabItem[];

const MonthFields = () => {
  const { control } = useFormContext<KpiTableWidgetConfigForm>();

  return (
    <>
      <PeriodTypesField
        control={control}
        options={SUPPORTED_KPI_TABLE_PERIOD_TYPES_OPTIONS}
      />
    </>
  );
};

const BasicTab = () => {
  const { widget } =
    useWidgetConfig<typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE>();
  const methods = useForm<KpiTableWidgetConfigForm>({
    resolver: yupResolver(PERIOD_SHIFT_SCHEMA),
    defaultValues: {
      ...(widget.widgetConfig.default_options.period_type !== 'week'
        ? DEFAULT_KPI_TABLE_WIDGET_CONFIG_MONTH
        : DEFAULT_KPI_TABLE_WIDGET_CONFIG_WEEK),
      ...transformKpiTableWidgetConfigToForm(widget.widgetConfig),
    },
  });
  const { control, watch, reset } = methods;

  useUpdateWidgetConfigEffect<
    KpiTableWidgetConfigForm,
    typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE
  >(methods, updateDefaultOptions);

  const date_picker_settings = watch('datePickerSetting');

  return (
    <FormProvider {...methods}>
      <FieldsContainer>
        <UpdateWidgetTitle />
        <Controller
          render={({ field }) => (
            <RadioGroup
              options={DATE_PICKER_SETTINGS_OPTIONS}
              value={field.value}
              onChange={(o) => {
                field.onChange(o.value);
                reset(
                  o.value === 'month'
                    ? DEFAULT_KPI_TABLE_WIDGET_CONFIG_MONTH
                    : DEFAULT_KPI_TABLE_WIDGET_CONFIG_WEEK,
                );
              }}
            />
          )}
          name="datePickerSetting"
          control={control}
        />
        {date_picker_settings === 'month' && <MonthFields />}
        <Field labelText="Period Shift">
          <PeriodShiftField control={control} name="period_shift" />
        </Field>
        <HideTotalField fieldType="row" control={control} name="hideTotalRow" />
        <GroupingTypeField
          availableGroupingTypes={['assets', 'segments']}
          control={control}
          name="groupingType"
        />
        <RecapPageField />
      </FieldsContainer>
    </FormProvider>
  );
};

export function KpiTableWidgetConfigComponent() {
  const { tab, thinTabGroupProps } = useTabs(TABS, TABS[0]);
  return (
    <div className="flex flex-col gap-tw-4">
      <ThinTabGroup fullWidth {...thinTabGroupProps} />
      <div className="p-tw-1">
        {tab?.id === 'basic' && <BasicTab />}
        {tab?.id === 'columns' && (
          <DefaultColumnsTab
            ColumnModal={KpiTableWidgetConfigColumnPanel}
            widgetType={EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE}
            actions={commonTableWidgetUpdaters}
          />
        )}
      </div>
    </div>
  );
}
