import useBoolean from '@/shared/lib/hooks/useBoolean';
import { IconButton } from 'stories/index';
import { MetricsSettingsPanel } from '@/entities/reconcile/forecasting/widgets/ForecastingBudgetMetricsSettingsModal';
import { ForecastingBudget } from '@/entities/reconcile/forecasting/api/reconcileForecastingEntitiesGeneratedApi';

export function ForecastingBudgetUpdateMetrics({
  budget,
}: {
  budget: ForecastingBudget;
}) {
  const {
    value: metricsPanel,
    setTrue: openMetricsPanel,
    setFalse: closeMetricsPanel,
  } = useBoolean();

  return (
    <>
      <div className="flex w-full items-center justify-between gap-tw-4">
        KPIs and Metrics
        <IconButton onClick={openMetricsPanel} iconName="settings" />
      </div>
      {metricsPanel && (
        <MetricsSettingsPanel budget={budget} onClose={closeMetricsPanel} />
      )}
    </>
  );
}
