import { ERP_SYSTEMS, ERPSystem } from '@/entities/erpsystem';

type SourceTypeSettings = {
  title: string;
  site: string;
  /**
   * @deprecated use ERPSystemIcon instead
   */
  image?: string;
};

export const SOURCE_TYPE_SETTINGS: Record<ERPSystem, SourceTypeSettings> = {
  [ERP_SYSTEMS.QUICKBOOKS_DESKTOP]: {
    title: 'Quickbooks Desktop',
    site: 'quickbooks.intuit.com/desktop',
  },
  [ERP_SYSTEMS.QUICKBOOKS_ONLINE]: {
    title: 'Quickbooks Online',
    site: 'quickbooks.intuit.com/online',
  },
  [ERP_SYSTEMS.YARDI]: {
    title: 'Yardi',
    site: 'www.yardi.com',
  },
  [ERP_SYSTEMS.ENTRATA]: {
    title: 'Entrata',
    site: 'www.entrata.com',
  },
  [ERP_SYSTEMS.M3]: {
    title: 'M3',
    site: 'www.m3as.com',
  },
  [ERP_SYSTEMS.MRI]: {
    title: 'MRI Software',
    site: 'www.mrisoftware.com',
  },
  [ERP_SYSTEMS.REALPAGE]: {
    title: 'Realpage',
    site: 'realpage.com',
  },
  [ERP_SYSTEMS.RESMAN]: {
    title: 'Resman',
    site: 'myresman.com',
  },
  [ERP_SYSTEMS.CYMA]: {
    title: 'CYMA',
    site: 'cyma.com',
  },
  [ERP_SYSTEMS.SAGE]: {
    title: 'Sage',
    site: 'www.sage.com',
  },
  [ERP_SYSTEMS.SYMMETRE]: {
    title: 'symmetRE',
    site: 'symmetre.com',
  },
  [ERP_SYSTEMS.NET_SUITE]: {
    title: 'NetSuite',
    site: 'netsuite.com',
  },
  [ERP_SYSTEMS.FORTRESS]: {
    title: 'Fortress',
    site: 'fortress.com',
  },
  [ERP_SYSTEMS.APP_FOLIO]: {
    title: 'AppFolio',
    site: 'appfolio.com',
  },
  [ERP_SYSTEMS.RENTEGI]: {
    title: 'Rentegi',
    site: 'rentegi.com',
  },
  [ERP_SYSTEMS.RASI]: {
    title: 'RASI',
    site: 'rasiusa.com',
  },
  [ERP_SYSTEMS.XERO]: {
    title: 'Xero',
    site: 'xero.com',
  },
  [ERP_SYSTEMS.RENT_MANAGER]: {
    title: 'Rent Manager',
    site: 'rentmanager.com',
  },
  [ERP_SYSTEMS.BUILDIUM]: {
    title: 'Buildium',
    site: 'buildium.com',
  },
  [ERP_SYSTEMS.PROPERTYWARE]: {
    title: 'Propertyware',
    site: 'propertyware.com',
  },
  [ERP_SYSTEMS.RENTVINE]: {
    title: 'Rentvine',
    site: 'rentvine.com',
  },
  [ERP_SYSTEMS.ZOHO]: {
    title: 'ZOHO',
    site: 'zoho.com',
  },
  [ERP_SYSTEMS.MICROSOFT_DYNAMICS_365]: {
    title: 'Microsoft Dynamics 365',
    site: 'microsoft.com',
  },
  [ERP_SYSTEMS.ACUMATICA]: {
    title: 'Acumatica',
    site: 'acumatica.com',
  },
  [ERP_SYSTEMS.INFOR]: {
    title: 'Infor',
    site: 'infor.com',
  },
  [ERP_SYSTEMS.RADIX]: {
    title: 'Radix',
    site: 'radix.com',
  },
  [ERP_SYSTEMS.ANYONE_HOME]: {
    title: 'Anyone Home',
    site: 'anyonehome.com',
  },
};
