import * as React from 'react';
import styles from './PathBarContainer.module.scss';
import { cn } from '@/shared/lib/css/cn';

function FlyBottomPanelContainer({
  children,
  className,
}: React.PropsWithChildren<PropsWithClassName>) {
  return <div className={cn(styles.barContainer, className)}>{children}</div>;
}

export default FlyBottomPanelContainer;
