import { RentRollChargeCode } from '@/bundles/REport/components/operational/Rentroll/types';
import { SETTINGS_REPORT_OPERATIONAL_DICTIONARY } from '@/pages/settings/report/operational/config';
import { SelectedEntityTags } from '@/pages/settings/report/operational/ui/SelectedEntityTags';
import { SourceChargeCode } from 'bundles/Shared/entities/sreChargeCodes';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { asserts } from 'lib/typeHelpers/assertsType';
import {
  Button,
  Modal,
  ModalActions,
  ModalHeaderWithSubtitle,
} from '@/stories';
import { useState } from 'react';
import { RadioGroup } from 'stories/RadioButton/RadioGroup';
import { SectionField } from 'stories/Field/FieldsWrappers';

export function BulkSetChargeCodeModal({
  sourceChargeCodes,
  onClose,
  onSubmit,
  sreChargeCodes,
}: {
  sourceChargeCodes: SourceChargeCode[];
  sreChargeCodes: {
    label: string;
    value: RentRollChargeCode;
  }[];
} & DialogProps<RentRollChargeCode>) {
  const [checkedChargeCode, setCheckedChargeCode] =
    useState<RentRollChargeCode | null>(null);

  const handleSubmit = () => {
    asserts(checkedChargeCode != null);
    onSubmit?.(checkedChargeCode);
  };

  return (
    <Modal
      header={<ModalHeaderWithSubtitle title="Set Charge Codes" />}
      size="400"
      actions={
        <ModalActions alignItems="space-between">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="success"
            onClick={handleSubmit}
            disabled={checkedChargeCode == null}
          >
            Set Unit Status
          </Button>
        </ModalActions>
      }
      classes={{
        body: 'gap-tw-4 flex flex-col',
      }}
      toggle={onClose}
    >
      <SelectedEntityTags
        items={sourceChargeCodes}
        itemName={
          SETTINGS_REPORT_OPERATIONAL_DICTIONARY.ChargeCodes.singularForm
        }
      />
      <SectionField labelText="sRE Charge Codes">
        <RadioGroup
          className="pl-tw-1"
          options={sreChargeCodes}
          value={checkedChargeCode}
          onChange={(o) => setCheckedChargeCode(o.value as RentRollChargeCode)}
        />
      </SectionField>
    </Modal>
  );
}
