import * as yup from 'yup';
import { ADJUSTMENT_EXPRESSION_SCHEMA } from 'bundles/Shared/widgets/dashboard/widgets/common/config';
import { HistoricalReviewTableWidgetConfigDto } from 'bundles/Shared/shared/api/dashboardsSettingsGeneratedApi';
import { TableVizConfigRow } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import {
  NUMERIC_ROW_SETTINGS_FORM_SCHEMA,
  SPARKLINE_ROW_SETTINGS_FORM_SCHEMA,
  TEXT_ROW_SETTINGS_FORM_SCHEMA,
  transformRowSettingsToForm,
  WidgetTableRowSettingsRowType,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/form';
import {
  DEFAULT_NUMBER_VALUE_DISPLAY_OPTIONS,
  DEFAULT_TEXT_VALUE_DISPLAY_OPTIONS,
} from '@/shared/lib/formatting/displayOptions';
import { TEXT_DISPLAY_STRATEGIES } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/TextDisplayStrategyField';
import { yupResolver } from '@hookform/resolvers/yup';

export const HISTORICAL_TABLE_WIDGET_CONFIG_NUMERIC_ROW_SCHEMA = yup
  .object()
  .shape({
    key: yup.number(),
    label: yup.string().required(),
    expression: yup.string().required(),
    adjustment: ADJUSTMENT_EXPRESSION_SCHEMA.nullable().optional(),
  })
  .concat(NUMERIC_ROW_SETTINGS_FORM_SCHEMA);

export const HISTORICAL_TABLE_WIDGET_CONFIG_TEXT_ROW_SCHEMA = yup
  .object()
  .shape({
    key: yup.number(),
    label: yup.string().required(),
    expression: yup.string().required(),
  })
  .concat(TEXT_ROW_SETTINGS_FORM_SCHEMA);

export const HISTORICAL_TABLE_WIDGET_CONFIG_SPARKLINE_ROW_SCHEMA = yup
  .object()
  .shape({
    key: yup.number(),
    label: yup.string().required(),
    expression: yup.string().required(),
    history_size: yup.number().required(),
  })
  .concat(SPARKLINE_ROW_SETTINGS_FORM_SCHEMA);

export const getHistoricalTableResolver = (
  rowType: WidgetTableRowSettingsRowType,
) => {
  if (rowType === 'sparkline') {
    return yupResolver(HISTORICAL_TABLE_WIDGET_CONFIG_SPARKLINE_ROW_SCHEMA);
  }
  if (rowType === 'numeric') {
    return yupResolver(HISTORICAL_TABLE_WIDGET_CONFIG_NUMERIC_ROW_SCHEMA);
  }
  return yupResolver(HISTORICAL_TABLE_WIDGET_CONFIG_TEXT_ROW_SCHEMA);
};
export type HistoricalTableWidgetSparklineRowForm = yup.InferType<
  typeof HISTORICAL_TABLE_WIDGET_CONFIG_SPARKLINE_ROW_SCHEMA
>;

export type HistoricalTableWidgetTextRowForm = yup.InferType<
  typeof HISTORICAL_TABLE_WIDGET_CONFIG_TEXT_ROW_SCHEMA
>;

export type HistoricalTableWidgetNumericRowForm = yup.InferType<
  typeof HISTORICAL_TABLE_WIDGET_CONFIG_NUMERIC_ROW_SCHEMA
>;

export type HistoricalTableWidgetRowForm =
  | HistoricalTableWidgetNumericRowForm
  | HistoricalTableWidgetTextRowForm
  | HistoricalTableWidgetSparklineRowForm;

export const DEFAULT_HISTORICAL_TABLE_WIDGET_ROW_FORM = {
  type: 'numeric',
  value_display_options: DEFAULT_NUMBER_VALUE_DISPLAY_OPTIONS,
} as const satisfies Partial<HistoricalTableWidgetNumericRowForm>;

export const DEFAULT_HISTORICAL_TABLE_WIDGET_TEXT_ROW_FORM = {
  type: 'text',
  value_display_options: DEFAULT_TEXT_VALUE_DISPLAY_OPTIONS,
  text_display_strategy: TEXT_DISPLAY_STRATEGIES.TEXT_DEFAULT,
} as const satisfies Partial<HistoricalTableWidgetTextRowForm>;

export const DEFAULT_HISTORICAL_TABLE_WIDGET_SPARKLINE_ROW_FORM = {
  type: 'sparkline',
  value_display_options: DEFAULT_NUMBER_VALUE_DISPLAY_OPTIONS,
} as const satisfies Partial<HistoricalTableWidgetSparklineRowForm>;

export const getHistoricalTableDefaultRowForm = (
  rowType: WidgetTableRowSettingsRowType,
) => {
  if (rowType === 'sparkline') {
    return DEFAULT_HISTORICAL_TABLE_WIDGET_SPARKLINE_ROW_FORM;
  }
  if (rowType === 'numeric') {
    return DEFAULT_HISTORICAL_TABLE_WIDGET_ROW_FORM;
  }
  return DEFAULT_HISTORICAL_TABLE_WIDGET_TEXT_ROW_FORM;
};

export type HistoricalReviewTableWidgetConfigRow =
  HistoricalReviewTableWidgetConfigDto['rows'][number];

export const transformRowConfigToForm = (
  rowConfig: HistoricalReviewTableWidgetConfigRow,
  rowSettings: TableVizConfigRow,
):
  | HistoricalTableWidgetNumericRowForm
  | HistoricalTableWidgetTextRowForm
  | HistoricalTableWidgetSparklineRowForm => {
  return {
    key: rowConfig.key,
    label: rowConfig.label,
    expression: rowConfig.expression,
    adjustment: rowConfig.adjustment_expression,
    type: rowConfig.type ?? 'numeric',
    history_size: rowConfig.history_size ?? 1,
    ...transformRowSettingsToForm(rowSettings),
  };
};
