import React from 'react';
import {
  ActionItem,
  GroupContainerItem,
  Icon,
  IconButton,
  Popover,
} from '@/stories';
import { ActionsContainer } from 'stories/Table/Actions/ActionsContainer';
import { DEFAULT_DROPDOWN_OFFSET } from 'stories/Popover/Popover';
import { cn } from '@/shared/lib/css/cn';
import { capitalize } from 'lodash-es';

import {
  ReportDashboardStatus,
  STATUS_OPTIONS_SETTINGS,
} from 'bundles/Shared/entities/dashboard';

const StatusActionItem = ({
  children,
  className,
  ...props
}: React.PropsWithChildren &
  React.ComponentProps<typeof ActionItem> &
  PropsWithClassName) => (
  <ActionItem
    className={cn(
      'group secondary-regular flex h-[24px] cursor-pointer flex-row items-center gap-tw-1 text-neutral-900',
      className,
    )}
    {...props}
  >
    {children}
  </ActionItem>
);

interface Props {
  value: ReportDashboardStatus | `${ReportDashboardStatus}`;
  onChange?: (status: ReportDashboardStatus) => void;
}

export function DashboardStatusDropdown({ value, onChange }: Props) {
  return (
    <Popover
      hideOnExpandedAreaClick
      offset={DEFAULT_DROPDOWN_OFFSET}
      placement="bottom-start"
      trigger="click"
      className="p-0"
      hiddenArrow
      template={
        <ActionsContainer>
          <GroupContainerItem title="Options">
            {Object.keys(STATUS_OPTIONS_SETTINGS).map(
              (status: ReportDashboardStatus) => (
                <StatusActionItem
                  className={cn(status === value && 'cursor-default')}
                  key={status}
                  onClick={
                    status != value ? () => onChange?.(status) : undefined
                  }
                >
                  <Icon
                    className={cn(
                      'text-neutral-450',
                      status === value
                        ? STATUS_OPTIONS_SETTINGS[status].color
                        : STATUS_OPTIONS_SETTINGS[status].hoverColor,
                    )}
                    iconName={STATUS_OPTIONS_SETTINGS[status].icon}
                  />
                  {capitalize(status)}
                </StatusActionItem>
              ),
            )}
          </GroupContainerItem>
        </ActionsContainer>
      }
    >
      {({ triggered }) => (
        <IconButton
          className={cn(
            STATUS_OPTIONS_SETTINGS[value].color,
            triggered &&
              '!border-neutral-999 !bg-neutral-999 !text-neutral-000',
          )}
          iconName={STATUS_OPTIONS_SETTINGS[value].icon}
        />
      )}
    </Popover>
  );
}
