import { cn } from '@/shared/lib/css/cn';
import {
  ColumnRowGroupChangedEvent,
  RowClassParams,
  RowHeightParams,
  RowNode,
} from 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-enterprise';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import GroupRowRenderer from 'bundles/Settings/components/REport/GeneralLedgers/Table/GroupRowRenderer';
import Header from 'bundles/Settings/components/REport/GeneralLedgers/Table/Header';
import LoadingTable from 'bundles/Shared/components/LoadingTable';
import { forwardRef, useState } from 'react';
import { AnimationLoader } from 'stories';

const DEFAULT_ROW_HEIGHT = 40;
const AG_GRID_WITH_GROUPING_CLASS = 'ag-with-groups';
const AG_GRID_WITH_CHECKBOX_CLASS = 'ag-with-checkbox-column';

export const groupHasDivider = (node: RowNode) =>
  node.hasChildren() && !node.firstChild && node.level === 0;

const getRowHeight = ({ node }: RowHeightParams) => {
  if (groupHasDivider(node)) return 70;

  if (node.hasChildren()) return 40;

  return DEFAULT_ROW_HEIGHT;
};
const getRowClass = ({ node }: RowClassParams) =>
  cn(
    'group',
    node.firstChild && 'ag-first-group-row',
    node.lastChild && 'ag-last-group-row',
    node.childrenAfterGroup?.some((n) => n.hasChildren()) &&
      'ag-group-has-children',
  );

interface Props extends AgGridReactProps, PropsWithClassName {
  loading: boolean;
  fetching: boolean;
}

const GeneralLedgersTable = forwardRef<AgGridReact, Props>(
  ({ loading, className, fetching, ...props }, ref) => {
    const [gridClasses, setGridClasses] = useState({});

    const groupChangedListener = (e: ColumnRowGroupChangedEvent) => {
      const groupedColumns = e.columnApi
        .getColumnState()
        .filter((c) => c.rowGroup);

      setGridClasses((prev) => ({
        ...prev,
        [AG_GRID_WITH_GROUPING_CLASS]: groupedColumns.length > 0,
      }));
    };

    return (
      <div
        className={cn(
          'ag-theme-light relative h-full w-full',
          className,
          AG_GRID_WITH_CHECKBOX_CLASS,
          gridClasses,
        )}
      >
        {loading && <LoadingTable rows={10} cols={7} />}
        {fetching && !loading && (
          <AnimationLoader
            withBg
            className="top-[40px] h-[calc(100%_-_40px)] rounded-[16px]"
          />
        )}
        {!loading && (
          <AgGridReact
            className="h-full w-full"
            loadingOverlayComponent={AnimationLoader}
            animateRows={false}
            suppressAnimationFrame
            getRowId={({ data }) => data.id}
            suppressMultiSort
            onGridReady={(e) => {
              ref(e);
              groupChangedListener(e);
            }}
            onColumnRowGroupChanged={groupChangedListener}
            groupSelectsChildren
            suppressContextMenu
            groupDefaultExpanded={-1}
            headerHeight={40}
            rowHeight={DEFAULT_ROW_HEIGHT}
            groupDisplayType="groupRows"
            autoGroupColumnDef={{
              headerName: 'Group 1',
              field: 'balanceType',
            }}
            defaultColDef={{
              suppressMovable: true,
              headerComponent: Header,
              // server side sorting
              comparator: () => 0,
            }}
            getRowClass={getRowClass}
            getRowHeight={getRowHeight}
            groupRowRenderer={GroupRowRenderer}
            {...props}
          />
        )}
      </div>
    );
  },
);

export default GeneralLedgersTable;
