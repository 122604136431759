import { COLUMN_CONFIGURATION_LABEL_INFO_TEXT } from '@/bundles/Shared/widgets/dashboard/widgets/common/config';
import { ColumnHeaderField } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/ColumnHeaderField';
import { TextDisplayStrategyField } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/fields/TextDisplayStrategyField';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { yupResolver } from '@hookform/resolvers/yup';
import { EAGLE_EYE_DASHBOARD_SECTION_TYPE } from 'bundles/Shared/entities/dashboard';
import {
  AdjustmentField,
  CreateNewForm,
  EditPanelActions,
  FormulaField,
  PeriodShiftField,
  TextVariableField,
  useUpdateWidgetConfig,
  useWidgetConfig,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import {
  NumericColumnSettingsFields,
  TextColumnSettingsFields,
} from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/ColumnSettingsFields';
import { TotalCalculationStrategyField } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/fields/TotalCalculationStrategyField';
import { TableVizConfigColumn } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/table/model';
import {
  DEFAULT_KPI_TABLE_WIDGET_CONFIG_NUMERIC_COLUMN,
  DEFAULT_KPI_TABLE_WIDGET_CONFIG_TEXT_COLUMN,
  KPI_TABLE_WIDGET_CONFIG_NUMERIC_COLUMN_SCHEMA,
  KPI_TABLE_WIDGET_CONFIG_TEXT_COLUMN_SCHEMA,
  KpiTableWidgetColumnType,
  KpiTableWidgetConfigColumn,
  KpiTableWidgetConfigNumericColumnForm,
  KpiTableWidgetConfigTextColumnForm,
  removeColumn,
  transformColumnConfigToForm,
  upsertColumn,
} from 'bundles/Shared/widgets/dashboard/widgets/kpiTable';
import { FormProvider, useForm } from 'react-hook-form';
import { Field, Input } from 'stories';
import { FieldsContainer } from 'stories/Field/Field';
import { SectionField } from 'stories/Field/FieldsWrappers';
import { SidePanel } from 'stories/Modals/Modal/Modal';

interface Props extends DialogProps<CreateNewForm> {
  type: 'create' | 'edit';
  columnType: KpiTableWidgetColumnType;
  columnConfig?: KpiTableWidgetConfigColumn;
  columnSettings?: TableVizConfigColumn;
  groupId?: string;
}

export function KpiTableWidgetConfigColumnPanel({
  type,
  columnConfig,
  columnSettings,
  columnType,
  groupId,
  onClose,
  onSubmit,
}: Props) {
  const resolver = yupResolver(
    columnType === 'numeric'
      ? KPI_TABLE_WIDGET_CONFIG_NUMERIC_COLUMN_SCHEMA
      : KPI_TABLE_WIDGET_CONFIG_TEXT_COLUMN_SCHEMA,
  );
  const methods = useForm<
    (
      | KpiTableWidgetConfigNumericColumnForm
      | KpiTableWidgetConfigTextColumnForm
    ) &
      CreateNewForm
  >({
    resolver,
    mode: 'all',
    defaultValues: {
      ...(columnType === 'numeric'
        ? DEFAULT_KPI_TABLE_WIDGET_CONFIG_NUMERIC_COLUMN
        : DEFAULT_KPI_TABLE_WIDGET_CONFIG_TEXT_COLUMN),
      ...(columnConfig &&
        columnSettings &&
        transformColumnConfigToForm(columnConfig, columnSettings)),
    },
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = methods;
  console.log(errors, isValid);
  const { widget } =
    useWidgetConfig<typeof EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE>();
  const [updateConfig] = useUpdateWidgetConfig(
    EAGLE_EYE_DASHBOARD_SECTION_TYPE.KPI_TABLE,
  );

  const handleRemove = () => {
    updateConfig({
      config: removeColumn(
        {
          columnKey: columnConfig!.key.toString(),
        },
        widget.widgetConfig,
      ),
    });
    onClose();
  };

  const handleSubmitClick = handleSubmit(async (values) => {
    const config = upsertColumn(values, widget.widgetConfig, { groupId });
    await updateConfig({ config });

    onSubmit?.({ createNew: values.createNew });
  });

  const renderLabelField = () => {
    return (
      <Field note={COLUMN_CONFIGURATION_LABEL_INFO_TEXT} labelText="Title">
        <Input
          placeholder="Enter Primary Title"
          className="w-full"
          {...register('label')}
        />
      </Field>
    );
  };

  const renderNumericColumnFields = () => {
    return (
      <>
        {renderLabelField()}
        <FormulaField control={control} name="expression" required />
        <AdjustmentField control={control} name="adjustment" />
        <SectionField labelText="Period Shift">
          <PeriodShiftField control={control} name="period_shift" />
        </SectionField>
        <TotalCalculationStrategyField
          control={control}
          name="total_calculation_strategy"
        />
        <NumericColumnSettingsFields
          columnsConfig={widget.widgetConfig.columns}
          vizConfig={widget.widgetConfig.viz_config}
          control={control}
        />
      </>
    );
  };

  const renderTextColumnFields = () => {
    return (
      <>
        {renderLabelField()}
        <TextVariableField control={control} name="expression" required />
        <SectionField labelText="Period Shift">
          <PeriodShiftField control={control} name="period_shift" />
        </SectionField>
        <TextDisplayStrategyField
          control={control}
          name="text_display_strategy"
        />
        <TextColumnSettingsFields control={control} />
      </>
    );
  };

  return (
    <FormProvider {...methods}>
      <SidePanel
        header={type === 'create' ? 'Create' : 'Edit'}
        toggle={onClose}
        actions={
          <EditPanelActions
            type={type}
            onClose={onClose}
            onSubmit={handleSubmitClick}
            onRemove={handleRemove}
          />
        }
      >
        <FieldsContainer>
          {columnType === 'numeric'
            ? renderNumericColumnFields()
            : renderTextColumnFields()}
          <ColumnHeaderField name="header" control={control} />
        </FieldsContainer>
      </SidePanel>
    </FormProvider>
  );
}
