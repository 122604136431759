import {
  settingsReportBuilderTemplatesGeneratedApi,
  type DeleteApiSettingsReportBuilderTemplatesByIdApiResponse,
  type PostApiSettingsReportBuilderTemplatesApiResponse,
  type PostApiSettingsReportBuilderTemplatesByIdDuplicateApiResponse,
  type PutApiSettingsReportBuilderTemplatesByIdApiResponse,
  type ReportBuilderTemplateGroupDto,
} from '@/entities/report/reportBuilder/api/settingsReportBuilderTemplatesGeneratedApi';
import { sortByPosition } from '@/shared/lib/listHelpers/toSorted';
import { EntityToastrCreator } from '@/shared/lib/toastr/entityToastrCreator';
import { arrayMoveImmutable } from 'array-move';

const entityToastr = new EntityToastrCreator('Report Builder Template');
const tagToastr = new EntityToastrCreator('Tag');

export const settingsReportBuilderTemplatesEnhancedApi =
  settingsReportBuilderTemplatesGeneratedApi.enhanceEndpoints({
    addTagTypes: [
      'allTemplates',
      'template',
      'meta:assets',
      'meta:tags',
      'widget:evaluate',
      'widget:preview',
      'widget:copyable_list_from_template',
      'widget:copyable_list_from_object_level_dashboard',
    ],
    endpoints: {
      /**
       * @deprecated use `getApiSettingsReportBuilderGotenbergTemplatesById` instead
       */
      getApiSettingsReportBuilderTemplatesById: {
        providesTags: ['template'],
      },
      getApiSettingsReportBuilderGotenbergTemplatesById: {
        providesTags: ['template'],
      },
      getApiSettingsReportBuilderTemplatesByTemplateIdCopyableWidgetSections: {
        providesTags: ['widget:copyable_list_from_template'],
      },
      postApiSettingsReportBuilderTemplatesByTemplateIdCopyableWidgetSectionsCopyToGroup:
        {
          invalidatesTags: ['widget:copyable_list_from_template', 'template'],
        },

      getApiSettingsReportBuilderTemplatesByTemplateIdObjectDashboardsCopyableWidgetSections:
        {
          providesTags: ['widget:copyable_list_from_object_level_dashboard'],
        },
      postApiSettingsReportBuilderTemplatesByTemplateIdObjectDashboardsCopyableWidgetSectionsCopyToGroup:
        {
          invalidatesTags: [
            'widget:copyable_list_from_object_level_dashboard',
            'template',
          ],
        },

      deleteApiSettingsReportBuilderTemplateTagsById: {
        invalidatesTags: ['meta:tags', 'allTemplates'],
        transformResponse: (
          response: DeleteApiSettingsReportBuilderTemplatesByIdApiResponse,
        ) => {
          toastr.success(tagToastr.delete().toString());
          return response;
        },
      },

      deleteApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsId:
        {
          invalidatesTags: ['template', 'widget:preview', 'widget:evaluate'],
        },
      deleteApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndId: {
        invalidatesTags: ['template', 'widget:preview', 'widget:evaluate'],
      },
      postApiSettingsReportBuilderTemplatesByTemplateIdGroups: {
        invalidatesTags: ['template', 'widget:preview', 'widget:evaluate'],
      },
      postApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSections:
        {
          invalidatesTags: ['template', 'widget:preview', 'widget:evaluate'],
        },
      putApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsId:
        {
          invalidatesTags: ['template', 'widget:preview', 'widget:evaluate'],
          onQueryStarted(patch, { dispatch, queryFulfilled }) {
            const widgetId = patch.id;
            const { groupId } = patch;
            const newPosition = patch.body.position;
            const newWidth = patch.body.width_percent;
            const newGroupId = patch.body.destination_group_id;

            const patchResult = dispatch(
              settingsReportBuilderTemplatesEnhancedApi.util.updateQueryData(
                'getApiSettingsReportBuilderTemplatesById',
                {
                  id: patch.templateId,
                },
                (draft) => {
                  const currentGroup = draft.groups.find(
                    (g) => g.id === groupId,
                  );
                  if (currentGroup == null) return;

                  const currentWidget = currentGroup.widgetSections.find(
                    (w) => w.id === widgetId,
                  );

                  if (currentWidget == null) return;

                  let resultGroups: ReportBuilderTemplateGroupDto[] = [];

                  if (newGroupId == null) {
                    let newGroupWidgets = [...currentGroup.widgetSections];

                    if (newPosition != null) {
                      newGroupWidgets = arrayMoveImmutable(
                        currentGroup.widgetSections,
                        currentWidget.position.y,
                        newPosition,
                      );
                    }

                    newGroupWidgets = newGroupWidgets.map((w, wIdx) => {
                      return {
                        ...w,
                        position: {
                          ...w.position,
                          w:
                            newWidth != null && w.id === widgetId
                              ? newWidth
                              : w.position.w,
                          y: wIdx,
                        },
                      };
                    });

                    const newGroup = {
                      ...currentGroup,
                      widgetSections: newGroupWidgets,
                    };

                    resultGroups = draft.groups.map((g) => {
                      if (g.id !== groupId) return g;

                      return newGroup;
                    });
                  } else {
                    resultGroups = draft.groups.map((g) => {
                      if (g.id === currentGroup.id) {
                        return {
                          ...g,
                          widgetSections: g.widgetSections.filter(
                            (w) => w.id !== widgetId,
                          ),
                        };
                      }

                      if (g.id === newGroupId) {
                        return {
                          ...g,
                          widgetSections: g.widgetSections.toSpliced(
                            newPosition ?? currentWidget.position.y,
                            0,
                            currentWidget,
                          ),
                        };
                      }
                      return g;
                    });
                  }

                  Object.assign(draft, {
                    groups: resultGroups,
                  });
                },
              ),
            );
            queryFulfilled.catch(patchResult.undo);
          },
        },
      putApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndId: {
        invalidatesTags: ['template', 'widget:preview', 'widget:evaluate'],
        onQueryStarted(patch, { dispatch, queryFulfilled }) {
          const groupId = patch.id;
          const newPosition = patch.body.position;
          const newPageOrientation = patch.body.page_orientation;

          if (newPosition == null || newPageOrientation == null) return;

          const patchResult = dispatch(
            settingsReportBuilderTemplatesEnhancedApi.util.updateQueryData(
              'getApiSettingsReportBuilderTemplatesById',
              {
                id: patch.templateId,
              },
              (draft) => {
                const currentGroup = draft.groups.find((g) => g.id === groupId);
                if (currentGroup == null) return;

                const newGroups = arrayMoveImmutable(
                  draft.groups.toSorted(sortByPosition),
                  currentGroup.position,
                  newPosition,
                ).map((g, gIdx) => {
                  if (g.id === groupId) {
                    return {
                      ...g,
                      position: gIdx,
                      pageOrientation: newPageOrientation,
                    };
                  }
                  return { ...g, position: gIdx };
                });

                Object.assign(draft, {
                  groups: newGroups,
                });
              },
            ),
          );
          queryFulfilled.catch(patchResult.undo);
        },
      },

      postApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsPreview:
        {
          providesTags: ['widget:preview', 'widget:evaluate'],
        },

      getApiSettingsReportBuilderTemplates: {
        providesTags: ['allTemplates'],
      },

      deleteApiSettingsReportBuilderTemplatesById: {
        invalidatesTags: ['allTemplates'],
        transformResponse: (
          response: DeleteApiSettingsReportBuilderTemplatesByIdApiResponse,
        ) => {
          toastr.success(entityToastr.delete().toString());
          return response;
        },
      },

      postApiSettingsReportBuilderTemplates: {
        invalidatesTags: ['allTemplates'],
        transformResponse: (
          response: PostApiSettingsReportBuilderTemplatesApiResponse,
        ) => {
          toastr.success(entityToastr.create().toString());
          return response;
        },
      },

      postApiSettingsReportBuilderTemplatesByIdDuplicate: {
        invalidatesTags: ['allTemplates'],
        transformResponse: (
          response: PostApiSettingsReportBuilderTemplatesByIdDuplicateApiResponse,
        ) => {
          toastr.success(entityToastr.duplicate().toString());
          return response;
        },
      },

      putApiSettingsReportBuilderTemplatesById: {
        invalidatesTags: ['template', 'allTemplates'],
        transformResponse: (
          response: PutApiSettingsReportBuilderTemplatesByIdApiResponse,
        ) => {
          toastr.success(entityToastr.update().toString());
          return response;
        },
      },

      getApiSettingsReportBuilderTemplatesMeta: {
        providesTags: ['meta:assets', 'meta:tags'],
      },

      postApiSettingsReportBuilderGotenbergWidgetSectionsByIdEvaluate: {
        providesTags: ['widget:evaluate'],
      },
    },
  });

export const {
  // Template editor
  useGetApiSettingsReportBuilderGotenbergTemplatesByIdQuery,
  usePutApiSettingsReportBuilderTemplatesByIdMutation,

  // Template editor (Group actions)
  useDeleteApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndIdMutation,
  usePutApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndIdMutation,
  usePostApiSettingsReportBuilderTemplatesByTemplateIdGroupsMutation,

  // Template editor (Widget Section actions)
  useGetApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdQuery,
  useDeleteApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdMutation,
  usePutApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsIdMutation,
  usePostApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsMutation,
  usePostApiSettingsReportBuilderTemplatesByTemplateIdGroupsAndGroupIdWidgetSectionsPreviewQuery,

  // Template view page
  usePostApiSettingsReportBuilderGotenbergWidgetSectionsByIdEvaluateQuery,

  // Template list
  useGetApiSettingsReportBuilderTemplatesQuery,
  useDeleteApiSettingsReportBuilderTemplatesByIdMutation,
  usePostApiSettingsReportBuilderTemplatesByIdDuplicateMutation,
  usePostApiSettingsReportBuilderTemplatesMutation,

  // Template Meta
  useGetApiSettingsReportBuilderGotenbergTemplatesMetaQuery,

  // Template Tags
  useDeleteApiSettingsReportBuilderTemplateTagsByIdMutation,

  // Copyable widgets
  useGetApiSettingsReportBuilderTemplatesByTemplateIdCopyableWidgetSectionsQuery,
  useGetApiSettingsReportBuilderTemplatesByTemplateIdObjectDashboardsCopyableWidgetSectionsQuery,
  usePostApiSettingsReportBuilderTemplatesByTemplateIdCopyableWidgetSectionsCopyToGroupMutation,
  usePostApiSettingsReportBuilderTemplatesByTemplateIdObjectDashboardsCopyableWidgetSectionsCopyToGroupMutation,
} = settingsReportBuilderTemplatesEnhancedApi;
