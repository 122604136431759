import { SearchableTree } from '@/bundles/Settings/components/REport/CategoriesList/SearchableTree/SearchableTree';
import { DataNodeAndExtraProps } from '@/bundles/Settings/components/REport/CategoriesList/SearchableTree/utils';
import { OBJECT_DASHBOARD_SECTION_TYPE } from '@/bundles/Shared/entities/dashboard';
import {
  useUpdateWidgetConfig,
  useWidgetConfig,
} from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/config';
import { useGetApiSettingsReportRentRollUnitTypesTreeQuery } from '@/entities/report/unitTypes/api';
import { ReportRentRollUnitTypeTreeDto } from '@/entities/report/unitTypes/api/settingsReportOperationalUnitTypesGeneratedApi';
import { transformTree } from '@/lib/treeHelpers';
import { clipboardWriteText } from '@/shared/lib/browser/clipboardApi';
import { DialogProps } from '@/shared/lib/hooks/useModal';
import { JSONEditor } from '@/shared/ui/CodeEditor/component';
import {
  AnimationLoader,
  IconButton,
  Modal,
  Button,
  Dropdown,
  DropdownItem,
} from '@/stories';
import { produce } from 'immer';
import { useCallback, useEffect, useMemo, useState } from 'react';

const transformNodeToConfigurationObject = (
  node: ReportRentRollUnitTypeTreeDto[number],
) => ({
  type: 'unit_type',
  unit_type_path: node.path,
});

const transformTreeToConfigurationObject = (
  tree: ReportRentRollUnitTypeTreeDto[number],
) => {
  return transformTree(tree, (node) => ({
    ...transformNodeToConfigurationObject(node),
    children: node.children?.length ? node.children : undefined,
  }));
};

export const SearchableUnitTypeTree = () => {
  const { data } = useGetApiSettingsReportRentRollUnitTypesTreeQuery();
  const preprocessedTree = useMemo(
    () =>
      transformTree({ children: data?.tree ?? [] }, (node) => ({
        ...node,
        title: node.path,
      })).children,
    [data?.tree],
  );
  const itemActions = useCallback((item: DataNodeAndExtraProps) => {
    const handleCopyUnitType = () => {
      clipboardWriteText(
        JSON.stringify(transformNodeToConfigurationObject(item), null, 2),
      );
    };

    const handleCopyTreeBranch = () => {
      clipboardWriteText(
        JSON.stringify(transformTreeToConfigurationObject(item), null, 2),
      );
    };

    return (
      <Dropdown
        maxWidth="max-content"
        appendTo={document.body}
        items={
          <>
            <DropdownItem onClick={handleCopyUnitType}>
              Copy Current Unit Type
            </DropdownItem>
            <DropdownItem onClick={handleCopyTreeBranch}>
              Copy Tree Branch
            </DropdownItem>
          </>
        }
      >
        <IconButton iconName="more" />
      </Dropdown>
    );
  }, []);

  return (
    <SearchableTree
      itemActions={itemActions}
      readonly
      data={preprocessedTree}
    />
  );
};

export const UnitTypeWidgetConfigRowsJsonEditor = () => {
  const { data } = useGetApiSettingsReportRentRollUnitTypesTreeQuery();
  const { widget } =
    useWidgetConfig<typeof OBJECT_DASHBOARD_SECTION_TYPE.UNIT_TYPE_TABLE>();
  const [updateConfig] = useUpdateWidgetConfig(
    OBJECT_DASHBOARD_SECTION_TYPE.UNIT_TYPE_TABLE,
  );
  const stringifiedWidgetConfig = useMemo(
    () =>
      widget.widgetConfig.rows
        ? JSON.stringify(widget.widgetConfig.rows, null, 2)
        : null,
    [widget.widgetConfig.rows],
  );
  const [widgetConfig, setWidgetConfig] = useState(stringifiedWidgetConfig);
  const isDirty = widgetConfig !== stringifiedWidgetConfig;

  useEffect(() => {
    setWidgetConfig(stringifiedWidgetConfig);
  }, [stringifiedWidgetConfig]);

  const handleSave = () => {
    updateConfig({
      config: produce(widget.widgetConfig, (draft) => {
        draft.rows = JSON.parse(widgetConfig);
      }),
    });
  };

  const handleReset = () => {
    updateConfig({
      config: produce(widget.widgetConfig, (draft) => {
        draft.rows = undefined;
      }),
    });
  };

  const handleInsertFullTree = () => {
    setWidgetConfig(
      JSON.stringify(
        transformTreeToConfigurationObject({
          children: data?.tree ?? [],
        }).children,
        null,
        2,
      ),
    );
  };

  return (
    <div className="flex grow flex-col gap-tw-4">
      <div className="flex gap-tw-2">
        <Button disabled={!isDirty} onClick={handleSave} variant="success">
          Save Updates
        </Button>
        <Button onClick={handleInsertFullTree} variant="secondary">
          Insert Full Tree
        </Button>
        <Button
          disabled={widget.widgetConfig.rows == null}
          onClick={handleReset}
          variant="secondary"
        >
          Reset
        </Button>
      </div>
      <JSONEditor
        height="100%"
        onChange={(value) => {
          setWidgetConfig(value);
        }}
        value={widgetConfig}
      />
    </div>
  );
};

export const UnitTypeWidgetConfigRowsModal = (props: DialogProps) => {
  const { isLoading } =
    useWidgetConfig<typeof OBJECT_DASHBOARD_SECTION_TYPE.UNIT_TYPE_TABLE>();

  return (
    <Modal
      toggle={props.onClose}
      size="huge"
      header="Edit Unit Type Tree for widget"
    >
      <div className="grid grid-cols-2">
        {!isLoading && <SearchableUnitTypeTree />}
        {isLoading && <AnimationLoader />}
        <UnitTypeWidgetConfigRowsJsonEditor />
      </div>
    </Modal>
  );
};
