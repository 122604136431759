import { ChartContainer } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/ChartContainer';
import { useWidgetFullScreen } from '@/bundles/Shared/widgets/dashboard/widgets/common/ui/WidgetFullScreen';
import { cn } from '@/shared/lib/css/cn';
import { getAmchartNumberFormatByDisplayOptions } from '@/shared/lib/formatting/charts';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import * as am5plugins_json from '@amcharts/amcharts5/plugins/json';
import am5themesAnimated from '@amcharts/amcharts5/themes/Animated';
import { AxisRenderer, DateAxis, XYChart } from '@amcharts/amcharts5/xy';
import {
  WidgetDateGranularity,
  XyChartWidgetDto,
} from 'bundles/Shared/entities/dashboard';
import {
  DasbhoardWidgetChartCard,
  DashboardWidgetCard,
  DateRangeWidgetState,
  WidgetStateGranularity,
} from 'bundles/Shared/widgets/dashboard/widgets/common';
import { ExportChartButton } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/ExportChartButton';
import { WidgetStateCalendarRangeSelector } from 'bundles/Shared/widgets/dashboard/widgets/common/ui/state/WidgetStateCalendarRangeSelector';
import {
  WidgetProps,
  WidgetStateProps,
} from 'bundles/Shared/widgets/dashboard/widgets/model';
import { XYChartWidgetSection } from 'bundles/Shared/widgets/dashboard/widgets/xyChart/model';
import {
  addExportToChart,
  buildDataFieldsFromSeries,
} from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/lib/export';
import { useAmchart } from 'lib/amcharts/useAmchart';
import {
  chartDateMapper,
  COLOR_SET,
  getReturnDashboardTheme,
} from 'lib/amcharts/utils';
import { useRef } from 'react';
import AnimationLoader from 'stories/AnimationLoader/AnimationLoader';

import { hasSeriesRef } from 'bundles/Shared/widgets/dashboard/widgets/xyChartSingleKpi/lib/common';
import RecapPageButton from '@/bundles/Shared/components/RecapPageButton';
import { useWidgetFlags } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/useWidgetFlags';
import { getDefaultBaseIntervalForGranularity } from '@/bundles/Shared/widgets/dashboard/widgets/common/lib/utils';
import { replaceDateLabelsWithQuarters } from '@/bundles/Shared/widgets/dashboard/widgets/xyChart/lib';

export type XYChartWidgetState = DateRangeWidgetState & {
  granularity: WidgetDateGranularity;
};

export function XYChartWidget(
  props: WidgetProps<XyChartWidgetDto, XYChartWidgetSection> &
    WidgetStateProps<XYChartWidgetState>,
) {
  const {
    data,
    widgetSection,
    state,
    onStateChange,
    className,
    mode,
    context,
  } = props;
  const { shouldDisplayData, shouldDisplayLoader } = useWidgetFlags(props);
  const ref = useRef(null);
  const wrapperDivRef = useRef<HTMLDivElement>(null);
  const widgetStateFullScreenFeature = useWidgetFullScreen(wrapperDivRef);
  const chartRef = useRef<
    | (XYChart & {
        exporting: am5plugins_exporting.Exporting;
      })
    | null
  >(null);
  useAmchart(
    ref,
    (root) => {
      if (!data) {
        return;
      }
      const myTheme = getReturnDashboardTheme(root);
      myTheme.rule('ColorSet').set('colors', COLOR_SET);
      root.setThemes([am5themesAnimated.new(root), myTheme]);

      const parser = am5plugins_json.JsonParser.new(root);
      const config = widgetSection.widgetConfig.am_chart_config;
      const mappedData = data.items
        .map((item) => ({
          ...item,
          ...item.values,
        }))
        .map(chartDateMapper('dateFrom'));
      const dataRef = {
        data: mappedData,
      };
      parser
        .parse(
          {
            ...config,
            refs: [dataRef, ...config.refs],
          },
          {
            parent: root.container,
          },
        )
        .then(function (chart: XYChart) {
          // Chart is ready
          chartRef.current = chart;

          const firstKpi = widgetSection.widgetConfig.kpis?.at(0);
          if (firstKpi && hasSeriesRef(config)) {
            const yAxes = chart.yAxes.getIndex(0)!;
            yAxes
              .getNumberFormatter()
              .set(
                'numberFormat',
                getAmchartNumberFormatByDisplayOptions(
                  firstKpi.value_display_options!,
                ),
              );
          }

          if (mode === 'pdf') {
            chart.set('scrollbarX', undefined);
            chart.set('scrollbarY', undefined);
          }
          const dateAxis = chart.xAxes.getIndex(0) as DateAxis<AxisRenderer>;
          dateAxis.set(
            'baseInterval',
            getDefaultBaseIntervalForGranularity(state.granularity ?? 'month'),
          );
          dateAxis.set('periodChangeDateFormats', {
            month: 'YYYY',
          });

          if (state.granularity === 'quarter') {
            replaceDateLabelsWithQuarters(dateAxis, state);
          }
          if (mode !== 'pdf') {
            chart.series.getIndex(0)?.appear(1000);
            chart.appear(1000, 100);
          }

          const { exportingSettings, onWorkbookReady } = addExportToChart({
            widgetConfig: widgetSection.widgetConfig,
            widgetTitle: widgetSection.title,
            widgetId: widgetSection.id,
            state,
            data: mappedData,
            granularity: state.granularity,
            dataFields: buildDataFieldsFromSeries(chart.series.values),
            context,
          });
          chartRef.current!.exporting = am5plugins_exporting.Exporting.new(
            root,
            exportingSettings,
          );
          chartRef.current!.exporting.events.on(
            'workbookready',
            onWorkbookReady,
          );
        });
    },
    [data],
  );

  return (
    <DasbhoardWidgetChartCard
      {...props}
      ref={wrapperDivRef}
      className={cn(
        className,
        mode === 'pdf' && 'break-inside-avoid rounded-[8px] shadow-none',
      )}
    >
      {mode === 'pdf' && (
        <DashboardWidgetCard.PDFHeader>
          {widgetSection.title}
        </DashboardWidgetCard.PDFHeader>
      )}
      {mode !== 'pdf' && (
        <DashboardWidgetCard.Header>
          <div className="flex">
            <DashboardWidgetCard.Header.Title>
              {widgetSection.title}
            </DashboardWidgetCard.Header.Title>
            <RecapPageButton
              name={widgetSection.recapPage?.name}
              slug={widgetSection.recapPage?.slug}
            />
          </div>
          <div className="flex items-center gap-tw-2">
            <WidgetStateGranularity
              state={state}
              onStateChange={onStateChange}
              chartRef={chartRef}
              granularities={
                widgetSection.widgetConfig.default_options?.granularities
              }
            />
            <WidgetStateCalendarRangeSelector
              state={state}
              onStateChange={onStateChange}
            />
            <div className="grow" />
            <ExportChartButton
              chartRef={chartRef}
              widgetSectionId={widgetSection.id}
            />
            <widgetStateFullScreenFeature.IconButton />
          </div>
        </DashboardWidgetCard.Header>
      )}
      {shouldDisplayLoader && <AnimationLoader />}
      {shouldDisplayData && <ChartContainer ref={ref} />}
    </DasbhoardWidgetChartCard>
  );
}
