import * as React from 'react';
import { cn } from '@/shared/lib/css/cn';
import { Icon } from 'stories';
import styles from './EntitiesCounter.module.scss';

interface Props extends PropsWithClassName {
  totalCount: number;
  selectedCount: number;
  disabled?: boolean;
}

function EntitiesCounter({
  totalCount,
  selectedCount,
  disabled,
  className,
}: Props) {
  return (
    <div
      className={cn(
        styles.entitiesCounter,
        {
          [styles.entitiesCounter_changed]: totalCount !== selectedCount,
          [styles.entitiesCounter_disabled]: disabled,
        },
        className,
      )}
    >
      <Icon className="mr-xs" iconName="entities" />
      {totalCount !== selectedCount ? `${selectedCount} of ` : null}
      {totalCount}
    </div>
  );
}

export default EntitiesCounter;
